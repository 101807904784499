import React from 'react';
import { Popover, Box, IconButton } from '@material-ui/core';
import { Heading4 } from '../typography';
import { CloseGrey } from '../Icons';
import { ButtonSecondary, ButtonPrimary } from '../buttons';

export default function FormPopover({
  onSubmit,
  onCancel,
  children,
  open = false,
  anchorEl,
  disabled = false,
  formTitle = '',
  PaperProps,
  anchorOrigin,
  isSubmitting = false,
}) {
  return (
    <Popover
      open={anchorEl && open}
      anchorEl={anchorEl}
      PaperProps={PaperProps}
      anchorOrigin={anchorOrigin}
      elevation={0}
      onClose={onCancel}
    >
      {open && (
        <Box minWidth="350px">
          <Box
            width={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX={1.8}
            paddingY={2}
          >
            <Heading4>{formTitle}</Heading4>
            <IconButton edge="end" disabled={disabled} onClick={onCancel}>
              <CloseGrey compact />
            </IconButton>
          </Box>
          <Box
            width={1}
            border="none"
            display="flex"
            flexGrow={1}
            paddingX={1.8}
          >
            {children}
          </Box>
          <Box
            width={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            paddingX={1.8}
            paddingY={1}
          >
            <ButtonSecondary
              size="medium"
              capitalize
              onClick={onCancel}
              disabled={isSubmitting}
              style={{ marginRight: 4 }}
            >
              Cancel
            </ButtonSecondary>
            <ButtonPrimary onClick={onSubmit} size="medium" disabled={disabled}>
              {isSubmitting ? 'Applying filters...' : 'Apply'}
            </ButtonPrimary>
          </Box>
        </Box>
      )}
    </Popover>
  );
}
