/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getLearnerId } from 'src/store/authentication/selectors';
import config from 'src/store/config';
import { getEnrolledCourses } from 'src/store/enrolledCourses/selectors';
import { CourseSchema } from 'src/types';
import {
  getModFromCourseId,
  objIsEmpty,
  stringifyError,
  useRouter,
} from 'src/utils';
import { Back, ButtonText, DSLButton } from 'src/views/components/DSL/buttons';
import ButtonTextDestructive from 'src/views/components/DSL/buttons/ButtonTextDestructive';
import { CheckGreen, MinusCircleOrange } from 'src/views/components/DSL/Icons';
import { TransitionAlert } from 'src/views/components/DSL/info';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Heading1 } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import EmptyResults from '../../shared/Filters/EmptyResults';
import BlockEnrolledCourseInfo from '../components/BlockEnrolledCourseInfo';
import { CourseSchedItem } from '../components/CourseSchedItem';
import { handleUnenroll } from '../components/handleUnenroll';
import { loadRegistrations } from '../components/loadRegistrations';
import { SessionSchedItem } from '../components/SessionSchedItem';

export interface Registration {
  registrationid?: number;
  status?: number;
  sessionid?: number;
  text?: string;
  begins?: number;
  ends?: number;
  description?: string;
  address?: string;
  courseid?: number;
  location?: string;
  institution?: string;
  zoomlink?: string;
}
interface QueryType {
  courseId?: string;
  enrollmentId?: string;
  courseIdNumber?: string;
}

export type RegistrationList = Registration[];

/**
 * FIXME: name state variables better, registration should
 * be renamed to unenrollqueue because not every unenroll
 * action involves a registration record (e.g. ONL courses)
 * line 52 - a.h.
 */
export default function UnenrollContainer() {
  const isMobile = useBreakpoint('xs');

  const [showConfirmation, setshowConfirmation] = useState(false);
  const [error, setError] = React.useState<boolean | string>(false);
  const [mod, setmod] = useState('');
  const [registrations, setRegistrations] = useState<RegistrationList>([]);
  const [onlRegistration, setONLRegistration] = useState<CourseSchema>();
  const learnerId = useSelector(getLearnerId);
  const router = useRouter();
  const enrollments = useSelector(getEnrolledCourses);

  const queryParams: QueryType = router.query;
  const courseId = queryParams.courseId;
  const enrollmentId = queryParams.enrollmentId;
  const courseIdNumber = queryParams.courseIdNumber;

  useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }

    const loadONL = async (id: any) => {
      // eslint-disable-next-line no-empty
      try {
        // eslint-disable-next-line no-empty

        // console.log(id, enrollments);
        return enrollments?.byIdNumbers?.[id]?.course;
      } catch (error) {
        // console.log(error);
        return error;
      }
    };

    if (learnerId && courseIdNumber) {
      const modType = getModFromCourseId(courseIdNumber);
      setmod(modType);

      if (modType === config.MODALITY_TYPES.ONL) {
        // const singleCourse = getCourseToSessoinBlock(enrollments);
        loadONL(enrollmentId)
          .then((res) => {
            // console.log(res);
            if (!objIsEmpty(res)) {
              setONLRegistration(res);
            }
          })
          .catch((err) => {
            // console.log(err);

            setONLRegistration(null);
            setError(stringifyError(err) || 'Unable to load registrations');
          });
      } else if (
        modType === config.MODALITY_TYPES.ILT ||
        modType === config.MODALITY_TYPES.WEB
      ) {
        loadRegistrations({
          learnerId,
          courseId,
          courseIdNumber,
          enrollmentId,
        })
          .then((data) => {
            // console.log(data);
            setRegistrations(data);

            setError(false);
          })
          .catch((error) => {
            setRegistrations([]);

            setError(stringifyError(error) || 'Unable to load registrations');
          });
      } else {
        setRegistrations([]);

        setError(stringifyError(error) || 'Unable to load registrations');
      }
    }

    return () => {
      unmounted = true;
    };
  }, [queryParams]);

  const unenrollReq = () => {
    if (!learnerId || !courseId || !courseIdNumber) {
      setError('Unable to unenroll from course');
      return;
    }
    setshowConfirmation(true);
  };

  const unenrollConfirm = () => {
    handleUnenroll({
      learnerId,
      courseCode: courseIdNumber,
      onSuccess: (res) => {
        // console.log(res);
        setshowConfirmation(false);
        router.push(
          `${router.location.pathname}${
            router.location.search
          }&success=${true}&refresh=${true}`
        );
      },
      onError: (error) => {
        setError(error || 'Unable to unenroll from course');
      },
    });
  };

  const successFullUnenroll = router.location.search.includes('success');
  const courseName =
    enrollments?.byIdNumbers?.[enrollmentId]?.course?.fullname || '';

  return (
    <>
      {/*  */}
      {successFullUnenroll ? (
        <Dialog open={successFullUnenroll} fullScreen fullWidth>
          <DialogContent style={{ padding: isMobile ? 16 : '8px 160px' }}>
            <DialogContentText id="alert-dialog-description">
              <Grid
                direction="column"
                justify="space-around"
                alignItems="flex-start"
                style={{
                  padding: isMobile ? 0 : '48px 100px',
                  backgroundColor: '#ffff',
                }}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ padding: 16 }}
                >
                  <IconButton>
                    <CheckGreen fontSize={'large'} />
                  </IconButton>
                  <br />
                  <Heading1>Unenrolled from Course</Heading1>
                  {/* <Subheading>
                    {courseName
                      ? `You have been unenrolled from ${courseName}`
                      : 'Successfully unenrolled'}
                  </Subheading> */}
                  <br />
                  <DSLButton
                    onClick={() => {
                      router.push('/coursecatalog?refresh=true');
                    }}
                    fullWidth={isMobile ? true : false}
                    variant="contained"
                    color="secondary"
                    style={{ padding: 16 }}
                  >
                    Enroll in Courses
                  </DSLButton>
                  <DSLButton
                    onClick={() => {
                      router.push('/mycourses/enrolled?refresh=true');
                    }}
                    fullWidth={isMobile ? true : false}
                    color="secondary"
                    style={{ padding: 16 }}
                  >
                    Go to My Courses
                  </DSLButton>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}

      {/* Confirmation Dialog */}
      {showConfirmation ? (
        <Dialog
          open={showConfirmation}
          onClose={() => {
            setshowConfirmation(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to unenroll?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {courseName.includes('Module 1, Basic Training 70') === true
                ? `You are ${
                    courseName
                      ? 'unenrolling from Union Time and ' + courseName
                      : 'about to unenroll.'
                  }`
                : `You are ${
                    courseName
                      ? 'unenrolling from ' + courseName
                      : 'about to unenroll.'
                  }`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonText
              capitalize
              onClick={() => setshowConfirmation(false)}
              color="primary"
            >
              Cancel
            </ButtonText>
            <Button
              variant="contained"
              onClick={unenrollConfirm}
              style={{
                backgroundColor: '#d33c08',
                textTransform: 'capitalize',
                color: '#fff',
              }}
            >
              Unenroll
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {/* Enroll content */}
      <Dialog
        open
        fullScreen
        maxWidth="md"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#f7f8f9',
            boxShadow: 'none',
          },
        }}
      >
        <DialogTitle
          disableTypography
          style={{ padding: isMobile ? 16 : '24px 100px' }}
        >
          <Back to="/mycourses/enrolled">Enrolled Courses</Back>
        </DialogTitle>
        <DialogContent
          dividers={false}
          style={{ padding: isMobile ? 16 : '0px 295px' }}
        >
          {error && (
            <TransitionAlert
              message="Unable to unenroll from course"
              severity="error"
            />
          )}
          {mod === config.MODALITY_TYPES.ONL && onlRegistration ? (
            <CourseSchedItem {...onlRegistration} />
          ) : registrations.length ? (
            registrations[0].description?.includes(
              'Module 1, Basic Training 70'
            ) ? (
              registrations.map((reg: Registration, k: number) => {
                return <BlockEnrolledCourseInfo key={k} {...reg} />;
              })
            ) : (
              registrations.map((reg: Registration, k: number) => {
                return <SessionSchedItem key={k} {...reg} />;
              })
            )
          ) : (
            <EmptyResults text="No classes scheduled" />
          )}
          <Box my={1} width="100%">
            <ButtonTextDestructive
              capitalize
              startIcon={<MinusCircleOrange compact />}
              onClick={unenrollReq}
              fullWidth={isMobile ? true : false}
            >
              Unenroll
            </ButtonTextDestructive>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
