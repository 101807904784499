import { Card, CardContent, Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from 'src/consts';
import { theme } from 'src/views/components/DSL';
import { Descriptor, Subheading } from 'src/views/components/DSL/typography';
import ProgressWheel from './ProgressWheel';

// NOTE: added utc() because it was off by one day
const DueDateBadge = ({ duedate = '' }) => (
  <span
    style={{
      padding: '2px 15px',
      backgroundColor: '#ebe1fb',
      border: `1px solid ${theme.palette.primary[200]}`,
      borderRadius: 25,
      fontSize: 11,
      zIndex: 200,
      color: theme.palette.primary.dark,
      fontWeight: 'bolder',
    }}
  >
    {duedate
      ? `DUE ${moment(duedate).utc().format('MMM DD, YYYY').toUpperCase()}`
      : 'No due date'}
  </span>
);

function StatusLabels({ needToEnroll, earnedHours, enrolledHours }) {
  const labelBaseStyles: React.CSSProperties = {
    fontWeight: 'bolder',
    marginRight: 10,
  };
  return (
    <>
      {needToEnroll >= 0 && (
        <Descriptor display="inline" style={{ ...labelBaseStyles }}>
          <span style={{ color: '#e2e5e9', fontSize: 15 }}>&#8226;</span> Need
          to enroll{' '}
          <span style={{ fontWeight: 'lighter' }}>{needToEnroll}</span>
        </Descriptor>
      )}
      {enrolledHours >= 0 && (
        <Descriptor
          display="inline"
          style={{
            ...labelBaseStyles,
            color: '#2a8639',
          }}
        >
          <span style={{ color: '#c4e6a3', fontSize: 15 }}>&#8226;</span>{' '}
          Enrolled{' '}
          <span style={{ fontWeight: 'lighter' }}>{enrolledHours}</span>
        </Descriptor>
      )}
      {earnedHours >= 0 && (
        <Descriptor
          display="inline"
          style={{
            ...labelBaseStyles,
            color: '#2a8639',
          }}
        >
          <span style={{ color: '#248735', fontSize: 15 }}>&#8226;</span>{' '}
          Completed <span style={{ fontWeight: 'lighter' }}>{earnedHours}</span>
        </Descriptor>
      )}
    </>
  );
}

function ProgressCard({
  benefitContinuationDueDate = null,
  dueDate = null,
  requiredHours = 0,
  enrolledHours = 0,
  earnedHours = 0,
  units = 'credits',
  trainingName = '',
}: any) {
  const wheelWidth = 150;
  const wheelHeight = wheelWidth;
  const needToEnroll = requiredHours - (enrolledHours + earnedHours);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ marginBottom: -10 }}
      >
        {/* Dylan 2/17/21 (EQS-2075): Due date should be dueDate IF NOT bcDate ELSE 'No Due Date'.
        Reason if that bcDate, if set, always overrides original dueDate value. DueDate value is 
        preserved in object for consumption by certain services, which is why it is not just recalculated
        in same field.*/}
        <DueDateBadge
          duedate={
            benefitContinuationDueDate ? benefitContinuationDueDate : dueDate
          }
        />
      </Grid>
      <Card elevation={0} variant="outlined" style={{ width: '100%' }}>
        <CardContent>
          {trainingName && (
            <Subheading
              gutterBottom
              align="center"
              style={{ margin: '15px 0px' }}
            >
              {trainingName}
            </Subheading>
          )}

          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={2}
          >
            {/* Progress Wheel */}
            <Grid item style={{ height: wheelHeight, width: wheelWidth }}>
              <ProgressWheel
                requiredHours={requiredHours}
                // enrolledHours={enrolledHours - earnedHours}
                enrolledHours={enrolledHours}
                earnedHours={earnedHours}
                units={units}
              />
            </Grid>

            <Grid item xs={10} container alignItems="center" justify="center">
              <StatusLabels
                // enrolledHours={enrolledHours - earnedHours}
                enrolledHours={enrolledHours}
                needToEnroll={needToEnroll}
                earnedHours={earnedHours}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ProgressCard;
