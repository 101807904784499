import { Box, capitalize } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { B2CEXTENSIONSAPPID } from 'src/envvars';
import AuthService from 'src/store/api/AuthService';
import { isArray, objIsEmpty, useRouter } from 'src/utils';
import {
  Body,
  DescriptorError,
  Heading3,
} from 'src/views/components/DSL/typography';
import {
  OBContext,
  SET_CONSENT,
  SHOW_ABORT_CONSENT,
} from '../containers/OnboardingContainer';

export default function OBStep4({ renderConsentControls }) {
  const { store, dispatch } = useContext(OBContext);
  const router = useRouter();
  const [error, setError] = useState<string | boolean | undefined>();
  const [isSubmitting, setSubmitting] = useState(false);
  const prefLangReadable = capitalize(
    store?.properties?.preferredLanguage || 'English'
  );

  const onAgree = async () => {
    setSubmitting(true);

    dispatch({
      type: SET_CONSENT,
      payload: {
        cocSigned: 'SIGNED',
      },
    });

    try {
      const properties = store.properties;
      const languagesFluentIn = isArray(properties?.languagesFluentIn)
        ? properties.languagesFluentIn.join(';')
        : properties?.languagesFluentIn || 'English';

      console.log('LANGS: ', languagesFluentIn);

      const azureUser = await AuthService.createAzureUser({
        displayName: `${properties.firstName} ${properties.lastName}`,

        [`extension_${B2CEXTENSIONSAPPID}_role`]: 'CARE',
        [`extension_${B2CEXTENSIONSAPPID}_ssnLast4`]: properties.ssnLast4,
        [`extension_${B2CEXTENSIONSAPPID}_firstName`]: properties.firstName,
        [`extension_${B2CEXTENSIONSAPPID}_lastName`]: properties.lastName,
        [`extension_${B2CEXTENSIONSAPPID}_cellPhone`]: properties.cellPhone,
        [`extension_${B2CEXTENSIONSAPPID}_homePhone`]: properties.homePhone,
        [`extension_${B2CEXTENSIONSAPPID}_languagesFluentIn`]: languagesFluentIn,
        [`extension_${B2CEXTENSIONSAPPID}_learnerId`]: properties.learnerId,
        [`extension_${B2CEXTENSIONSAPPID}_personId`]: properties.learnerId,

        /* FIXME: Need ms graph culture codes map to get iso-631-1 value this field does not accept friendly names for languages*/
        preferredLanguage: 'en-US',

        identities: [
          {
            signInType: 'userName',
            issuer: 'seiu775bgb2c.onmicrosoft.com',
            issuerAssignedId: `${properties.learnerId}`,
          },
          {
            signInType: 'emailAddress',
            issuer: 'seiu775bgb2c.onmicrosoft.com',
            issuerAssignedId: properties.email,
          },
        ],
        userPrincipalName: `${properties.learnerId}@seiu775bgb2c.onmicrosoft.com`,
        passwordProfile: {
          password: properties.password,
          forceChangePasswordNextSignIn: false,
        },
      });

      setSubmitting(false);

      if (!objIsEmpty(azureUser)) {
        router.push('/onboarding/5');
      } else {
        setError('Unable to create user account.');
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setError(error?.message || 'Unable to create user account.');
    }
  };

  /**
   * This only shows abort screen
   */
  const onDisagree = () => {
    dispatch({
      type: SHOW_ABORT_CONSENT,
    });
    router.push(router.pathname.concat('?consentName=cocSigned'));
  };

  return (
    <>
      <Box
        my={2}
        bgcolor="#fff"
        p={2}
        borderRadius={6}
        border="1px solid #e2e5e9"
      >
        <Heading3 gutterBottom>
          Required: Accept Training Partnership Code of Conduct
        </Heading3>

        <Body gutterBottom style={{ marginBottom: 10 }}>
          Your acceptance of the Training Partnership Code of Conduct is
          required. You can access the Code of Conduct{' '}
          <a
            href={`/coc/${prefLangReadable}.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . You may download or print the document for your records.
        </Body>

        <Body>
          If you are having trouble accessing the PDF in your preferred language
          the English version of the document is available{' '}
          <a href={`/coc/English.pdf`} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Body>
      </Box>

      {renderConsentControls({
        isSubmitting,
        onAgree,
        onDisagree,
        children: (
          <>
            {error && <DescriptorError gutterBottom>{error}</DescriptorError>}
          </>
        ),
      })}
    </>
  );
}
