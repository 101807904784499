import React from 'react';
import ScheduleList from './ScheduleList';
import ScheduleCalendar from './ScheduleCalendar';
import {
  SCHED_MODES,
  VARIANT,
} from 'src/views/pages/Enrollment/components/hooks/helpers';

interface ScheduleProps {
  sessions: any;
  variant?: VARIANT;
  mode: SCHED_MODES;
  start?: moment.Moment | any; //Moment;
  end?: moment.Moment | any; //Moment;
}

function Schedule({ sessions, mode, start, end, variant }: ScheduleProps) {
  // console.log('Schedule: ', sessions, mode, start, end, variant);
  return mode === SCHED_MODES.LIST ? (
    <ScheduleList sessions={sessions} variant={variant} />
  ) : (
    // FIXME: The Date Picker component breaks typography of the app
    <ScheduleCalendar start={start} end={end} sessions={sessions} />
  );
}

export default React.memo(Schedule);
