import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'src/utils';
import { ButtonSwitch } from 'src/views/components/DSL/buttons';
import { InputPhoneNumber } from 'src/views/components/DSL/form';
import { Overline, Subheading } from 'src/views/components/DSL/typography';
import { OBContext, SET_ATTRIBUTES } from '../containers/OnboardingContainer';

export default function OBStep2({ renderFormControls }) {
  const router = useRouter();
  const [disabled] = useState(false);
  const { store, dispatch } = useContext(OBContext);
  const { handleSubmit, watch, reset, errors, register, formState } = useForm({
    mode: 'onChange',
  });
  const { contactPreference } = watch();
  const { isSubmitting, isValid } = formState;

  const cellPhoneRef = register({
    required: false,
    /*
      validate: (v) =>
        (v.length && /^\(([0-9]{3})\)[ ]([0-9]{3})[ ]([0-9]{4})$/.test(v)) ||
        'Invalid phone number',
      */
  });
  const homePhoneRef = register({
    required: false,
    /*
      validate: (v) =>
        (v.length && /^\(([0-9]{3})\)[ ]([0-9]{3})[ ]([0-9]{4})$/.test(v)) ||
        'Invalid phone number',
      */
  });

  const onsubmit = async (data) => {
    try {
      dispatch({
        type: SET_ATTRIBUTES,
        payload: data,
      });
      router.push('/onboarding/3');
    } catch (error) {
      console.log(error);
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onsubmit)}>
      <Box display="flex" flexDirection="column" alignItems="stretch" my={1}>
        <Overline>Contact Preferences</Overline>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="stretch">
        <InputPhoneNumber
          label="CELL PHONE (Optional)"
          name="cellPhone"
          placeholder="(123)-345-3454"
          defaultValue={store?.properties?.cellPhone || ''}
          gutterBottom
          inputRef={cellPhoneRef}
        />
        <InputPhoneNumber
          label="HOME PHONE (Optional)"
          name="homePhone"
          placeholder="(123)-345-3454"
          defaultValue={store?.properties?.homePhone || ''}
          gutterBottom
          inputRef={homePhoneRef}
        />

        <Subheading gutterBottom>
          Please tell us how you would like to be contacted. Important or
          required communications regarding learning, health or retirement
          benefits may still be sent to you through email, text or phone. You
          can change your preferences at any time in your profile.
        </Subheading>

        <Box
          my={2}
          bgcolor="#fff"
          p={1.8}
          borderRadius={6}
          border={`1px solid ${'#e2e5e9'}`}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <Box
            my={0.5}
            py={1.8}
            width={1}
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
            borderBottom="1px solid #ddd"
          >
            Text{' '}
            <ButtonSwitch
              disabled={!store?.properties?.cellPhone}
              onChange={(name, checked) => {
                console.log(name, checked);
              }}
              checked={contactPreference?.indexOf('mobile')}
            />
          </Box>
          <Box
            my={0.5}
            py={1.8}
            width={1}
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
            borderBottom="1px solid #ddd"
          >
            Phone{' '}
            <ButtonSwitch
              disabled={!store?.properties?.homePhone}
              onChange={(name, checked) => {
                console.log(name, checked);
              }}
              checked={contactPreference?.indexOf('home')}
            />
          </Box>
          <Box
            my={0.5}
            py={1.8}
            width={1}
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
          >
            Email
            <ButtonSwitch
              disabled={!store?.properties?.email}
              onChange={(name, checked) => {
                console.log(name, checked);
              }}
              checked={contactPreference?.indexOf('email')}
            />
          </Box>
        </Box>

        {renderFormControls({ disabled, isSubmitting })}
      </Box>
    </form>
  );
}
