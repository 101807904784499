import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

const Heading3 = ({
  children,
  gutterBottom,
  noWrap,
  display,
  align,
  style,
  ...rest
}: TypographyProps) => {
  return (
    <Typography
      variant="h3"
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      display={display}
      style={style}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default React.memo(Heading3);
