import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { ButtonText } from 'src/views/components/DSL/buttons';
import { PenBlue } from 'src/views/components/DSL/Icons';
import { Subheading } from 'src/views/components/DSL/typography';

export default function FieldReadOnly({
  label,
  value,
  onClick,
  fieldLabelProps = { style: { color: '#5f6772' } },
  fieldValProps = { style: { color: '#383c42' } },
  last,
}) {
  return (
    <Box
      display="flex"
      maxWidth={1}
      flexWrap="wrap"
      alignItems="center"
      py={{ xs: 2, sm: 3 }}
      borderBottom={!last && '1px solid #e2e5e9'}
    >
      {/* Lable */}
      <Box
        display={{ xs: 'block', sm: 'inline' }}
        width={{ xs: 1, sm: 2.5 / 12 }}
      >
        <Subheading {...fieldLabelProps}>{label}</Subheading>
      </Box>

      {/* Value */}
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {!value?.length && (
          <ButtonText
            style={{
              padding: 0,
              backgroundColor: 'yellow',
              height: 'auto',
              background: 'none',
            }}
            onClick={onClick}
          >{`Add ${label}`}</ButtonText>
        )}
        {value?.length && <Subheading {...fieldValProps}>{value}</Subheading>}
        {value?.length && (
          <IconButton onClick={onClick} size="small" edge="end">
            <PenBlue style={{ maxWidth: 16 }} fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
