import { Grid, GridProps } from '@material-ui/core';
import React from 'react';
import { useBreakpoint } from 'src/views/components/DSL/theme';

export default function ECMetaSection({ style, ...rest }: GridProps) {
  const isMobile = useBreakpoint('xs');
  return (
    <Grid
      container
      direction="column"
      style={{
        padding: 16,
        paddingRight: isMobile ? 16 : 90,
        ...style,
      }}
      {...rest}
    />
  );
}
