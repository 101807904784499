import { Box, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { useInputStyles } from '.';
import { Descriptor, DescriptorError } from '../typography';

interface EmailProps {
  label?: string;
  onChange?: (event: any) => void;
  value?: any;
  gutterBottom?: boolean;
  placeholder?: string;
  error?: string;
  errors?: any;
  inputRef?: React.Ref<HTMLInputElement>;
  register?: any;
  name?: string;
  defaultValue?: string;
  disabled?: boolean;
  [propName: string]: any;
}

export default function InputEmail({
  name,
  error = '',
  label,
  placeholder = 'Enter Your Email Address',
  gutterBottom = true,
  defaultValue = '',
  inputRef,
  ...rest
}: EmailProps) {
  const classes = useInputStyles();

  return (
    <Box mb={gutterBottom && 1}>
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <OutlinedInput
        name={name}
        type="email"
        margin="dense"
        error={error?.length > 0}
        fullWidth
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
        defaultValue={defaultValue}
        classes={classes}
        inputRef={inputRef}
        placeholder={placeholder}
        {...rest}
      />
      {error && <DescriptorError gutterBottom>{error}</DescriptorError>}
    </Box>
  );
}
