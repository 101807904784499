import React from 'react';
import { ReactComponent as BT30TopicSvg } from 'src/views/styles/assets/icons/choices-24.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const BT30Topic = ({ fontSize = 'small', ...props }: IconProps) => {
  return <Icon fontSize={fontSize} component={BT30TopicSvg} {...props} />;
};

export default BT30Topic;
