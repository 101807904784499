import { Grid, GridProps } from '@material-ui/core';
import React from 'react';
import { MOODLE } from 'src/envvars';
import { TPLogo } from 'src/views/components/DSL/Icons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import GeneralPreview from './GeneralPreview';

interface Props {
  sectProps?: GridProps;
}

function HCInstructorSect({ sectProps }: Props) {
  const isMobile = useBreakpoint('xs');

  const subgridProps: GridProps = {
    ...sectProps,
    md: 6,
  };

  return (
    <Grid
      item
      container
      spacing={2}
      style={{
        padding: `5px ${isMobile ? 5 : 200}px`,
      }}
    >
      <Grid
        {...subgridProps}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.open(`${MOODLE}/my`, '_self');
        }}
      >
        <GeneralPreview
          title="Facilitate Courses"
          subheader="View course pages, print rosters, take attendance, and more"
          avatar={<TPLogo size="medium" />}
        />
      </Grid>
      <Grid {...subgridProps}>
        <GeneralPreview
          title="Upcoming Classes"
          subheader="See a list of dates and details for upcoming classes"
          avatar={<TPLogo size="medium" />}
        />
      </Grid>
      <Grid {...subgridProps}>
        <GeneralPreview
          title="Past Classes"
          subheader="See a list of dates and details for past classes"
          avatar={<TPLogo size="medium" />}
        />
      </Grid>
      <Grid {...subgridProps}>
        <GeneralPreview
          title="Cancelled Classes"
          subheader="See a list of dates and details for cancelled classes"
          avatar={<TPLogo size="medium" />}
        />
      </Grid>
    </Grid>
  );
}

export default HCInstructorSect;
