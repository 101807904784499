import axios, { AxiosResponse } from 'axios';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import { objIsEmpty } from 'src/utils';
import { Registration } from '../containers/UnenrollContainer';

/**
  const axios = require('axios');

  let config = {
    method: 'get',
    url: 'https://api.seiu775.bg/dev/api/v1/learners/registrations/search?learnerId=999979807316&courseId=53',
    headers: { }
  };

  axios(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
 */

export interface LoadRegistrationsOptions {
  learnerId: string;
  courseId?: string;
  courseIdNumber?: string;
  enrollmentId?: string;
  [x: string]: any;
}

export async function loadRegistrations({
  learnerId,
  courseId,
  courseIdNumber,
  enrollmentId,
}: LoadRegistrationsOptions): Promise<any> {
  try {
    const response = await axios.get(
      `${MOODLE_SERVICE_URL}/learners/${learnerId}/course/${courseIdNumber}/registrations`
    );

    return response?.data?.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}
