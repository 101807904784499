import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthService from 'src/store/api/AuthService';
import { QueryPersonResponse } from 'src/types';
import { InputNumber, InputText } from 'src/views/components/DSL/form';
import {
  Body,
  Descriptor,
  DescriptorError,
  Overline,
} from 'src/views/components/DSL/typography';
import { OBContext, SET_ATTRIBUTES } from '../containers/OnboardingContainer';

type Inputs = {
  firstName: string;
  lastName: string;
  ssnLast4: string;
};

export default function OBStep0({ renderFormControls }) {
  const [error, setError] = useState(null);
  const { dispatch } = useContext(OBContext);
  const { register, handleSubmit, errors, reset, formState } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      ssnLast4: '',
    },
    shouldUnregister: true,
  });

  const history = useHistory();

  const onSubmit = async (data: Inputs) => {
    //Whitespace was causing issues, stripping whitespace here to fix bug EQS-1685
    Object.keys(data).forEach(function (key) {
      data[key] = data[key].trim();
    });
    try {
      const person: QueryPersonResponse & {
        languagesFluentIn?: string;
        [x: string]: any;
      } = await AuthService.queryPersonDEX(data);

      if (!person) {
        setError('No records found');
        reset();
      } else {
        dispatch({
          type: SET_ATTRIBUTES,
          payload: person,
        });

        history.push('/onboarding/1');
      }
    } catch (error) {
      console.error(error);
      setError('No records found');
      reset();
    }
  };

  const firstNameRef = register({
    required: true,
    validate: (v) => v.length > 0 || 'Invalid phone number',
  });

  const lastNameRef = register({
    required: true,
    validate: (v) => v.length > 0 || 'Invalid phone number',
  });

  const ssnLast4Ref = register({
    required: true,
    validate: (v) =>
      (v.length === 4 && /^(?!0000)[0-9]{4}/g.test(v)) ||
      'Please enter last 4 digits of your SSN',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
      <Box display="flex" flexDirection="column" alignItems="stretch" my={1}>
        <Overline>Account Setup</Overline>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <InputText
          name="firstName"
          label="FIRST NAME"
          placeholder="Enter first name"
          error={errors?.firstName?.message}
          inputRef={firstNameRef}
          gutterBottom
        />

        <InputText
          name="lastName"
          label="LAST NAME"
          placeholder="Enter last name"
          error={errors?.lastName?.message}
          inputRef={lastNameRef}
          gutterBottom
        />

        <Descriptor gutterBottom>
          SOCIAL SECURITY NUMBER: Last 4 numbers{' '}
        </Descriptor>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Box>
            <Body noWrap style={{ letterSpacing: 6, marginTop: -5 }}>
              XXX - XX -{' '}
            </Body>
          </Box>

          <Box flexGrow={1}>
            <InputNumber
              name="ssnLast4"
              placeholder="X X X X"
              error={errors?.ssnLast4?.message}
              inputRef={ssnLast4Ref}
            />
          </Box>
        </Box>
        {error && <DescriptorError gutterBottom>{error}</DescriptorError>}

        {renderFormControls({
          disabled: !formState.isValid || formState.isSubmitting,
          isSubmitting: formState.isSubmitting,
        })}
      </Box>
    </form>
  );
}
