import { arrayToObject } from 'src/utils';

export const getEnrollmentIds = (state) => state?.enrolledCourses?.idNumbers;

export const getEnrollmentsLoaded = (state) =>
  state?.enrolledCourses?.status === 'FINISHED';

export const getEnrolledCoursesFilters = (state) =>
  state?.enrolledCourses?.filters;

export const getEnrolledCourses = (state) => state.enrolledCourses;
export const getEnrolledCoursesAsArray = (state) =>
  Object.values(getEnrolledCourses(state)?.byIdNumbers || {});

export const getForbiddenCoursePrimaryKeys = (state) => {
  const enrollments = getEnrolledCoursesAsArray(state);
  const res = arrayToObject(enrollments, 'courseid', (el) => el.courseid);
  return res;
};
