import React from 'react';
import { IconButton } from '@material-ui/core';
import { LeftBlue } from 'src/views/components/DSL/Icons';

const CCDialogBackButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      style={{ backgroundColor: 'transparent', padding: 0 }}
    >
      <LeftBlue compact />
    </IconButton>
  );
};

export default React.memo(CCDialogBackButton);
