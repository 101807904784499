import React from 'react';
import { ReactComponent as CRTLogo } from 'src/views/styles/assets/icons/CertBlue.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

function CertBlue({ fontSize = 'small', ...props }: IconProps) {
  return <Icon component={CRTLogo} fontSize={fontSize} {...props} />;
}

export default CertBlue;
