import moment from 'moment';

export const compareCourseSequence = (a, b) => {
  if (a.sequence < b.sequence) {
    return -1;
  }
  if (a.sequence > b.sequence) {
    return 1;
  }
  return 0;
};

export const compareCourseStartDate = (a, b) => {
  if (a.begins < b.begins) {
    return -1;
  }
  if (a.begins > b.begins) {
    return 1;
  }
  return 0;
};

export function getCourseCodeInStr(cachedCourse) {
  const courseCode = cachedCourse;
  const courseCodeObj =
    courseCode === null ? courseCode : courseCode.split(',');
  const courseCodeArr = Object.values(courseCodeObj);
  let courseStr = '';
  courseCodeArr.forEach((i) => {
    courseStr += `exactcode[]=${i}&`;
  });
  return courseStr;
}

export function getSessionsInStr(cachedSessions) {
  const sessions = cachedSessions;
  const sessionsObj = sessions === null ? sessions : sessions.split(',');
  const sessionsArr = Object.values(sessionsObj);
  let sessionsStr = '';
  sessionsArr.forEach((i) => {
    sessionsStr += `code[]=${i}&`;
  });
  return sessionsStr;
}

export function getBlocksInStr(cachedSessionBlock) {
  const blocks = cachedSessionBlock;
  const blocksObj = blocks.includes(',') ? blocks.split(',') : blocks;
  // const blocksArr = Object.values(blocksObj);

  const blocksStr = `code[]=${blocksObj}`;

  // blocksObj.forEach((i) => {
  //   blocksStr += `id[]=${i}&`;
  // });

  // console.log(blocksStr);
  return blocksStr;
}

export enum BLOCKENROLLED_TR {
  BT70 = '201',
  BT30 = '202',
  TFC = '603',
}

export function getCoursesFromSessions(sessions) {
  const tempList = [];
  if (sessions.length > 0) {
    for (let i = 0; i < sessions.length; i++) {
      //BT70 is block enrolled so just need to return one course info
      if (
        sessions[i].name.substring(0, 3) === BLOCKENROLLED_TR.BT70 &&
        sessions.length > 2
      ) {
        // sessions[0] is UT ALWAYS and doesn't have a course
        const bt70template = {
          category: 'Basic Training 70',
          fullname: 'Basic Training 70',
          format: 'IN-PERSON AND WEBINAR',
          startdate: sessions[0]?.begins,
          enddate: sessions[sessions.length - 1]?.begins,
          idnumber:
            sessions[0]?.course.idnumber || sessions[1]?.course.idnumber,
          image: sessions[0]?.course.image || sessions[1]?.course.image,
          type: 'In-person and webinar',
          credit_hours: 70,
        };
        // console.log(bt70template);
        tempList.push(bt70template);
        break;
      } else if (
        sessions[i].name.substring(0, 3) === BLOCKENROLLED_TR.TFC &&
        sessions.length > 2
      ) {
        const tfcTemplate = {
          fullname: 'Tools for Calm - Mindfulness for Caregivers',
          format: 'WEBINAR',
          startdate: sessions[0].begins,
          enddate: sessions[sessions.length - 1].begins,
          idnumber: sessions[0].course.idnumber,
          image: sessions[0].course.image.replace(/ /g, '%20'),
          type: 'webinar',
          credit_hours: 6,
        };
        tempList.push(tfcTemplate);
        break;
      } else {
        tempList.push(sessions[i].course);
      }
    }
  }
  return tempList;
}

/* returns an object with key-pair values
    key: address of training venue
    value: count of how many sessions have the key as address
*/
export function getLocationCounts(sessions) {
  const tempList = [];
  const counts = {};
  if (sessions.length > 0) {
    for (let i = 0; i < sessions.length; i++) {
      const value = sessions[i].institution.replace(/,/g, ', ');
      const key = sessions[i].address?.replace(/,/g, ', ');
      if (key in tempList) {
        break;
      }
      if (typeof counts[key] === 'undefined') {
        counts[key] = 1;
      } else {
        counts[key]++;
      }
      tempList.push(sessions[i].address);
    }
  }
  return counts;
}

/* returns an object with key-pair values
    key: address of training venue
    value: name of training venue
*/
export function getAllLocations(sessions) {
  const tempList = [];
  const addressMap = {};
  if (sessions.length > 0) {
    for (let i = 0; i < sessions.length; i++) {
      const value = sessions[i].institution.replace(/,/g, ', ');
      const key = sessions[i].address?.replace(/,/g, ', ');
      if (key in tempList) {
        break;
      }
      if (typeof addressMap[key] === 'undefined') {
        addressMap[key] = value;
      }
      tempList.push(key);
    }
  }
  // if Live Webinar included, send it to the last item
  // addressMap.forEach((element, index) => {
  //   if (element.institution === 'Live Webinar') {
  //     addressMap.push(addressMap[index]);
  //     addressMap.splice(index, 1);
  //   }
  // });
  return addressMap;
}

export function checkForUT(sessionsForOption) {
  let sessions = sessionsForOption;
  if (sessionsForOption?.[0]?.course?.idnumber.substring(0, 7) === '2010421') {
    const utObj = {
      ...sessionsForOption[0],
      description: 'Union Time',
      ends: sessionsForOption[0].begins,
      begins: moment(sessionsForOption[0].begins).subtract(30, 'minutes'),
      // course: { id: null },
    };
    sessions = [utObj, ...sessionsForOption];
  }
  return sessions;
}
