import React from 'react';
import { IconProps } from './types';
import training_logo from 'src/views/styles/assets/icons/training-logo@2x.png';

const TPLogo = ({ compact = false, size }: IconProps) => {
  return (
    <img
      alt="Training Logo"
      src={training_logo}
      style={{
        maxWidth: compact
          ? 12
          : size === 'small'
          ? 12
          : size === 'medium'
          ? 50
          : size === 'large'
          ? 75
          : '100%',
      }}
    />
  );
};

export default TPLogo;
