import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { ButtonPrimary, ButtonText } from 'src/views/components/DSL/buttons';
import { Subheading } from 'src/views/components/DSL/typography';

export interface OBConsentControlsProps {
  // used with next button in most cases
  onAgree: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDisagree: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
  isSubmitting: boolean;
  children?: React.ReactNode;
}

export default function OBConsentControls({
  children,
  isSubmitting,
  disabled,
  onAgree,
  onDisagree,
}: OBConsentControlsProps) {
  return (
    <>
      {children && <Box my={0.5}>{children}</Box>}

      <Subheading gutterBottom>Do you agree to this Consent?</Subheading>

      <ButtonPrimary
        disabled={disabled || isSubmitting}
        onClick={onAgree}
        capitalize
        fullWidth
        size="large"
      >
        {isSubmitting ? (
          <>
            <CircularProgress size={20} color="inherit" />
            Processing
          </>
        ) : (
          'Agree'
        )}
      </ButtonPrimary>

      <ButtonText capitalize onClick={onDisagree} fullWidth size="large">
        Disagree
      </ButtonText>
    </>
  );
}
