import Axios from 'axios';
import { TRANSCRIPTS_URL } from 'src/envvars';
import { LoadTranscriptsProps, Transcript } from 'src/types';

const loadCompletedCourses = async ({
  learnerId,
  onSuccess,
  onError,
}: LoadTranscriptsProps) => {
  if (!learnerId) {
    onError('Unable to load transcripts at this time.');
    return;
  }

  try {
    const response: Transcript = (
      await Axios.get(`${TRANSCRIPTS_URL}/transcripts/${learnerId}`)
    )?.data;

    const byIdNumbers = response?.course_completions?.reduce((acc, comp) => {
      return {
        ...acc,
        [comp.courseId]: comp,
      };
    }, {});

    console.log(byIdNumbers);

    onSuccess(byIdNumbers);
  } catch (error) {
    onError(error.message || 'Error loading transcripts');
  }
};

export default loadCompletedCourses;
