import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '78px',
    right: '20px',
  },
}));

function CCScrollToTop({ children }) {
  const classes = useStyles();

  const handleClick = (_event) => {
    const el = document.getElementById('root');

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div onClick={handleClick} role="presentation" className={classes.root}>
      {children}
    </div>
  );
}

export default CCScrollToTop;
