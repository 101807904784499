import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import React from 'react';
import {
  Body,
  BodyAnchor,
  BodyLink,
  Heading1,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';
import { PageContent, PageHeader } from 'src/views/components/Layouts';

interface HelpCardProps {
  title: string | null;
  children: React.ReactElement<any> | null;
}

const HelpCard = ({ title = null, children = null }: HelpCardProps) => {
  return (
    <Grid item xs={12} sm={4}>
      <Card variant="outlined">
        <CardHeader
          disableTypography
          title={title && <Heading4>{title}</Heading4>}
        />
        {children && (
          <>
            <Divider />
            <CardContent>{children}</CardContent>
          </>
        )}
      </Card>
    </Grid>
  );
};

const topics = [
  {
    title: 'Learning',
    body: (
      <>
        <Subheading gutterBottom>How do I register for class?</Subheading>

        <ol>
          <li>
            <Body gutterBottom>
              Log in to My Benefits * and click on “Manage My Training.” You
              will automatically be taken to a screen that lists the classes you
              are currently registered for.{' '}
            </Body>
          </li>
          <li>
            <Body gutterBottom>
              From here, click on the “Training Search” tab to register for
              classes.
            </Body>
          </li>
          <li>
            <Body gutterBottom>
              There are two options to search for a class. You can search for a
              specific class at the top of the page. Or, you can find all of the
              classes available to you listed below the search box. When you are
              ready to enroll, check the box to the left of the training name.
            </Body>
          </li>
          <li>
            <Body gutterBottom>
              At the top of the page, click on “Enroll in Selected Items” to
              register for the class. You will see a yellow box at the top of
              the page telling you that you have successfully enrolled.
            </Body>
          </li>
        </ol>

        <Body gutterBottom>*It is easiest to register from a computer.</Body>

        <Subheading gutterBottom>
          Where do I find my Course Schedule?
        </Subheading>

        <Body>
          Log in to My Benefits and click on “Manage My Training” to find all of
          the classes that you are currently enrolled in.
        </Body>
      </>
    ),
    row: 1,
    col: 1,
  },
  {
    title: 'Becoming Certified As a Home Care Aide',
    body: (
      <>
        <Subheading gutterBottom>
          Where do I get help with my steps to certification?
        </Subheading>
        <Body gutterBottom>
          For help with your steps to certification, contact a Peer Mentor.
          Mentors are certified HCAs trained to help you complete your training
          and certification requirements.
        </Body>
        <Body gutterBottom>
          Contact Peer Mentors at 855-803-2095 (Monday-Friday, 10a.m.–6p.m) or
          email{' '}
          <BodyAnchor
            href="mailto:peer.mentorship@myseiubenefits.org"
            target="_blank"
            rel="noreferrer noopener"
          >
            peer.mentorship@myseiubenefits.org
          </BodyAnchor>{' '}
          . Please leave a message if a mentor is not immediately available. You
          will receive a call back within one business day.
        </Body>
        <Body gutterBottom>
          You can also visit the{' '}
          <BodyAnchor
            href="https://myseiu.be/2PBRsyV"
            target="_blank"
            rel="noreferrer noopener"
          >
            Peer Mentor page
          </BodyAnchor>{' '}
          to read more.
        </Body>
      </>
    ),

    row: 1,
    col: 2,
  },
  {
    title: 'Health Benefits',
    body: (
      <>
        <Subheading gutterBottom>
          How do I qualify for health benefits?{' '}
        </Subheading>
        <Body gutterBottom>
          To qualify for coverage from SEIU 775 Benefits Group you must work 80
          hours each month for two months in a row and maintain 80 hours per
          month going forward for continuous coverage. If you are eligible, you
          will typically receive an application packet in the mail.
        </Body>
        <Body gutterBottom>
          You can also visit the <BodyLink to="/health">Health page</BodyLink>{' '}
          to read more.
        </Body>
      </>
    ),
    row: 1,
    col: 3,
  },
  {
    title: 'Language Support',
    body: (
      <>
        <Subheading gutterBottom>How do I get language support?</Subheading>

        <Body gutterBottom>
          See the{' '}
          <BodyAnchor
            href="https://myseiu.be/2PATOOv"
            target="_blank"
            rel="noreferrer noopener"
          >
            Language Support page
          </BodyAnchor>{' '}
          for a description of what information is available in your language.
        </Body>
      </>
    ),

    row: 2,
    col: 1,
  },
  {
    title: 'Technology',
    body: (
      <>
        <Subheading gutterBottom>How do I reset my password?</Subheading>
        <Body>
          If you have forgotten your password, visit{' '}
          <BodyAnchor
            href="https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Reset_Pass&client_id=87341714-179f-4073-899f-2d326f89c539&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&scope=openid&response_type=id_token&prompt=login"
            target="_self"
            rel="noreferrer noopener"
          >
            Forgot Password page
          </BodyAnchor>
          , enter your email address and follow the instructions sent to you by
          email.
        </Body>
      </>
    ),
    row: 2,
    col: 2,
  },
  {
    title: 'Need more caregiving hours?',
    body: (
      <>
        <Subheading gutterBottom>
          Where can I find more work as a Home Care Aide, or find Respite
          Caregivers?
        </Subheading>
        <Body gutterBottom>
          <BodyAnchor
            href="https://www.carinacare.com/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Carina
          </BodyAnchor>{' '}
          is a free online tool that helps connect In-Home Care Clients and
          Individual Providers who are contracted with the Department of Social
          and Health Services (DSHS) in Washington state. Visit{' '}
          <BodyAnchor
            href="https://www.carinacare.com/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Carina
          </BodyAnchor>{' '}
          to register and find new clients.
        </Body>
      </>
    ),
    row: 2,
    col: 3,
  },
];

export default function HelpContainer() {
  return (
    <>
      <PageHeader>
        <Grid container direction="column" alignItems="center" justify="center">
          <Heading1 gutterBottom>Help</Heading1>
          <Subheading>Common Questions</Subheading>
        </Grid>
      </PageHeader>

      <PageContent>
        <Grid container spacing={3} alignItems="flex-start">
          {topics.map((datum, k) => {
            return (
              <HelpCard key={k} title={datum?.title}>
                {datum.body}
              </HelpCard>
            );
          })}
        </Grid>
      </PageContent>
    </>
  );
}
