import { ReactComponent as HelpLogo } from 'src/views/styles/assets/icons/help_default.svg';
import React from 'react';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

export default function HelpDefault({
  fontSize = 'small',
  ...props
}: IconProps) {
  return <Icon component={HelpLogo} fontSize={fontSize} {...props} />;
}
