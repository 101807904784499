import { Grid, Hidden, MenuItem, MenuList } from '@material-ui/core';
import React from 'react';
import { Heading4 } from 'src/views/components/DSL/typography';

const MCOption = ({ value, ...props }: any) => {
  return (
    <MenuItem data-value={value} {...props}>
      {value}
    </MenuItem>
  );
};

export default function MCSideNav({
  title,
  selection,
  options,
  handleSelect,
}: any) {
  return (
    <Grid item xs={12} md={3}>
      <Hidden xsDown>
        <Heading4 gutterBottom>
          {options?.length ? title || 'Filter' : 'No Filters'}
        </Heading4>

        <MenuList>
          {options.map((opt, key) => {
            return (
              <MCOption
                selected={selection === opt}
                onClick={handleSelect}
                key={`${key}${opt}`}
                value={opt}
              />
            );
          })}
        </MenuList>
      </Hidden>
    </Grid>
  );
}
