import {
  Avatar,
  Box,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { BrokenImage } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from 'src/consts';
import { MOODLE } from 'src/envvars';
import { isMobile, useRouter } from 'src/utils';
import { COLORS } from 'src/views/components/DSL';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import { CertBlue } from 'src/views/components/DSL/Icons';
import { Body2, Descriptor } from 'src/views/components/DSL/typography';
import useCardStyles from './hooks/useCardStyles';

interface CompletedCourseProps {
  completed: string;
  src?: string;
  name: string;
  certLink: string;
}

export default function CompletedCourse({
  completed,
  src,
  name,
  certLink,
}: CompletedCourseProps) {
  const dateLabel = moment(completed).format(DATE_FORMAT);
  const classes = useCardStyles();
  const router = useRouter();
  const image = src ? `${MOODLE}${src}` : '';
  return (
    <ListItem
      className={classes.list}
      component={Paper}
      disableGutters
      variant="outlined"
      // onClick={() => router.push(certLink)}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={image}>
          {!src ? <BrokenImage color="inherit" /> : null}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={isMobile ? '55%' : '80%'}
          >
            <Body2 style={{ color: COLORS.GREY[600], fontWeight: 700 }} noWrap>
              {name}
            </Body2>
          </Box>
        }
        secondary={
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={isMobile ? '55%' : '80%'}
          >
            <Descriptor noWrap>
              <span style={{ color: COLORS.GREEN[400] }}>Completed</span>
              {` - ${dateLabel}`}
            </Descriptor>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <ButtonOutlined
          rounded
          disabled
          // onClick={() => router.push(certLink)}
          startIcon={
            <CertBlue color="disabled" size="small" fontSize="small" />
          }
        >
          Cert.
        </ButtonOutlined>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
