import { useMediaQuery } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import BREAKPOINTS from './breakpoints';
import COLORS from './colors';
import { mediaMdUp, mediaSmUp } from './mediaQueryUtils';

// Create a theme instance.
const theme = createMuiTheme({
  // breakpoints
  breakpoints: BREAKPOINTS,

  // palette
  palette: {
    common: {
      white: COLORS.COMMON.WHITE,
      black: COLORS.COMMON.BLACK,
    },
    primary: {
      ...COLORS.PURPLE,
      light: COLORS.PURPLE[100],
      main: COLORS.PURPLE[300],
      dark: COLORS.PURPLE[500],
      contrastText: COLORS.COMMON.WHITE,
    },
    secondary: {
      ...COLORS.BLUE,
      light: COLORS.BLUE[100],
      main: COLORS.BLUE[300],
      dark: COLORS.BLUE[500],
      contrastText: COLORS.COMMON.WHITE,
    },
    error: {
      ...COLORS.RED,
      light: COLORS.RED[100],
      main: COLORS.RED[400],
      dark: COLORS.RED[500],
      contrastText: COLORS.COMMON.WHITE,
    },
    warning: {
      ...COLORS.ORANGE,
      light: COLORS.ORANGE[100],
      main: COLORS.ORANGE[400],
      dark: COLORS.ORANGE[500],
      contrastText: COLORS.COMMON.WHITE,
    },
    success: {
      ...COLORS.GREEN,
      light: COLORS.GREEN[100],
      main: COLORS.GREEN[400],
      dark: COLORS.GREEN[500],
      contrastText: COLORS.COMMON.WHITE,
    },
    grey: COLORS.GREY,
    divider: COLORS.GREY[300],
    background: {
      paper: COLORS.COMMON.WHITE,
      default: COLORS.COMMON.WHITE,
    },
  },

  // shadows
  /* eslint-disable-next-line */
  // @ts-ignore: shadows are fine
  shadows: [
    'none',
    '0px 2px 1px 0px #e2e5e9,0px 1px 1px 0px #e2e5e9,0px 1px 3px 0px #e2e5e9',
    '0px 3px 1px -2px #e2e5e9,0px 2px 2px 0px #e2e5e9,0px 1px 5px 0px #e2e5e9',
    '0px 3px 3px -2px #e2e5e9,0px 3px 4px 0px #e2e5e9,0px 1px 8px 0px #e2e5e9',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  ],
  shape: {
    borderRadius: 4,
  },

  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontFamily: '"Rockwell Regular", serif',
      color: COLORS.GREY[600],
      fontSize: 30,
      [mediaSmUp()]: {
        fontSize: 42,
      },
      [mediaMdUp()]: {
        fontSize: 45,
      },
    },
    h2: {
      fontFamily: '"Rockwell Regular", serif',
      color: COLORS.GREY[600],
      fontSize: 24,
      [mediaSmUp()]: {
        fontSize: 36,
      },
    },
    h3: {
      color: COLORS.GREY[600],
      fontSize: 20,
      fontWeight: 700,
      [mediaSmUp()]: {
        fontSize: 24,
      },
    },
    h4: {
      color: COLORS.GREY[600],
      fontSize: 16,
      fontWeight: 700,
    },
    overline: {
      color: COLORS.GREY[600],
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0.75,
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
    },
    // used for Subheading
    subtitle1: {
      color: COLORS.GREY[600],
      fontSize: 16,
      fontWeight: 400,
    },
    // used for Body
    body1: {
      color: COLORS.GREY[500],
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      color: COLORS.GREY[500],
      fontSize: 14,
      fontWeight: 400,
    },
    // used for Descriptor
    caption: {
      color: COLORS.GREY[500],
      fontSize: 12,
      fontWeight: 400,
    },
  },
});

theme.overrides.MuiIconButton = {
  root: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

theme.overrides.MuiCard = {
  root: {
    padding: 0,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,

    /* Targets CardContent */
    '& > .MuiCardContent-root': {
      padding: theme.spacing(1.5),
      flexGrow: 1,
      '&:last-child': {
        paddingBottom: theme.spacing(1.5),
      },
    },

    /* Targets CardHeader */
    '& > .MuiCardHeader-root': {
      padding: theme.spacing(1.5),
      justifySelf: 'flex-start',
    },

    /* Targets CardHeader */
    '& > .MuiCardActions-spacing': {
      padding: theme.spacing(1.5),
    },
    '& > .MuiCardActions-root': {
      padding: theme.spacing(1.5),
      justifySelf: 'flex-end',
    },

    /* Targets CardMedia */
    '& > .MuiCardMedia-root': {
      padding: 0,
      margin: 0,
      marginBottom: theme.spacing(1.5),
    },

    [mediaSmUp()]: {
      marginBottom: theme.spacing(1),
    },
  },
};

theme.overrides.MuiTab = {
  root: {
    textTransform: 'capitalize',
  },
};

theme.overrides.MuiTableRow = {
  root: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
};

theme.overrides.MuiAvatar = {
  root: {
    backgroundColor: '#ddd',
  },
  fallback: {
    backgroundColor: '#ddd',
  },
};

export const useBreakpoint = (bp: Breakpoint) =>
  useMediaQuery(theme.breakpoints.down(bp));

export default theme;
