import React from 'react';
import moment from 'moment';
import { DATE_FORMAT, TRAINING_CODE } from 'src/consts';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CourseCompletionsEntity } from 'src/types';
import { useSelector } from 'src/views/providers';
import { getTranscriptsCompletions } from 'src/store/transcripts/selectors';
import abbySig from './Abby_sig.png';
import adamSig from './Adam_sig.png';

export const PROGRAM = (code) => {
  const trainingCode = code.substring(0, 4);
  let res = '';
  switch (true) {
    case trainingCode.includes(TRAINING_CODE.BT_7.CODE):
      res = 'BT7';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_9.CODE):
      res = 'BT9';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_30.CODE):
      res = 'BT30';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_70.CODE):
      res = 'BT70';
      break;
    case trainingCode.includes(TRAINING_CODE.CE_12.CODE):
      res = 'CE';
      break;
    case trainingCode.includes(TRAINING_CODE.AHCAS.CODE):
      res = 'AHCAS';
      break;
    case trainingCode.includes(TRAINING_CODE.OS.CODE) ||
      trainingCode.includes(TRAINING_CODE.ORIENTATION.CODE) ||
      trainingCode.includes(TRAINING_CODE.SAFETY.CODE):
      res = 'O&S';
      break;
    case trainingCode.includes(TRAINING_CODE.MINDFUL_WC.CODE):
      res = 'Tools for Calm';
      break;
    default:
      break;
  }
  return res;
};

interface LearnerInfoProps {
  learnerId: number | string;
  learnerName: string;
  learnerDOB: string;
}

function HiddenTable({ learnerId, learnerName, learnerDOB }: LearnerInfoProps) {
  const completions: CourseCompletionsEntity[] = useSelector(
    getTranscriptsCompletions
  );
  return (
    <div id="content">
      <table id="pdfhead" style={{ width: '100%', display: 'none' }}>
        <tbody>
          <tr>
            <td colSpan={4}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <strong>Official Training Transcript</strong>
              <br />
              Learner Name: {learnerName}
              <br />
              Learner ID: {learnerId}
              <br />
              Learner DOB: {learnerDOB}
            </td>
          </tr>
        </tbody>
      </table>
      <table id="transcript_pdf_table" style={{ display: 'none' }}>
        <thead>
          <tr>
            <th>COMPLETED</th>
            <th>COURSE NAME</th>
            <th>DSHS ID</th>
            <th>PROGRAM</th>
            <th>HOURS</th>
            <th>COURSE TYPE</th>
            <th>INSTRUCTOR</th>
          </tr>
        </thead>
        <tbody>
          {completions?.map((row, i) => (
            <tr key={i}>
              <td>
                {row.completed_date
                  ? moment(row.completed_date).format('MM/DD/YYYY')
                  : 'N/A'}
              </td>
              <td>{row.name}</td>
              <td>{row.dshsId || 'N/A'}</td>
              <td>{PROGRAM(row.courseId)}</td>
              <td>{parseFloat(row.units)}</td>
              <td>{row.modality}</td>
              <td>{row.instructor || 'instructor'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table id="pdffoot" style={{ display: 'none' }}>
        <tbody>
          <tr>
            <td>
              <strong>Legend:</strong>
              <br />
              BT70: Basic Training 70
              <br />
              BT30: Basic Training 30
              <br />
              BT9: Basic Training 9
              <br />
              BT7: Basic Training 7
              <br />
              BT70-ECB: Basic Training 70 Exempt Certification Benefit
            </td>
            <td>
              PM: Peer Mentoring
              <br />
              O&S: Orientation and Safety
              <br />
              CE: Continuing Education
              <br />
              CE: Continuing Education
              <br />
              CE-CLL: Coninuing Education Caregiver Library
              <br />
              AHCAS: Advanced Home Care Aide Specialist Program
            </td>
            <td>
              <br />
              <br />
              <br />
              <br />
              Abigail Solomon
              <br />
              EXECUTIVE DIRECTOR
            </td>
            <td>
              <br />
              <br />
              <br />
              <br />
              Adam Glickman
              <br />
              CHAIR BOARD OF TRUSTEES
            </td>
          </tr>
        </tbody>
      </table>
      <table id="footBar">
        <tbody>
          <tr>
            <td>
              THE SEIU 775 BENEFITS GROUP INCLUDES THE SEIU NW TRAINING
              PARTNERSHIP A NONPROFIT 501(c)(3) SCHOOL FOR CAREGIVERS
              <br />
              {moment().format(DATE_FORMAT)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default HiddenTable;
