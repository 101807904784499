/**
 * Completed Courses:
 *
 * - get learner id
 * - get transcripts for that learner
 * - reflect completed courses loaded in state
 */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getEnrolledCourses } from 'src/store/enrolledCourses/selectors';
import { getTranscripts } from 'src/store/transcripts/selectors';
import { TranscriptStore } from 'src/types';
import theme from 'src/views/components/DSL/theme';
import { PageContent } from 'src/views/components/Layouts';
import { useSelector } from 'src/views/providers';
import MCCompleted from '../components/MCCompleted';
import MCEnrolled from '../components/MCEnrolled';
import MCHeader from '../components/MCHeader';

function MyCoursesContainer() {
  const { error, ...transcripts }: TranscriptStore = useSelector(
    getTranscripts
  );
  const enrolledCourses = useSelector(getEnrolledCourses);

  return (
    <>
      {/* Header */}
      <MCHeader />

      {/* Sections */}
      <PageContent
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Switch>
          <Route path="/mycourses/completed">
            <MCCompleted error={error} {...transcripts} />
          </Route>
          <Route path="/mycourses/enrolled">
            <MCEnrolled error={enrolledCourses?.error} {...enrolledCourses} />
          </Route>
        </Switch>
      </PageContent>
    </>
  );
}

export default MyCoursesContainer;
