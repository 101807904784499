import React from 'react';
import PreviewCard from './PreviewCard';
import PreviewHeader from './PreviewHeader';

interface GeneralPreviewProps {
  title?: string;
  subheader?: string;
  avatar?: React.ReactNode;
}

function GeneralPreview({ title, subheader, avatar }: GeneralPreviewProps) {
  return (
    <PreviewCard>
      <PreviewHeader title={title} subheader={subheader} avatar={avatar} />
    </PreviewCard>
  );
}

export default React.memo(GeneralPreview);
