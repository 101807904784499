import { Box, CircularProgress } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import { authSelectors } from 'src/store/authentication';
import config from 'src/store/config';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { enrolledCoursesSelectors } from 'src/store/enrolledCourses';
import { enrollmentSelectors } from 'src/store/enrollment';
import { transcriptSelectors } from 'src/store/transcripts';
import { CourseSchema, SessionBlockSchema } from 'src/types';
import { objIsEmpty, useRouter } from 'src/utils';
import { Back, ButtonPrimary } from 'src/views/components/DSL/buttons';
import { TransitionAlert } from 'src/views/components/DSL/info';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Heading3 } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import CourseItemCompact from '../../CLC/components/CourseItemCompact';
import EmptyResults from '../../shared/Filters/EmptyResults';
import ECOptionDetails from './ECOptionDetails';
import { progressCalculator } from './ECutililty';

export interface ScheduleEntry {
  courseid: number | string;
  sessionid?: number | string;
  blockid?: number | string;
}

export interface LearnerInfo {
  email: string;
  id: number | string;
  firstname: string;
  lastname: string;
}

export interface EnrollmentRequestOptionsData {
  learner: LearnerInfo;
  schedule: ScheduleEntry[];
}
export interface EnrollmentRequestOptions {
  data: EnrollmentRequestOptionsData;
  onSuccess: (response?: any) => void;
  onError: (message: string) => void;
}

export async function handleEnrollmentRequest({
  data,
  onSuccess,
  onError,
}: EnrollmentRequestOptions) {
  try {
    if (objIsEmpty(data?.learner) || !data?.schedule?.length) {
      onError('Unable to enroll: invalid request made to enroll learner');
      return;
    } else {
      const response: AxiosResponse<'data'> = (
        await axios.post(`${MOODLE_SERVICE_URL}/registrations/target`, data)
      )?.data;

      if (!response) {
        onError('Unable to enroll: no response received');
      } else {
        onSuccess('Successfully Enrolled Learner');
      }
    }
  } catch (error) {
    onError(
      error?.response?.data?.statusText ||
        'Unable to enroll: invalid request made to enroll learner'
    );
  }
}

export default function ECOptionsReview() {
  const router = useRouter();
  const isMobile = useBreakpoint('xs');

  const { learnerId, email, firstName, lastName, dashboard } = useSelector(
    authSelectors.getAuthentication
  );
  const transcript = useSelector(transcriptSelectors.getTranscriptsCompletions);
  const enrolledCourses = useSelector(
    enrolledCoursesSelectors.getEnrolledCoursesAsArray
  );
  const learnerDashboard = dashboard;
  const learnerTranscript = transcript;

  const selectedCourse: CourseSchema | any = useSelector(
    courseCatalogSelectors.getSelectedCourse
  );
  const selectedOption: SessionBlockSchema = useSelector(
    enrollmentSelectors.getSelectedOption
  );
  const [processingEnroll, setProcessingEnroll] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }

    setLoadingOption(false);

    if (objIsEmpty(selectedOption)) {
      setError('Unable to load option for enrollment');
    } else {
      setError('');
    }

    return () => {
      unmounted = true;
    };
  }, [selectedOption]);

  const onEnrollSuccess = () => {
    setError('');
    setProcessingEnroll(false);

    const confirmationRoute = `/enrollment/success${router.location.search}&email=${email}`;

    router.push(confirmationRoute);
  };
  const onEnrollError = (error) => {
    setError(error || 'Unable to enroll learner');
    setProcessingEnroll(false);
  };

  const onConfirmEnrollment = () => {
    try {
      const modtype = selectedOption?.type?.toUpperCase();

      if (
        modtype !== config.MODALITY_TYPES.ONLINE &&
        !modtype.startsWith?.(config.MODALITY_TYPES.ONL, 0)
      ) {
        setError('Unable to enroll learner at this time');
        setProcessingEnroll(false);
        return;
      }

      const validation = progressCalculator(
        learnerDashboard,
        learnerTranscript,
        selectedCourse,
        enrolledCourses
      );
      console.log('validation: ', validation);

      if (validation) {
        setError(validation);
        setProcessingEnroll(false);
        return;
      }

      setProcessingEnroll(true);
      // only handles online courses at the moment
      // ILT and WEB are handled via TE
      const data: EnrollmentRequestOptionsData = {
        learner: {
          lastname: lastName,
          firstname: firstName,
          id: learnerId,
          email,
        },
        schedule: [
          {
            courseid: selectedCourse.id,
          },
        ],
      };

      handleEnrollmentRequest({
        data,
        onError: onEnrollError,
        onSuccess: onEnrollSuccess,
      });
    } catch (error) {
      console.log(error);
      setProcessingEnroll(false);
      setError('Unable to enroll learner');
    }
  };

  const empty = objIsEmpty(selectedOption) && objIsEmpty(selectedCourse);

  return (
    <Box display="flex" flexDirection="column" maxWidth={1}>
      {!empty && (
        <Box>
          <Back
            style={{ paddingLeft: 0 }}
            gutterBottom
            size="medium"
            to={`/enrollment/filters${router.location.search.toString()}`}
          >
            Other Options
          </Back>
          <Heading3>Review Selected Option</Heading3>
        </Box>
      )}

      <Box py={2}>
        {error && <TransitionAlert severity="error" message={error} />}
        {/* message="The course option you selected is currently unavailable" */}

        {loadingOption && <CircularProgress />}

        {empty ? (
          <EmptyResults text="404 Unable to load selected option for enrollment">
            <ButtonPrimary
              capitalize
              onClick={() => router.push('/coursecatalog?refresh=true')}
            >
              Back to Course Catalog
            </ButtonPrimary>
          </EmptyResults>
        ) : (
          <>
            <Box
              my={1}
              mb={2}
              width={1}
              flexGrow={isMobile ? 1 : 'initial'}
              display="flex"
              flexDirection={{ xs: 'column-reverse', sm: 'row' }}
              flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <ECOptionDetails {...selectedCourse} />
              <CourseItemCompact
                style={{ width: isMobile ? '100%' : '50%', marginBottom: 0 }}
                onClick={null}
                {...selectedCourse}
              />
            </Box>

            <Box
              my={1}
              width={1}
              display="flex"
              alignItems="center"
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <ButtonPrimary
                capitalize
                fullWidth={isMobile}
                onClick={onConfirmEnrollment}
                disabled={processingEnroll || error.length > 0}
                startIcon={
                  processingEnroll ? <CircularProgress size={14} /> : null
                }
              >
                {processingEnroll ? 'Processing...' : 'Confirm Enrollment'}
              </ButtonPrimary>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
