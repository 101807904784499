import React from 'react';
import { IconProps } from './types';
import healthlogo from 'src/views/styles/assets/icons/health-logo@2x.png';

const HealthLogo = ({ compact = false, size }: IconProps) => {
  return (
    <img
      src={healthlogo}
      alt="Health Information Symbol"
      style={{
        maxWidth: compact
          ? 12
          : size === 'small'
          ? 12
          : size === 'medium'
          ? 50
          : size === 'large'
          ? 75
          : '100%',
      }}
    />
  );
};

export default HealthLogo;
