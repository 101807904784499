/*
  NOTES: Alternative method of loading flattened list of all registrations

  const response2 = (
    await Axios.get(`${MOODLE_SERVICE_URL}/learners/${learnerId}/registrations`)
  )?.data;

*/

import Axios from 'axios';
import { MOODLE_SERVICE_URL, TRANSCRIPTS_URL } from 'src/envvars';
import { Transcript } from 'src/types';

const loadEnrolledCourses = async ({ learnerId, onSuccess, onError }) => {
  if (!learnerId) {
    onError(
      'Unable to load transcripts at this time. Error: invalid learnerId'
    );
    return;
  }

  try {
    const response = (
      await Axios.get(`${MOODLE_SERVICE_URL}/learners/${learnerId}/enrollments`)
    )?.data?.data;

    const coursesToFilterOut = (
      await Axios.get(`${TRANSCRIPTS_URL}/transcripts/${learnerId}`)
    )?.data?.course_completions;

    // only show courses that has NOT been already completed
    // filter courses with same dshs id
    let filteredEnrolledCourses = response.filter(
      (responseObj) =>
        !coursesToFilterOut.find(
          (transcriptObj) => responseObj.course.dshsId === transcriptObj.dshsId
        )
    );

    // filter courses with same TP generated course id
    filteredEnrolledCourses = response.filter(
      (responseObj) =>
        !coursesToFilterOut.find(
          (transcriptObj) =>
            responseObj.course.idnumber.substring(0, 7) ===
            transcriptObj.courseId.substring(0, 7)
        )
    );

    // console.log(filteredEnrolledCourses);

    if (onSuccess) {
      onSuccess(filteredEnrolledCourses);
    }
  } catch (error) {
    console.log(error);
    if (onError) {
      onError(error);
    }
  }
};

export default loadEnrolledCourses;
