/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AZURESIGNOUTLINK } from 'src/envvars';
import AuthService from 'src/store/api/AuthService';
import { authFailed, authSuccess } from 'src/store/authentication';
import { getAuthentication } from 'src/store/authentication/selectors';
import {
  getRolesFromActiveEmployers,
  objIsEmpty,
  processStateParams,
  redirectToAzure,
  useRouter,
  useToken,
} from 'src/utils';
import { useDispatch, useSelector } from 'src/views/providers';
import { TransitionAlert } from '../components/DSL/info';
import LoadingPage from '../components/DSL/info/LoadingPage';
import Unauthorized from '../components/DSL/info/Unauthorized';

function ProtectedRoute({ children, ...rest }: RouteProps) {
  const { location } = useRouter();
  const { isLoggedIn, error: authError } = useSelector(getAuthentication);
  const dispatch = useDispatch();

  // get the params from redirect_uri
  const url = location.hash?.substring(1)?.split?.('&');
  const redirectUriParam = {};

  for (let i = 0; i < url.length; i++) {
    const tmp = url[i].split?.('=');
    redirectUriParam[tmp[0]] = tmp[1];
  }

  /* target enrollment state param passed via url */
  if (redirectUriParam['state']) {
    processStateParams(redirectUriParam['state']);
  }

  const [token, removeToken, setToken] = useToken({
    key: 'id_token',
    initialState: redirectUriParam['id_token']
      ? redirectUriParam['id_token']
      : null,
  });

  const resetAuthAndRedirect = (error = 'Unable to authorize') => {
    dispatch(authFailed(error));
    removeToken();
    redirectToAzure();
  };

  const verifyToken = async (token) => {
    try {
      const response = await AuthService.getUserProfileFromToken(token);

      if (!objIsEmpty(response)) {
        setToken(token);
        dispatch(
          authSuccess({
            ...response,
            role: getRolesFromActiveEmployers(response).join('|'),
          })
        );
      } else {
        resetAuthAndRedirect();
      }
    } catch (error) {
      resetAuthAndRedirect(error);
    }
  };

  useEffect(() => {
    if (!isLoggedIn && token) {
      verifyToken(token);
    } else {
      resetAuthAndRedirect();
    }
  }, [token]);

  // Adding redirect url fix before msal
  if (window.location.hash.includes('error')) {
    console.log(window.location.hash);
    return (
      <TransitionAlert message={'Unable to authenticate'}>
        <a href={AZURESIGNOUTLINK}>Try logging in again.</a>
      </TransitionAlert>
    );
  }

  if (!isLoggedIn && authError) {
    return <Unauthorized message="Unauthorized redirecting to login page..." />;
  }

  if (!isLoggedIn) {
    return <LoadingPage message="Logging in..." />;
  }

  return <Route {...rest}>{children}</Route>;
}

export default ProtectedRoute;
