import { Box, BoxProps, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { useInputStyles } from '.';
import { Descriptor, DescriptorError } from '../typography';

type TextProps = BoxProps & {
  label?: string;
  onChange?: (event: any) => void;
  value?: any;
  gutterBottom?: boolean;
  placeholder?: string;
  error?: string;
  errors?: any;
  inputRef?: React.Ref<HTMLInputElement>;
  register?: any;
  name?: string;
  defaultValue?: string;
  disabled?: boolean;
  startAdornment?: JSX.Element;
  rounded?: boolean;
  onCollapse?: () => void;
  size?: 'small' | 'medium' | 'large';
};

export default function InputText({
  name,
  error = '',
  label = '',
  placeholder = '',
  gutterBottom = true,
  defaultValue = '',
  inputRef,
  rounded = false,
  ...rest
}: TextProps) {
  const classes = useInputStyles({ rounded });

  return (
    <Box display="flex" flexDirection="column" mb={gutterBottom && 1}>
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <OutlinedInput
        name={name}
        type="text"
        margin="dense"
        error={error?.length > 0}
        fullWidth
        defaultValue={defaultValue}
        classes={classes}
        inputRef={inputRef}
        placeholder={placeholder}
        {...rest}
      />
      {error && <DescriptorError gutterBottom>{error}</DescriptorError>}
    </Box>
  );
}
