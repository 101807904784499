import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

const ButtonRound = ({ style, ...rest }: ButtonProps) => (
  <Button
    style={{
      borderRadius: 25,
      ...style,
    }}
    {...rest}
  />
);

export default ButtonRound;
