/* eslint-disable react/display-name */
import { Dialog } from '@material-ui/core';
import React from 'react';
import config from 'src/store/config';
import { Descriptor, Heading1 } from 'src/views/components/DSL/typography';
import CCFiltersOverview from '../components/CCFiltersOverview';
import CCGeneralFilters from '../components/CCGeneralFilters';
import CCLanguageFilter from '../components/filters/CCLanguageFilter';
import { DialogContentKeys } from 'src/types';

export type DialogProps = {
  open: boolean;
  contentKey: DialogContentKeys;
};

/*
  This is the main dialog container which lays out all
  the different container components which can be rendered
  inside it. Other parts of the CourseCatalogContainer
  component can trigger the dialog via redux actions, and
  this component will access the dialog object from redux -
  it'll then take the contentKey (dialog.contentKey) and
  access the corresponding component inside the content)
  */
function CCSelectedCourseContainer({
  open,
  contentKey = 'GENERAL_FILTERS_OVERVIEW',
}: DialogProps) {
  const content = {
    FILTER_OVERVIEW: () => <CCFiltersOverview />,
    GENERAL_FILTERS_OVERVIEW: () => <CCGeneralFilters />,
    GENERAL_FILTERS_LANGUAGE: () => (
      <CCLanguageFilter segWayType={config.SEGWAY_TYPES.DIALOG} />
    ),
  };
  /**
   * Used by all the filter pages
   */
  const renderHeader = () => {
    return <div>header</div>;
  };
  const renderFooter = () => {
    return <div>Footer</div>;
  };

  return (
    <Dialog fullScreen={true} open={open}>
      {contentKey ? (
        content?.[contentKey]?.({
          renderHeader,
          renderFooter,
          segWayType: config.SEGWAY_TYPES.DIALOG,
        })
      ) : (
        <Heading1 style={{ padding: 30, fontSize: 25 }}>
          404 - Content not found
          <Descriptor>
            {'\n'}Check the code in CCSelectedCourseContainer
          </Descriptor>
        </Heading1>
      )}
    </Dialog>
  );
}

export default React.memo(CCSelectedCourseContainer);
