import React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { isXS } from 'src/utils';

/*
  Row width break down:

  row name |-10%-|------50%------|----30%----|-10%-|
  preview  |Icon |RangePreview   |Expand txt |Icon |
  detail   |     |RangeDetail    |Expand txt |     |
 */

interface Props {
  startIcon: React.ReactNode;
  expanded?: boolean;
  expandControl: React.ReactNode;
  rangePreviewLabel: React.ReactNode;
  rangeDetailLabel: React.ReactNode;
  expandDetailLabel: React.ReactNode;
}

function OptionPreview({
  startIcon,
  expanded = false,
  expandControl,
  rangePreviewLabel,
  rangeDetailLabel,
  expandDetailLabel,
}: Props) {
  const isMobile = isXS();

  const cell1BaseProps: GridProps = {
    container: true,
    item: true,
    alignItems: 'center',
    style: {
      width: isMobile ? '9%' : '7%',
    },
  };

  const cell2BaseProps: GridProps = {
    container: true,
    alignItems: 'center',
    item: true,
    xs: true,
    zeroMinWidth: true,
  };

  const cell3BaseProps: GridProps = {
    item: true,
    container: true,
    alignItems: 'center',
    justify: 'flex-end',
    xs: 5,
    sm: 3,
  };

  const rowProps: GridProps = {
    item: true,
    xs: 12,
    container: true,
    wrap: 'nowrap',
    alignItems: 'center',
    justify: 'space-between',
  };

  return (
    <Grid
      style={{
        padding: expanded ? `15px 10px` : 10,
        transition: '.3s all',
      }}
      container={true}
      wrap="nowrap"
      direction="column"
      justify="flex-start"
    >
      {/* Row 1 */}
      <Grid {...rowProps}>
        <Grid {...cell1BaseProps}>{startIcon}</Grid>

        <Grid {...cell2BaseProps}>{rangePreviewLabel}</Grid>

        <Grid {...cell3BaseProps}>{expandControl}</Grid>
      </Grid>

      {/* Row 2: hide upon expand */}
      {!expanded && (
        <Grid {...rowProps}>
          <Grid {...cell1BaseProps}></Grid>
          <Grid {...cell2BaseProps}>{rangeDetailLabel}</Grid>
          <Grid
            {...cell3BaseProps}
            style={{ paddingRight: isMobile ? 21 : 23 }}
          >
            {expandDetailLabel}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(OptionPreview);
