import { Box, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useContext, useEffect, useRef } from 'react';
import { useRouter } from 'src/utils';
import { ButtonPrimary } from 'src/views/components/DSL/buttons';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import { Heading4 } from 'src/views/components/DSL/typography';
import theme from 'src/views/styles/theme';
import { MRCFooter } from '../../shared/Footer/FooterContainer';
import {
  CLEAR_ABORT_CONSENT,
  OBContext,
  SET_CONSENT,
  SHOW_ABORT_CONSENT,
} from '../containers/OnboardingContainer';

export default function OBDisagree() {
  const { store, dispatch } = useContext(OBContext);

  const onDisagree = () => {
    dispatch({
      type: SET_CONSENT,
      payload: {
        pcrSigned: 'DENIED',
      },
    });
  };
  const onCancel = () => {
    dispatch({
      type: CLEAR_ABORT_CONSENT,
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      open={store.showConsentAbort}
      onClose={() => dispatch({ type: [CLEAR_ABORT_CONSENT] })}
    >
      <DialogTitle disableTypography>
        <Heading4>Do you disagree?</Heading4>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you choose {`"disagree"`}, you will not be able to take required
          training, access information, or complete your certification.
        </DialogContentText>

        <DialogActions>
          <ButtonPrimary
            style={{ marginRight: theme.spacing(1) }}
            onClick={onDisagree}
          >
            Disagree
          </ButtonPrimary>
          <ButtonOutlined onClick={onCancel}>Cancel</ButtonOutlined>
        </DialogActions>
      </DialogContent>
      <MRCFooter flexDirection="column" p={0.8} />
    </Dialog>
  );
}
