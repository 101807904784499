import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import {
  createMuiTheme,
  Grid,
  Paper,
  ThemeProvider,
  useTheme,
} from '@material-ui/core';
import {
  // useStaticState,
  Calendar,
  Day,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Descriptor } from '../typography';
import { Overrides } from '@material-ui/core/styles/overrides';

const useCalendarTheme = (theme) =>
  createMuiTheme({
    overrides: {
      MuiPickersCalendarHeader: {
        root: {
          backgroundColor: 'blue',
        },
        switchHeader: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        daysHeader: {
          flex: 1,
          marginLeft: 0,
          marginRight: 0,
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        dayLabel: {
          ...theme.typography.subtitle2,
          width: 43,
        },
      },
      MuiPickersCalendar: {
        week: {
          justifyContent: 'space-between',
          flex: 1,
        },
      },
    } as Overrides,
  });

interface Props {
  start?: Moment;
  end?: Moment;
  minDate?: Moment;
  maxDate?: Moment;
  onChange: (start: Moment, end: Moment) => void;
}

function InputDateRange({
  start = moment(),
  end = moment().add(1, 'day'),
  minDate = moment(),
  maxDate = moment().add(1, 'month'),
  onChange,
}: Props) {
  const theme = useTheme();
  const calTheme = useCalendarTheme(theme);

  const [range, setRange] = useState({
    start: start,
    end: end,
  });
  const [dayKeys, setDayKeys] = useState({});
  const [rangeInReview, setRangeInReview] = useState('start');

  const getDayKey = (day) => {
    const iso = day?.toISOString?.() || null;

    if (dayKeys[iso]?.length === iso.length) {
      return dayKeys[iso];
    }

    setDayKeys({ ...dayKeys, [iso]: iso });
    return iso;
  };

  const handleDateChange = (date, onFinish) => {
    if (onFinish === true) {
      if (rangeInReview === 'start') {
        setRange({
          end: date.isSameOrAfter(range.end) ? date : range.end,
          start: date,
        });
        setRangeInReview('end');
      } else if (rangeInReview === 'end') {
        setRange({
          start: date.isSameOrBefore(range.start) ? date : range.start,
          end: date,
        });
        setRangeInReview('start');
      }
    }
  };

  useEffect(() => {
    onChange(range.start, range.end);
  }, [range.start, range.end, onChange]);

  const renderDay = (day, _selectedDate, _isInCurrentMonth, dayComponent) => {
    const dayKey = getDayKey(day);

    const isBetween = day.isBetween(range?.start, range?.end, 'day', '[]');
    const isStart = range?.start?.isSame?.(day, 'day');
    const isEnd = range?.end?.isSame?.(day, 'day');
    const isBoundary = isStart || isEnd;
    const isBoundaryHard =
      day.isBetween(range?.end, maxDate, 'day', '(]') ||
      day.isBetween(minDate, range?.start, 'day', '[)');
    const dayStyles = {
      border: `2px solid ${isBoundary ? '#00a2c6' : 'transparent'}`,
      backgroundColor: isBetween
        ? '#ebf8fa'
        : isBoundary
        ? '#ccecf3'
        : 'transparent',
      height: 43,
      width: 43,
      color:
        isBetween || isBoundary
          ? '#00a2c6'
          : isBoundaryHard
          ? theme.typography.subtitle1.color
          : '#ddd',
      fontSize: 16,
    };

    const content = (
      <Day key={dayKey} {...dayComponent.props} style={dayStyles}>
        {dayComponent.props.children}
      </Day>
    );

    return content;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Paper elevation={0} component={Grid}>
        <Descriptor gutterBottom>Select {rangeInReview} date</Descriptor>
        <ThemeProvider theme={calTheme}>
          <Calendar
            loadingIndicator={<span>{'Loading...'}</span>}
            date={range.start}
            disablePast
            maxDate={maxDate}
            onChange={handleDateChange}
            renderDay={renderDay}
          />
        </ThemeProvider>
      </Paper>
    </MuiPickersUtilsProvider>
  );
}

export default InputDateRange;
