import { Box, capitalize, Card, CardContent } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useRouter } from 'src/utils';
import { Body, Overline } from 'src/views/components/DSL/typography';
import {
  OBContext,
  SET_CONSENT,
  SHOW_ABORT_CONSENT,
} from '../containers/OnboardingContainer';
import ConsetRelease from './ConsentRelease';

export default function OBStep3({ renderConsentControls }) {
  const router = useRouter();
  const { store, dispatch } = useContext(OBContext);
  const preferredLanguage = capitalize(
    store?.properties?.preferredLanguage || 'English'
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const employers = store?.properties?.activeEmployers || [];
  const showNDA = isDSHS(employers);

  const onAgree = () => {
    setSubmitting(true);

    dispatch({
      type: SET_CONSENT,
      payload: {
        pcrSigned: 'SIGNED',
      },
    });

    setSubmitting(false);
    router.push('/onboarding/4');
  };

  /**
   * This only shows abort screen
   */
  const onDisagree = () => {
    dispatch({
      type: SHOW_ABORT_CONSENT,
    });
    router.push(router.pathname.concat('?consentName=pcrSigned'));
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="stretch" my={1}>
        <Overline>
          {showNDA ? 'Non-Disclosure Agreement' : 'Providers Consent Release'}
        </Overline>
      </Box>
      <Card variant="outlined">
        <CardContent>
          {showNDA ? (
            <>
              <Body>
                I am an employee of Department of Social and Health Services
                (“DSHS”) or of one of the thirteen Washington Area Agencies on
                Aging. I have been given a copy of the SEIU Web Portal License
                Agreement between the SEIU Healthcare NW Training Partnership
                and the Washington State Department of Social and Health
                Services because I may have access to the Confidential
                Information available under the T.P. – DSHS Agreement. (In this
                Nondisclosure Agreement, the Licensing Agreement, including
                Exhibit A, the SEIU Training Portal Data Sharing Agreement,
                between the Training Partnership and DSHS is referred to as the
                “T.P. – DSHS Agreement”.) I have read the T.P. – DSHS Agreement
                and I understand that it imposes obligations on DSHS and the
                Area Agencies on Aging and on me as an Authorized User described
                under Paragraph 2.2. I understand that the T.P. – DSHS Agreement
                applies to me because I am an employee who may have access to
                Confidential Information while I am reviewing and verifying
                Training Information about Individual Providers and certain home
                care agency employees. I understand that by clicking
                `&quot;`Accept`&quot;` and electronically signing this document,
                I am affirming that I will maintain the confidentiality of all
                Confidential Information that I obtain and review in the
                Training Partnership’s Web Portal. I will not disclose the
                Confidential Information to any person, other than (1) an
                Individual Provider or Agency Provider whom I am assisting to
                obtain and review their own Training Information; (2) any other
                employees at DSHS with a “need to know” the information and who
                have signed this Non-disclosure Agreement; (3) the Public
                Records Officer and/or Discovery Officer for DSHS upon their
                specific request; or (4) as required by law.
              </Body>
              <Body>
                If you would like to view the agreement as a PDF which you can
                save or print for your records, please click{' '}
                <a
                  href="/nda/English.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here.
                </a>
              </Body>
            </>
          ) : (
            <ConsetRelease lang={preferredLanguage} />
          )}
        </CardContent>
      </Card>
      {renderConsentControls({ isSubmitting, onAgree, onDisagree })}
    </>
  );

  interface Employer {
    employerName: string;
    empstatus: string;
    [x: string]: any;
  }

  function isDSHS(employers: Employer[]) {
    let dshs = false;

    for (const employer of employers) {
      if (
        employer.employerName.includes('DSHS') &&
        (employer.empstatus === 'ACTIVE' || employer.empstatus === 'Active') &&
        // eslint-disable-next-line eqeqeq
        employer?.associatedRoles?.filter((role) => role.role == 'Caregiver')
          ?.length === 0
      ) {
        dshs = true;
      }
    }
    return dshs;
  }
}
