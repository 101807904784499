import React from 'react';
import { ReactComponent as HLogo } from 'src/views/styles/assets/icons/health_default.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const HealthDefault = ({ fontSize = 'small', ...props }: IconProps) => {
  return <Icon component={HLogo} fontSize={fontSize} {...props} />;
};

export default HealthDefault;
