import { AppBar, BottomNavigation, makeStyles } from '@material-ui/core';
import React from 'react';
import { MRCFooter } from 'src/views/pages/shared/Footer/FooterContainer';
import theme from 'src/views/styles/theme';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    margin: 0,
    padding: 0,
    zIndex: theme.zIndex.drawer + 10,
    boxShadow: theme.shadows[13],
    height: theme.spacing(8.2),
    backgroundColor: '#eff1f3',
  },
});

export default function OBFooter() {
  const classes = useStyles();

  return (
    <BottomNavigation className={classes.root}>
      <MRCFooter />
    </BottomNavigation>
  );
}
