import { Box, Divider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useRouter } from 'src/utils';
import { theme } from 'src/views/components/DSL';
import { Subheading } from 'src/views/components/DSL/typography';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2.5),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  listIcon: {
    margin: theme.spacing(0, 1.2, 0, 0),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    fontSize: 16,
    margin: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
});
export interface ListItemLinkProps {
  type: 'EXTERNAL_LINK' | 'STATE_BUTTON' | 'INTERNAL_LINK';
  to?: string;
  href?: string;
  rel?: string;
  target?: string;
  startIcon?: any;
  handleClose?: () => void;
  onClick?: (e: any) => void;
  text?: string;
  [x: string]: any;
}

const ListItemLink = ({
  type,
  text,
  to,
  href,
  target = '_blank',
  startIcon: StartIcon,
  handleClose,
  onClick,
}: any) => {
  const classes = useStyles();
  const { push } = useRouter();

  const liProps = {
    EXTERNAL_LINK: {
      href,
      rel: 'noopener noreferer',
      target,
      component: 'a',
    },
    INTERNAL_LINK: {
      button: true,
      onClick: () => {
        handleClose();
        push(to);
      },
    },
    STATE_BUTTON: {
      button: true,
      onClick,
    },
  };

  return (
    <ListItem className={classes.listItem} disableGutters {...liProps[type]}>
      <StartIcon fontSize="small" className={classes.listIcon} />
      <ListItemText disableTypography className={classes.listItemText}>
        <Subheading>{text}</Subheading>
      </ListItemText>
    </ListItem>
  );
};

export default function SideNav({
  anchor,
  open,
  handleClose,
  navHeader,
  links,
}) {
  const classes = useStyles();

  return (
    <Drawer
      elevation={1}
      anchor={anchor}
      open={anchor && open}
      onBackdropClick={handleClose}
      variant="temporary"
    >
      {navHeader && <Box className={classes.drawerHeader}>{navHeader}</Box>}
      <Divider />
      <List disablePadding className={classes.list}>
        {links.map((linkProps, k) =>
          linkProps.divider ? (
            <Divider key={k} />
          ) : (
            <ListItemLink
              {...linkProps}
              handleClose={handleClose}
              key={k + linkProps.text}
            />
          )
        )}
      </List>
    </Drawer>
  );
}
