import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import config from 'src/store/config';
import AddressMap from 'src/views/components/DSL/AddressMap';
import { ButtonMultiState } from 'src/views/components/DSL/buttons';
import { Schedule } from 'src/views/components/DSL/schedule';
import { Heading4, Subheading } from 'src/views/components/DSL/typography';
import {
  CalendarGrey,
  ClockGrey,
  InterpreterGray,
  ListIcon,
  MapMarkerGrey,
} from 'src/views/components/DSL/Icons';
import {
  formatStartDatePrimary,
  formatStartDateSecondary,
  findLangFromID,
  handleIcon,
  handleTypeText,
  SCHED_MODES,
  VARIANT,
} from '../../Enrollment/components/hooks/helpers';
import theme, { useBreakpoint } from 'src/views/components/DSL/theme';
import { checkForUT, getAllLocations, getLocationCounts } from './TEutility';
import { LoadingPage } from 'src/views/components/DSL/info';
import { MOODLE } from 'src/envvars';
import { BT30_SKILLS } from 'src/consts';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f7f8f9',
    marginRight: 16,
  },
  item: {
    textDecoration: '#aa5600',
    // theme.palette.secondary.main,
  },
});

/**
 * TEReview()
 * - Displays learners assigned courses and sessions
 *
 * @param selectedCourse
 * @param sessionsForOption
 * @param courseCount
 */
function TEReview({ selectedCourse, sessionsForOption, courseCount }) {
  // console.log('TEReview: ', selectedCourse, sessionsForOption, courseCount);
  const modifiedSessionsForOption = sessionsForOption;
  const sessions = checkForUT(sessionsForOption);

  const isMobile = useBreakpoint('xs');
  const isTablet = useBreakpoint('md');
  const classes = useStyles();

  /* LOCAL STATE */
  const [scheduleViewMode, setScheduleMode] = useState<SCHED_MODES>(
    SCHED_MODES.LIST
  );

  // used in multiple places
  const [courseType, setCourseType] = useState(
    selectedCourse?.type.toUpperCase() || 'IN-PERSON'
  );
  if (courseType === 'IN-PERSON') {
    setCourseType('ILT');
  }

  const courseCreditHours = selectedCourse?.credit_hours || 0;
  const courseFullname = selectedCourse?.fullname;
  const courseIdNumber = selectedCourse?.idnumber;
  const sessionCount = sessions.length ? sessions.length : 1;
  const sessionIdNumber =
    courseCount === 1 ? sessions?.[0]?.name : sessions?.name;
  const sessionAddress =
    courseCount === 1
      ? sessions[0].address?.replace(/,/g, ', ')
      : sessions.address?.replace(/,/g, ', ');
  const sessionVenueName =
    courseCount === 1
      ? sessions[0].institution.replace(/,/g, ', ')
      : sessions.institution.replace(/,/g, ', ');
  // const zoomlink =
  //   courseCount === 1
  //     ? sessions[0].zoomlink.replace(/,/g, ', ')
  //     : sessions.zoomlink.replace(/,/g, ', ');

  // find out how many courses have the same venues
  const locationCountsMap = getLocationCounts(sessions); // key is address, value is count
  const allLocationsMap = getAllLocations(sessions); // key is address, valule is the training location name

  const listRenderer = ({
    courseType,
    courseIdNumber,
    sessions,
    sessionCount,
    sessionIdNumber,
    sessionAddress,
    sessionVenueName,
    locationCountsMap,
    allLocationsMap,
  }) => {
    if (sessionVenueName === 'Live Webinar') {
      courseType = 'WEBINAR';
    }

    return (
      <List>
        {/* COURSE TYPE */}
        <ListItem>
          <IconButton
            className={classes.root}
            size={isMobile ? 'small' : 'medium'}
          >
            {handleIcon(courseType)}
          </IconButton>
          <ListItemText primary={handleTypeText(courseType)} />
        </ListItem>
        <Divider style={{ width: isMobile ? '100%' : '98%' }} />
        {/* BT30 TOPIC  */}
        {(courseIdNumber?.substring(0, 7) === BT30_SKILLS.MOBILITY ||
          courseIdNumber?.substring(0, 7) === BT30_SKILLS.PERSONALCARE) && (
          <>
            <ListItem>
              <IconButton
                className={classes.root}
                size={isMobile ? 'small' : 'medium'}
              >
                {handleIcon('TOPIC')}
              </IconButton>
              <ListItemText
                primary={sessions?.[0]?.description || sessions?.description}
              />
            </ListItem>
            <Divider style={{ width: isMobile ? '100%' : '98%' }} />
          </>
        )}
        {/* COURSE VENUE */}
        <ListItem>
          <IconButton
            className={classes.root}
            size={isMobile ? 'small' : 'medium'}
          >
            <MapMarkerGrey />
          </IconButton>
          {/* TODO: render number of courses */}
          {courseType === config.MODALITY_TYPES.ILT && (
            <Grid style={{ width: '100vw' }}>
              <ListItemText
                primary={
                  <>
                    {sessionVenueName}
                    <AddressMap
                      address={sessionAddress}
                      institution={sessionVenueName}
                    />
                  </>
                }
                secondary={sessionAddress}
              />
            </Grid>
          )}
          {courseType === config.MODALITY_TYPES.WEBINAR && (
            <ListItemText
              primary="Live Video class"
              secondary="You will receive instructions to join your class"
            />
          )}
          {/* TODO: render number of courses */}
          {courseType === config.MODALITY_TYPES.ILTWEB && (
            <Grid style={{ width: '100vw' }}>
              {Object.keys(allLocationsMap).map(
                (trainingVenueAddress, index) => {
                  return (
                    <>
                      <ListItemText
                        key={index}
                        primary={
                          <>
                            {allLocationsMap[trainingVenueAddress] !==
                            'Live Webinar' ? (
                              <>
                                {allLocationsMap[trainingVenueAddress]}
                                <AddressMap
                                  address={trainingVenueAddress}
                                  institution={
                                    allLocationsMap[trainingVenueAddress]
                                  }
                                />
                              </>
                            ) : (
                              'Live video class'
                            )}
                          </>
                        }
                        secondary={
                          allLocationsMap[trainingVenueAddress] !==
                          'Live Webinar' ? (
                            index !== 0 ? (
                              <>
                                <div>{trainingVenueAddress} </div>
                                <div style={{ color: '#aa5600' }}>
                                  {locationCountsMap[trainingVenueAddress]}{' '}
                                  classes
                                </div>
                              </>
                            ) : (
                              <>
                                <div>{trainingVenueAddress} </div>
                                <div>
                                  {locationCountsMap[trainingVenueAddress]}{' '}
                                  classes
                                </div>
                              </>
                            )
                          ) : (
                            <>
                              <div>
                                You will receive instructions to join your class
                              </div>
                              <div style={{ color: '#aa5600' }}>
                                {locationCountsMap[trainingVenueAddress]}{' '}
                                classes
                              </div>
                            </>
                          )
                        }
                      />

                      {index + 1 !== Object.keys(allLocationsMap).length && (
                        <Divider style={{ width: isMobile ? '100%' : '98%' }} />
                      )}
                    </>
                  );
                }
              )}
            </Grid>
          )}
        </ListItem>
        <Divider style={{ width: isMobile ? '100%' : '98%' }} />
        {/* COURSE DATE */}
        {courseType !== config.MODALITY_TYPES.ONLINE && sessionCount === 1 && (
          <>
            <ListItem>
              <IconButton
                className={classes.root}
                size={isMobile ? 'small' : 'medium'}
              >
                <CalendarGrey />
              </IconButton>
              <ListItemText
                primary={formatStartDatePrimary(
                  courseCount === 1 ? sessions[0].begins : sessions.begins,
                  courseCount
                )}
                secondary={formatStartDateSecondary(
                  courseCount === 1 ? sessions[0].begins : sessions.begins,
                  courseCount === 1 ? sessions[0].ends : sessions.ends,
                  courseCount
                )}
              />
            </ListItem>
            <Divider style={{ width: isMobile ? '100%' : '98%' }} />
          </>
        )}
        {courseType !== config.MODALITY_TYPES.ONLINE && sessionCount > 1 && (
          <>
            <ListItem>
              <IconButton
                className={classes.root}
                size={isMobile ? 'small' : 'medium'}
              >
                <CalendarGrey />
              </IconButton>
              <ListItemText
                primary={formatStartDatePrimary(
                  courseCount === 1 ? sessions[0].begins : sessions.begins,
                  courseCount === 1
                    ? sessions[sessionCount - 1].ends
                    : sessions.ends,
                  sessionCount
                )}
              />
              {!isMobile && (
                <ListItemSecondaryAction>
                  <ButtonMultiState>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setScheduleMode(SCHED_MODES.LIST);
                      }}
                    >
                      <ListIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setScheduleMode(SCHED_MODES.CALENDAR);
                      }}
                    >
                      <CalendarGrey />
                    </IconButton>
                  </ButtonMultiState>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {isMobile && (
              <ListItem>
                <ListItemSecondaryAction>
                  <ButtonMultiState>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setScheduleMode(SCHED_MODES.LIST);
                      }}
                    >
                      <ListIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setScheduleMode(SCHED_MODES.CALENDAR);
                      }}
                    >
                      <CalendarGrey />
                    </IconButton>
                  </ButtonMultiState>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            <ListItem>
              {/* Renders session schedule in different modes */}
              <Schedule
                mode={scheduleViewMode}
                sessions={courseCount > 1 ? sessions.data : sessions}
                start={sessions[0].begins}
                variant={VARIANT.non_dynamic_detailed}
              />
            </ListItem>
          </>
        )}
        {/* LANGUAGE OF INSTRUCTION */}
        <ListItem>
          <IconButton
            className={classes.root}
            size={isMobile ? 'small' : 'medium'}
          >
            <InterpreterGray />
          </IconButton>
          <ListItemText
            primary={findLangFromID(courseIdNumber)}
            secondary={
              findLangFromID(courseIdNumber) === 'AM'
                ? 'Classes are taught in Amharic. One of the three course material is available in English only.'
                : ''
            }
          />
        </ListItem>
      </List>
    );
  };

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="flex-start"
      style={{
        padding: isMobile ? 0 : isTablet ? '10px 50px' : '20px 100px',
        backgroundColor: '#ffff',
      }}
    >
      {/* MOBILE SCREEN */}
      {isMobile ? (
        <>
          {/* COURSE IMAGE */}
          <Box
            style={{
              width: '100vw',
              height: 100,
              backgroundImage: `url(${MOODLE}${
                selectedCourse.image.replace(/ /g, '%20') ||
                'https://source.unsplash.com/random'
              })`,
              backgroundColor: '#edeaea',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
            }}
          />

          <Grid style={{ width: '100vw' }}>
            {/* COURSE NAME AND CREDIT HOURS */}
            <Heading4 style={{ padding: 16 }}>{courseFullname}</Heading4>
            {courseCreditHours && (
              <Subheading>
                <IconButton>
                  <ClockGrey compact />
                </IconButton>
                {courseCreditHours}{' '}
                {courseCreditHours > 1 ? 'paid hours' : 'paid hour'}
              </Subheading>
            )}
            <Divider style={{ width: isMobile ? '100%' : '98%' }} />
          </Grid>
          {/* COURSE INFO */}
          <Grid style={{ width: '100vw' }} item xs={12}>
            {listRenderer({
              courseType,
              courseIdNumber,
              sessions,
              sessionCount,
              sessionIdNumber,
              sessionAddress,
              sessionVenueName,

              locationCountsMap,
              allLocationsMap,
            })}
          </Grid>
        </>
      ) : (
        <>
          {/* DESKTOP SCREEN */}
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              {listRenderer({
                courseType,
                courseIdNumber,
                sessions,
                sessionCount,
                sessionIdNumber,
                sessionAddress,
                sessionVenueName,

                locationCountsMap,
                allLocationsMap,
              })}
            </Grid>
            {/* course image, course title, and hours*/}
            <Grid item xs={4}>
              <Grid
                style={{
                  border: '1px solid #e2e5e9',
                  borderRadius: 8,
                }}
              >
                <Grid
                  style={{
                    height: '6.5vw',
                    backgroundImage: `url(${MOODLE}${
                      selectedCourse.image.replace(/ /g, '%20') ||
                      'https://source.unsplash.com/random'
                    })`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    border: '1px solid #e2e5e9',
                    cursor: 'pointer',
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px',
                  }}
                />
                <Grid style={{ padding: 16 }}>
                  {' '}
                  <Heading4>{courseFullname}</Heading4>
                  {courseCreditHours && (
                    <Subheading style={{ paddingTop: 16 }}>
                      <IconButton style={{ padding: 0, paddingRight: 10 }}>
                        <ClockGrey compact />
                      </IconButton>
                      {courseCreditHours} paid hours
                    </Subheading>
                  )}{' '}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default TEReview;
