export default {
  SEQUENCED_VERTICALS: {
    BT30: '202',
  },
  REQUEST_STATUSES: {
    FINISHED: 'FINISHED',
    PENDING: 'PENDING',
  },
  ONBOARDING_STEPS: {
    SETUP: 'SETUP',
    DETAILS: 'DETAILS',
    PREFERENCES: 'PREFERENCES',
    CONSENT_RELEASE: 'CONSENT_RELEASE',
    CODE_OF_CONDUCT: 'CODE_OF_CONDUCT',
    ACTIVATE: 'ACTIVATE',
  },
  DIALOG_CONTENT_KEYS: {
    DATES: 'DATES',
    LANGUAGE: 'LANGUAGE',
    DISTANCE: 'DISTANCE',
    GENERAL_FILTERS_OVERVIEW: 'GENERAL_FILTERS_OVERVIEW',
    GENERAL_FILTERS_LANGUAGE: 'GENERAL_FILTERS_LANGUAGE',
    // course specific filters
    FILTER_OVERVIEW: 'FILTER_OVERVIEW',
    COURSE_OPTIONS: 'COURSE_OPTIONS',
    ENROLL_ONE: 'ENROLL_ONE',
    ENROLL_TWO: 'ENROLL_TWO',
  },
  POPOVER_CONTENT_KEYS: {
    DATES: 'DATES',
    LANGUAGE: 'LANGUAGE',
    DISTANCE: 'DISTANCE',
    GENERAL_FILTERS_LANGUAGE: 'GENERAL_FILTERS_LANGUAGE',
  },
  FILTER_DEFAULTS: {
    radius: 45,
    zipcode: null,
  },
  SEGWAY_TYPES: {
    POPOVER: 'POPOVER',
    DIALOG: 'DIALOG',
  },
  MODALITY_TYPES: {
    ILT: 'ILT',
    WEBINAR: 'WEBINAR',
    ONLINE: 'ONLINE',
    ILTWEB: 'IN-PERSON AND WEBINAR',
    ONL: 'ONL',
    WEB: 'WEB',
    online: 'online',
    'in-person': 'in-person',
    webinar: 'webinar',
    iltweb: 'IN-PERSON AND WEBINAR',
  },
  FILTER_TYPES: {
    DISTANCE: 'DISTANCE',
    DATE: 'DATE',
    LANGUAGE: 'LANGUAGE',
  },
  LANG_TO_ISO: {
    English: 'en',
    русский: 'ru',
    Español: 'es',
    'Tiếng Việt': 'vi',
    한국어: 'ko',
    中文: 'zh_ch',
    አማርኛ: 'am',
    عربى: 'ar',
    ភាសាខ្មែរ: 'km',
    ລາວ: 'lo',
    Soomaali: 'so',
    Tagalog: 'tl',
    // 'Cambodian/Khmer': 'km',
  },
  ISO_TO_LANG: {
    EN: 'English',
    RU: 'русский', //'Russian',
    SP: 'Español', //'Spanish',
    VI: 'Tiếng Việt', //'Vietnamese',
    AM: 'አማርኛ', //'Amharic',
    KO: '한국어', //'Korean',
    ZH: '中文', //'Chinese',
    ZH_CH: '中文', //'Chinese',
    KM: 'ភាសាខ្មែរ', // 'Cambodian',
  },
  LANGS_STRING: [
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Bengali',
    'Bosnian',
    'Bulgarian',
    'Burmese',
    'Cambodian/Khmer',
    'Cantonese',
    'Cebuano',
    'Chuukese',
    'Croatian',
    'Dari',
    'English',
    'Farsi',
    'French',
    'German',
    'Hindi',
    'Hmong',
    'Ilocano',
    'Indonesian',
    'Italian',
    'Japanese',
    'Karen',
    'Kirundi',
    'Korean',
    'Kurdish',
    'Lao',
    'Mandarin',
    'Marshallese',
    'Mien',
    'Moldavian',
    'Mongolian',
    'Nepali',
    'Oromo',
    'Other',
    'Pashtu',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Romanian',
    'Russian',
    'Samoan',
    'Sign Language',
    'Somali',
    'Spanish',
    'Swahili',
    'Tagalog',
    'Tamil',
    'Thai',
    'Tigrinya',
    'Tongan',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Vietnamese',
  ],
  LANGS_MAP_ISO_TOSTRING: {
    ab: 'Abkhazian',
    aa: 'Afar',
    af: 'Afrikaans',
    ak: 'Akan',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    an: 'Aragonese',
    hy: 'Armenian',
    as: 'Assamese',
    av: 'Avaric',
    ae: 'Avestan',
    ay: 'Aymara',
    az: 'Azerbaijani',
    bm: 'Bambara',
    ba: 'Bashkir',
    eu: 'Basque',
    be: 'Belarusian',
    bn: 'Bengali',
    bh: 'Bihari languages',
    bi: 'Bislama',
    bs: 'Bosnian',
    br: 'Breton',
    bg: 'Bulgarian',
    my: 'Burmese',
    ca: 'Catalan, Valencian',
    km: 'Central Khmer',
    ch: 'Chamorro',
    ce: 'Chechen',
    ny: 'Chichewa, Chewa, Nyanja',
    zh: 'Chinese',
    zh_ch: 'Chinese',
    cu: 'Church Slavonic, Old Bulgarian, Old Church Slavonic',
    cv: 'Chuvash',
    kw: 'Cornish',
    co: 'Corsican',
    cr: 'Cree',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    dv: 'Divehi, Dhivehi, Maldivian',
    nl: 'Dutch, Flemish',
    dz: 'Dzongkha',
    en: 'English',
    eo: 'Esperanto',
    et: 'Estonian',
    ee: 'Ewe',
    fo: 'Faroese',
    fj: 'Fijian',
    fi: 'Finnish',
    fr: 'French',
    ff: 'Fulah',
    gd: 'Gaelic, Scottish Gaelic',
    gl: 'Galician',
    lg: 'Ganda',
    ka: 'Georgian',
    de: 'German',
    ki: 'Gikuyu, Kikuyu',
    el: 'Greek (Modern)',
    kl: 'Greenlandic, Kalaallisut',
    gn: 'Guarani',
    gu: 'Gujarati',
    ht: 'Haitian, Haitian Creole',
    ha: 'Hausa',
    he: 'Hebrew',
    hz: 'Herero',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hu: 'Hungarian',
    is: 'Icelandic',
    io: 'Ido',
    ig: 'Igbo',
    id: 'Indonesian',
    ia: 'Interlingua (International Auxiliary Language Association)',
    ie: 'Interlingue',
    iu: 'Inuktitut',
    ik: 'Inupiaq',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    jv: 'Javanese',
    kn: 'Kannada',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    kk: 'Kazakh',
    rw: 'Kinyarwanda',
    kv: 'Komi',
    kg: 'Kongo',
    ko: 'Korean',
    kj: 'Kwanyama, Kuanyama',
    ku: 'Kurdish',
    ky: 'Kyrgyz',
    lo: 'Lao',
    la: 'Latin',
    lv: 'Latvian',
    lb: 'Letzeburgesch, Luxembourgish',
    li: 'Limburgish, Limburgan, Limburger',
    ln: 'Lingala',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    mk: 'Macedonian',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    gv: 'Manx',
    mi: 'Maori',
    mr: 'Marathi',
    mh: 'Marshallese',
    ro: 'Moldovan, Moldavian, Romanian',
    mn: 'Mongolian',
    na: 'Nauru',
    nv: 'Navajo, Navaho',
    nd: 'Northern Ndebele',
    ng: 'Ndonga',
    ne: 'Nepali',
    se: 'Northern Sami',
    no: 'Norwegian',
    nb: 'Norwegian Bokmål',
    nn: 'Norwegian Nynorsk',
    ii: 'Nuosu, Sichuan Yi',
    oc: 'Occitan (post 1500)',
    oj: 'Ojibwa',
    or: 'Oriya',
    om: 'Oromo',
    os: 'Ossetian, Ossetic',
    pi: 'Pali',
    pa: 'Panjabi, Punjabi',
    ps: 'Pashto, Pushto',
    fa: 'Persian',
    pl: 'Polish',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Romansh',
    rn: 'Rundi',
    ru: 'Russian',
    sm: 'Samoan',
    sg: 'Sango',
    sa: 'Sanskrit',
    sc: 'Sardinian',
    sr: 'Serbian',
    sn: 'Shona',
    sd: 'Sindhi',
    si: 'Sinhala, Sinhalese',
    sk: 'Slovak',
    sl: 'Slovenian',
    so: 'Somali',
    st: 'Sotho, Southern',
    nr: 'South Ndebele',
    es: 'Spanish, Castilian',
    su: 'Sundanese',
    sw: 'Swahili',
    ss: 'Swati',
    sv: 'Swedish',
    tl: 'Tagalog',
    ty: 'Tahitian',
    tg: 'Tajik',
    ta: 'Tamil',
    tt: 'Tatar',
    te: 'Telugu',
    th: 'Thai',
    bo: 'Tibetan',
    ti: 'Tigrinya',
    to: 'Tonga (Tonga Islands)',
    ts: 'Tsonga',
    tn: 'Tswana',
    tr: 'Turkish',
    tk: 'Turkmen',
    tw: 'Twi',
    ug: 'Uighur, Uyghur',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    ve: 'Venda',
    vi: 'Vietnamese',
    vo: 'Volap_k',
    wa: 'Walloon',
    cy: 'Welsh',
    fy: 'Western Frisian',
    wo: 'Wolof',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang, Chuang',
    zu: 'Zulu',
  },
  PROGRAMCODES: [
    100,
    204,
    203,
    202,
    201,
    901,
    902,
    200,
    913,
    912,
    911,
    300,
    301,
    400,
    401,
    602,
    500,
    601,
    603,
  ],
  /**
   * This is the config variable that determines which
   * course codes can be loaded via regular enrollment
   * flows
   */
  NONCOVID: {
    300: '300',
    100: '100',
    203: '203',
    202: '202',
    901: '901',
    902: '902',
  },
  COURSENUMBYVERTICAL: {
    202: {
      '0018':
        'Care for Common Medical Conditions: Congestive Heart Failure BTON EN',
      '0019':
        'Consumer-Directed Care for Common Medical Conditions: COPD BTON EN',
      '0020':
        'Consumer-Directed Care for Common Medical Conditions: Coronary Artery Disease BT',
      '0021':
        'Care for Common Medical Conditions: Peripheral Vascular Disease BTON EN',
      '0022':
        'Consumer-Directed Care for Common Medical Conditions: Seizure BTON EN',
      '0024':
        'Care for Common Medical Conditions: Urinary Tract Infection BTON EN',
      '0033': 'Diabetes Nutrition: Managing Diabetes Through Diet BTON EN',
      '0038': 'Ergonomics: Home Care Aide and Consumer Safety BTON EN',
      '0055': 'Oral Health Basics BTON EN',
      '0067': 'Care for Common Medical Conditions: Dehydration BTON EN',
      '0078':
        'Smoking Cessation: Supporting Consumers Who Want to Quit BTON EN',
      '0081': 'Supporting Behavior Changes in Consumers, Part 1 BTON EN',
      '0082': 'Supporting Behavior Changes in Consumers, Part 2 BTON EN',
      '0084': 'Supporting Consumer Mobility and Health BTON EN',
      '0086': 'SUPPORTING CONSUMERS WITH ANXIETY DISORDERS BTON EN',
      '0356':
        'Healthy Cooking and Supporting Consumer Food Preferences Course BTON EN',
      '0357': 'LGBTQ Community: Unique Needs of Older Adults BTON EN',
      '0359':
        'Nutrition: Creating Healthy and Balanced Meals for Consumers BTON EN',
      '0363': 'Supporting Consumers with Cerebral Palsy BTON EN',
      '0365': 'Taking Care of your Mental and Emotional Health BTON EN',
      '0366': "Understanding Depression's Effects BTON EN",
      '0381': 'Supporting Consumers with Mental Illness, Part 1 BTON EN',
    },
  },
};
