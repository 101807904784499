import Grid from '@material-ui/core/Grid';
import React from 'react';
import { mandatory } from 'src/consts';
import { getDashboard } from 'src/store/authentication/selectors';
import { LOCK_STATUSES } from 'src/store/consts';
import { useRouter } from 'src/utils';
import { ButtonPrimary } from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { useSelector } from 'src/views/providers';
import EmptyResults from '../../shared/Filters/EmptyResults';
import CourseItem from './CCCourseItem';

function CCCourseList({
  courses = {
    byCourseCodes: {},
    codes: [],
  },
  onItemClick,
}) {
  const router = useRouter();
  const isMobile = useBreakpoint('xs');
  const courseCards = [];
  const learnerCurrentTraining = useSelector(getDashboard);
  console.log(learnerCurrentTraining);

  // FIXME: optimize filtering; courses with mandatory tags are added first
  let filteredCourses = {};
  const electives = {};
  for (const [key, value] of Object.entries(courses.byCourseCodes)) {
    if (
      courses.byCourseCodes[key].tags &&
      courses.byCourseCodes[key].tags.includes(mandatory)
    ) {
      filteredCourses[key] = value;
    } else {
      electives[key] = value;
    }
  }
  filteredCourses = { ...filteredCourses, ...electives };

  let i = 0;
  for (const coursekey in filteredCourses) {
    const course = filteredCourses[coursekey];

    i = i + 1;

    if (course.lockStatus !== LOCK_STATUSES.HIDDEN) {
      courseCards.push(
        <Grid item xs={12} sm={4} key={`${i}_${course.idnumber}`}>
          <CourseItem
            onClick={onItemClick.bind(
              null,
              `/enrollment/filters?lang=${course.lang}&code=${course.idnumber}&courseidpk=${course.id}`,
              course.idnumber
            )}
            type={course.type}
            currentTraining={
              learnerCurrentTraining.status === 'FINISHED'
                ? learnerCurrentTraining?.inProgress?.[0].trainingCode
                : ''
            }
            {...course}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      item
      container
      spacing={2}
      style={{
        paddingLeft: isMobile ? 10 : 0,
      }}
    >
      {!courseCards?.length ? (
        <EmptyResults text="No available courses to show based on your training requirements.">
          <ButtonPrimary
            capitalize
            onClick={() => router.push('/coursecatalog?refresh=true')}
          >
            Reset Filters
          </ButtonPrimary>
        </EmptyResults>
      ) : (
        courseCards
      )}
    </Grid>
  );
}

export default React.memo(CCCourseList);
