import { arrayToObject } from 'src/utils';
import defaultState from '../defaultState';
import enrolledCoursesActionTypes from './types';

const {
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_FILTER,
  APPLY_FILTER,
} = enrolledCoursesActionTypes;

export default function enrolledCoursesReducer(
  state = defaultState.enrolledCourses,
  action
) {
  const mutations = {
    [LOAD_ERROR]: (_state, _action) => ({
      ...state,
      error: action.error,
      status: 'FINISHED',
    }),
    [SET_FILTER]: (_state, _action) => ({
      ...state,
      contentKey: action.contentKey,
    }),
    [LOAD_SUCCESS]: (_state, _action) => {
      const nextByIdNumbers = arrayToObject(action.response || [], 'id');

      return {
        ...state,
        byIdNumbers: nextByIdNumbers,
        status: 'FINISHED',
        idNumbers: Object.keys(nextByIdNumbers),
      };
    },
    [APPLY_FILTER]: (_state, _action) => {
      const nextByIdNumbers = arrayToObject(action.response || [], 'id');

      return {
        ...state,
        byIdNumbers: nextByIdNumbers,
        status: 'FINISHED',
        idNumbers: Object.keys(nextByIdNumbers),
      };
    },
  };

  return mutations[action.type]?.(state, action) || state;
}
