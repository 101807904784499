import InputEmail from './InputEmail';
import InputNumber from './InputNumber';
import InputText from './InputText';
import InputPassword from './InputPassword';
import InputPhoneNumber from './InputPhoneNumber';
import InputDateRange from './InputDateRange';
import { makeStyles, useTheme } from '@material-ui/core';
import PasswordStrength from './PasswordStrength';
import InputLangSelect from './InputLangSelect';
import InputLangSelectMulti from './InputLangSelectMulti';
import InputLangSearchMulti from './InputLangSearchMulti';

const useInputStyles = (props?: any) => {
  const theme = useTheme();
  return makeStyles((theme) => ({
    root: {
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: props?.rounded ? 25 : 5,
      margin: theme.spacing(0.5, 0.2),
      '&:hover': {
        border: '1px solid inherit',
      },
    },
    disabled: {
      border: `1px solid ${theme.palette.grey[300]}`,
      '&:hover': {
        border: '1px solid inherit',
      },
    },
    focused: {
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        border: '1px solid inherit',
      },
    },
    error: {
      border: `1px solid ${theme.palette.error.main}`,
      '&:hover': {
        border: '1px solid inherit',
      },
    },
    notchedOutline: {
      border: 'none',
      '&:hover': {
        border: '1px solid inherit',
      },
    },
  }))(theme);
};

export interface PasswordStrengthProps {
  minlengthpassed: boolean;
  minoneletterpassed: boolean;
  minonenumberpassed: boolean;
  minonespecialcharpassed: boolean;
}

const getPassValidationRes = (value): PasswordStrengthProps => ({
  minlengthpassed: !new RegExp(/^(.{0,9})$/).test(value),
  minoneletterpassed: !new RegExp(/^([^a-zA-Z]*)$/).test(value),
  minonenumberpassed: !new RegExp(/^([^0-9]*)$/).test(value),
  minonespecialcharpassed: !new RegExp(/^([^!#+$\\%\\-_=<>]*)$/).test(value),
});

const handleFormatting = (e: any) => {
  const tmp = e.target.value
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  e.target.value = !tmp[2]
    ? tmp[1]
    : '(' + tmp[1] + ') ' + tmp[2] + (tmp[3] ? ' ' + tmp[3] : '');
};

export {
  useInputStyles,
  getPassValidationRes,
  InputText,
  InputNumber,
  InputEmail,
  PasswordStrength,
  InputPassword,
  InputPhoneNumber,
  InputDateRange,
  handleFormatting,
  InputLangSelect,
  InputLangSelectMulti,
  InputLangSearchMulti,
};
