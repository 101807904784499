import React, { useState } from 'react';
import { Box } from '@material-ui/core';

export default function ButtonSwitch({
  onChange,
  checked = false,
  disabled = false,
}) {
  const [toggled, settoggled] = useState(checked || disabled);

  const handleToggle = () => {
    settoggled(!toggled);
    if (typeof onChange === 'function') {
      onChange();
    }
  };

  return disabled ? (
    <Box
      bgcolor={'#abb2ba'}
      width="37px"
      height="20px"
      display="flex"
      alignItems="center"
      px="2px"
      borderRadius="10px"
      justifyContent={'flex-start'}
    >
      <Box
        component="circle"
        height="16px"
        width="16px"
        bgcolor="#fff"
        borderRadius="50%"
      >
        {' '}
      </Box>
    </Box>
  ) : (
    <Box
      bgcolor={toggled ? '#00a2c6' : '#abb2ba'}
      width="37px"
      height="20px"
      display="flex"
      alignItems="center"
      px="2px"
      borderRadius="10px"
      justifyContent={toggled ? 'flex-end' : 'flex-start'}
      onClick={handleToggle}
      style={{
        cursor: 'pointer',
      }}
    >
      <Box
        component="circle"
        height="16px"
        width="16px"
        bgcolor="#fff"
        borderRadius="50%"
      >
        {' '}
      </Box>
    </Box>
  );
}
