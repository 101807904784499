import React from 'react';
import { IconProps } from './types';
import online_green from 'src/views/styles/assets/icons/online_green@2x.png';

const OnlineGreen = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Online Course Symbol"
      src={online_green}
      style={{ maxWidth: compact ? 13 : '100%' }}
    />
  );
};

export default OnlineGreen;
