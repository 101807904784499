import {
  Avatar,
  Box,
  capitalize,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { BrokenImage } from '@material-ui/icons';
import moment from 'moment';
import queryString from 'query-string';
import React, { useState } from 'react';
import { MOODLE } from 'src/envvars';
import { CourseSchema } from 'src/types';
import { isMobile, useRouter } from 'src/utils';
import { COLORS } from 'src/views/components/DSL';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import { CalendarBlue } from 'src/views/components/DSL/Icons';
import { TransitionAlert } from 'src/views/components/DSL/info';
import { Body2, Descriptor } from 'src/views/components/DSL/typography';
import useCardStyles from './hooks/useCardStyles';

interface EnrolledCourseProps {
  [prop: string]: any;
}

//TODO: Online courses must be shown at the top, then sort by filters
export default function EnrolledCourse(props: EnrolledCourseProps) {
  // console.log(props);
  const classes = useCardStyles();
  const router = useRouter();
  const [error, setError] = useState('');
  const course: CourseSchema = props?.course || {};
  const src = course?.image ? `${MOODLE}${course?.image}` : '';

  const mod = course?.fullname.includes('Skills Prep')
    ? 'Webinar'
    : capitalize(course?.type); // || 'Mixed';

  const dateLabel = props?.session?.[0]?.['coursestarttime']
    ? moment.unix(props.session[0]['coursestarttime']).format('MMM DD')
    : '';

  const name = course?.fullname || 'Course Name Unavailable';
  const unenrollUrl =
    queryString.stringifyUrl(
      {
        url: '/unenroll',
        query: {
          enrollmentId: props.id,
          courseId: course?.id,
          courseIdNumber: course.idnumber,
        },
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    ) || undefined;
  const toUnenroll = () => {
    if (unenrollUrl) {
      router.push(unenrollUrl);
    }
  };

  const toLaunchCourse = () => {
    if (!course?.id) {
      setError(
        'Unable to launch course. Invalid course ID. Please contact MRC.'
      );
    } else {
      setError('');

      // Verified functional
      window.open(`${MOODLE}/course/view.php?id=${course?.id}`, '_self');
    }
  };

  return (
    <ListItem
      className={classes.list}
      component={Paper}
      disableGutters
      variant="outlined"
      onClick={toLaunchCourse}
    >
      {error?.length > 0 && (
        <TransitionAlert
          severity="error"
          message={error || 'Unable to launch course. Please contact MRC.'}
        />
      )}
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={src}>
          {!src ? <BrokenImage color="inherit" /> : null}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth={isMobile ? '55%' : '80%'}
          >
            <Body2 style={{ color: COLORS.GREY[600], fontWeight: 700 }} noWrap>
              {name}
            </Body2>
          </Box>
        }
        secondary={
          mod !== 'Online' && props?.session?.[0]?.['coursestarttime'] ? (
            <Box
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth={isMobile ? '55%' : '80%'}
            >
              <Descriptor noWrap>{`${dateLabel} · ${mod}`}</Descriptor>
            </Box>
          ) : (
            <Box
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth={isMobile ? '55%' : '80%'}
            >
              <Descriptor noWrap>{`${mod}`}</Descriptor>
            </Box>
          )
        }
      />
      <ListItemSecondaryAction>
        <ButtonOutlined
          rounded
          style={{ backgroundColor: 'white' }}
          disabled={!unenrollUrl}
          capitalize
          onClick={toUnenroll}
          startIcon={
            <CalendarBlue color="inherit" size="small" fontSize="small" />
          }
        >
          Edit
        </ButtonOutlined>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
