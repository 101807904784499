import { To } from 'history';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { AZUREREDIRECTLINK, AZURESIGNINLINK } from 'src/envvars';
import { monthLabels, sessionNames, wDayNames } from 'src/store/consts';
import zips from 'src/store/data/zips.json';
import { Point, QueryPersonResponse } from 'src/types';

export const getLatLong = (zipcode: string): Point | undefined => {
  const cords = zips[zipcode];
  return cords && [cords['LAT'], cords['LNG']];
};

export const getWDayNameFromDate = (date) =>
  wDayNames[(typeof date === 'string' ? new Date(date) : date).getDay()];

export const getFormattedDate = (date) => {
  date = typeof date === 'string' ? new Date(date) : date;

  return `${monthLabels[date.getMonth()]} ${date.getDate()}, ${
    date.toDateString().split(' ')[3]
  }`;
};

export const getStartEndMonth = (sched) => {
  const start = moment(sched[0].begins);
  const end = moment(sched[sched.length - 1].begins);
  const endYear = end.format('YYYY');

  return `${start.format('MMM D')} - ${end.format('MMM D')}, ${endYear}`;
};

export const normalizeSessionObj = (session) => {
  const date = moment(session.begins);
  const endDate = moment(session.ends);
  const sessionNumber = parseInt(session.sequence);

  return {
    ...session,
    // normalize
    sessionNumber,
    sessionName: sessionNames[sessionNumber],
    date: date.format('MMM D, YYYY'),
    abbreviation: session.name,
    location: session.location,
    // de-hardcode
    time: `${date.format('ha')} - ${endDate.format('ha')}`,
    day: wDayNames[date.format('d')],
    seatsAvailable: session.maxregistrations,
  };
};

export const getSessionDetailsForSchedule = (session) => {
  const date = moment(session.begins);

  return {
    ...session,
    date: {
      month: date.format('MMM'),
      day: date.format('D'),
    },
  };
};

export const getRegistrationIdsFromSchedule = (schedule) =>
  schedule.map((s) => s.id);

/* New Enrollment App Utils, anything above this line will be deprecated */

/**
 * STATE UTILS
 */
const rad = (x: number): number => (x * Math.PI) / 180;

/*
  p1 / p2 are 1d arrays with the shape [lat, long]
  this function returns the distance in miles
  between p1 and p2 using the Haversine formula
 */
export const getDistance = (p1: Point, p2: Point): number => {
  const R = 3959;

  const dLat = rad(p2[0] - p1[0]);
  const dLong = rad(p2[1] - p1[1]);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[0])) *
      Math.cos(rad(p2[0])) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
};

export const getMomentDate = (date: any): Moment => {
  if (!date) {
    throw new Error(`${date} is not a valid date`);
  }

  return moment(date);
};

export const getMomentizedRange = ({
  begins,
  ends,
}: {
  begins: any;
  ends: any;
}) => {
  return {
    begins: getMomentDate(begins),
    ends: getMomentDate(ends),
  };
};

export const capitalizeString = (str: string): string =>
  `${str?.charAt(0)?.toUpperCase()}${str?.slice(1)?.toLowerCase()}`;

export const stringifyError = (error) => {
  const name = error?.name ? String(error.name) : 'Error: ';
  const msg = error?.message ? String(error.message) : '';
  const statusText = error?.response?.statusText || '';

  return statusText ? statusText : `${name} ${msg}`;
};

export const parseIdNumber = (idnumber) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('TODO: implement idparsing');
  }

  return idnumber;
};

export const objIsEmpty = (o) =>
  o === undefined ||
  o === null ||
  (Object.keys(o).length === 0 && o.constructor === Object);

export const getIn = (p = [], o = {}) => {
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
};
export interface DebounceProps {
  value: string | number | React.RefObject<any> | any;
  delay: number;
  cb?: () => void;
}
export function useDebounce({ value, delay, cb }: DebounceProps) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        if (typeof cb === 'function') {
          cb();
        }
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, cb] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export type UseRouterReturns = {
  push: (to: To, state?: any) => void;
  replace: (to: To, state?: any) => void;
  pathname: string;
  hash: string;
  query: any;
  match: any;
  location: any;
  history: any;
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      hash: location.hash,
      query: {
        ...params,
        ...Object.fromEntries(new URLSearchParams(location.search).entries()), // Convert string to object
      },
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
}

export function useToken({ key, initialState }) {
  const [token, sync] = useState(
    initialState || localStorage.getItem(key) || ''
  );

  const removeToken = () => {
    localStorage.removeItem(key);
    sync('');
  };

  const setToken = (value) => {
    localStorage.setItem(key, value);
    sync(value);
  };

  return [token, removeToken, setToken];
}

export function processStateParams(encryptedstate) {
  const stateParams = {};
  const subUri = window.atob(decodeURIComponent(encryptedstate)).split?.('&');

  for (let i = 0; i < subUri.length; i++) {
    const tmp = subUri[i].split('=');
    stateParams[tmp[0]] = tmp[1];
  }
  const serialized = JSON.stringify(stateParams).replace(/ /g, '');

  if (localStorage.getItem('state')) {
    localStorage.removeItem('state');
    localStorage.setItem('state', serialized);
  } else {
    localStorage.setItem('state', serialized);
  }
}

/**
 * VIEWS UTILS
 */
// TODO: Replace all of these usages with useBreakpoint()
export const isXXS = () => window.innerWidth <= 320;
export const isXS = () => window.innerWidth <= 600;
export const isSM = () => window.innerWidth <= 960;
export const isMD = () => window.innerWidth <= 1280;
export const isLG = () => window.innerWidth <= 1920;
export const isMobile = () => isXS();
export const isDesktop = () => !isSM();

export function useDeviceWidth() {
  return {
    isXXS: isXXS(),
    isXS: isXS(),
    isSM: isSM(),
    isMD: isMD(),
    isLG: isLG(),
    isMobile: isMobile(),
    isDesktop: isDesktop(),
  };
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const arrayToObject = (arr, keyField, cb?: (el: any) => any) =>
  Object.assign(
    {},
    ...arr.map((item) => ({
      [item[keyField]]: typeof cb === 'function' ? cb(item) : item,
    }))
  );

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return typeof item === 'object' && !isArray(item);
}

export function isArray(item) {
  return (
    Array.isArray(item) ||
    Object.prototype.toString.call(item) === '[object Array]'
  );
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function removeDexPersonFromCache() {
  localStorage.removeItem('dexPerson');
}

export function oidcRedirect(url?: string) {
  if (url) {
    window.open(
      AZUREREDIRECTLINK + '&redirect_uri=' + encodeURIComponent(url),
      '_self'
    );
  }
}

export function redirectToAzure() {
  if (AZURESIGNINLINK) {
    window.open(AZURESIGNINLINK, '_self');
  } else {
    console.error('No azure sign in link');
  }
}

export const CNUMINDEX: [number, number] = [3, 7];
export const MODINDEX: [number, number] = [7, 10];
export const LANGINDEX: [number, number] = [10, 12];
export const VERTINDEX: [number, number] = [0, 3];

/**
 * Returns capitalized modality abbreviation
 */
export function getModFromCourseId(courseId: string) {
  return courseId.substring(...MODINDEX);
}

/**
 * Returns capitalized language abbreviation
 */
export function getLangFromCourseId(courseId: string) {
  return courseId.substring(...LANGINDEX);
}

/**
 * Returns course module number from course id
 */
export function getCNumFromCourseId(courseId: string) {
  return courseId.substring(...CNUMINDEX);
}

/**
 * Returns vertical from courseId
 */
export function getVerticalFromCourseId(courseId: string) {
  return courseId.substring(...VERTINDEX);
}

/**
 * FIXME: needs to be turned into a selector
 */
export function getRolesFromActiveEmployers(response: QueryPersonResponse) {
  return response?.activeEmployers?.map((emp) =>
    emp.associatedRoles?.map((role) => role.role)
  );
}
