import React from 'react';
import { IconProps } from './types';
import close_grey from 'src/views/styles/assets/icons/close-grey@2x.png';

const CloseGrey = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Close"
      src={close_grey}
      style={{ maxWidth: compact ? 18 : '100%' }}
    />
  );
};

export default CloseGrey;
