import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiLink, { LinkProps } from '@material-ui/core/Link';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    // proprietary
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },

    // conditionally assigned
    textTransform: 'capitalize',
    cursor: 'pointer',
    // defaults
    textDecoration: 'none',
    color: palette.secondary[400],
    fontWeight: 700,
    letterSpacing: 0,
  },
}));

export default function ButtonLink(props: LinkProps<any>) {
  const classes = useStyles();

  return <MuiLink classes={classes} underline="none" {...props} />;
}
