/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  InputEmail,
  InputLangSearchMulti,
  InputLangSelect,
  InputPassword,
  PasswordStrength,
  PasswordStrengthProps,
} from 'src/views/components/DSL/form';
import { Overline } from 'src/views/components/DSL/typography';
import {
  emailRegEx,
  OBContext,
  SET_ATTRIBUTES,
} from '../containers/OnboardingContainer';

const getPassValidationRes = (value) => ({
  minlengthpassed: !new RegExp(/^(.{0,9})$/).test(value),
  minoneletterpassed: !new RegExp(/^([^a-zA-Z]*)$/).test(value),
  minonenumberpassed: !new RegExp(/^([^0-9]*)$/).test(value),
  minonespecialcharpassed: !new RegExp(/^([^!#+$\\%\\-_=<>]*)$/).test(value),
});

type Inputs = {
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  preferredLanguage: string;
  languagesFluentIn: string;
};

export default function OBStep1({ renderFormControls }) {
  const [, setDisabled] = useState(true);
  const [passValidation, setPassValidation] = useState<PasswordStrengthProps>({
    minlengthpassed: false,
    minoneletterpassed: false,
    minonenumberpassed: false,
    minonespecialcharpassed: false,
  });
  const { store, dispatch } = useContext(OBContext);
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    formState,
    control,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      email: store?.properties?.email || '',
      confirmEmail: store?.properties?.email || '',
      password: '',
      confirmPassword: '',
    },
  });
  const { password, confirmPassword } = watch();
  const { isSubmitting, isValid } = formState;
  const history = useHistory();

  const onsubmit = async (data) => {
    try {
      dispatch({
        type: SET_ATTRIBUTES,
        payload: data,
      });

      history.push('/onboarding/2');
    } catch (error) {
      console.log(error);
      reset();
    }
  };

  useEffect(() => {
    setDisabled(!store?.properties?.languagesFluentIn?.length);
  }, [store?.properties?.languagesFluentIn]);

  useEffect(() => {
    setDisabled(!store?.properties?.preferredLanguage?.length);
  }, [store?.properties?.preferredLanguage]);

  useEffect(() => {
    setDisabled(Object.values(passValidation).includes(false));
  }, [passValidation]);

  const emailRef = register({
    required: true,
    pattern: {
      value: emailRegEx,
      message: 'Invalid email address',
    },
  });
  const confirmEmailRef = register({
    required: true,
    validate: (value) =>
      value === watch('email') || 'Email addresses dont match',
  });
  const passwordRef = register({
    validate: (v) => {
      const pval = getPassValidationRes(v);
      setPassValidation(pval);
      if (!Object.values(pval).includes(false)) {
        return true;
      }
      return 'Weak password';
    },
  });

  const onLangFluentInChange = (value) => {
    dispatch({
      type: SET_ATTRIBUTES,
      properties: {
        languagesFluentIn: value,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onsubmit)} autoSave="off">
      <Box display="flex" flexDirection="column" alignItems="stretch" my={1}>
        <Overline>Account Details</Overline>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="stretch">
        <InputEmail
          name="email"
          label="EMAIL ADDRESS"
          defaultValue={store?.properties?.email}
          error={errors?.email?.message}
          placeholder="Enter email address"
          disabled={store?.activated}
          inputRef={emailRef}
          gutterBottom
        />

        <InputEmail
          name="confirmEmail"
          placeholder="Re-enter email address"
          label="CONFIRM EMAIL ADDRESS"
          error={errors?.confirmEmail?.message}
          inputRef={confirmEmailRef}
          disabled={store?.activated}
          gutterBottom
        />

        {/* PASSWORD */}
        <InputPassword
          placeholder="Create a new password"
          label="PASSWORD"
          name="password"
          error={errors?.password?.message}
          inputRef={passwordRef}
          gutterBottom
        />

        {/* PASS Strength */}
        {password?.length > 0 ? <PasswordStrength {...passValidation} /> : null}

        {/* CONFIRM PASSWORD */}
        <InputPassword
          placeholder="Re-Enter password"
          label="CONFIRM PASSWORD"
          hasError={() => !!confirmPassword && password !== confirmPassword}
          name="confirmPassword"
          inputRef={register({
            required: true,
            validate: (v) => v === watch('password') || "Passwords don't match",
          })}
        />

        {/* Preferred language */}
        <InputLangSelect
          control={control}
          fullWidth
          name="preferredLanguage"
          defaultValue={store?.properties?.preferredLanguage || 'English'}
          inputRef={register({
            required: true,
            minLength: 1,
          })}
          label="PREFERRED LANGUAGE"
        />

        {/* FLUENT LANGS */}
        <InputLangSearchMulti
          label="LANGUAGE(S) FLUENT IN"
          selectedLanguages={store?.properties?.languagesFluentIn || 'English'}
          onChange={onLangFluentInChange}
        />

        {renderFormControls({
          disabled: !isValid || isSubmitting,
          isSubmitting,
        })}
      </Box>
    </form>
  );
}
