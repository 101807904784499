import React from 'react';
import { IconProps } from './types';
import clock from 'src/views/styles/assets/icons/clock@2x.png';

const ClockGrey = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="clock_img"
      src={clock}
      style={{ maxWidth: compact ? 24 : '100%' }}
    />
  );
};

export default ClockGrey;
