/* eslint-disable eqeqeq */
import { Box, Grid, List } from '@material-ui/core';
import React, { useState } from 'react';
import { useRouter } from 'src/utils';
import { theme } from 'src/views/components/DSL';
import { ButtonPrimary } from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { DescriptorError } from 'src/views/components/DSL/typography';
import EmptyResults from 'src/views/pages/shared/Filters/EmptyResults';
import EnrolledCourse from './EnrolledCourse';
import MCSideNav from './MCSideNav';

interface MCEnrolledProps {
  [prop: string]: any;
}

export default function MCEnrolled({ error, byIdNumbers }: MCEnrolledProps) {
  const router = useRouter();
  const isMobile = useBreakpoint('xs');
  const courses = Object.values(byIdNumbers || {});
  const [mod, setMod] = useState('All');

  const modEtl = {
    All: 'All',
    'In-person': 'ILT',
    Webinar: 'WEB',
    Online: 'ONL',
  };
  const options = Object.keys(modEtl);

  //TODO: make this filter work
  const handleClick = (event: {
    currentTarget: {
      dataset: {
        value: any;
      };
    };
  }) => {
    const { value } = event.currentTarget.dataset;
    setMod(value);
  };

  return (
    <Grid container>
      <MCSideNav
        title="Modality Type"
        handleSelect={handleClick}
        selection={mod}
        options={options}
      />

      <Grid
        item
        xs={12}
        md={9}
        style={{ paddingLeft: isMobile ? 0 : theme.spacing(2) }}
      >
        {/* Filter */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {courses?.length} Course{`${courses?.length !== 1 ? 's' : ''}`}
          {/* FIXME: enable sort filters and determine which data is valid for comparison */}
          {/* 
            
            <Typography style={{ fontWeight: 700 }} variant="body2">
              Sort by{' '}
              <ButtonText disabled capitalize>
                Start Date
              </ButtonText>
            </Typography> 
          */}
        </Box>

        {/* List */}
        {!error && <DescriptorError gutterBottom>{error}</DescriptorError>}

        {courses?.length ? (
          <List>
            {courses.map((enrollment: any, k) => {
              const showEnrollment =
                modEtl[mod] == 'All' ||
                enrollment?.course?.type
                  ?.toLowerCase?.()
                  ?.includes(mod?.toLowerCase());

              return showEnrollment ? (
                <EnrolledCourse key={k} {...(enrollment || {})} />
              ) : null;
            })}
          </List>
        ) : (
          <EmptyResults text="You don’t have any enrolled courses. When you are enrolled in a course, you will see the course here.">
            <ButtonPrimary
              capitalize
              onClick={() => router.push('/coursecatalog?refresh=true')}
            >
              View Course Catalog
            </ButtonPrimary>
          </EmptyResults>
        )}
      </Grid>
    </Grid>
  );
}
