import React from 'react';
import { Box } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { Descriptor } from '../typography';
import theme from '../theme';
import { PasswordStrengthProps } from '.';

const PasswordStrength = ({
  minlengthpassed = false,
  minoneletterpassed = false,
  minonenumberpassed = false,
  minonespecialcharpassed = false,
}: PasswordStrengthProps) => {
  const rowProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    m: 0.25,
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="#fff"
      p={0.25}
      borderRadius="4px"
      border="1px solid #ddd"
    >
      <Box {...rowProps}>
        {minlengthpassed ? (
          <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
        ) : (
          <CancelRoundedIcon htmlColor={theme.palette.error.main} />
        )}
        <Descriptor>10+ characters</Descriptor>
      </Box>
      <Box {...rowProps}>
        {minoneletterpassed ? (
          <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
        ) : (
          <CancelRoundedIcon htmlColor={theme.palette.error.main} />
        )}
        <Descriptor>At least 1 letter</Descriptor>
      </Box>
      <Box {...rowProps}>
        {minonenumberpassed ? (
          <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
        ) : (
          <CancelRoundedIcon htmlColor={theme.palette.error.main} />
        )}
        <Descriptor>At least 1 number</Descriptor>
      </Box>
      <Box {...rowProps}>
        {minonespecialcharpassed ? (
          <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
        ) : (
          <CancelRoundedIcon htmlColor={theme.palette.error.main} />
        )}
        <Descriptor>{`At least 1 special character (!#+$%-_=<>)`}</Descriptor>
      </Box>
    </Box>
  );
};

export default PasswordStrength;
