import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

type TransitionAlertProps = AlertProps & {
  duration?: number;
  message?: string;
  severity?: string;
  [prop: string]: any;
};

export default function TransitionAlert({
  duration = 300,
  message = 'Error',
  severity = 'error',
}: TransitionAlertProps) {
  const [open, setOpen] = React.useState(true);

  return (
    <Collapse timeout={duration} in={open}>
      <Alert
        severity={severity}
        style={{ marginBottom: 8 }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Collapse>
  );
}
