import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'src/views/providers';
import { DSLButton } from 'src/views/components/DSL/buttons';
import { CheckDarkGreen } from 'src/views/components/DSL/Icons';
import { Heading1, Subheading } from 'src/views/components/DSL/typography';
import { getStudentEmail } from 'src/store/authentication/selectors';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { useRouter } from 'src/utils';

function TEConfirmed() {
  /* REDUX STATE */
  const studentEmail = useSelector(getStudentEmail);

  /* LOCAL STATE */
  const isMobile = useBreakpoint('xs');

  const { history } = useRouter();
  const toMyCourses = () => {
    if (localStorage.getItem('state')) {
      localStorage.removeItem('state');
    }
    history.push(`/mycourses/enrolled?refresh=true`);
  };
  const toCatalog = () => {
    if (localStorage.getItem('state')) {
      localStorage.removeItem('state');
    }
    history.push(`/coursecatalog?refresh=true`);
  };
  return (
    <Grid
      direction="column"
      justify="space-around"
      alignItems="flex-start"
      style={{
        padding: isMobile ? 0 : '48px 100px',
        backgroundColor: '#ffff',
      }}
    >
      {/* RENDER IF ENROLLMENT IS SUCCESSFUL  */}
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <CheckDarkGreen fontSize={'large'} />
        <br />
        <Heading1>Enrolled in Course</Heading1>
        {/* <Subheading>A confirmation will be sent to</Subheading>
        <Subheading>{studentEmail || 'student email is missing'}</Subheading> */}
        <br />
        <DSLButton
          onClick={toMyCourses}
          fullWidth={isMobile ? true : false}
          variant="contained"
          color="secondary"
          style={{ padding: 16, width: 280 }}
        >
          Go to My Courses
        </DSLButton>{' '}
        <DSLButton
          onClick={toCatalog}
          fullWidth={isMobile ? true : false}
          color="secondary"
          style={{ padding: 16, width: 280 }}
        >
          Go to Course Catalog
        </DSLButton>
      </Grid>
    </Grid>
  );
}

export default TEConfirmed;
