import courseCatalogActionTypes from './types';
import { makeActionCreator } from '../makeActionCreator';

const {
  // DIRECT STORE CHANGES
  SET_COURSES,
  SET_SELECTED_COURSE,
  SET_SELECTED_COURSE_OPTIONS,
  SET_FILTERS_FOR_COURSES,
  SET_FILTERS_FOR_COURSE_OPTIONS,
  SET_FILTERS_FOR_ALL_COURSE_OPTIONS,

  // REQUESTS
  REQUEST_LOAD_COURSES,
  REQUEST_LOAD_OPTIONS_FOR_COURSE,
  REQUEST_APPLY_FILTERS_FOR_OPTIONS,
  COMMIT_FILTERS_FOR_COURSE_OPTIONS,

  // ERRORS
  COURSE_LOAD_ERROR,
} = courseCatalogActionTypes;

const courseCatalogActionCreators = {
  setCourses: makeActionCreator(SET_COURSES, 'courses'),
  setSelectedCourse: makeActionCreator(SET_SELECTED_COURSE, 'selectedCourse'),
  setSelectedCourseOptions: makeActionCreator(
    SET_SELECTED_COURSE_OPTIONS,
    'options'
  ),

  // 06/12/2020 a.h. This should be renamed to setSetGeneralFilters
  setFiltersForCourse: makeActionCreator(
    SET_FILTERS_FOR_COURSES,
    'filters',
    'onSuccess',
    'onError'
  ),

  // 06/12/2020 a.h. This should be renamed to setFiltersForSelectedCourse
  setFiltersForCourseOptions: makeActionCreator(
    SET_FILTERS_FOR_COURSE_OPTIONS,
    'filters',
    'idnumber',
    'onSuccess',
    'onError'
  ),

  commitFiltersForCourseOptions: makeActionCreator(
    COMMIT_FILTERS_FOR_COURSE_OPTIONS,
    'filters',
    'idnumber',
    'onSuccess',
    'onError'
  ),

  setFiltersForAllCourseOptions: makeActionCreator(
    SET_FILTERS_FOR_ALL_COURSE_OPTIONS,
    'filters',
    'onSuccess',
    'onError'
  ),

  requestLoadCourses: makeActionCreator(
    REQUEST_LOAD_COURSES,
    'filters',
    'onSuccess',
    'onError'
  ),
  requestLoadCourseOptions: makeActionCreator(
    REQUEST_LOAD_OPTIONS_FOR_COURSE,
    'filters'
  ),
  requestApplyFiltersForOptions: makeActionCreator(
    REQUEST_APPLY_FILTERS_FOR_OPTIONS,
    'filtertype',
    'onSuccess',
    'onError'
  ),

  courseLoadError: makeActionCreator(COURSE_LOAD_ERROR, 'error'),
};

export default courseCatalogActionCreators;

// HomeContainer CLCContainer -> CourseCatalogContainer -> CCSelectedCourseContainer -> CCEnrollmentContainer
