import { makeStyles } from '@material-ui/core/styles';
import signupBGMedia from 'src/views/styles/assets/media/sign-up.jpg';
import { isMobile } from 'src/utils';

const drawerWidth = isMobile() ? '0%' : '50%';

const useOBLayoutStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    backgroundColor: theme.palette.grey[100],
  },
  appBar: {},
  drawer: {
    width: drawerWidth,
    zIndex: theme.zIndex.appBar - 1,
    border: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `url(${signupBGMedia})`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    height: '100vh',
    border: 'none',
    zIndex: theme.zIndex.appBar - 1,
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8.2),
    marginTop: 'auto',
    overflowY: 'scroll',
    backgroundColor: 'transparent',
  },
}));

export default useOBLayoutStyles;
