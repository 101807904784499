/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DSLButtonProps, useButtonBaseStyles } from '.';

const useStyles = makeStyles({
  root: (props: DSLButtonProps) => {
    const baseStyles = useButtonBaseStyles(props);
    return {
      ...baseStyles,
      textTransform: 'capitalize',
    };
  },
});
interface Props extends DSLButtonProps {
  focused?: boolean;
  rounded?: boolean;
  gutterRight?: boolean;
}

function ButtonOutlined({
  focused,
  rounded,
  gutterRight,
  ...props
}: DSLButtonProps & Props) {
  const classes = useStyles({ focused, rounded, gutterRight });

  return (
    <Button
      disableRipple
      disableFocusRipple
      disableTouchRipple
      className={classes.root}
      variant="outlined"
      {...props}
    />
  );
}

export default React.memo(ButtonOutlined);
