/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import React, { createContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouter } from 'src/utils';
import { Display } from 'src/views/components/DSL/typography';
import useOBLayoutStyles from '../components/hooks/useOBLayoutStyles';
import { renderFormControls } from '../components/hooks/useRenderFormControls';
import OBConsentControls from '../components/OBConsentControls';
import OBDisagree from '../components/OBDisagree';
import OBFooter from '../components/OBFooter';
import OBHeader from '../components/OBHeader';
import OBHero from '../components/OBHero';
import OBStep0 from '../components/OBStep0';
import OBStep1 from '../components/OBStep1';
import OBStep2 from '../components/OBStep2';
import OBStep3 from '../components/OBStep3';
import OBStep4 from '../components/OBStep4';
import OBStep5 from '../components/OBStep5';

export const emailRegEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const OBContext = createContext(null);
export const OBCConsumer = OBContext.Consumer;
export const OBCProvider = OBContext.Provider;

export const PERSON_FOUND = 'PERSON_FOUND';
export const PERSON_NOT_FOUND = 'PERSON_NOT_FOUND';
export const FROM_WELCOME_EMAIL = 'FROM_WELCOME_EMAIL';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const REQUEST_NEXT = 'REQUEST_NEXT';
export const REQUEST_PREV = 'REQUEST_PREV';
export const DISAGREE = 'DISAGREE';
export const SET_CONSENT = 'SET_CONSENT';
export const SHOW_ABORT_CONSENT = 'SHOW_ABORT_CONSENT';
export const CLEAR_ABORT_CONSENT = 'CLEAR_ABORT_CONSENT';

const getDispatch = (store, setStore) => ({ type, payload }) => {
  const mutations = {
    [SET_CONSENT]: () =>
      setStore({
        ...store,
        properties: {
          ...store.properties,
          ...payload,
        },
      }),

    [SHOW_ABORT_CONSENT]: () => {
      setStore({
        ...store,
        showConsentAbort: true,
      });
    },
    [CLEAR_ABORT_CONSENT]: () =>
      setStore({
        ...store,
        showConsentAbort: false,
      }),

    [PERSON_FOUND]: () =>
      setStore({
        ...store,
        properties: payload,
      }),
    [PERSON_NOT_FOUND]: () =>
      setStore({
        ...store,
        properties: {},
      }),

    [FROM_WELCOME_EMAIL]: () =>
      setStore({
        ...store,
        activated: true,
        properties: {
          ...store.properties,
          email: payload.email,
        },
      }),
    [SET_ATTRIBUTES]: () =>
      setStore({
        ...store,
        properties: {
          ...store.properties,
          ...payload,
        },
      }),
  };

  if (typeof mutations[type] === 'function') {
    mutations[type]();
  }
};

const renderConsentControls = ({
  disabled,
  onDisagree,
  onAgree,
  isSubmitting,
  children = null,
}) => (
  <OBConsentControls
    disabled={disabled}
    onDisagree={onDisagree}
    onAgree={onAgree}
    isSubmitting={isSubmitting}
  >
    {children}
  </OBConsentControls>
);

function OnboardingContainer() {
  const classes = useOBLayoutStyles();
  const { query, location, ...router } = useRouter();
  const [store, setStore] = useState({
    // form control meta data
    step: location.pathname?.split?.('/')?.[2] || 0,
    activated: false,

    showConsentAbort: false,

    // form data to be sent
    /** FIXME: a.h. typecheck */
    properties: {
      pcrSigned: 'PENDING',
      cocSigned: 'PENDING',
    },
  });

  const dispatch = getDispatch(store, setStore);

  useEffect(() => {
    const email = new URLSearchParams(query)?.get?.('email');

    if (emailRegEx.test(email)) {
      dispatch({
        type: FROM_WELCOME_EMAIL,
        payload: {
          email,
        },
      });
    }
  }, [query]);

  console.log(process.env.PUBLIC_URL);

  return (
    <Box className={classes.root}>
      <OBHeader classes={classes} />
      <OBHero classes={classes} />

      {/* FORM STEPS */}
      <Box className={classes.content}>
        <OBCProvider value={{ store, dispatch }}>
          {/* Title + Stepper */}
          <Display gutterBottom>
            {store?.activated ? 'Create Login' : 'Sign Up'}
          </Display>

          {/* Form Wizard */}
          <Switch>
            <Route exact path="/onboarding/0">
              <OBStep0 renderFormControls={renderFormControls} />
            </Route>
            <Route exact path="/onboarding/1">
              <OBStep1 renderFormControls={renderFormControls} />
            </Route>

            <Route exact path="/onboarding/2">
              <OBStep2 renderFormControls={renderFormControls} />
            </Route>

            <Route exact path="/onboarding/3">
              {/* Provider Consent Release */}
              <OBDisagree />
              <OBStep3 renderConsentControls={renderConsentControls} />
            </Route>

            <Route exact path="/onboarding/4">
              {/* Provider Consent Release */}
              <OBDisagree />
              <OBStep4 renderConsentControls={renderConsentControls} />
            </Route>

            <Route exact path="/onboarding/5">
              <OBStep5 />
            </Route>

            <Route exact path="/b2cstatic/index.html">
              <div id="api"></div>
            </Route>
          </Switch>
        </OBCProvider>
        {/* <Toolbar /> */}
      </Box>

      <OBFooter />
    </Box>
  );
}

export default OnboardingContainer;
