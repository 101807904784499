import React from 'react';
import { ReactComponent as LogOutDefault } from 'src/views/styles/assets/icons/logout_default.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

function LogoutDefault({ fontSize = 'small', ...props }: IconProps) {
  return <Icon component={LogOutDefault} fontSize={fontSize} {...props} />;
}

export default LogoutDefault;
