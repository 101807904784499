/* eslint-disable react-hooks/rules-of-hooks */
import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getDashboard } from 'src/store/authentication/selectors';
import { getEnrolledCoursesAsArray } from 'src/store/enrolledCourses/selectors';
import { Dashboard } from 'src/types';
import { useRouter } from 'src/utils';
import { TPLogo } from 'src/views/components/DSL/Icons';
import { BodyLink, DescriptorError } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import CourseOptionsCTA from './CourseOptionsCTA';
import PreviewCard from './PreviewCard';
import PreviewContent from './PreviewContent';
import PreviewHeader from './PreviewHeader';
import ProgressCard from './ProgressCard';

interface CLCPreviewProps extends RouteComponentProps {
  dashboard?: Dashboard;
}

function CLCPreview({ dashboard }: CLCPreviewProps) {
  const errorStyles = { fontSize: 16 };
  const progress =
    (dashboard || useSelector(getDashboard)).inProgress?.[0] || null;
  const error = !progress;
  const router = useRouter();
  const enrolled = useSelector(getEnrolledCoursesAsArray).length || 0;
  const available =
    useSelector((state) => state?.coursecatalog?.courses?.codes)?.length || 0;

  return (
    <PreviewCard>
      <BodyLink to="/caregiverlearningcenter">
        <PreviewHeader
          title="Caregiver Learning Center"
          subheader="Manage your courses, enroll and more"
          action={
            <IconButton
              disableRipple
              disableTouchRipple
              disableFocusRipple
              edge="end"
              onClick={() => router.push('/caregiverlearningcenter')}
            >
              <ChevronRight color="secondary" />
            </IconButton>
          }
          avatar={<TPLogo size="medium" />}
        />
      </BodyLink>
      <PreviewContent>
        {error ? (
          <DescriptorError style={errorStyles}>
            No active requirements found.
          </DescriptorError>
        ) : (
          <>
            {/* Progress Card */}
            {progress && <ProgressCard {...progress} />}

            {/* Course Options */}
            {progress && (
              <CourseOptionsCTA
                enrolled={enrolled}
                // {progress?.enrolledHours || 0}
                available={available}
              />
            )}
          </>
        )}
      </PreviewContent>
    </PreviewCard>
  );
}

export default withRouter(React.memo(CLCPreview));
