import { useState } from 'react';

export default function useFilters({ initialState, schema }) {
  const [filters, setFilters] = useState(initialState);
  const [errors, setErrors] = useState({});

  const onChange = (event = null) => {
    if (event) {
      const name = event?.target?.name;
      const value = event?.target?.value;

      setFilters({
        ...filters,
        [name]: value,
      });

      const rules = schema?.[name];

      if (rules?.required && rules?.isValid) {
        setErrors({
          [name]: !rules.isValid(value),
        });
      }
    }
  };

  return [filters, errors, onChange];
}
