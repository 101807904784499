/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Fab,
  GridList,
  makeStyles,
  RootRef,
  FabProps,
} from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import CourseItemCompact from './CourseItemCompact';
import { LOCK_STATUSES } from 'src/store/consts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 1,
  },
  gridList: {
    flexWrap: 'nowrap',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    transform: 'translateZ(0)',
    flexGrow: 1,
  },
  tile: {
    cursor: 'pointer',
    scrollSnapAlign: 'start',
  },
}));

interface CursorButtonProps extends Omit<FabProps, 'children'> {
  next?: boolean;
  prev?: boolean;
}

function CursorButton({
  style = {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    zIndex: 9999,
    backgroundColor: '#fff',
    border: `2px solid #ddd`,
  },
  hidden = true,
  onClick,
  next = true,
  prev,
  ...props
}: CursorButtonProps) {
  return (
    !hidden && (
      <Fab
        onClick={onClick}
        size="small"
        style={{
          position: 'absolute',
          top: 'calc(50% - 20px)',
          zIndex: 9999,
          backgroundColor: '#fff',
          border: `1px solid #e2e5e9`,
          boxShadow: `0 2px 10px 0 rgba(0, 0, 0, 0.1)`,
          right: next ? 5 : undefined,
          left: prev ? 5 : undefined,
          ...style,
        }}
        {...props}
      >
        {prev ? (
          <ArrowBackIosRounded fontSize="small" color="secondary" />
        ) : (
          <ArrowForwardIosRounded fontSize="small" color="secondary" />
        )}
      </Fab>
    )
  );
}

function CatalogPreview({ isMobile, courses, expand }) {
  const classes = useStyles();
  const carouselRef = useRef(null);
  const [{ left, right, width }, setScroll] = useState({
    left: 0,
    right: 2000,
    width: undefined,
  });

  const coursePreviews = [];

  let i = 0;

  for (const coursekey in courses.byCourseCodes) {
    const course = courses.byCourseCodes[coursekey];

    i = i + 1;
    if (course.lockStatus !== LOCK_STATUSES.HIDDEN) {
      coursePreviews.push(
        <GridListTile
          onClick={expand}
          className={classes.tile}
          key={`${i}${course.idnumber}`}
        >
          <CourseItemCompact {...course} />
        </GridListTile>
      );
    }
  }

  useEffect(() => {
    setScroll({
      left: parseInt(carouselRef.current.scrollLeft),
      right: carouselRef.current.scrollWidth - carouselRef.current.scrollLeft,
      width: parseInt(carouselRef.current.getBoundingClientRect().width),
    });
  }, []);

  const onScroll = () => {
    setScroll({
      width,
      left: parseInt(carouselRef.current.scrollLeft),
      right: carouselRef.current.scrollWidth - carouselRef.current.scrollLeft,
    });
  };
  const hideLeft = left <= 50;
  const hideRight = right - width <= 50;

  return (
    <Box className={classes.root} maxWidth={1}>
      <CursorButton
        onClick={() => {
          carouselRef.current.scrollLeft -= width;
        }}
        hidden={hideLeft}
        prev
      />

      <RootRef rootRef={carouselRef}>
        <GridList
          cellHeight="auto"
          className={classes.gridList}
          onScroll={onScroll}
          cols={isMobile ? 2 : 3}
        >
          {coursePreviews}
        </GridList>
      </RootRef>

      <CursorButton
        onClick={() => {
          carouselRef.current.scrollLeft += width;
        }}
        hidden={hideRight}
        next
      />
    </Box>
  );
}

export default CatalogPreview;
