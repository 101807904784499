import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import { Cancel, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { useInputStyles } from '.';
import { Descriptor, DescriptorError } from '../typography';

interface PasswordProps {
  label?: string;
  onChange?: (event: any) => void;
  value?: any;
  gutterBottom?: boolean;
  placeholder?: string;
  error?: string;
  errors?: any;
  inputRef?: any;
  name?: string;
  defaultValue?: string;
  reset?: () => void;
  [propName: string]: any;
}

export default function InputPassword({
  name,
  error = '',
  label = '',
  placeholder = '(123) 456 7890',
  inputRef = undefined,
  gutterBottom = true,
  defaultValue = '',
  reset = undefined,
  ...rest
}: PasswordProps) {
  const classes = useInputStyles();
  const [showPass, setshowPass] = useState(false);

  return (
    <Box mb={gutterBottom && 1}>
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <OutlinedInput
        fullWidth
        classes={classes}
        type={showPass ? 'text' : 'password'}
        placeholder={placeholder}
        inputRef={inputRef}
        margin="dense"
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
        name={name}
        defaultValue={defaultValue}
        error={error?.length > 0}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setshowPass(!showPass)}
              edge="start"
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            <IconButton
              aria-label="toggle password visibility"
              onClick={reset}
              edge="end"
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
      {error && <DescriptorError gutterBottom>{error}</DescriptorError>}
    </Box>
  );
}
