import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { LOCK_STATUSES } from 'src/store/consts';
import { CourseSchema } from 'src/types';
import {
  getDistance,
  getVerticalFromCourseId,
  objIsEmpty,
  stringifyError,
} from 'src/utils';
import { getLatLong } from '../api';
import lmsService from '../api/LMSService';
import config from '../config';
import {
  getTranscriptsCompletionIds,
  getTranscriptsCompletions,
} from '../transcripts/selectors';
import { arrayToObject } from './../../utils/index';
import { getLearnerSteps } from './../learnersteps/index';
import courseCatalogActionCreators from './actioncreators';
import courseCatalogSelectors from './selectors';
import courseCatalogActionTypes from './types';

const { FILTER_DEFAULTS, MODALITY_TYPES } = config;
const {
  getFilters,
  getFiltersForSelectedCourse,
  getCoursesQueryObjFromFilters,
} = courseCatalogSelectors;
const {
  setCourses,
  setFiltersForCourseOptions,
  setSelectedCourseOptions,
} = courseCatalogActionCreators;
const { SET_SELECTED_COURSE, REQUEST_LOAD_COURSES } = courseCatalogActionTypes;

function* extractFiltersAndLoadCourses(action) {
  try {
    const learnerSteps = yield select(getLearnerSteps);
    const currentFilters = yield select(getFilters);

    if (!currentFilters?.code?.length && !currentFilters?.tag?.length) {
      return {
        byCourseCodes: {},
        codes: [],
      };
    }

    let filteredCourses: CourseSchema[] = (yield call(
      lmsService.getFilteredCourses,
      getCoursesQueryObjFromFilters(currentFilters)
    ))?.data;

    if (!objIsEmpty(currentFilters?.forbiddenCourses)) {
      filteredCourses = filteredCourses.filter(
        (fcourse) => !currentFilters?.forbiddenCourses?.[fcourse.id]
      );
    }

    const transcriptCompletionIds = yield select(getTranscriptsCompletionIds);
    const completions = yield select(getTranscriptsCompletions);
    // filter out all the courses that have been completed before
    if (transcriptCompletionIds.length > 0) {
      // filters courses with same dshs id
      filteredCourses = filteredCourses.filter(
        (fcourse) => !completions.find((item) => fcourse.dshsId === item.dshsId)
      );

      // filters courses with same TP generated course id
      filteredCourses = filteredCourses.filter(
        (fcourse) =>
          !transcriptCompletionIds.find((item) =>
            item.includes(fcourse.idnumber.substring(0, 7))
          )
      );
    }

    /* Initial load */
    const results = arrayToObject(
      filteredCourses,
      'idnumber',
      (element: CourseSchema & any) => {
        // const verticalFromIdNumber = getVerticalFromCourseId(element.idnumber);
        // const stepForVertical = learnerSteps?.[verticalFromIdNumber]?.step || 0;

        const lockStatus = LOCK_STATUSES.UNLOCKED;
        // stepForVertical > element.sequence
        //   ? LOCK_STATUSES.HIDDEN
        //   : stepForVertical < element.sequence
        //   ? LOCK_STATUSES.LOCKED
        //   : LOCK_STATUSES.UNLOCKED;

        return {
          ...element,
          lockStatus,
        };
      }
    );

    const normalizedCourses = {
      byCourseCodes: results,
      codes: Object.keys(results),
    };

    return normalizedCourses;
  } catch (error) {
    if (typeof action.onError === 'function') {
      action.onError(stringifyError(error));
    }
  }
}

function* onRequestLoadCourses(action) {
  try {
    const normalizedCourses = yield call(extractFiltersAndLoadCourses, action);

    yield put(setCourses(normalizedCourses));

    if (typeof action.onSuccess === 'function') {
      action.onSuccess();
    }
  } catch (error) {
    if (typeof action.onError === 'function') {
      action.onError(stringifyError(error));
    }
  }
}

/**
 * Procedure outline:
 * - get courseid from action
 * - call loadAndSyncCourseOptions
 * - if no filters are present for this
 *   courses' options
 *    - construct default filters
 *    - call set filters for options
 * - for each item in course options
 *   - calculate the distance
 */
function* onSelectCourse(action) {
  try {
    const { selectedCourse } = action;
    const filtersForCourse = yield select(getFiltersForSelectedCourse);

    let defaultFilters: any = {};

    if (!filtersForCourse) {
      defaultFilters = {
        // zipcode: select(getZipcodeFromAddress) || null,
        zipcode: null,
        radius: FILTER_DEFAULTS.radius,
      };

      yield put(
        setFiltersForCourseOptions(defaultFilters, selectedCourse.idnumber)
      );
    }

    /**
     * Procedure outline:
     *  - get latlong of block
     *  - take student latlong
     *  - compute the distance between
     *    the two
     *  - set block.distanceFromUser = distance
     */
    let newOptions = [];
    const userLatLong = defaultFilters.zipcode
      ? yield call(getLatLong, defaultFilters.zipcode)
      : null;

    /**
     * 06/03/2020 a.h. NOTE:
     *
     * Only need to perform the folliwing if block
     * once any given course is selected, the onSelectCourse
     * saga loads all other course options level data modulo
     * distance from user.
     */
    if (userLatLong) {
      newOptions = yield all(
        selectedCourse.options.map(async (block) => {
          const newBlock = { ...block };

          if (
            block.type === MODALITY_TYPES.ILT &&
            block.firstsession?.address
          ) {
            const blockLatLong = await getLatLong(block.firstsession?.address);
            newBlock.distanceFromUser = await getDistance(
              userLatLong,
              blockLatLong
            );
          }

          return newBlock;
        })
      );

      yield put(setSelectedCourseOptions(newOptions));
    }
  } catch (error) {
    if (typeof action.onError === 'function') {
      action.onError(stringifyError(error));
    }
  }
}

export default function* courseCatalogSagas() {
  try {
    yield takeEvery(SET_SELECTED_COURSE, onSelectCourse);
    yield takeLatest(REQUEST_LOAD_COURSES, onRequestLoadCourses);
  } catch (error) {
    console.log('ERROR: ', stringifyError(error));
  }
}
