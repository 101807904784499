/* eslint-disable react-hooks/exhaustive-deps */
import { Fab, Grid } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { courseCatalogActionCreators } from 'src/store/coursecatalog';
import { useDebounce } from 'src/utils';
import { COLORS, theme } from 'src/views/components/DSL';
import InputText from 'src/views/components/DSL/form/InputText';
import { useDispatch } from 'src/views/providers';

/* CCSearchFilter
 On Desktop,
 - round icon button with search image
 On Mobile, Not shown
 */

function CCExpandSearchInput({ onClick }) {
  const fabStyles = {
    backgroundColor: '#fff',
    border: `1px solid ${COLORS.BLUE[300]}`,
    color: theme.palette.secondary.main,
    // fontSize: 14,
  };
  return (
    <Fab onClick={onClick} size="small" style={fabStyles}>
      <SearchOutlined color="inherit" fontSize="inherit" />
    </Fab>
  );
}

function CCSearchFilter() {
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [keyword, setKeyword] = useState('');

  const debouncedCourseName = useDebounce({ value: keyword, delay: 700 });

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
  };

  useEffect(() => {
    let unmounted = false;

    if (unmounted) {
      return;
    }

    console.log(debouncedCourseName);

    dispatch(
      courseCatalogActionCreators.setFiltersForCourse({
        name: debouncedCourseName,
      })
    );
    dispatch(
      courseCatalogActionCreators.requestLoadCourses({
        name: debouncedCourseName,
      })
    );
    return () => {
      unmounted = true;
    };
  }, [debouncedCourseName]);

  return (
    <Grid
      item
      xs={focused ? true : 'auto'}
      sm={focused ? 4 : 'auto'}
      alignItems="center"
    >
      {!focused ? (
        <CCExpandSearchInput onClick={() => setFocused(true)} />
      ) : (
        <InputText
          rounded
          startAdornment={
            <SearchOutlined
              style={{ marginLeft: 0, marginRight: 8, cursor: 'pointer' }}
              onClick={() => setFocused(false)}
              color="secondary"
              fontSize="inherit"
            />
          }
          gutterBottom={false}
          style={{
            margin: 0,
          }}
          placeholder="Course name"
          onChange={handleOnChange}
          value={keyword}
          size="small"
        />
      )}
    </Grid>
  );
}

export default React.memo(CCSearchFilter);
