import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  GridProps,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Descriptor, Heading4 } from 'src/views/components/DSL/typography';

const useStyles = ({ gutterLeft = false, gutterBottom = false }) => {
  const basePadding = '8px 12px';

  return makeStyles({
    root: {
      borderRadius: 6,
      marginBottom: gutterBottom ? 10 : undefined,
      marginLeft: gutterLeft ? 10 : undefined,
    },
    header: {
      padding: basePadding,
      paddingTop: 20,
      justifySelf: 'flex-start',
      paddingBottom: 0,
    },
    actions: {
      padding: basePadding,
      justifySelf: 'flex-end',
    },
    content: {
      padding: basePadding,
      flexGrow: 1,
    },
  });
};

interface Props {
  children?: React.ReactNode;
  title?: string;
  subheader?: string;
  gutterLeft?: boolean;
  gutterBottom?: boolean;
  gridProps?: GridProps;
  renderActions?: () => React.ReactNode;
}

function PreviewSection({
  title = '',
  subheader = '',
  gutterLeft = false,
  gutterBottom = false,
  children,
  gridProps,
  renderActions,
}: Props) {
  const classes = useStyles({ gutterBottom, gutterLeft })();

  return (
    <Card
      variant="outlined"
      component={(props) =>
        props?.children && (
          <Grid
            direction="column"
            item={true}
            xs={12}
            {...gridProps}
            {...props}
          >
            {props.children}
          </Grid>
        )
      }
      className={classes.root}
    >
      {(title || subheader) && (
        <CardHeader
          className={classes.header}
          title={title && <Heading4 gutterBottom>{title}</Heading4>}
          subheader={subheader && <Descriptor>{subheader}</Descriptor>}
          disableTypography
        />
      )}
      {children && (
        <CardContent
          component={Grid}
          container={true}
          className={classes.content}
        >
          {children}
        </CardContent>
      )}
      {renderActions && (
        <>
          <Divider />
          <CardActions className={classes.actions} disableSpacing>
            {renderActions()}
          </CardActions>
        </>
      )}
    </Card>
  );
}

export default PreviewSection;
