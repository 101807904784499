import { ReactComponent as ContactUsDefaultLogo } from 'src/views/styles/assets/icons/contact-us_default.svg';
import React from 'react';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

export default function ContactUsDefault({
  fontSize = 'small',
  ...props
}: IconProps) {
  return (
    <Icon component={ContactUsDefaultLogo} fontSize={fontSize} {...props} />
  );
}
