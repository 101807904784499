import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardMedia,
  CardProps,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Lock } from '@material-ui/icons';
import React from 'react';
import { MOODLE } from 'src/envvars';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Body2, Descriptor } from 'src/views/components/DSL/typography';

export default function CourseItemCompact(
  props: CardProps & CSSProperties & { [x: string]: any }
) {
  const image = props?.image
    ? `${MOODLE}${props?.image?.replace(/ /g, '%20')}`
    : '';
  const isMobile = useBreakpoint('xs');
  const lock =
    props.lockStatus === 'LOCKED' ? (
      <Lock color="inherit" fontSize="default" />
    ) : null;

  const cardStyles = {
    ...props.style,
    opacity: props.lockStatus === 'LOCKED' ? 0.6 : 1,
    cursor: props.lockStatus === 'LOCKED' ? 'not-allowed' : 'pointer',
  };

  return (
    <Card variant="outlined" style={cardStyles}>
      <CardMedia
        component="img"
        src={image}
        height="120"
        alt="Course image"
        image={image}
        style={{ backgroundColor: 'lavender' }}
      />
      <CardContent
        style={{
          padding: `4px 8px`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: isMobile ? 90 : 110,
          width: '100%',
        }}
      >
        <Box overflow="hidden" textOverflow="ellipsis" maxWidth={1}>
          <Body2
            noWrap
            style={{ fontSize: 14, fontWeight: 700, color: '#383c42' }}
          >
            {props.fullname}
            {lock}
          </Body2>
          <Descriptor>{capitalize(props?.type)}</Descriptor>
        </Box>
      </CardContent>
    </Card>
  );
}
