import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouter } from 'src/utils';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { useSelector } from 'src/views/providers';
import ECContent from '../components/ECContent';
import ECCourseMeta from '../components/ECCourseMeta';
import ECHero from '../components/ECHero';
import ECLockedMessageBox from '../components/ECLockedMessageBox';
import { default as Section } from '../components/ECMetaSection';
import ECOptionsCatalog from '../components/ECOptionsCatalog';
import ECOptionsConfirmed from '../components/ECOptionsConfirmed';
import ECOptionsReview from '../components/ECOptionsReview';
import ECWrapper from '../components/ECWrapper';
import useECStyles from '../components/hooks/useECStyles';

function EnrollmentContainer() {
  const { location } = useRouter();
  const isMobile = useBreakpoint('xs');
  const selectedCourse = useSelector(
    (state) => state.coursecatalog.selectedCourse
  );
  const classes = useECStyles(selectedCourse);
  const [value, setValue] = useState(0);
  let code: string =
    selectedCourse?.idnumber ||
    new URLSearchParams(location.search).get('code');
  const links = ['About', 'Enroll'];

  const handleScrollChange = (_event, newValue) => {
    const el = document.getElementById(links[newValue]);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setValue(newValue);
  };
  code = code?.substring(0, 3);
  const sectProps = {
    backgroundColor: '#f7f8f9',
    minHeight: '75vh',
    maxHeight: 'auto',
  };

  return (
    <ECWrapper isMobile={isMobile} className={classes.root} container>
      <ECHero
        isMobile={isMobile}
        classes={classes}
        image={selectedCourse?.image}
      >
        {selectedCourse?.fullname && selectedCourse?.fullname}
      </ECHero>

      <ECContent isMobile={isMobile} classes={classes}>
        <Grid container direction="column" className={classes.content}>
          {selectedCourse?.lockStatus === 'LOCKED' && <ECLockedMessageBox />}
          {location.pathname.includes('/filters') && (
            <ECCourseMeta
              value={value}
              handleChange={handleScrollChange}
              links={links}
              code={code}
              course={selectedCourse}
            />
          )}
          <Switch>
            <Route path="/enrollment/filters">
              <Section id="Enroll" style={sectProps}>
                <ECOptionsCatalog />
              </Section>
            </Route>
            <Route path="/enrollment/review">
              <Section
                style={{
                  height: '100vh',
                  backgroundColor: '#f7f8f9',
                  paddingRight: 16,
                }}
              >
                <ECOptionsReview />
              </Section>
            </Route>
            <Route path="/enrollment/success">
              <Section
                style={{
                  height: '100vh',
                  backgroundColor: '#fff',
                  paddingRight: 16,
                }}
              >
                <ECOptionsConfirmed />
              </Section>
            </Route>
          </Switch>
        </Grid>
      </ECContent>
    </ECWrapper>
  );
}

export default EnrollmentContainer;
