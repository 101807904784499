import React from 'react';
import { IconProps } from './types';
import minusCircleOrange from 'src/views/styles/assets/icons/minus-circle_orange@2x.png';

const MinusCircleOrange = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="minusCircleOrange_img"
      src={minusCircleOrange}
      style={{ maxWidth: compact ? 14 : '100%' }}
    />
  );
};

export default MinusCircleOrange;
