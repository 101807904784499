export const DATE_FORMAT = 'MMM DD, YYYY';

export const TRAINING_CODE = {
  OS: {
    CODE: '100',
    NAME: 'Orientation and Safety',
    HOURS: '5',
    REQUIRED: true,
  },
  BT_7: {
    CODE: '204',
    NAME: 'Parent Provider Basic Training 7 Hours',
    HOURS: '7',
    REQUIRED: true,
  },
  BT_9: {
    CODE: '203',
    NAME: 'Respite Basic Training 9 Hours',
    HOURS: '9',
    REQUIRED: true,
  },
  BT_30: {
    CODE: '202',
    NAME: 'Basic Training 30 Hours',
    HOURS: '30',
    REQUIRED: true,
  },
  BT_70: {
    CODE: '201',
    NAME: 'Basic Training 70 Hours',
    HOURS: '70',
    REQUIRED: true,
  },
  SAFETY: { CODE: '901', NAME: 'Safety', HOURS: '', REQUIRED: true },
  ORIENTATION: {
    CODE: '902',
    NAME: 'Orientation',
    HOURS: '',
    REQUIRED: true,
  },
  REFRESHER: { CODE: '200', NAME: 'Refresher', HOURS: '', REQUIRED: false },
  BT_42: {
    CODE: '913',
    NAME: 'Basic Training 42 Hours',
    HOURS: '42',
    REQUIRED: true,
  },
  MFOC: {
    CODE: '912',
    NAME: 'Modified Fundamentals of Care',
    HOURS: '',
    REQUIRED: false,
  },
  RFOC: {
    CODE: '911',
    NAME: 'Revised Fundamentals of Care',
    HOURS: '',
    REQUIRED: false,
  },
  CE_12: {
    CODE: '300',
    NAME: 'Continuing Education 12 Hours',
    HOURS: '12',
    REQUIRED: true,
  },
  CBP_WINTER2019: {
    CODE: '301',
    NAME: 'Caregiver Best Practices Winter 2019',
    HOURS: '',
    REQUIRED: false,
  },
  NDC_9: {
    CODE: '400',
    NAME: 'Nurse Delegation Core 9 Hours',
    HOURS: '',
    REQUIRED: false,
  },
  NDD_3: {
    CODE: '401',
    NAME: 'Nurse Delegation Diabetes 3 Hours',
    HOURS: '',
    REQUIRED: false,
  },
  EXEMPT_BT70: {
    CODE: '602',
    NAME: 'Exempt Certification Benefit 70 Hours',
    HOURS: '',
    REQUIRED: false,
  },
  AHCAS: {
    CODE: '500',
    NAME: 'Advanced Training',
    HOURS: '70',
    REQUIRED: false,
  },
  CE_OPEN_LIB: {
    CODE: '601',
    NAME: 'CE Open Library',
    HOURS: '',
    REQUIRED: false,
  },
  MINDFUL_WC: {
    CODE: '603',
    NAME: 'Mindfulness at Work for Caregivers',
    HOURS: '',
    REQUIRED: false,
  },
  BT_UMBRELLA: {
    CODE: '000',
    NAME: 'BT Umbrella *INTERNAL USE ONLY NOT A VALID CODE*',
    HOURS: '',
    REQUIRED: false,
  },
};

export enum TRAINING_STATUS {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

// course numbers and BT courses don't have dshs id
export enum BT30_SKILLS {
  MOBILITY = '2020184',
  PERSONALCARE = '2020186',
  PREP = '2020188',
}

//BT9 and BT30 mandatory classes
export enum BT_MANDATORY {
  // This course has no dshsId; use the first 7 digits of the course code
  CAREGIVER_CLIENT_EXP = '2020379',
  CAREGIVER_CLIENT_EXP_LEGACY_203 = '2030379', // legacy code 1 for CAREGIVER_CLIENT_EXP
  CAREGIVER_CLIENT_EXP_LEGACY_300 = '3000379', // legacy code 2 for CAREGIVER_CLIENT_EXP
  // the courses below use dshsId
  PERSON_CENTERED_APPROACH = '3000066',
  PERSON_CENTERED_APPROACH_DSHSID = 'CO1728776',
  RECOGNIZING_REPORTING_CONSUMER_ABUSE = '3000070',
  RECOGNIZING_REPORTING_CONSUMER_ABUSE_DSHSID = 'CE135036',
  REDUCING_THE_SPREAD_OF_INFECTION = '3000071',
  REDUCING_THE_SPREAD_OF_INFECTION_DSHSID = 'CE135037',
  MEDICATION_ASSISTANCE = '3000051', //BT30 only
  MEDICATION_ASSISTANCE_DSHSID = 'CE1832555',
}

// dshs id?
export enum HIGHLYRECOMMENDED {
  CBP_OVEREXERTION = '3000353',
}

export const mandatory = 'mandatory' || 'Mandatory' || 'MANDATORY';

export const US_STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
