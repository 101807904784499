/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, Hidden } from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import lmsService from 'src/store/api/LMSService';
import config from 'src/store/config';
import { FILTER_MODAL_CONTENT_TYPES, LOCK_STATUSES } from 'src/store/consts';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { enrollmentActions } from 'src/store/enrollment';
import { CourseSchema } from 'src/types';
import { objIsEmpty, useRouter } from 'src/utils';
import { ButtonPrimary } from 'src/views/components/DSL/buttons';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import FormPopover from 'src/views/components/DSL/form/FormPopover';
import { FilterDialsBlue } from 'src/views/components/DSL/Icons';
import { TransitionAlert } from 'src/views/components/DSL/info';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Heading3, Subheading } from 'src/views/components/DSL/typography';
import DateFilter from 'src/views/pages/shared/Filters/DateFilter';
import DistanceFilter from 'src/views/pages/shared/Filters/DistanceFilter';
import useFilters from 'src/views/pages/shared/Filters/useFilters';
import { useDispatch, useSelector } from 'src/views/providers';
import OptionCard from '../../CourseCatalog/components/OptionCard/OptionCard';
import EmptyResults from '../../shared/Filters/EmptyResults';
import { getCourseToSessoinBlock } from './hooks/helpers';

const getInitialFilters = (props) => ({
  initialState: {
    zipcode: props.zipcode || 98105,
    radius: props.radius || 45,
    startdate: moment().toISOString(),
    enddate: moment().add(10, 'days').toISOString(),
    maxdate: props.maxdate || moment().add(50, 'days').toISOString(),
  },
  schema: {
    zipcode: {
      type: 'number',
      name: 'zipcode',
      required: true,
      isValid: (value) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value),
    },
    radius: {
      name: 'radius',
      required: true,
      errorMessage: 'Invalid radius',
      max: 100,
      min: 10,
    },
    startdate: {
      name: 'startdate',
      required: true,
      errorMessage: 'Invalid start date',
    },
    enddate: {
      name: 'startdate',
      required: true,
      errorMessage: 'Invalid start date',
    },
  },
});

const TITLES = {
  [FILTER_MODAL_CONTENT_TYPES.DATES]: 'Dates',
  [FILTER_MODAL_CONTENT_TYPES.DISTANCE]: 'Distance',
};
const FilterContent = {
  [FILTER_MODAL_CONTENT_TYPES.DATES]: DateFilter,
  [FILTER_MODAL_CONTENT_TYPES.DISTANCE]: DistanceFilter,
};

const { setSelectedOptionForEnrollment } = enrollmentActions;

function ECOptionsCatalog() {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs');

  /* Manages Form Container Visibility */
  const [error, setError] = useState('');
  const [dopen, setDOpen] = useState(false);
  const [popen, setPOpen] = useState(false);
  const [contentKey, setContentKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const selectedCourse = useSelector(courseCatalogSelectors.getSelectedCourse);

  const openFormPopover = () => setPOpen(true);
  const closeFormPopover = () => setPOpen(false);
  const openFormDialog = () => setDOpen(true);
  const closeFormDialog = () => setDOpen(false);

  const openFilter = (contentKey, event) => {
    event.persist();

    if (!contentKey || !event.currentTarget) {
      console.error('No contentKey found for filter popover');
      return;
    } else {
      if (isMobile) {
        openFormDialog();
      } else {
        openFormPopover();
      }

      setAnchorEl(event.currentTarget);
      setContentKey(contentKey);
    }
  };

  /* Manages Form Data Entry/Submission */
  const [applyingFilters, setApplyingFilters] = useState(false);
  const [formData] = useFilters(getInitialFilters(config.FILTER_DEFAULTS));

  const handleSubmit = () => {
    setApplyingFilters(true);
    if (isMobile) {
      closeFormDialog();
    } else {
      closeFormPopover();
    }
  };

  /* TODO: NOTE - data range filter uses different onchange functionality */
  const formBody = FilterContent[contentKey]?.({ formData });
  const totalOptions = selectedCourse?.totalOptions;
  const [options, setOptions] = useState([]);

  const cancel = Axios.CancelToken;

  useEffect(() => {
    async function fetchData(courseId: CourseSchema) {
      try {
        console.log(courseId);

        const response = (await lmsService.getBlocks(courseId, cancel))?.data
          ?.data;
        setOptions(response);
        setError('');
      } catch (error) {
        setOptions([]);
        setError('Unable to load options for course');
      }
    }

    // if online course selected, normalize it as an array of session blocks
    if (
      selectedCourse?.type?.toUpperCase?.() === config.MODALITY_TYPES.ONLINE
    ) {
      const onlineOptionAsBlock = getCourseToSessoinBlock(selectedCourse);

      setOptions(onlineOptionAsBlock);
    } else {
      fetchData(selectedCourse?.id);
    }
  }, [selectedCourse]);

  const onSelectGoToReview = (option) => {
    const query = router.location.search;

    if (!query?.length || objIsEmpty(option)) {
      setError('Unable to load enrollment option');
      return;
    }

    dispatch(setSelectedOptionForEnrollment(option));
    router.push(`/enrollment/review${query}&optionid=${option?.id}`);
  };

  return (
    <>
      {selectedCourse?.lockStatus === LOCK_STATUSES.LOCKED ? (
        <>Locked</>
      ) : (
        <>
          {/* filter popover container */}
          <Hidden smUp>
            <Dialog fullScreen={isMobile} open={dopen}>
              <Button onClick={closeFormDialog}>close</Button>
            </Dialog>
          </Hidden>

          {/* filter popover container */}
          <FormPopover
            onCancel={closeFormPopover}
            open={popen}
            anchorEl={anchorEl}
            onSubmit={handleSubmit}
            formTitle={TITLES[contentKey]}
            isSubmitting={applyingFilters}
            PaperProps={{
              variant: 'outlined',
              style: {
                borderRadius: 6,
                marginTop: 10,
                height: 'auto',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {formBody}
          </FormPopover>

          {error ? (
            <TransitionAlert message={error} />
          ) : (
            <Box
              marginBottom="15px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Heading3 display="inline">Select a Course Option</Heading3>
              <Subheading display="inline">
                {totalOptions || 0} {`option(s)`}
              </Subheading>
            </Box>
          )}

          {/* Desktop / Mobile Filter Triggers */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent={isMobile ? 'stretch' : 'flex-start'}
          >
            {isMobile ? (
              <ButtonOutlined
                onClick={openFilter.bind(
                  null,
                  FILTER_MODAL_CONTENT_TYPES.OVERVIEW
                )}
                style={{ backgroundColor: 'white' }}
                fullWidth
                rounded
                startIcon={<FilterDialsBlue color="inherit" compact />}
                color="secondary"
              >
                Filter
              </ButtonOutlined>
            ) : (
              <>
                {/* 
                  <ButtonOutlined
                    onClick={openFilter.bind(
                      null,
                      FILTER_MODAL_CONTENT_TYPES.DISTANCE
                    )}
                    focused={false}
                    rounded
                    endIcon={<ArrowDropDown color="secondary" />}
                    gutterRight
                  >
                    48 mi from 98087
                  </ButtonOutlined>
                  <ButtonOutlined
                    rounded
                    color="secondary"
                    focused={false}
                    onClick={openFilter.bind(
                      null,
                      FILTER_MODAL_CONTENT_TYPES.DATES
                    )}
                    endIcon={<ArrowDropDown color="secondary" />}
                  >
                    Date range
                  </ButtonOutlined>
                */}
              </>
            )}
          </Box>

          {/* OPTIONS */}
          <Box mt={2} display="flex" flexDirection="column">
            {options?.length ? (
              options.map((opt) => {
                return (
                  <OptionCard
                    key={opt.idnumber}
                    onSelect={onSelectGoToReview}
                    {...opt}
                  />
                );
              })
            ) : (
              <EmptyResults text="No options found">
                <ButtonPrimary
                  capitalize
                  onClick={() => {
                    router.push('/coursecatalog?refresh=true');
                  }}
                >
                  Back to Course Catalog
                </ButtonPrimary>
              </EmptyResults>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default ECOptionsCatalog;
