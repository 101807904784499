/**
 * TODO:
 * - pascal case all other modules in this directory
 */
import axios from 'axios';
import { stringifyError } from 'src/utils';
import {
  GEOLOC,
  GEOLOC_API_KEY,
  GEOLOC_APPID,
  MOODLE_SERVICE_URL,
} from 'src/envvars';
import { Point } from 'src/types';

export const getZipcodeFromLatLong = async (latlong) => {
  try {
    const response = await axios.get(
      `${GEOLOC}/geocoding/reverse?lat=${latlong.latitude}&lng=${latlong.longitude}`,
      {
        headers: {
          'X-Application-Id': GEOLOC_APPID,
          'X-Api-Key': GEOLOC_API_KEY,
          'Accept-Language': 'en-US',
        },
      }
    );

    let zipcode = null;

    for (let i = 0; i < response?.data?.features?.length; i++) {
      const props = response?.data?.features[i]?.properties;

      if (!zipcode && !!props.postcode) {
        zipcode = props?.postcode;
        break;
      }
    }

    return zipcode;
  } catch (error) {
    throw new Error(`Issues: ${error}`);
  }
};

export const getLatLong = async (address: string): Promise<Point | null> => {
  if (!address) {
    throw new Error('No address provided to getLatLong');
  }

  try {
    const response = await axios.get(
      `${GEOLOC}/geocoding/search?query='${address}'`,
      {
        headers: {
          'X-Application-Id': GEOLOC_APPID,
          'X-Api-Key': GEOLOC_API_KEY,
          'Accept-Language': 'en-US',
        },
      }
    );

    const lat: number | null =
      response?.data?.features[0]?.geometry?.coordinates[1];
    const long: number | null =
      response?.data?.features[0]?.geometry?.coordinates[0];
    return (lat && long && [lat, long]) || null;
  } catch (error) {
    throw new Error(`Unable to get distance: ${stringifyError(error)}`);
  }
};

/*
 * Issue: loadSessionsInBlock needs to load courses from
 *         Courses Microservice (Online courses) as well as Sessions Microservice (ILT and Webinar)
 * TODO: Design better way to load all types of courses
 */
export const loadSessionsInBlock = async ({ blockid }) => {
  try {
    const response = await axios.get(
      `${MOODLE_SERVICE_URL}/blocks/${blockid}/sessions/available`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    throw new Error(stringifyError(error));
  }
};
