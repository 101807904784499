import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AZURESIGNINLINK, PTLSLSAPI, PTLSLSAPIKEY } from 'src/envvars';
import { objIsEmpty, useRouter } from 'src/utils';
import { LoadingPage, TransitionAlert } from '../components/DSL/info';

export interface ActivationReq {
  date?: string;
  upn?: string;
  email?: string;
  token?: string;
  [x: string]: any;
}

export default function Activating() {
  const [error, setError] = useState('');
  const { location, ...router } = useRouter();
  useEffect(() => {
    let unmounted = false;

    if (unmounted) {
      return;
    }

    if (location?.pathname?.includes?.('/activated')) {
      const parsedActivationReq: ActivationReq & any = router?.query;

      if (
        objIsEmpty(parsedActivationReq) ||
        !parsedActivationReq?.upn ||
        !parsedActivationReq?.email
      ) {
        localStorage.removeItem('dexPerson');
        localStorage.removeItem('id_token');

        setError('Unable to activate, invalid activation link.');
      } else {
        setError('');
        const activate = async () => {
          try {
            const res = await Axios.post(
              `${PTLSLSAPI}/activatePerson`,
              parsedActivationReq,
              {
                headers: {
                  'X-Api-Key': PTLSLSAPIKEY,
                  'Content-Type': 'application/json',
                },
              }
            );
            if (objIsEmpty(res?.data)) {
              setError('Unable to activate, please contact MRC.');
            } else {
              setError('');
              window.open(
                `${AZURESIGNINLINK}&login_hint=${parsedActivationReq?.email}`,
                '_self'
              );
            }
          } catch (e) {
            const res = await Axios.post(
              `https://cors-anywhere.herokuapp.com/${PTLSLSAPI}/activatePerson`,
              parsedActivationReq,
              {
                headers: {
                  'X-Api-Key': PTLSLSAPIKEY,
                  'Content-Type': 'application/json',
                },
              }
            );

            if (objIsEmpty(res?.data)) {
              setError('Unable to activate, please contact MRC.');
            } else {
              window.open(
                `${AZURESIGNINLINK}&login_hint=${parsedActivationReq?.email}`,
                '_self'
              );
              setError('');
            }
          } finally {
            setError('Unable to activate, please contact MRC.');
          }
        };

        activate();
      }
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <LoadingPage message="Redirecting in...">
      {error?.length > 0 ? (
        <TransitionAlert message={error} severity="error" />
      ) : null}
      {`Activating account, you'll be redirected to login with your credentials shortly`}
    </LoadingPage>
  );
}
