import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {
  AccountCircleOutlined,
  ChevronLeft,
  CloseOutlined,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { AZURESIGNOUTLINK } from 'src/envvars';
import { getAuthentication } from 'src/store/authentication/selectors';
import { isMobile, useRouter } from 'src/utils';
import {
  LinksResDefault,
  LogoutDefault,
  ProfileDefault,
} from 'src/views/components/DSL/Icons';
import {
  Descriptor,
  Heading1,
  Heading4,
} from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import seiu775bglogo from 'src/views/styles/assets/seiu-775-bg-logo_color@2x.png';
import { INTERNAL_LINK, STATE_BUTTON } from './linktypes';
import { LN_EXT_LINKS, LN_LINKS } from './LN_LINKS';
import { NAV_COPY } from './NAV_COPY';
import SideNav from './SideNav';

const RN_LINKS = [
  {
    text: 'Profile',
    to: '/profile',
    startIcon: ProfileDefault,
    type: INTERNAL_LINK,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textTransform: 'capitalize',
    flexGrow: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },
}));

const NavTitle = ({ classes, startIcon, title, link }) => {
  const router = useRouter();
  return (
    <Button
      disableRipple
      disableFocusRipple
      className={classes.title}
      startIcon={startIcon}
    >
      <Heading1
        style={{ fontSize: 20, color: '#fff' }}
        onClick={() => {
          router.push(link);
        }}
      >
        {isMobile() && title?.length > 15 ? title.slice(0, 15) + '...' : title}
      </Heading1>
    </Button>
  );
};

function NavbarContainer() {
  const { pathname } = useRouter();
  const trigger = useScrollTrigger();
  const classes = useStyles();
  const { isLoggedIn, ...authentication } = useSelector(getAuthentication);

  const [sideNav, setSideNav] = React.useState({
    left: {
      anchor: 'left',
      open: false,
      view: 1,
    },
    right: {
      anchor: 'right',
      open: false,
    },
  });

  const toggleSideNav = (anchor, open) => () =>
    setSideNav({
      ...sideNav,
      [anchor]: {
        ...sideNav[anchor],
        open,
      },
    });

  const toLinksResExt = {
    text: `Links & Resources`,
    startIcon: LinksResDefault,
    type: STATE_BUTTON,
    onClick: () =>
      setSideNav({
        ...sideNav,
        left: {
          ...sideNav.left,
          view: 2,
        },
      }),
  };
  const toLinksResInt = {
    text: `Main Menu`,
    startIcon: ChevronLeft,
    type: STATE_BUTTON,
    onClick: () =>
      setSideNav({
        ...sideNav,
        left: {
          ...sideNav.left,
          view: 1,
        },
      }),
  };

  const lnView1 = [...LN_LINKS, toLinksResExt];
  const lnView2 = [toLinksResInt, ...LN_EXT_LINKS];

  const rnView1 = [
    ...RN_LINKS,
    {
      text: 'Log out',
      startIcon: LogoutDefault,
      type: STATE_BUTTON,
      onClick: () => {
        localStorage.removeItem('id_token');

        window.open(`${AZURESIGNOUTLINK}`, '_self');

        // redirectToAzure();
      },
    },
  ];

  return (
    <>
      {/* Left Nav */}
      <SideNav
        handleClose={toggleSideNav('left', false)}
        navHeader={
          <>
            <img
              src={seiu775bglogo}
              style={{ maxHeight: 20 }}
              alt="BG Logo Colored"
            />
            <IconButton edge="end" onClick={toggleSideNav('left', false)}>
              <CloseOutlined />
            </IconButton>
          </>
        }
        links={sideNav.left.view === 2 ? lnView2 : lnView1}
        {...sideNav.left}
      />

      {/* Right Nav */}
      <SideNav
        handleClose={toggleSideNav('right', false)}
        navHeader={
          <>
            {isLoggedIn ? (
              <Box display="flex" flexDirection="column">
                <Heading4>{`${authentication?.firstName} ${authentication?.lastName}`}</Heading4>
                <Descriptor>{authentication?.email}</Descriptor>
              </Box>
            ) : (
              <Heading4>Logged Out</Heading4>
            )}
            <IconButton edge="end" onClick={toggleSideNav('right', false)}>
              <CloseOutlined />
            </IconButton>
          </>
        }
        links={rnView1}
        {...sideNav.right}
      />

      <Slide appear={false} direction="down" timeout={270} in={!trigger}>
        <AppBar elevation={0} className={classes.root}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleSideNav('left', true)}
            >
              <MenuIcon />
            </IconButton>

            <NavTitle classes={classes} {...NAV_COPY[pathname]} />

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              color="inherit"
              edge="end"
              onClick={toggleSideNav('right', true)}
            >
              <AccountCircleOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
    </>
  );
}

export default NavbarContainer;
