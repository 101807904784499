import { Chip, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { arrayToObject, useDebounce } from 'src/utils';
import { Descriptor } from '../typography';

export const LANGUAGE_SELECT_OPTIONS = [
  { value: 'AM', label: 'Amharic' },
  { value: 'AR', label: 'Arabic' },
  { value: 'ZH', label: 'Cantonese' },
  { value: 'KH', label: 'Cambodian' },
  { value: 'EN', label: 'English' },
  { value: 'RU', label: 'Russian' },
  { value: 'SO', label: 'Somali' },
  { value: 'ES', label: 'Spanish' },
  { value: 'KO', label: 'Korean' },
  { value: 'VI', label: 'Vietnamese' },
];

interface LangOpt {
  label: string;
  value: string;
}

function langsFromString(langs) {
  if (typeof langs !== 'string') {
    return ['English'];
  }

  return langs.split(/[,|;]+/).filter((l) => l.length > 0);
}

function getInitialLangs(langs: string): LangOpt[] {
  try {
    const options = arrayToObject(LANGUAGE_SELECT_OPTIONS, 'label');
    const processedString = langsFromString(langs).map((o) => options[o].label);

    const res = Array.from(new Set(processedString)).reduce((acc, lang) => {
      if (lang.length > 0 && options[lang] && !acc[lang]) {
        return [...acc, ...[options[lang]]];
      }
      return acc;
    }, []);

    return res;
  } catch (error) {
    return [LANGUAGE_SELECT_OPTIONS[4]];
  }
}

export default function InputLangSearchMulti({
  selectedLanguages = 'English',
  onChange = undefined,
  label = 'Select Languages',
}: {
  selectedLanguages: string;
  onChange?: (value: string) => void;
  label?: string;
}) {
  const fixedLanguages = getInitialLangs(selectedLanguages);
  const [languages, setLanguages] = useState(fixedLanguages);
  const debouncedLangs = useDebounce({ value: languages, delay: 400 });

  useEffect(() => {
    let unmounted = false;
    if (unmounted) {
      return;
    }
    const fluentLangStr = debouncedLangs.map((lang) => lang.label).join(';');

    console.log('fluentLangStr', fluentLangStr);

    if (typeof onChange === 'function') {
      onChange(fluentLangStr);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedLangs]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        size="small"
        key={`${index}${option.value}`}
        label={option.label}
        {...getTagProps({ index })}
        onDelete={(event) => {
          setLanguages([
            ...languages.filter(
              (prev) => JSON.stringify(prev) !== JSON.stringify(option)
            ),
          ]);
        }}
      />
    ));

  const handleChange = (event, newValue) => {
    setLanguages([
      ...languages,
      ...newValue.filter((option) => languages.indexOf(option) === -1),
    ]);
  };

  const renderInput = (params) => (
    <TextField
      multiline={false}
      style={{ backgroundColor: '#fff' }}
      color="secondary"
      {...params}
      variant="outlined"
    />
  );

  return (
    <FormControl fullWidth style={{ marginBottom: 8 }}>
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <Autocomplete
        multiple
        id="fluent-languages"
        value={languages}
        onChange={handleChange}
        disabled={!onChange}
        limitTags={4}
        size="small"
        options={LANGUAGE_SELECT_OPTIONS}
        getOptionLabel={(option) => option.label}
        renderTags={renderTags}
        renderInput={renderInput}
        fullWidth
      />
    </FormControl>
  );
}
