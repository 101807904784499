import { makeStyles, Theme } from '@material-ui/core';

const useCardStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[300],
    marginRight: theme.spacing(1),
    width: 60,
    height: 60,
    borderRadius: 8,
  },
  list: {
    listStyleType: 'none',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: '6px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useCardStyles;
