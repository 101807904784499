// imports
import React, { useState } from 'react';
import { dialogSelectors } from 'src/store/dialog';
import { courseCatalogActionCreators } from 'src/store/coursecatalog';
import { popoverActionCreators, popoverSelectors } from 'src/store/popover';
import { isXS, useRouter } from 'src/utils';
import { Body } from 'src/views/components/DSL/typography';
import { PageContent } from 'src/views/components/Layouts';
import { useDispatch, useSelector } from 'src/views/providers';
import CCCourseList from '../components/CCCourseList';
import CCCPageHeader from '../components/CCCPageHeader';
import CCGeneralFiltersOpeners from '../components/CCGeneralFiltersOpeners';
import CCFilterPopoverContainer from './CCFilterPopoverContainer';
import CCSelectedCourseContainer from './CCSelectedCourseContainer';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import CCScrollToTop from '../components/CCScrollToTop';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { Grid, useScrollTrigger, Zoom } from '@material-ui/core';
import { authSelectors } from 'src/store/authentication';
import { TRAINING_CODE } from 'src/consts';

const { setSelectedCourse } = courseCatalogActionCreators;
const { closePopover, setPopoverContentKey } = popoverActionCreators;

const useStyles = makeStyles((theme) => ({
  template: {
    height: 48,
    width: 48,
    backgroundColor: '#ffffff',
    marginBottom: '6px',
  },
  hours: {
    height: 48,
    width: 48,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#9880c0', //'#8568b4',
    textTransform: 'none',
  },
}));

const ErrorCopy = ({ error }) =>
  error ? (
    <Body color="error" gutterBottom>
      {error}
    </Body>
  ) : null;

function CourseCatalogContainer(props) {
  /* GLOBAL STATE */
  const dispatch = useDispatch();

  const classes = useStyles();
  const trigger = useScrollTrigger();

  const dialog = useSelector(dialogSelectors.getDialog);
  const popover = useSelector(popoverSelectors.getPopover);
  const courses = useSelector((state) => state.coursecatalog.courses);
  const { history } = useRouter();

  const { dashboard } = useSelector(authSelectors.getAuthentication);
  const currentReq = dashboard?.inProgress?.[0];
  const hasRoom =
    currentReq?.requiredHours -
    (currentReq?.earnedHours + currentReq?.enrolledHours);

  /* FIXME: 06/02/2020 a.h.
    - address course match filtering
  */
  const onCourseItemClick = (to, idnumber) => {
    if (courses?.byCourseCodes?.[idnumber]) {
      dispatch(setSelectedCourse(courses?.byCourseCodes?.[idnumber]));
      if (to) {
        history.push(to);
      }
    }
  };

  /* LOCAL STATE */
  const [error] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  /* SETUP */
  const onClosePopover = () => {
    dispatch(closePopover());
    dispatch(setPopoverContentKey(null));
    setFilterAnchorEl(null);
  };

  return (
    <>
      {/* Course Options Dialog */}
      <CCFilterPopoverContainer
        contentKey={popover?.contentKey}
        open={filterAnchorEl && popover?.open}
        onClose={onClosePopover}
        anchorEl={filterAnchorEl}
      />

      <CCCPageHeader />

      <CCSelectedCourseContainer {...dialog} />

      {/* Course List */}
      <PageContent
        style={{
          ...(isXS() && {
            paddingLeft: '5px',
          }),
        }}
      >
        {/*Scroll to Top Button */}
        <CCScrollToTop {...props}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Zoom in={trigger}>
              <Fab
                className={classes.template}
                color="default"
                aria-label="scroll back to top"
              >
                <VerticalAlignTopIcon color="action" />
              </Fab>
            </Zoom>
            {courses.codes.length > 0 && hasRoom && (
              <Fab className={classes.hours} aria-label="show required hours">
                <>
                  {hasRoom}
                  <br />
                  {dashboard?.inProgress?.[0].trainingCode ===
                    TRAINING_CODE.CE_12.CODE ||
                  dashboard?.inProgress?.[0].trainingCode ===
                    TRAINING_CODE.AHCAS.CODE
                    ? 'credits'
                    : 'hours'}
                </>
              </Fab>
            )}
          </Grid>
        </CCScrollToTop>

        <CCGeneralFiltersOpeners setFilterAnchorEl={setFilterAnchorEl} />
        <ErrorCopy error={error} />
        <CCCourseList onItemClick={onCourseItemClick} courses={courses} />
      </PageContent>
    </>
  );
}

export default CourseCatalogContainer;
