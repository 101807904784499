import React from 'react';
import {
  DialogContent,
  DialogContentText,
  DialogContentProps,
} from '@material-ui/core';

interface Props extends DialogContentProps {
  disableTypography?: boolean;
}

function CCContentSection({
  children,
  disableTypography,
  dividers = true,
  ...rest
}: Props) {
  return (
    <DialogContent dividers={dividers} {...rest}>
      {disableTypography ? (
        children
      ) : (
        <DialogContentText>{children}</DialogContentText>
      )}
    </DialogContent>
  );
}

export default React.memo(CCContentSection);
