import { Box, Link } from '@material-ui/core';
import React from 'react';
import { Body } from 'src/views/components/DSL/typography';

export default function OBStep5({
  linkStyles = {
    color: '#087189',
    fontWeight: 700,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}) {
  return (
    <Box
      my={2}
      bgcolor="#fff"
      p={2}
      borderRadius={6}
      border={`1px solid #e2e5e9`}
    >
      <Body gutterBottom>
        To finish creating your account, go to your email inbox and click the
        confirmation link in the email sent to you from SEIU 775 Benefits Group.
      </Body>
      <Body gutterBottom>
        If you did not get an email, you can{' '}
        <Link color="secondary" style={linkStyles}>
          resend the activation email
        </Link>{' '}
        or{' '}
        <Link color="secondary" style={linkStyles}>
          reset your password.
        </Link>{' '}
        If you are still having trouble, call the Member Resource Center (MRC)
        at 1-866-371-3200 Monday to Friday, 8 a.m.-6 p.m.
      </Body>
      <Body gutterBottom>
        If you did not get an email, you can resend the activation email or
        reset your password. If you are still having trouble, call the Member
        Resource Center (MRC) at 1-866-371-3200 Monday to Friday, 8 a.m.-6 p.m.
      </Body>
    </Box>
  );
}
