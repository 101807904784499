import Icon from '@material-ui/core/Icon';
import React from 'react';
// import { ReactComponent as CheckGreenSvg } from 'src/views/styles/assets/icons/checkgreen.svg';
import { ReactComponent as CheckGreenSvg } from 'src/views/styles/assets/icons/group.svg';
import { IconProps } from './types';

export default function CheckGreen({
  fontSize = 'small',
  ...props
}: IconProps) {
  return <Icon component={CheckGreenSvg} fontSize={fontSize} {...props} />;
}
