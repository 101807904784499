import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Badge, IconButton } from '@material-ui/core';
import { BellGrey } from 'src/views/components/DSL/Icons';

const StyledBadge = withStyles({
  badge: {
    right: 0,
    top: 0,
    border: `3px solid #fff`,
    color: '#fff',
    padding: '1px',
    backgroundColor: '#d64205',
    fontSize: 10,
    fontWeight: 'bold',
    boxShadow: '0px 0px 6px 3px rgba(0,0,0,.1)',
  },
})(Badge);

const Notifications = ({ count }) => {
  return (
    <Grid
      item
      xs={3}
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      style={{ position: 'relative' }}
    >
      <StyledBadge badgeContent={count}>
        <IconButton
          style={{
            padding: '5px 7px',
            border: '2px solid #ddd',
            borderRadius: 4,
          }}
        >
          <BellGrey />
        </IconButton>
      </StyledBadge>
    </Grid>
  );
};

export default Notifications;
