import { getMomentDate } from 'src/utils';
import { DATE_FORMAT } from '../consts';
import config from './config';

const defaultStart = getMomentDate(new Date().toISOString());
const defaultEnd = getMomentDate(defaultStart.clone().add(10, 'days'));
const defaultMaxEnd = getMomentDate(defaultEnd.clone().add(1, 'y'));

const schedule = {
  status: config.REQUEST_STATUSES.PENDING,
  schedule: {
    past: { bySessionNumber: {}, allSessionNumbers: [] },
    upcoming: { bySessionNumber: {}, allSessionNumbers: [] },
    canceled: { bySessionNumber: {}, allSessionNumbers: [] },
  },
  replacement: null,
};

const defaultState = {
  /* NOTE: used for managing enrolled courses */
  schedule,
  /* NOTE: used for queueing course selections in enrollment process */
  /** USED in EnrollmentContainer */
  enrollment: {
    selectedOption: null,
    sessionsForOption: null,
  },

  unenroll: {},
  dialog: {
    open: false,
    contentKey: config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_OVERVIEW,
  },
  popover: {
    contentKey: config.POPOVER_CONTENT_KEYS.GENERAL_FILTERS_LANGUAGE, // required
    open: false, // required
  },
  coursecatalog: {
    filters: {
      // filters for course level
      language: [],
      code: [],
      tag: [],
      // stores primary keys of courses a learner is
      // already enrolled in
      forbiddenCourses: {},
      online: true,
      inperson: false,
      webinar: false,
      name: '',

      // defaults filters for options level
      zipcode: null,
      radius: config.FILTER_DEFAULTS.radius,
      startdate: defaultStart.format(DATE_FORMAT),
      enddate: defaultEnd.format(DATE_FORMAT),
      maxenddate: defaultMaxEnd.format(DATE_FORMAT),

      // filters for course options level
      byId: {},
    },

    courses: {
      byCourseCodes: {},
      codes: [],
      status: config.REQUEST_STATUSES.PENDING,
    },
    selectedCourse: null,
  },
  error: {},
  authentication: {
    // App specific
    status: config.REQUEST_STATUSES.PENDING,
    isLoggedIn: false,
    error: null,

    // DEX Person/Learner record
    role: ['Caregiver'],
    firstName: null,
    lastName: null,
    ssnLast4: null,
    userName: null,
    learnerId: null,
    activeEmployers: [],
    email: null,
    homePhone: null,
    address: null,
    city: null,
    state: null,
    zipCode: null,
    preferredLanguage: 'English',
    languagesFluentIn: 'English',
    contactPreference: ['mobile'],

    // dashboard
    dashboard: {
      status: config.REQUEST_STATUSES.PENDING,
      error: null,

      completed: [],
      inProgress: [],
    },

    // trainings
    trainings: {
      status: config.REQUEST_STATUSES.PENDING,
      error: null,

      codes: [],
      byCourseCodes: {},
    },

    // learnersteps
    learnersteps: {},
  },
  // unused
  learnersteps: [],

  dialogDetail: {},
  filter: {},
  onboarding: {},
  sessions: {},
  makeups: {},
  scheduleManagement: {},

  /* byIdNumbers: { [idnumber]: CourseCompletionsEntity } */
  transcripts: {
    error: '',
    filter: {
      sortBy: 'COMPLETIONDATE',
      modality: ['ONLINE', 'INPERSON', 'WEBINAR'],
      completionYear: '2000',
    },
    status: config.REQUEST_STATUSES.PENDING,
    idNumbers: [],
    byIdNumbers: {},
  },

  /** USED in MyCourses enrolled tab */
  enrolledCourses: {
    error: '',
    filter: {
      sortBy: 'STARTDATE',
      modality: ['ALL', 'ONLINE', 'INPERSON', 'WEBINAR'],
    },
    status: config.REQUEST_STATUSES.PENDING,

    idNumbers: [],
    byIdNumbers: {},
  },
  //FIXME:
  enrolledSessions: {
    error: '',
    filter: {
      sortBy: 'STARTDATE',
      modality: ['ALL', 'ONLINE', 'INPERSON', 'WEBINAR'],
    },
    status: config.REQUEST_STATUSES.PENDING,

    idNumbers: [],
    byIdNumbers: {},
  },
};

export default defaultState;
