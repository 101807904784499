import { ListItemSecondaryAction } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BrokenImage } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { MOODLE } from 'src/envvars';
import { COURSE_TYPES } from 'src/store/consts';
import { CourseSchema } from 'src/types';
import { COLORS } from 'src/views/components/DSL';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Body2, Descriptor } from 'src/views/components/DSL/typography';
import theme from 'src/views/styles/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0),
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
}));

function EnrolledCourse(props) {
  // console.log(props);

  const classes = useStyles();
  const isMobile = useBreakpoint('xs');
  const src = props.course.image
    ? `${MOODLE}${props.course.image?.replace(/ /g, '%20')}`
    : '';
  const mod = props.course.type.toUpperCase();
  const modeUpdated = mod.charAt(0).toUpperCase() + mod.slice(1).toLowerCase();

  let startdate = props.session ? props.session?.[0]?.['coursestarttime'] : '';
  const enddate = props.session ? props.session?.[0]?.['courseendtime'] : '';

  if (!!enddate && moment.unix(startdate).isSame(moment.unix(enddate))) {
    startdate += enddate ? ' - ' + moment.unix(enddate) : '';
  }

  // Modified image url
  return (
    <ListItem className={classes.listItem}>
      <ListItemAvatar>
        <Avatar
          src={src}
          style={{ backgroundColor: theme.palette.grey[200] }}
          variant="rounded"
        >
          {!src ? <BrokenImage color="inherit" /> : null}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        secondaryTypographyProps={{
          component: Body2,
          style: { color: COLORS.GREY[500], fontWeight: 700 },
        }}
        primaryTypographyProps={{
          component: Descriptor,
          color: 'primary',
          style: { fontWeight: 700 },
        }}
        title={props.course.fullname}
        primary={
          mod === COURSE_TYPES.ONLINE
            ? ''
            : moment.unix(startdate).format('MMM DD')
        }
        secondary={
          isMobile ? (
            <>
              {' '}
              {props.course.fullname.slice(0, 40) + '...'}
              <br />
              <Descriptor>{modeUpdated}</Descriptor>{' '}
            </>
          ) : (
            props.course.fullname
          )
        }
      />
      {!isMobile && (
        <ListItemSecondaryAction>
          <Descriptor>{modeUpdated}</Descriptor>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

export default function EnrollmentPreview({
  byIdNumbers,
}: {
  byIdNumbers: {
    [id: string]: {
      course: CourseSchema;
      [x: string]: any;
    };
  };
}) {
  const classes = useStyles();
  const enrollments = [...Object.values(byIdNumbers || {}).slice(0, 4)];
  return (
    <List className={classes.root}>
      {enrollments.map((en, k) => (
        <EnrolledCourse key={k} {...en} />
      ))}
    </List>
  );
}
