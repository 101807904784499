import { makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { useRouter } from 'src/utils';
import { DSLButtonProps, useButtonBaseStyles } from '.';
import ButtonText from './ButtonText';

/* eslint-disable react-hooks/rules-of-hooks */

interface Props extends DSLButtonProps {
  to?: string;
  onClick?: (e: any) => any;
  href?: string;
}

const useStyles = makeStyles({
  root: (props: DSLButtonProps) => {
    const baseStyles = useButtonBaseStyles(props);
    return {
      ...baseStyles,
    };
  },
});

export default function Back(props: Props) {
  const classes = useStyles({ capitalize: true, ...props });
  const startIcon = <ChevronLeft color="secondary" />;
  const router = useRouter();

  const handleClick = (e: any) => {
    if (props?.to || props?.href) {
      router.push(props?.to || props?.href);
    } else {
      router.history.back();
    }

    if (props?.onClick) {
      props?.onClick(e);
    }
  };

  return (
    <ButtonText
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      color="secondary"
      onClick={handleClick}
      startIcon={startIcon}
      className={classes.root}
      {...props}
    />
  );
}
