export const FILTER_MODAL_CONTENT_TYPES = {
  DISTANCE: 'DISTANCE',
  OVERVIEW: 'OVERVIEW',
  LANGUAGE: 'LANGUAGE',
  DATES: 'DATES',
  COURSE_TYPES_INFO: 'COURSE_TYPES_INFO',
};

export const COURSE_TYPES = {
  WEBINAR: 'WEBINAR',
  ONLINE: 'ONLINE',
  INPERSON: 'INPERSON',
};

export const CE_langs = [
  'አማርኛ', //AM
  'عربى', //AR
  'ភាសាខ្មែរ', //CA KM
  '中文', //CH
  'English', //EN
  '한국어', //KO
  'ລາວ', //LA
  'русский', //RU
  'Faasamoa', //SA
  'Soomaali', //SO
  'Español', //SP
  'Tagalog', //TA
  'Український', //UK
  'Tiếng Việt', //VI
];
export const languageMap = {
  AM: 'አማርኛ',
  AR: 'عربى',
  CA: 'ភាសាខ្មែរ',
  CH: '中文',
  EN: 'English',
  KO: '한국어',
  LA: 'ລາວ',
  RU: 'русский',
  SA: 'Faasamoa',
  SO: 'Soomaali',
  SP: 'Español',
  TA: 'Tagalog',
  UK: 'Український',
  VI: 'Tiếng Việt',
};
export const LangToISOcode = {
  አማርኛ: 'AM',
  عربى: 'AR',
  ភាសាខ្មែរ: 'CA',
  中文: 'CH',
  English: 'EN',
  한국어: 'KO',
  ລາວ: 'LA',
  русский: 'RU',
  Faasamoa: 'SA',
  Soomaali: 'SO',
  // temporary
  // Español: 'SP',
  Spanish: 'SP',
  Tagalog: 'TA',
  Український: 'UK',
  'Tiếng Việt': 'VI',
};
export const ISOToLang = Object.entries(LangToISOcode).reduce(
  (res, cur) => ({ ...res, [cur[1]]: cur[0] }),
  {}
);
export const wDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const monthLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const courseFullNames = {
  ahcas: 'Advanced Home Care Aide (HCA) Specialist Program',
  ce: 'Continuing Education',
};
export const sessionNames = [
  'Person-Centered Care',
  'Motivational Interviewing',
  'De-Escalation',
  'Care Plans, Observing',
  'Medication Management',
  'Health Literacy',
  'Organizing Care Activity',
  'Wrap Up',
  'Make Up Day',
];
export const monthLabelsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const LANGUAGES_MB = [
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Amharic', label: 'Amharic' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Armenian', label: 'Armenian' },

  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Burmese', label: 'Burmese' },

  { value: 'Cambodian/Khmer', label: 'Cambodian/Khmer' },
  { value: 'Cantonese', label: 'Cantonese' },
  { value: 'Cebuano', label: 'Cebuano' },
  { value: 'Chuukese', label: 'Chuukese' },
  { value: 'Croatian', label: 'Croatian' },

  { value: 'Dari', label: 'Dari' },

  { value: 'English', label: 'English' },

  { value: 'Farsi', label: 'Farsi' },
  { value: 'French', label: 'French' },

  { value: 'German', label: 'German' },

  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hmong', label: 'Hmong' },

  { value: 'Ilocano', label: 'Ilocano' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Italian', label: 'Italian' },

  { value: 'Japanese', label: 'Japanese' },

  { value: 'Karen', label: 'Karen' },
  { value: 'Kirundi', label: 'Kirundi' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kurdish', label: 'Kurdish' },

  { value: 'Lao', label: 'Lao' },

  { value: 'Mandarin', label: 'Mandarin' },
  { value: 'Marshallese', label: 'Marshallese' },
  { value: 'Mien', label: 'Mien' },
  { value: 'Moldavian', label: 'Moldavian' },
  { value: 'Mongolian', label: 'Mongolian' },

  { value: 'Nepali', label: 'Nepali' },

  { value: 'Oromo', label: 'Oromo' },
  { value: 'Other', label: 'Other' },

  { value: 'Pashtu', label: 'Pashtu' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Punjabi', label: 'Punjabi' },

  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },

  { value: 'Samoan', label: 'Samoan' },
  { value: 'Sign Language', label: 'Sign Language' },
  { value: 'Somali', label: 'Somali' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Swahili', label: 'Swahili' },

  { value: 'Tagalog', label: 'Tagalog' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Tigrinya', label: 'Tigrinya' },
  { value: 'Tongan', label: 'Tongan' },
  { value: 'Turkish', label: 'Turkish' },

  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },

  { value: 'Vietnamese', label: 'Vietnamese' },
];

export const LOCK_STATUSES = {
  HIDDEN: 'HIDDEN',
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
};
