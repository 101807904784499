import React from 'react';
import { Box } from '@material-ui/core';
import { NoResults } from 'src/views/components/DSL/Icons';
import { Descriptor } from 'src/views/components/DSL/typography';

export default function EmptyResults({ text = '', children = null }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={1}
      marginY={2}
      justifyContent="center"
      alignItems="center"
    >
      <NoResults />
      {text && (
        <Descriptor
          gutterBottom={!!children}
          align="center"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          {text}
        </Descriptor>
      )}
      {children}
    </Box>
  );
}
