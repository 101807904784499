/**
 * TODO:
 * - add headers support to config
 * - use axios docs to determine which config
 *   variables would be relevant for our use-
 *   case
 */
import axios from 'axios';
import decode from 'jwt-decode';
import {
  MOODLE_SERVICE_URL,
  PTLSLSAPI,
  PTLSLSAPIKEY,
  TRAININGS_API_KEY,
  TRAININGS_URL,
} from 'src/envvars';
import {
  CreatePersonRequestB2C,
  CreatePersonResponseB2C,
  QueryPersonResponse,
} from 'src/types';
import { objIsEmpty, removeDexPersonFromCache } from 'src/utils';

const TTLDEX = 1600000;

interface AZDecodedToken {
  readonly extension_firstName?: string;
  readonly extension_lastName?: string;
  readonly extension_ssnLast4?: string;
  readonly extension_learnerId?: string | number;
  readonly emails?: string[];
  [propName: string]: any;
}

type CachedQueryPersonResponse = QueryPersonResponse & {
  ttl?: number | 0;
  learnerId?: string | number;
  [x: string]: any;
};

interface QueryPersonParams {
  firstName: string;
  lastName: string;
  ssnLast4: string | number;
  [attribute: string]: any;
}

const authenticationFailed = {
  status: 401,
  message: 'Login failed, invalid token - unable to get Learner ID.',
};
const trainingsLoadFailed = {
  status: 401,
  message: 'Unable to read learner eligibility records.',
  type: 'trainings',
};
const queryPersonFailed = {
  status: 401,
  message: 'Invalid query passed to DEX queryPerson.',
};
const userCreateFailed = {
  status: 500,
  message: 'Internal - Unable to create user in Azure',
};

interface DexCacheInvalidateProps {
  parsedProfile?: CachedQueryPersonResponse | any;
  learnerId?: string | number;
}

function isInvalidDexCache({
  parsedProfile = { ttl: 0, learnerId: undefined },
  learnerId = undefined,
}: DexCacheInvalidateProps) {
  const curr = Math.round(Date.now() / 1000);

  return (
    curr - parsedProfile.ttl >= TTLDEX || learnerId !== parsedProfile?.learnerId
  );
}

interface MoodleUserCreateProps {
  id: string | number;
  email: string;
  firstname: string;
  lastname: string;
  [x: string]: any;
}

const syncPersonInMoodle = async (person: MoodleUserCreateProps) => {
  if (
    objIsEmpty(person) ||
    !person?.id ||
    !person?.email ||
    !person?.firstname ||
    !person?.lastname
  ) {
    throw new Error(
      'Unable to locate instructor account or insufficient user data in profile'
    );
  }

  let user = undefined;

  try {
    user = await axios.get(`${MOODLE_SERVICE_URL}/learners/${person.id}`);
    console.log(user);
  } catch (error) {
    console.log(error);
    const data = JSON.stringify({
      ...person,
      confirmed: 1,
      auth: 'oidc',
    });
    const response = await axios.post(`${MOODLE_SERVICE_URL}/learners`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response);
  }
};

class AuthService {
  /**
   * - Invite a user via Graph
   * - Grab their id
   * - Create extensions for them using this id
   */
  createAzureUser = async (
    user: CreatePersonRequestB2C
  ): Promise<CreatePersonResponseB2C> => {
    if (!user || !user.identities) {
      throw new Error(userCreateFailed.message);
    }

    const response = (
      await axios.post(`${PTLSLSAPI}/createB2CUser`, user, {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-key': `${PTLSLSAPIKEY}`,
        },
      })
    )?.data as CreatePersonResponseB2C;

    return response?.status === 200 ? response : null;
  };

  getUserTrainings = async (learnerid) => {
    if (!learnerid) {
      return Promise.reject(trainingsLoadFailed);
    }
    try {
      const response = await axios.get(
        `${TRAININGS_URL}${learnerid}/trainings`,
        {
          headers: {
            'x-api-key': `${TRAININGS_API_KEY}`,
          },
        }
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new Error(trainingsLoadFailed.message);
    }
  };
  /* TODO:
    - determine if this would be better suited in different
      service (i.e. DEXService)
   */
  queryPersonDEX = async (
    data: QueryPersonParams
  ): Promise<QueryPersonResponse | null> => {
    if (
      (!data?.firstName || !data?.lastName || !data?.ssnLast4) &&
      !data?.learnerId &&
      !data?.personId
    ) {
      throw new Error(queryPersonFailed.message);
    }

    let response = await axios.post(
      `${PTLSLSAPI}/queryPerson`,
      {
        requestType: 'QUERY PERSON',
        attributes: data,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': `${PTLSLSAPIKEY}`,
        },
      }
    );

    if (
      objIsEmpty(response?.data) ||
      // eslint-disable-next-line eqeqeq
      response?.data?.responseStatus == 'Person Not Found'
    ) {
      response = await axios.post(
        `${PTLSLSAPI}/dexProxy/queryEmprel`,
        {
          attributes: {
            personId: data?.learnerId || data?.personId,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': `${PTLSLSAPIKEY}`,
          },
        }
      );
    }

    return response?.status === 200 ? response?.data?.attributes : null;
  };
  /*
   * - decode token
   * - load dex user
   * - load dashboard
   * - load trainings
   *
   * FIXME: Consolidate personId vs learnerId
   */
  getUserProfileFromToken = async (token: string) => {
    if (!token) {
      throw new Error(authenticationFailed.message);
    }

    /* FIXME:
      - Replace mock with live call
      - introduce auth token validity verification
     */

    const {
      extension_firstName: firstName,
      extension_lastName: lastName,
      extension_ssnLast4: ssnLast4,
      extension_learnerId: learnerId,
      extension_personId: personId,
      emails,
      exp,
    }: AZDecodedToken = decode(token);

    const curr = Math.round(Date.now() / 1000);
    if (exp < curr || ((!firstName || !lastName || !ssnLast4) && !learnerId)) {
      throw new Error(authenticationFailed.message);
    }

    const personFromCache: string | undefined | null = localStorage.getItem(
      'dexPerson'
    );
    let parsedProfile: CachedQueryPersonResponse = personFromCache
      ? JSON.parse(personFromCache)
      : { ttl: 0 };

    const cacheIsInvalid = isInvalidDexCache({
      parsedProfile,
      learnerId,
    });

    // exceeds ttl, or different person -> refresh from api
    if (cacheIsInvalid) {
      removeDexPersonFromCache();
      parsedProfile = await this.queryPersonDEX({
        firstName,
        lastName,
        ssnLast4,
        /**
         * DEX ONLY ACCEPTS personId
         */
        personId: learnerId,
      });

      if (objIsEmpty(parsedProfile)) {
        throw new Error(authenticationFailed.message);
      }

      localStorage.setItem('dexPerson', JSON.stringify(parsedProfile));
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('B2C EXPIRES IN: ', exp - curr);
      console.log('DEX EXPIRES IN: ', TTLDEX - (curr - parsedProfile.ttl));
    }

    try {
      await syncPersonInMoodle({
        id: parsedProfile?.personId || personId || learnerId,
        email: emails?.[0] || parsedProfile?.email,
        firstname: firstName || parsedProfile?.firstName,
        lastname: lastName || parsedProfile?.lastName,
      });
    } catch (error) {
      console.log(error);
    }

    return parsedProfile;
  };
}

export default new AuthService();
