/* 
    IMPORTANT:
    Saga action type only - any action type with the REQUEST_ prefix
    should not be used in courseCatalogReducer
  */
const courseCatalogActionTypes = {
  /* Mutation action types */
  SET_COURSES: 'CC/SET_COURSES',
  SET_SELECTED_COURSE: 'CC/SET_SELECTED_COURSE',
  SET_SELECTED_COURSE_OPTIONS: 'CC/SET_SELECTED_COURSE_OPTIONS',
  SET_FILTERS_FOR_COURSES: 'CC/SET_FILTERS_FOR_COURSES',
  SET_FILTERS_FOR_COURSE_OPTIONS: 'CC/SET_FILTERS_FOR_COURSE_OPTIONS',
  SET_FILTERS_FOR_ALL_COURSE_OPTIONS: 'CC/SET_FILTERS_FOR_ALL_COURSE_OPTIONS',
  // a.h. SET_OPTION_FOR_ENROLLMENT should be renamed to SET_SELECTED_OPTION_FOR_ENROLLMENT
  SET_OPTION_FOR_ENROLLMENT: 'CC/SET_OPTION_FOR_ENROLLMENT',
  COMMIT_FILTERS_FOR_COURSE_OPTIONS: 'CC/COMMIT_FILTERS_FOR_COURSE_OPTIONS',

  /* Saga effect action types */
  REQUEST_LOAD_COURSES: 'CC/REQUEST_LOAD_COURSES',
  REQUEST_LOAD_OPTIONS_FOR_COURSE: 'CC/REQUEST_LOAD_OPTIONS_FOR_COURSE',
  REQUEST_APPLY_FILTERS_FOR_COURSES: 'CC/REQUEST_APPLY_FILTERS_FOR_COURSES',
  REQUEST_APPLY_FILTERS_FOR_OPTIONS: 'CC/REQUEST_LOAD_OPTIONS_FOR_COURSE',

  // RESPONSE STATUSES
  COURSE_LOAD_ERROR: 'CC/COURSE_LOAD_ERROR',
};

export default courseCatalogActionTypes;
