import { CourseCompletionsEntity, TranscriptStore } from 'src/types';

export const getTranscriptsCompletions = ({
  transcripts,
}: {
  transcripts: TranscriptStore;
}): CourseCompletionsEntity[] => Object.values(transcripts?.byIdNumbers || {});

export const getTranscriptsCompletionIds = ({
  transcripts,
}: {
  transcripts: TranscriptStore;
}) => transcripts.idNumbers;

export const getTranscriptsLoaded = ({
  transcripts,
}: {
  transcripts: TranscriptStore;
}) => transcripts?.status === 'FINISHED';

export const getTranscriptsFilters = ({
  transcripts,
}: {
  transcripts: TranscriptStore;
}) => transcripts.filters;

export const getTranscripts = (state): TranscriptStore => state.transcripts;
