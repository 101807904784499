import popoverActionTypes from './types';
import defaultState from '../defaultState';

const { CLOSE_POPOVER, OPEN_POPOVER, SET_CONTENT_KEY } = popoverActionTypes;

export default function popoverReducer(state = defaultState.popover, action) {
  const mutations = {
    [OPEN_POPOVER]: (state, _action) => ({ ...state, open: true }),
    [CLOSE_POPOVER]: (state, _action) => ({ ...state, open: false }),
    [SET_CONTENT_KEY]: (state, action) => ({
      ...state,
      contentKey: action.contentKey,
    }),
  };

  return mutations[action.type]?.(state, action) || state;
}
