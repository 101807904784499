import React from 'react';
import { CardActionArea, CardHeader } from '@material-ui/core';
import { Heading4, Subheading } from 'src/views/components/DSL/typography';

interface PreviewHeaderProps {
  title?: string;
  avatar?: any;
  subheader?: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

export default function PreviewHeader({
  title,
  avatar,
  subheader,
  action,
  children,
}: PreviewHeaderProps) {
  return (
    <CardHeader
      avatar={avatar}
      title={title && <Heading4 gutterBottom>{title}</Heading4>}
      subheader={
        subheader && (
          <Subheading gutterBottom style={{ fontSize: 14, lineHeight: 1.5 }}>
            {subheader}
          </Subheading>
        )
      }
      action={action}
    >
      {children}
    </CardHeader>
  );
}
