import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { BGLogoWhite } from 'src/views/components/DSL/Icons';
import { isMobile } from 'src/utils';

export default function OBHeader({ classes }) {
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <BGLogoWhite style={{ maxHeight: isMobile() ? 24 : 27 }} />
      </Toolbar>
    </AppBar>
  );
}
