import React from 'react';
import {
  WebinarNavy,
  OnlineGreen,
  InpersonRed,
  NotFoundBlack,
} from 'src/views/components/DSL/Icons';
import { isXS } from 'src/utils';

const content = {
  WEBINAR: <WebinarNavy />,
  ONLINE: <OnlineGreen />,
  ILT: <InpersonRed />,
};

const OptionIcon = ({ type }: { type?: keyof typeof content }) => {
  return type ? (
    content[type]
  ) : (
    <NotFoundBlack style={{ maxWidth: isXS() ? 14 : 12 }} />
  );
};

export default OptionIcon;
