import React from 'react';
import { IconProps } from './types';
import map_marker_grey from 'src/views/styles/assets/icons/map-marker_grey@2x.png';

const MapMarkerGrey = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Map Marker Grey"
      src={map_marker_grey}
      style={{ maxWidth: compact ? 18 : '100%' }}
    />
  );
};

export default MapMarkerGrey;
