import React from 'react';
import { ReactComponent as MixedModalityIcon } from 'src/views/styles/assets/icons/multi-modality-filled-24.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const MultiModalityFilled = ({ fontSize = 'small', ...props }: IconProps) => {
  return <Icon fontSize={fontSize} component={MixedModalityIcon} {...props} />;
};

export default MultiModalityFilled;
