import {
  Box,
  Button,
  Collapse,
  Divider,
  Hidden,
  makeStyles,
  Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { isMobile } from 'src/utils';
import { ButtonText } from 'src/views/components/DSL/buttons';
import { PenBlue } from 'src/views/components/DSL/Icons';
import {
  Body2,
  DescriptorError,
  Display,
  Heading3,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    color: '#fff',
  },
}));

const useAccordionStyles = makeStyles(() => ({
  expanded: {
    transform: 'rotate(180deg)',
  },
  collapsed: {
    transform: 'rotate(0deg)',
  },
  button: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'none',
    padding: 16,
    '&:hover': {
      background: 'none',
    },
  },
}));

function AttributesSection({
  learnerId,
  providerId,
  activeEmployers = [],
  fieldProps = {
    width: 1,
    display: 'block',
    mb: 2,
  },
  fieldLabelProps = { style: { color: '#5f6772' } },
  fieldValProps = { style: { color: '#383c42', fontWeight: 400 } },
}) {
  const classes = useAccordionStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Hidden smUp>
        <Button
          fullWidth
          disableFocusRipple
          disableElevation
          disableRipple
          size="large"
          variant="text"
          className={classes.button}
          endIcon={
            <ExpandMoreIcon
              fontSize="large"
              className={expanded ? classes.expanded : classes.collapsed}
            />
          }
          onClick={() => setExpanded(!expanded)}
        >
          <Heading4>Learner ID &amp; More</Heading4>
        </Button>
      </Hidden>
      <Collapse in={!isMobile() ? true : expanded} timeout="auto" unmountOnExit>
        <Box px={2} py={1}>
          {/* Fields */}
          <Box {...fieldProps}>
            <Subheading {...fieldLabelProps}>Learner ID</Subheading>
            <Heading4 {...fieldValProps}>{learnerId || 'Not found'}</Heading4>
          </Box>

          <Box {...fieldProps}>
            <Subheading {...fieldLabelProps}>Provider ID</Subheading>
            <Heading4 {...fieldValProps}>{providerId || 'Not found'}</Heading4>
          </Box>

          <Box {...fieldProps}>
            <Subheading {...fieldLabelProps}>Active Employer(s)</Subheading>
            {activeEmployers.map((ae, k) => (
              <Heading4 gutterBottom key={k + '' + ae.id} {...fieldValProps}>
                {ae.employerName || 'Not found'}
              </Heading4>
            ))}
          </Box>
        </Box>
      </Collapse>
    </>
  );
}

export default function ProfileCard({
  isLoggedIn,
  firstName,
  lastName,
  email,
  error,
  learnerId,
  providerId,
  activeEmployers,
}) {
  const classes = useStyles();
  const clcUrl = window.location.hostname.includes('prod')
    ? 'https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PASS_RESET_ACTIVATION&client_id=87341714-179f-4073-899f-2d326f89c539&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fenrollment.clc.prod.seiu775.bg&scope=openid%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2Fuser_impersonation%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2FUser.Read&response_type=id_token%20token&prompt=login'
    : window.location.hostname.includes('staging')
    ? 'https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PASS_RESET_ACTIVATION&client_id=87341714-179f-4073-899f-2d326f89c539&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fenrollment.clc.staging.seiu775.bg&scope=openid%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2Fuser_impersonation%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2FUser.Read&response_type=id_token%20token&prompt=login'
    : 'https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PASS_RESET_ACTIVATION&client_id=87341714-179f-4073-899f-2d326f89c539&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fenrollment.clc.dev.seiu775.bg&scope=openid%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2Fuser_impersonation%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2FUser.Read&response_type=id_token%20token&prompt=login';

  return (
    <Box
      component={Paper}
      borderRadius={{ xs: 0, sm: 8 }}
      border={{ xs: 'none', sm: `1px solid #e2e5e9` }}
      boxShadow={null}
      width={{
        xs: 1,
        sm: 3 / 12,
      }}
      mr={{ xs: 0, sm: 2 }}
    >
      {error && <DescriptorError>{error.message}</DescriptorError>}
      {isLoggedIn ? (
        <>
          <Box
            px={2}
            py={1.8}
            width={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            borderBottom="1px solid #ddd"
          >
            <Box borderRadius="50%" className={classes.avatar} my={1} mb={2}>
              <Heading3 className={classes.avatarText}>{`${firstName?.[0]}${
                ' ' + lastName?.[0]
              }`}</Heading3>
            </Box>
            <Display style={{ fontSize: 24, textTransform: 'capitalize' }}>
              {firstName}
            </Display>
            <Display
              style={{ fontSize: 24, textTransform: 'capitalize' }}
              gutterBottom
            >
              {lastName}
            </Display>
            <Body2 gutterBottom>{email}</Body2>
            <ButtonText
              size="large"
              startIcon={<PenBlue style={{ fontSize: 14 }} />}
              href={clcUrl}
            >
              Change Password
            </ButtonText>
            <Divider variant="fullWidth" />
          </Box>

          <AttributesSection
            activeEmployers={activeEmployers}
            providerId={providerId}
            learnerId={learnerId}
          />
        </>
      ) : (
        'Unauthorized'
      )}
    </Box>
  );
}
