import React from 'react';
import language_grey from 'src/views/styles/assets/icons/language@2x.png';
import { IconProps } from './types';

const LanguageGrey = ({ fontSize = 'small', compact = false }: IconProps) => {
  return (
    <img
      alt="Language Grey"
      src={language_grey}
      style={{ maxWidth: compact ? 18 : '100%' }}
    />
  );
};

export default LanguageGrey;
