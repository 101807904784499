import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({ size, disabled, variant }) =>
  makeStyles((theme) => ({
    button: {
      height: size === 'small' ? 30 : 45,
      textTransform: 'none',
      backgroundColor: disabled ? '#E2E5E9' : undefined,
      color: disabled ? '#6D737C' : undefined,
      fontWeight: 'bolder',
      boxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      border:
        variant === 'outlined'
          ? `1.5px solid ${theme.palette.secondary.main}`
          : 'none',
      '&:hover': {
        border:
          variant === 'outlined'
            ? `1.5px solid ${theme.palette.secondary.main}`
            : 'none',
      },
    },
  }));

function DSLButton({ size, disabled, variant, ...rest }: ButtonProps) {
  const classes = useStyles({ size, disabled, variant })();

  return (
    <Button
      disableElevation
      className={classes.button}
      size={size}
      disabled={disabled}
      variant={variant}
      {...rest}
    />
  );
}

export default React.memo(DSLButton);
