import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Link,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import { ValidPass } from 'src/views/components/DSL/Icons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import {
  Banner,
  Heading3,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';
import { PageContent, PageHeader } from 'src/views/components/Layouts';
import imageCaregiverKicks from 'src/views/styles/assets/jpg/caregiver-kicks.jpg';
import imageGinger from 'src/views/styles/assets/jpg/ginger.jpg';
import imageTfC from 'src/views/styles/assets/jpg/tools-for-calm.jpg';
import imageEpicHearing from 'src/views/styles/assets/jpg/epic-hearing.jpg';
import imageEAP from 'src/views/styles/assets/jpg/eap.jpg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '100%',
      justifyContent: 'center',
      marginBottom: 16,
      padding: 0,
    },
    avatar: {
      borderRadius: 4,
      backgroundColor: red[500],
    },
  })
);

interface BenefitCardProps {
  src: string;
  title: string;
  subheader: string;
  href: string;
}

function BenefitCard({ src, title, subheader, href }: BenefitCardProps) {
  const classes = useStyles();

  return (
    <Card elevation={0} variant="outlined" className={classes.root}>
      <CardActionArea href={href} rel="noreferrer noopener" target="_blank">
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" src={src} className={classes.avatar} />
          }
          action={
            <IconButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              edge="end"
            >
              <ChevronRight color="secondary" />
            </IconButton>
          }
          title={title}
          subheader={subheader}
        />
      </CardActionArea>
    </Card>
  );
}

export default function HBTContainer() {
  const isMobile = useBreakpoint('xs');
  const benefitsList = [
    {
      title: 'Caregiver Kicks',
      subheader: 'Free shoes for caregivers',
      href: 'http://www.myseiubenefits.org/caregiverkicks/',
      src: imageCaregiverKicks,
    },
    {
      title: 'Ginger',
      subheader: 'On-demand emotional support',
      href: 'https://www.myseiubenefits.org/emotional_support/',
      src: imageGinger,
    },
    {
      title: 'Tools for Calm',
      subheader: 'Helps reduce stress and anxiety',
      href: 'https://www.myseiubenefits.org/tools-for-calm/',
      src: imageTfC,
    },
    {
      title: 'EPIC Hearing',
      subheader: 'Hearing aid hardware and exams',
      href: 'https://www.epichearing.com/tpa-ap-web/?navDeepDive=EPIC_seiu775',
      src: imageEpicHearing,
    },
    {
      title: 'Employee Assistance Program (EAP)',
      subheader: 'Helps with common life or work issues',
      href: 'https://www.myseiubenefits.org/employee-assistance-program/',
      src: imageEAP,
    },
  ];

  return (
    <>
      <PageHeader>
        <Heading3 gutterBottom>Health</Heading3>

        <Subheading>
          Learn about health insurance and other benefits available to
          caregivers, and log in to My Plan.
        </Subheading>
      </PageHeader>

      <PageContent>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            disableTypography
            title={<Heading4 gutterBottom>About Health Insurance</Heading4>}
            subheader={
              <Subheading>
                For just $25 a month, qualifying caregivers can get health
                insurance benefits, including:
              </Subheading>
            }
          />
          <CardContent>
            <Banner
              avatar={
                <ValidPass
                  style={{ maxWidth: 12 }}
                  size="small"
                  fontSize="small"
                />
              }
            >
              <Subheading>Medical</Subheading>
            </Banner>
            <Banner
              avatar={
                <ValidPass
                  style={{ maxWidth: 12 }}
                  size="small"
                  fontSize="small"
                />
              }
            >
              <Subheading>Prescription drug</Subheading>
            </Banner>
            <Banner
              avatar={
                <ValidPass
                  style={{ maxWidth: 12 }}
                  size="small"
                  fontSize="small"
                />
              }
            >
              <Subheading>Behavioral health</Subheading>
            </Banner>
            <Banner
              avatar={
                <ValidPass
                  style={{ maxWidth: 12 }}
                  size="small"
                  fontSize="small"
                />
              }
            >
              <Subheading>Hearing coverage</Subheading>
            </Banner>
            <Banner
              avatar={
                <ValidPass
                  style={{ maxWidth: 12 }}
                  size="small"
                  fontSize="small"
                />
              }
            >
              <Subheading>Dental coverage</Subheading>
            </Banner>
          </CardContent>

          <CardActions>
            <Link
              underline="none"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.myseiubenefits.org/health-benefits-trust/get-covered/"
              color="secondary"
            >
              Learn more about getting covered.
            </Link>
          </CardActions>
        </Card>

        <Card variant="outlined" elevation={0}>
          <CardHeader
            disableTypography
            title={<Heading4 gutterBottom>My Plan</Heading4>}
            subheader={
              <Subheading>
                Apply for health benefits online and check eligibility details
                by logging in to your account on My Plan.
              </Subheading>
            }
          />

          <CardActions>
            <Link
              underline="none"
              size="medium"
              fullWidth={isMobile}
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.myplanbg.org/"
              component={ButtonPrimary}
            >
              Learn more about getting covered.
            </Link>
          </CardActions>
        </Card>

        <Box my={1} pt={2}>
          <Heading3 gutterBottom>More Benefits for You</Heading3>

          <Subheading gutterBottom>
            Health and safety resources are available at no cost to you! For
            most benefits, you do not have to be enrolled in the health plan to
            qualify.
          </Subheading>

          {benefitsList.map((item, k) => {
            return <BenefitCard key={k + 'item' + item?.href} {...item} />;
          })}
        </Box>

        <Box mb={2} py={1} textAlign="center">
          <Heading4 gutterBottom style={{ marginBottom: 8 }}>
            Visit the Health page for more information
          </Heading4>

          <Link
            href="https://www.myseiubenefits.org/health-benefits-trust/"
            target="_blank"
            underline="none"
            rel="noreferrer noopener"
            component={ButtonSecondary}
          >
            Learn More
          </Link>
        </Box>
      </PageContent>
    </>
  );
}
