import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ButtonSecondary } from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Subheading } from 'src/views/components/DSL/typography';

interface CourseOption {
  id: number;
  idnumber: string;
  course: number;
  name: string;
  bookingmanager: string;
  duration: string;
  credits: string;
  type: string;
  modified: string;
  range: string;
  firstsession: any;
  sessionblockscount: number;
}
interface Props {
  label: React.ReactNode;
  expanded: boolean;
  onClick: React.MouseEventHandler;
  onSelect: (option: any) => void;
  option: CourseOption;
}

function OptionExpandControls({
  label,
  expanded,
  onClick,
  onSelect,
  option,
}: Props) {
  const isMobile = useBreakpoint('xs');

  return expanded ? (
    <ButtonSecondary capitalize size="medium" onClick={() => onSelect(option)}>
      Select
    </ButtonSecondary>
  ) : (
    <Subheading
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      noWrap
    >
      Details
      <ExpandMoreIcon style={{ margin: 0, padding: 0 }} color="secondary" />
    </Subheading>
  );
}

export default React.memo(OptionExpandControls);
