// external
import { Collapse, Divider, Fade, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import config from 'src/store/config';
import { getMomentizedRange } from 'src/utils';
import { DescriptorError } from 'src/views/components/DSL/typography';
import { getRangeDetailLabel, getRangePreviewLabel } from './localutils';
import OptionDetails from './OptionDetails';
import OptionExpandControl from './OptionExpandControl';
import OptionIcon from './OptionIcon';
import OptionPreview from './OptionPreview';

function OptionCard({
  id,
  idnumber,
  course,
  name,
  bookingmanager,
  duration,
  credits,
  type,
  modified,
  range,
  firstsession,
  sessionblockscount,
  onSelect,
  distanceFromUser,
  language,
}) {
  const courseOption = {
    id: id,
    idnumber: idnumber,
    course: course,
    name: name,
    bookingmanager: bookingmanager,
    duration: duration,
    credits: credits,
    type: type,
    modified: modified,
    range: range,
    firstsession: firstsession,
    sessionblockscount: sessionblockscount,
    language: language,
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  /* Course Type Icon */
  const startIcon = <OptionIcon type={type} />;

  /* Date Range Preview */
  const rangePreviewLabel = getRangePreviewLabel({
    type,
    expanded,
    distanceFromUser,
    firstsession,
  });

  /* Date Range Detail */
  const rangeDetailLabel = getRangeDetailLabel({
    type,
    firstsession,
    expanded,
    idnumber,
  });

  /* Expand Button Preview Label */
  // const distancePreviewLabel = getExpandButtonPreviewLabel({
  //   ...arguments[0],
  //   expanded,
  // });

  /* Expand Button Icon */
  const expandControl = (
    <OptionExpandControl
      expanded={expanded}
      onClick={() => setExpanded(!expanded)}
      onSelect={onSelect}
      label={''}
      option={courseOption}
    />
  );

  /* Range processing */
  const rangeMomentized =
    firstsession?.begins && firstsession?.ends
      ? getMomentizedRange({
          begins: firstsession.begins,
          ends: firstsession.ends,
        })
      : null;

  return (
    <Grid
      container
      direction="column"
      onClick={() => setExpanded(!expanded)}
      style={{
        backgroundColor: '#fff',
        borderRadius: 6,
        border: '1px solid #e2e5e9',
        marginBottom: 15,
        cursor: 'pointer',
      }}
    >
      <>
        {/* Displayed Section */}
        {!type ? (
          <DescriptorError style={{ padding: 10 }}>
            No details found for option
          </DescriptorError>
        ) : type === config.MODALITY_TYPES.ONLINE ? (
          <OptionPreview
            expanded={expanded}
            startIcon={startIcon}
            expandControl={expandControl}
            rangeDetailLabel={rangeDetailLabel}
            rangePreviewLabel={rangePreviewLabel}
            expandDetailLabel={'online'}
          />
        ) : (
          <OptionPreview
            expanded={expanded}
            startIcon={startIcon}
            expandControl={expandControl}
            rangeDetailLabel={rangeDetailLabel}
            rangePreviewLabel={rangePreviewLabel}
            expandDetailLabel={
              firstsession?.address.split(',')[1] || firstsession?.address
            }
          />
        )}

        {/* Divider */}
        <Fade in={expanded}>
          <Divider />
        </Fade>

        {/* Collapsable Section */}
        <Collapse in={expanded}>
          {type === config.MODALITY_TYPES.ONLINE ? (
            <OptionDetails
              address={firstsession?.address}
              venue={firstsession?.institution}
              type={type}
              range={rangeMomentized}
              language={language}
            />
          ) : (
            <OptionDetails
              address={firstsession?.address}
              venue={firstsession?.institution}
              type={type}
              range={rangeMomentized}
              language={idnumber}
            />
          )}
        </Collapse>
      </>
    </Grid>
  );
}

export default React.memo(OptionCard);
