import { Box } from '@material-ui/core';
import { parseUrl } from 'query-string';
import React, { useEffect, useState } from 'react';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { objIsEmpty, useRouter } from 'src/utils';
import { ButtonPrimary, ButtonText } from 'src/views/components/DSL/buttons';
import { CheckGreen } from 'src/views/components/DSL/Icons';
import { TransitionAlert } from 'src/views/components/DSL/info';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Body, Display } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import CourseItemCompact from '../../CLC/components/CourseItemCompact';
import ECOptionDetails from './ECOptionDetails';

export default function ECOptionsConfirmed() {
  const isMobile = useBreakpoint('xs');
  const router = useRouter();
  const email = parseUrl(router.location.search)?.query?.email;
  const selectedCourse = useSelector(courseCatalogSelectors.getSelectedCourse);
  const [error, seterror] = useState('');

  useEffect(() => {
    let unmounted = false;

    if (unmounted) {
      return;
    }

    if (objIsEmpty(selectedCourse) || !email) {
      seterror('Unable to load enrollment confirmation at this time');
    } else {
      seterror('');
    }

    return () => {
      unmounted = true;
    };
  }, [selectedCourse, email]);

  console.log(
    `${router.location.pathname}${router.location.search}&refresh=true`
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={1}
      justifyContent="center"
      alignItems="stretch"
      pt={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        {!error && <CheckGreen fontSize="large" />}

        <Display gutterBottom style={{ fontSize: 40 }}>
          Enrolled In Course
        </Display>
        {/* {email ? (
          <Body gutterBottom align="center">
            A confirmation will be sent to {`${email}`}
          </Body>
        ) : (
          <Body gutterBottom align="center">
            A confirmation will be sent to you shortly.
          </Body>
        )} */}
        <ButtonPrimary
          fullWidth={false}
          capitalize
          onClick={() => {
            router.push('/mycourses/enrolled?refresh=true');
          }}
        >
          Go to My Courses
        </ButtonPrimary>
        <br />
        <ButtonText
          fullWidth={false}
          capitalize
          onClick={() => {
            router.push('/coursecatalog?refresh=true');
          }}
        >
          Back to Course Catalog
        </ButtonText>
      </Box>

      <Box py={2}>
        {error && (
          <TransitionAlert message="Unable to load confirmation at this time" />
        )}

        <Box
          my={1}
          mb={2}
          width={1}
          flexGrow={isMobile ? 1 : 'initial'}
          display="flex"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <ECOptionDetails {...selectedCourse} />
          <CourseItemCompact
            style={{ width: isMobile ? '100%' : '50%', marginBottom: 0 }}
            onClick={null}
            {...selectedCourse}
          />
        </Box>
      </Box>
    </Box>
  );
}
