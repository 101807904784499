/**
 * ATTENTION! All values are PUBLIC because they are part of the Javascript bundle
 */
export const BGLINK = 'https://www.myseiubenefits.org/';
export const MOODLE = process.env.REACT_APP_MOODLE;
export const API =
  process.env.REACT_APP_API || 'https://api.clc.prod.seiu775.bg';
export const MOODLE_SERVICE_URL =
  process.env.REACT_APP_MOODLE_SERVICE_URL ||
  `https://moodle-service.clc.prod.seiu775.bg/api/v1`;
export const AUTH_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? window?.location?.protocol +
      '//' +
      window?.location?.hostname +
      ':' +
      window?.location?.port
    : window?.location?.protocol + '//' + window?.location?.hostname;
export const GEOLOC =
  process.env.REACT_APP_GEOLOC || 'http://api.traveltimeapp.com/v4';
export const GEOLOC_APPID = process.env.REACT_APP_GEOLOC_APPID || '66e54a7d';
export const GEOLOC_API_KEY =
  process.env.REACT_APP_GEOLOC_API_KEY || 'a7ddc964403d2d51868a99c27e3d54e9';

// `Access-Control-Allow-Origin: *` is set for the dev instance,
// but not staging or prod
export const DEX_BASE_URL =
  process.env.REACT_APP_DEX_BASE_URL ||
  `https://enrollment.clc.dev.seiu775.bg/dex`;
export const DEX_API_KEY =
  process.env.REACT_APP_DEX_API_KEY ||
  'Basic cG9ydGFsZGV2QHNlaXVoZWFsdGhjYXJlbnd0cmFpbmluZ3AtSUFYWjFMLlBTUzA1VDowMTA5NjA4NC01ZjQ5LTQ2NTEtOTMwNS0xZmNlNzI3N2FjMzQ=';
export const TRAININGS_URL = `${API}/training-ms/learners/`;
export const TRAININGS_API_KEY =
  process.env.REACT_APP_TRAININGS_API_KEY ||
  '45Q1DffqZs8tO9OSUtUReaPtQgnRucyp11LhWIev';
export const LEARNER_STEPS_URL =
  process.env.REACT_APP_LEARNER_STEPS_URL ||
  'https://a72od03cgb.execute-api.us-west-2.amazonaws.com/dev/steps';
export const TRANSCRIPTS_URL =
  process.env.REACT_APP_TRANSCRIPTS_URL ||
  'https://transcripts.clc.prod.seiu775.bg';
/**
 * Used to handle SPA auth redirects
 */
export const AZUREREDIRECTLINK = `https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignInOnly&
client_id=87341714-179f-4073-899f-2d326f89c539
&response_type=id_token%20token
&response_mode=form_post
&scope=openid%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2Fuser_impersonation%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2FUser.Read
&nonce=defaultNonce`;
export const AZURESIGNINLINK = `https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignInOnly&client_id=87341714-179f-4073-899f-2d326f89c539&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
  window.location.origin
)}&scope=openid%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2Fuser_impersonation%20https%3A%2F%2Fseiu775bgb2c.onmicrosoft.com%2F87341714-179f-4073-899f-2d326f89c539%2FUser.Read&response_type=id_token%20token&prompt=login`;
export const AZURESIGNOUTLINK = `https://seiu775bgb2c.b2clogin.com/seiu775bgb2c.onmicrosoft.com/B2C_1_SignInOnly/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
  AZURESIGNINLINK
)}`;
export const PTLSLSAPI =
  process.env.REACT_APP_PTLSLSAPI ||
  `https://f8e4aapszb.execute-api.us-west-2.amazonaws.com/dev/portal`;
export const PTLSLSAPIKEY =
  process.env.REACT_APP_PTLSLSAPIKEY ||
  `Iz4VU8MZAl5ZRJGWhA3HN9hgPgnSiKDU7ivA2iBg`;
export const B2CEXTENSIONSAPPID = 'be90092b9a90457dbcd7991e42e74939';
