import { Grid, GridProps } from '@material-ui/core';
import React from 'react';
import { Dashboard } from 'src/types';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { BodyLink } from 'src/views/components/DSL/typography';
import CLCPreview from './CLCPreview';
import HealthPreview from './HealthPreview';
import RetirementPreview from './RetirementPreview';

interface Props {
  sectProps: GridProps;
  dashboard: Dashboard;
}

export default function HCStudentSect({ sectProps, dashboard }: Props) {
  const isMobile = useBreakpoint('xs');
  return (
    <Grid
      item
      container
      spacing={2}
      style={{
        padding: `10px ${isMobile ? 5 : 20}px`,
      }}
    >
      <Grid {...sectProps}>
        <CLCPreview dashboard={dashboard} />
      </Grid>

      <Grid {...sectProps}>
        <BodyLink to="/health">
          <HealthPreview />
        </BodyLink>
      </Grid>

      <Grid {...sectProps}>
        <BodyLink to="/secureretirement">
          <RetirementPreview />
        </BodyLink>
      </Grid>
    </Grid>
  );
}
