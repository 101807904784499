import { makeStyles } from '@material-ui/core';

const LEFT = '45%';
const RIGHT = '55%';

const useECStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  leftPaper: {
    width: LEFT,
  },
  drawerLeft: {
    width: LEFT,
    border: 'none',
    flexShrink: 0,
  },
  rightPaper: {
    width: RIGHT,
  },
  drawerRight: {
    width: RIGHT,
    border: 'none',
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
  },
  hero: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'none',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 40,
    backgroundImage:
      'url(https://images.unsplash.com/photo-1594952961393-a91e1d938c43?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max)',
  },
}));

export default useECStyles;
