import { makeActionCreator } from '../makeActionCreator';
import popoverActionTypes from './types';

const { CLOSE_POPOVER, OPEN_POPOVER, SET_CONTENT_KEY } = popoverActionTypes;

export default {
  openPopover: makeActionCreator(OPEN_POPOVER),
  closePopover: makeActionCreator(CLOSE_POPOVER),
  setPopoverContentKey: makeActionCreator(SET_CONTENT_KEY, 'contentKey'),
};
