import { Divider, Grid, GridProps } from '@material-ui/core';
import React from 'react';
import { useRouter } from 'src/utils';
import { ButtonLink } from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Subheading } from 'src/views/components/DSL/typography';
function CourseOptionsCTA({ enrolled, available }) {
  const { push } = useRouter();
  const centeredContainer: GridProps = {
    container: true,
    direction: 'column',
    alignItems: 'center',
    justify: 'center',
  };
  const fluidItem: GridProps = {
    item: true,
    xs: true,
  };
  const isMobile = useBreakpoint('xs');
  return (
    <Grid container alignItems="center" style={{ padding: '15px 0px' }}>
      <Grid
        {...fluidItem}
        {...centeredContainer}
        onClick={() => push('/mycourses/enrolled')}
        style={{ cursor: 'pointer' }}
      >
        <ButtonLink color="secondary">{enrolled}</ButtonLink>
        <Subheading>Enrolled Courses</Subheading>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid
        {...fluidItem}
        {...centeredContainer}
        onClick={() => push('/coursecatalog')}
        style={{ cursor: 'pointer' }}
      >
        <ButtonLink color="secondary">{available}</ButtonLink>
        <Subheading>Available Courses</Subheading>
      </Grid>
    </Grid>
  );
}
export default React.memo(CourseOptionsCTA);
