import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link, { LinkProps } from '@material-ui/core/Link';

const useStyles = ({
  gutterRight = false,
  gutterLeft = false,
  gutterTop = false,
  gutterBottom,
  contrast,
}) =>
  makeStyles(({ palette }) => ({
    root: {
      // proprietary
      background: 'none',
      '&:hover': {
        background: 'none',
      },
      '&:focus': {
        background: 'none',
      },

      // conditionally assigned
      marginTop: gutterTop ? 20 : undefined,
      marginBottom: gutterBottom ? 20 : undefined,
      marginLeft: gutterLeft ? 20 : undefined,
      marginRight: gutterRight ? 20 : undefined,
      textTransform: 'capitalize',
      fontWeight: 700,

      // defaults
      textDecoration: 'none',
      color: contrast ? palette.common.white : palette.secondary[400],
      fontSize: 14,
      letterSpacing: 0,
    },
  }));

interface Props extends LinkProps {
  gutterRight?: boolean;
  gutterLeft?: boolean;
  gutterTop?: boolean;
  gutterBottom?: boolean;
  contrast?: boolean;
}

export default function ButtonAnchor({
  target = '_blank',
  href = '#',
  gutterRight,
  gutterLeft,
  gutterTop,
  gutterBottom,
  contrast,
  ...rest
}: Props) {
  const classes = useStyles({
    gutterRight,
    gutterLeft,
    gutterTop,
    gutterBottom,
    contrast,
  })();

  return (
    <Link
      variant="body2"
      rel="noreferrer noopener"
      target={target}
      underline="none"
      href={href}
      classes={classes}
      {...rest}
    />
  );
}
