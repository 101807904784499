import { useTheme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';
import { Link } from 'react-router-dom';
import Display from 'src/views/components/DSL/typography/Display';

function HeroContent({ children, isMobile = false }) {
  const theme = useTheme();

  return (
    <>
      <Fab
        variant="extended"
        tabIndex={0}
        to={'/coursecatalog'}
        disableFocusRipple
        disableRipple
        component={Link}
        style={{
          top: isMobile ? 12 : 40,
          left: isMobile ? 12 : 40,
          backgroundColor: '#fff',
          position: 'absolute',
          color: theme.palette.secondary.main,
          textTransform: 'capitalize',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        title="Course Catalog"
        focusRipple={true}
        size="medium"
      >
        <ArrowBack
          style={{
            margin: '0px 10px 0px -5px',
          }}
        />
        Course Catalog
      </Fab>
      <Display align="left" contrast>
        {children || '404 - Course Not Found'}
      </Display>
    </>
  );
}

export default React.memo(HeroContent);
