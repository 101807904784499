import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ButtonGroup, ButtonGroupProps } from '@material-ui/core';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ButtonGroup);

function ButtonMultiState({ children, ...props }: ButtonGroupProps) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <StyledToggleButtonGroup {...props}>{children}</StyledToggleButtonGroup>
    </Paper>
  );
}

export default ButtonMultiState;
