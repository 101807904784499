import React from 'react';
import {
  BGLogoWhite,
  MBHomeWhite,
  TPLogoWhite,
} from 'src/views/components/DSL/Icons';

export const NAV_COPY = {
  '/caregiverlearningcenter': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },
  '/coursecatalog': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },
  '/certificates': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },
  '/schedule': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },
  '/mycourses/enrolled': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },
  '/mycourses/completed': {
    title: 'Caregiver Learning Center',
    startIcon: <TPLogoWhite size="small" />,
    link: '/caregiverlearningcenter',
  },

  '/unauthorized': {
    title: '',
    startIcon: <BGLogoWhite size="small" />,
  },

  '/': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
  '/health': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
  '/profile': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
  '/help': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
  '/secureretirement': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
  '/contact': {
    title: 'My Benefits',
    startIcon: <MBHomeWhite size="small" />,
    link: '/',
  },
};
