import React from 'react';
import { ReactComponent as CALogo } from 'src/views/styles/assets/icons/calendar_blue.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

function CalendarBlue({ fontSize = 'small', ...props }: IconProps) {
  return <Icon component={CALogo} fontSize={fontSize} {...props} />;
}

export default CalendarBlue;
