import config from 'src/store/config';
import { Authentication, Dashboard, Progress } from 'src/types';

export const getIsLoggedIn = (state) => state.authentication.isLoggedIn;
export const getFirstname = (state) => state.authentication.firstName;
export const getLastname = (state) => state.authentication.lastName;
export const getLearnerId = (state) => state.authentication.learnerId;
export const getLoginError = ({ authentication }) => authentication.error;
export const getUserDashboard = ({ authentication }) =>
  authentication.dashboard;
export const getUserRole = ({ authentication }) => authentication.role;
export const getStudentEmail = ({ authentication }) => authentication.email;

export const getRecordsLoaded = (state) =>
  getDashboardLoaded(state) && getTrainingsLoaded(state);
export const getDashboardLoaded = (state) =>
  state.authentication.dashboard?.status === config.REQUEST_STATUSES.FINISHED;
export const getTrainingsLoaded = (state) =>
  state.authentication.trainings?.status === config.REQUEST_STATUSES.FINISHED;
export const getTrainings = (state) => state.authentication.trainings;

export const getAuthentication = (state): Authentication =>
  state.authentication;
export const getDashboard = (state): Dashboard =>
  getAuthentication(state).dashboard;

export const getZipcode = ({
  authentication,
}: {
  authentication: Authentication;
}): string => authentication.zipCode;

export const getPreferredLanguage = ({ authentication }): string =>
  authentication?.preferredLanguage;

export const getFluentLanguages = ({ authentication }): string =>
  authentication?.languagesFluentIn;

/* FIXME: switch to transcripts */
export const getVerticalProgression = ({ authentication }): boolean => {
  const codes = authentication?.trainings?.codes;

  for (let i = 0; i < codes.length; i++) {
    if (codes[i]?.startsWith('202')) {
      return true;
    }
  }

  return false;
};

export const getInprogressCodesFromDashboard = (
  dashboard: Dashboard
): (number | string)[] => {
  const inProgress = dashboard.inProgress || [];
  return inProgress.map((p: Progress) => p.trainingCode);
};
