import React from 'react';
import { Drawer, Toolbar } from '@material-ui/core';

export default function OBHero({ classes }) {
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
    </Drawer>
  );
}
