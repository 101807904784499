import { Box, Link } from '@material-ui/core';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useBreakpoint } from 'src/views/components/DSL/theme';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface CertViewerProps {
  file: string;
}

export default function CertViewer({ file }: CertViewerProps) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);

  const isMobile = useBreakpoint('xs');

  const onDocumentLoadSuccess = (params) => {
    setNumPages(params?.numPages);
  };

  return (
    <Box display="flex" flexDirection="column" overflow="scroll" maxWidth={1}>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Link href={file} target="_self" download>
          <Page
            width={isMobile ? window.innerWidth - 32 : null}
            pageNumber={pageNumber}
          />
        </Link>
      </Document>
    </Box>
  );
}
