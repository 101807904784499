import defaultState from '../defaultState';
import { takeEvery, call, put } from 'redux-saga/effects';
import Axios from 'axios';
import { normalizeSessionObj } from 'src/utils';
import { MOODLE_SERVICE_URL } from 'src/envvars';

/**
 * TYPES
 */
export const makeupActionTypes = {
  REQUEST_LOAD_MAKEUPS: 'makeups/REQUEST_LOAD_MAKEUPS',
  LOAD_MAKEUPS_SUCCESS: 'makeups/LOAD_MAKEUPS_SUCCESS',
  LOAD_MAKEUPS_FAILURE: 'makeups/LOAD_MAKEUPS_FAILURE',
  CLEAR_MAKEUPS: 'makeups/CLEAR_MAKEUPS',
};

/**
 * ACTION CREATORS
 *
 * @param {Object} state
 * @param {Object} action
 */
const requestLoadMakeups = (query, onSuccess, onError) => ({
  type: makeupActionTypes.REQUEST_LOAD_MAKEUPS,
  query,
  onSuccess,
  onError,
});
const loadMakeupsSuccess = (makeups) => ({
  type: makeupActionTypes.LOAD_MAKEUPS_SUCCESS,
  makeups,
});
const loadMakeupsFailure = (error) => ({
  type: makeupActionTypes.LOAD_MAKEUPS_FAILURE,
  error,
});
const clearMakeups = () => ({ type: makeupActionTypes.CLEAR_MAKEUPS });

export const makeupActions = {
  requestLoadMakeups,
  loadMakeupsSuccess,
  loadMakeupsFailure,
  clearMakeups,
};

/**
 * API INTERACTION
 */
const loadMakeups = async ({ index }) => {
  try {
    const response = await Axios.get(
      `${MOODLE_SERVICE_URL}sessions/available/class/${index}`
    );

    return response.data.data.map((sess) => normalizeSessionObj(sess));
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * SAGAS
 */
function* onMakeupLoadRequest(action) {
  const { query, onSuccess, onError } = action;
  const { index } = query;

  try {
    /**
     * - check header from token (this has a dependency on communities app)
     * - call moodle service to validate said token
     * - dispatch AUTH_SUCCESS
     */

    const makeups = yield call(loadMakeups, { index });

    yield put({
      type: makeupActionTypes.LOAD_MAKEUPS_SUCCESS,
      makeups,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    /**
     * - extract error message
     * - dispatch appropriate actions
     */

    yield put({
      type: makeupActionTypes.LOAD_MAKEUPS_FAILURE,
      status: 'Failed',
      error,
    });

    if (onError) {
      onError(error);
    }
  }
}

export function* makeupSagas() {
  yield takeEvery(makeupActionTypes.REQUEST_LOAD_MAKEUPS, onMakeupLoadRequest);
}

/**
 * REDUCERS
 *
 * @param {Object} state
 * @param {Object} action
 */
const makeupsReducer = (state = defaultState.makeups, action) => {
  switch (action.type) {
    case makeupActionTypes.REQUEST_LOAD_MAKEUPS:
      return {
        ...state,
        status: 'Pending',
      };
    case makeupActionTypes.LOAD_MAKEUPS_FAILURE:
      return {
        status: 'Failed',
        error: action.error,
      };
    case makeupActionTypes.LOAD_MAKEUPS_SUCCESS:
      return {
        status: 'Success',
        makeups: action.makeups,
      };
    case makeupActionTypes.CLEAR_MAKEUPS:
      return defaultState.makeups;
    default:
      return state;
  }
};

export default makeupsReducer;
