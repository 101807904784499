import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { BT_MANDATORY, HIGHLYRECOMMENDED, TRAINING_CODE } from 'src/consts';
import { MOODLE } from 'src/envvars';
import {
  getDashboard,
  getInprogressCodesFromDashboard,
} from 'src/store/authentication/selectors';
import { theme } from 'src/views/components/DSL';
import { CheckGreen, CheckRed } from 'src/views/components/DSL/Icons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import {
  Descriptor,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import clock from 'src/views/styles/assets/icons/clock@2x.png';
import padlock from 'src/views/styles/assets/icons/lock_dark-grey@2x.png';

interface StyleProps {
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  mediaHeight?: number;
}

const useStyles = ({ style, onClick, mediaHeight }: StyleProps) =>
  makeStyles(({ palette }) => {
    const CARD_HEIGHT = 330;

    return {
      root: {
        cursor: onClick ? 'pointer' : undefined,
        width: style?.width ?? '100%',
        height: style?.height ?? CARD_HEIGHT,
        borderRadius: 8,
        border: `1px solid ${palette.grey}`,
        backgroundColor: '#fff',
      },
      media: {
        height: mediaHeight ?? CARD_HEIGHT / 2,
        backgroundColor: 'lavender',
      },
      content: {
        height: mediaHeight ?? CARD_HEIGHT / 2,
        '&:last-child': {
          paddingBottom: 12,
        },
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    };
  });

interface Props extends StyleProps {
  lockStatus?: 'LOCKED' | 'UNLOCKED' | 'HIDDEN';
  image: string;
  format: string;
  fullname: string;
  credit_hours: number;
  totalOptions?: number;
  type: string;
  idnumber: string;
  currentTraining?: string;
}

// TODO: use enum
export const BadgeIcon = (props) => {
  // console.log(props);
  const isMobile = useBreakpoint('xs');

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      style={{
        // marginBottom: 10,
        // padding: '0 5',
        backgroundColor:
          props.title === 'Mandatory'
            ? '#f9e2da'
            : props.title === 'Credits' || props.title === 'UpTo12'
            ? '#ebe1fb'
            : props.title === 'HighlyRecc' || props.title === 'WageRaise'
            ? '#e3f3d3'
            : '',
        borderRadius: 4,
        width:
          props.title === 'Mandatory'
            ? '90px'
            : props.title === 'Credits'
            ? '55px'
            : props.title === 'HighlyRecc'
            ? '140px'
            : '',
      }}
    >
      {props.title === 'HighlyRecc' && (
        <>
          <CheckGreen compact style={{ fontSize: 14 }} />
          <Grid item xs style={{ paddingLeft: 5 }}>
            <Descriptor
              style={{
                color: '#20662b',
              }}
            >
              Highly recommended
            </Descriptor>
          </Grid>
        </>
      )}
      {props.title === 'Mandatory' && (
        <>
          <CheckRed compact />
          <Grid item xs style={{ paddingLeft: 5 }}>
            <Descriptor
              style={{
                color: '#ab3108',
              }}
            >
              {props.title}
            </Descriptor>
          </Grid>
        </>
      )}
      {props.title === 'Credits' && (
        <>
          <Grid item xs style={{ paddingLeft: 5 }}>
            <Descriptor
              style={{
                color: '#5c3d8e',
              }}
            >
              {props.hours > 1
                ? `${props.hours} credits`
                : `${props.hours} credit`}
            </Descriptor>
          </Grid>
        </>
      )}
    </Grid>
  );
};

function CCCourseItem({
  image,
  type,
  fullname,
  credit_hours,
  style,
  mediaHeight,
  onClick,
  lockStatus,
  totalOptions,
  idnumber,
  currentTraining,
}: Props) {
  const mandatoryTag =
    idnumber.substring(0, 7) === BT_MANDATORY.CAREGIVER_CLIENT_EXP ||
    idnumber.substring(0, 7) === BT_MANDATORY.PERSON_CENTERED_APPROACH ||
    idnumber.substring(0, 7) ===
      BT_MANDATORY.RECOGNIZING_REPORTING_CONSUMER_ABUSE ||
    idnumber.substring(0, 7) ===
      BT_MANDATORY.REDUCING_THE_SPREAD_OF_INFECTION ||
    idnumber.substring(0, 7) === BT_MANDATORY.MEDICATION_ASSISTANCE
      ? true
      : false;
  const recommendedTag =
    idnumber.substring(0, 7) === HIGHLYRECOMMENDED.CBP_OVEREXERTION
      ? true
      : false;

  const classes = useStyles({ style, onClick, mediaHeight })();
  return (
    <Card
      className={classes.root}
      style={{ opacity: lockStatus === 'LOCKED' ? 0.6 : 1 }}
      onClick={onClick}
      variant="outlined"
    >
      <CardMedia
        className={classes.media}
        image={`${MOODLE}${image?.replace(/ /g, '%20')}`}
      />
      <CardContent className={classes.content}>
        <Box width={1} maxWidth={1}>
          <Heading4 gutterBottom>
            {lockStatus === 'LOCKED' && (
              <img
                src={padlock}
                alt="Locked"
                style={{ marginRight: 3, width: 14 }}
              />
            )}
            {fullname}
          </Heading4>
          <Descriptor>
            {capitalize(type)}
            {currentTraining === TRAINING_CODE.CE_12.CODE && recommendedTag ? (
              <BadgeIcon title="HighlyRecc" />
            ) : (
              ''
            )}
            {(currentTraining === TRAINING_CODE.BT_30.CODE ||
              currentTraining === TRAINING_CODE.BT_9.CODE) &&
            mandatoryTag ? (
              <BadgeIcon title="Mandatory" />
            ) : (
              ''
            )}
            {currentTraining === TRAINING_CODE.AHCAS.CODE ||
            currentTraining === TRAINING_CODE.CE_12.CODE ? (
              <BadgeIcon title="Credits" hours={credit_hours} />
            ) : (
              ''
            )}
          </Descriptor>
        </Box>

        <Box width={1} maxWidth={1} display="flex" alignItems="center">
          <Box
            width="50%"
            maxWidth="50%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Descriptor
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={clock}
                alt="Clock"
                style={{
                  marginTop: -2.5,
                  marginRight: 5,
                  width: 20,
                }}
              />
              {credit_hours
                ? credit_hours > 1
                  ? `${credit_hours} paid hrs`
                  : `${credit_hours} paid hr`
                : 'No duration available'}
            </Descriptor>
          </Box>
          <Box
            width="50%"
            maxWidth="50%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* per UI spec, hide options viz if card is locked */}
            {lockStatus !== 'LOCKED' && (
              <Descriptor align="right">
                <span
                  style={{
                    color: theme.palette.secondary.dark,
                    fontSize: 14,
                    fontWeight: 'bolder',
                  }}
                >
                  {totalOptions || 0} {`Option(s)`}
                </span>
                <br />
                to choose from
              </Descriptor>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default React.memo(CCCourseItem);
