import axios, { AxiosInstance } from 'axios';
import qs from 'query-string';
import { MOODLE_SERVICE_URL } from 'src/envvars';

export interface LMSServiceQSParams {
  lang?: string[] | string;
  code?: string[] | string;
  types?: string[] | string;
  tag?: string[] | string;
  modalities?: string[] | string;
}

class LMSService {
  private moodleservice: AxiosInstance;

  constructor() {
    this.moodleservice = axios.create({
      baseURL: MOODLE_SERVICE_URL,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'bracket' }),
    });
  }
  getFilteredCourses = async (params: LMSServiceQSParams) => {
    const response = await this.moodleservice
      .get(`/courses/search`, {
        params: params,
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
    return Promise.resolve(response.data);
  };

  getBlocks = async (courseId, onCancel = undefined) => {
    const response = await axios
      .get(
        `${MOODLE_SERVICE_URL}/courses/${courseId}/blocks/available`,
        onCancel
          ? {
              cancelToken: onCancel,
            }
          : null
      )
      .catch(function (error) {
        return Promise.reject(error);
      });
    return Promise.resolve(response.data);
  };

  getDashboard = async (learnerId) => {
    if (!learnerId) {
      throw new Error('Unable to load learner progress dashboard.');
    }

    try {
      const { dashboard } = (
        await axios.get(`${MOODLE_SERVICE_URL}/learners/${learnerId}/dashboard`)
      )?.data;

      return dashboard;
    } catch (error) {
      console.log(error);
      throw new Error('Unable to load learner progress dashboard.');
    }
  };

  loadSessionsInBlock = async ({ blockid }) => {
    try {
      const response = await axios.get(
        `${MOODLE_SERVICE_URL}/blocks/${blockid}/sessions/available`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export { LMSService };
export default new LMSService();
