import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

interface ScheduleCalendarProps {
  sessions: any;
  start?: Moment;
  end?: Moment;
  minDate?: Moment;
  maxDate?: Moment;
}

function ScheduleCalendar({
  sessions,
  start,
  end,
  minDate,
  maxDate,
}: ScheduleCalendarProps) {
  const selectedDays = [];
  sessions.forEach((element) => {
    selectedDays.push({
      year: moment(element.begins).get('year'),
      month: moment(element.begins).get('month') + 1,
      day: moment(element.begins).get('date'),
    });
  });
  // console.log(selectedDays);

  const [selectedDayRange] = useState(selectedDays);

  return (
    <Calendar
      value={selectedDayRange}
      colorPrimary={'#8568B4'}
      colorPrimaryLight={'#444444'}
      shouldHighlightWeekends
    />
  );
}

export default React.memo(ScheduleCalendar);
