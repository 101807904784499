import React from 'react';
import { Card } from '@material-ui/core';

export default function PreviewCard({ children }) {
  return (
    <Card variant="outlined" elevation={1}>
      {children}
    </Card>
  );
}
