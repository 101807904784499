import defaultState from '../defaultState';
import { takeEvery, put, call } from 'redux-saga/effects';
import zips from '../data/zips.json';
import { getLatLong } from 'src/utils';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import { Point } from 'src/types';
import axios from 'axios';
import moment from 'moment';

// error/success/status messages
export const messages = {
  LOAD_SESSIONS_PENDING: 'Pending',
  LOAD_SESSIONS_FAILED: 'Failing',
  LOAD_SESSIONS_SUCCESS: 'Success',
};

/**
 * TYPES
 */
export const sessionsActionTypes = {
  REQUEST_LOAD_SESSIONS: 'sessions/LOAD_SESSIONS',
  LOAD_SESSIONS_SUCCESS: 'sessions/SESSIONS_LOADED',
  LOAD_SESSIONS_FAILED: 'sessions/LOAD_SESSIONS_FAILED',
  CLEAR_SESSIONS: 'sessions/CLEAR_SESSIONS',
};

/**
 * ACTION CREATORS
 */
const requestLoadSessions = (query) => ({
  type: sessionsActionTypes.REQUEST_LOAD_SESSIONS,
  query,
});
const loadSessionsSuccess = (sessions) => ({
  type: sessionsActionTypes.LOAD_SESSIONS_SUCCESS,
  sessions,
});
const loadSessionsFailure = (error) => ({
  type: sessionsActionTypes.LOAD_SESSIONS_FAILED,
  error,
});
const clearSessions = () => ({ type: sessionsActionTypes.CLEAR_SESSIONS });

export const sessionsActions = {
  requestLoadSessions,
  loadSessionsSuccess,
  loadSessionsFailure,
  clearSessions,
};

/**
 * API UTILITIES
 */

// START: distance computation
/**
 * Hashtable in '../data/zips.json' contains
 * zipcodes as keys and their latitudes and longitudes
 * as values
 */
const isInvalidZipcode = (zipcode) => !zips[zipcode];

// computes radius
const rad = (x) => (x * Math.PI) / 180;

/*
  p1 / p2 are 1d arrays with the shape [lat, long]
  this function returns the distance in miles
  between p1 and p2 using the Haversine formula
 */
const getDistance = (p1: Point, p2: Point) => {
  const R = 3959;

  const dLat = rad(p2[0] - p1[0]);
  const dLong = rad(p2[1] - p1[1]);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[0])) *
      Math.cos(rad(p2[0])) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
};

const getZipFromAddress = (address) => {
  const deconstructedAddress = address.split(' ');

  return deconstructedAddress[deconstructedAddress.length - 1].split('-')[0];
};

const normalizeSession = (session) => {
  const beginsMoment = moment(session.begins);
  const endsMoment = moment(session.ends);

  return {
    ...session,
    sessionName: session.description,
    date: beginsMoment.format('MMM D'),
    abbreviation: session.name,
    location: session.location,
    // de-hardcode
    time: `${beginsMoment.format('h')} - ${endsMoment.format('h a')}`,
    day: beginsMoment.format('e'),
    seatsAvailable: session.maxregistrations,
  };
};

/*
  Inserts the session blocks into the schedule property
  for ease of use by corresponding React components
  */
const loadBlocks = async () => {
  try {
    const reqUrl = `${MOODLE_SERVICE_URL}/blocks/available`;
    const response = (await axios.get(reqUrl)).data;

    return {
      data: response.data.map((r) => r.id),
      current: reqUrl,
      next: null,
    };
  } catch (error) {
    console.log('error: ', error);
    return error;
  }
};

const insertDistance = (sessions, zipcode) => {
  sessions.schedule[0].distanceFromUser = parseFloat(
    getDistance(
      getLatLong(zipcode),
      getLatLong(getZipFromAddress(sessions.schedule[0].address))
    ).toFixed(2)
  );

  return sessions;
};

const closestFirst = (a, b) => {
  /**
   * - -1 means a comes first
   * - 1 means b comes first
   * - 0 means unchanged
   */
  const distFromA = a.schedule[0].distanceFromUser;
  const distFromB = b.schedule[0].distanceFromUser;

  if (distFromA > distFromB) {
    return 1;
  }
  if (distFromA < distFromB) {
    return -1;
  }

  return 0;
};

/* START: soonest date computation (now done by micro-service) */
const soonestFirst = (a, b) => {
  const aDate = moment(a.schedule[0].begins);
  const bDate = moment(b.schedule[0].begins);

  return aDate.diff(bDate);
};
/* END: soonest date computation */

const loadSessionsAndBlocks = async ({ zipcode, sortby }) => {
  try {
    /* STEP 1: For each block load its corresponding sessions,
      then prepare the sessions for sorting (i.e. sort all the
      sub array by sequence number, then insert a distance from
      user for the first session in each subarray) */
    const { data: blockIds, next: newNext, current } = await loadBlocks();

    let sessionsForBlocks = [];

    for (const id of blockIds) {
      let sessionItem = {
        schedule: (
          await axios.get(
            `${MOODLE_SERVICE_URL}blocks/${id}/sessions/available`
          )
        ).data.data
          .sort((a, b) => {
            if (a.sequence < b.sequence) {
              return -1;
            }
            if (b.sequence < a.sequence) {
              return 1;
            }
            return 0;
          })
          .map((s) => normalizeSession(s)),
      };

      sessionItem = {
        ...sessionItem,
        ...sessionItem.schedule[0],
      };

      if (sessionItem.schedule && sessionItem.schedule.length) {
        sessionsForBlocks.push(sessionItem);
      }
    }

    /* STEP 2: Sort loaded blocks */
    switch (sortby) {
      case 'startdate':
        return {
          sessions: sessionsForBlocks.sort(soonestFirst),
          next: newNext,
          current,
        };
      case 'distance':
        /* SIDE EFFECT WARNING: Adding distance from user to data */
        sessionsForBlocks = sessionsForBlocks.map((sessions) =>
          insertDistance(sessions, zipcode)
        );

        return {
          sessions: sessionsForBlocks.sort(closestFirst),
          next: newNext,
          current,
        };
      default:
        return {
          sessions: sessionsForBlocks,
          next: newNext,
          current,
        };
    }

    /*
      STEP 3: Normalize session for consumption by components
     */
  } catch (error) {
    return error;
  }
};

/**
 * SAGAS
 */
function* onSessionLoadRequest({ query: { zipcode, sortby, next } }: any) {
  try {
    /**
     * - process the action.query object to get it into a
     *   shape that the api can use
     * - call the moodle-service to load sessions and session_blocks
     * - dispatch AUTH_SUCCESS
     */

    if (sortby === 'distance' && isInvalidZipcode(zipcode)) {
      throw new Error('Invalid zipcode');
    }

    const { sessions, next: newNext } = yield call(loadSessionsAndBlocks, {
      zipcode,
      sortby,
    });

    yield put({
      type: sessionsActionTypes.LOAD_SESSIONS_SUCCESS,
      status: 'Success',
      sessions: {
        sessions,
        next: newNext,
        current: next,
      },
    });
  } catch (error) {
    /**
     * - extract error message
     * - dispatch appropriate actions
     */
    yield put({
      type: sessionsActionTypes.LOAD_SESSIONS_FAILED,
      error: error.message,
    });
  }
}

export function* sessionsSagas() {
  yield takeEvery(
    sessionsActionTypes.REQUEST_LOAD_SESSIONS,
    onSessionLoadRequest
  );
}

/**
 * REDUCERS
 *
 * @param {Object} state
 * @param {Object} action
 */
const sessionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case sessionsActionTypes.REQUEST_LOAD_SESSIONS:
      return {
        ...state,
        status: 'Pending',
      };
    case sessionsActionTypes.LOAD_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          ...action.sessions,
        },
        status: action.status,
      };
    case sessionsActionTypes.LOAD_SESSIONS_FAILED:
      return {
        status: 'Failed',
        error: action.error,
      };
    case sessionsActionTypes.CLEAR_SESSIONS:
      return defaultState.sessions;
    default:
      return state;
  }
};

export default sessionsReducer;
