import React from 'react';
import { ReactComponent as TPLogo } from 'src/views/styles/assets/icons/training_default.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

function TrainingDefault({ fontSize = 'small', ...props }: IconProps) {
  return <Icon component={TPLogo} fontSize={fontSize} {...props} />;
}

export default TrainingDefault;
