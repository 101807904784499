import React from 'react';
import baseline_not_interested_black_18dp from 'src/views/styles/assets/icons/baseline_not_interested_black_18dp.png';

interface Props {
  style?: React.CSSProperties;
}

function NotFoundBlack({ style }: Props) {
  return (
    <img
      alt="Not Found Symbol"
      src={baseline_not_interested_black_18dp}
      style={{ maxWidth: '100%', ...style }}
    />
  );
}

export default React.memo(NotFoundBlack);
