import React from 'react';
import { CardContent } from '@material-ui/core';

export default function PreviewContent({ children }) {
  return (
    <CardContent style={{ paddingTop: 10, paddingBottom: 10 }}>
      {children}
    </CardContent>
  );
}
