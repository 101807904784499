import { Box, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { useInputStyles } from '.';
import { Descriptor, DescriptorError } from '../typography';

interface PhoneProps {
  label?: string;
  onChange?: (event: any) => void;
  value?: any;
  gutterBottom?: boolean;
  placeholder?: string;
  error?: string;
  errors?: any;
  inputRef?: React.Ref<HTMLInputElement>;
  register?: any;
  name?: string;
  defaultValue?: string;
  [propName: string]: any;
}

export default function InputPhoneNumber({
  name,
  error = '',
  label,
  placeholder = '(123) 456 7890',
  gutterBottom = true,
  defaultValue = '',
  inputRef,
  ...rest
}: PhoneProps) {
  const classes = useInputStyles();
  const getFormattedNumber = (v) => {
    const tmp = v.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !tmp[2]
      ? tmp[1]
      : '(' + tmp[1] + ') ' + tmp[2] + (tmp[3] ? ' ' + tmp[3] : '');
  };
  const handleFormatting = (e: any) => {
    e.target.value = getFormattedNumber(e.target.value);
  };

  return (
    <Box mb={gutterBottom && 1}>
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <OutlinedInput
        onChange={handleFormatting}
        type="tel"
        name={name}
        error={error?.length > 0}
        margin="dense"
        fullWidth
        inputProps={{ maxLength: 14 }}
        defaultValue={getFormattedNumber(defaultValue)}
        classes={classes}
        inputRef={inputRef}
        placeholder={placeholder}
        {...rest}
      />
      {error && <DescriptorError gutterBottom>{error}</DescriptorError>}
    </Box>
  );
}
