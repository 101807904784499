import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    // proprietary
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },

    // conditionally assigned
    textTransform: 'capitalize',

    // defaults
    textDecoration: 'none',
    color: palette.secondary[400],
    fontWeight: 700,
    letterSpacing: 0,
  },
}));
const BodyLink = ({ children, to, ...rest }: LinkProps<any>) => {
  const classes = useStyles();
  return (
    <Link className={classes.root} to={to} {...rest}>
      {children}
    </Link>
  );
};

export default React.memo(BodyLink);
