import React from 'react';
import { Drawer, Grid, Box } from '@material-ui/core';

function ECContent({ children, classes, isMobile }) {
  return isMobile ? (
    <Box>{children}</Box>
  ) : (
    <Drawer
      className={classes.drawerRight}
      variant="permanent"
      classes={{
        paper: classes.rightPaper,
      }}
      anchor="right"
    >
      <Grid container direction="column" className={classes.content}>
        {children}
      </Grid>
    </Drawer>
  );
}

export default ECContent;
