import { Divider, Fab, Grid } from '@material-ui/core';
import React from 'react';
import { isXS } from 'src/utils';
import helpLogo from 'src/views/styles/assets/help@2x.png';
import linkOutBound from 'src/views/styles/assets/links-resources_default@2x.png';
import peerMentorLogo from 'src/views/styles/assets/peer-mentors@2x.png';
import { BodyLink, Descriptor, Heading4 } from './DSL/typography';

export const SupportSect = ({
  header,
  isMobile = isXS(),
  content,
  media,
  children = null,
  titleChild = null,
}) => (
  <Grid item xs={12} container spacing={3}>
    <Grid item xs={3}>
      <img
        style={{ maxWidth: !isMobile ? 50 : 45, marginTop: 5 }}
        src={media}
        alt={`${header} avatar`}
      />
    </Grid>
    <Grid item xs={true}>
      <Grid container alignItems="center" justify="space-between">
        <Heading4>{header}</Heading4>
        {titleChild}
      </Grid>
      <Descriptor>{content}</Descriptor>
      {children}
    </Grid>
  </Grid>
);

const Support = ({ showPM = true, ...props }) => {
  return (
    <Grid container direction="column" spacing={1}>
      {showPM && (
        <>
          <Grid
            onClick={() => {
              window.open(
                'https://www.myseiubenefits.org/peermentors/?utm_source=Help%20Topics&utm_medium=Become%20Certified&utm_campaign=My%20Benefits'
              );
            }}
            style={{ cursor: 'pointer', paddingBottom: '5px' }}
          >
            <SupportSect
              titleChild={
                <Fab
                  size="small"
                  variant="round"
                  style={{ background: 'none' }}
                >
                  <img
                    alt="See peer mentor link"
                    src={linkOutBound}
                    style={{ maxWidth: 20 }}
                  />
                </Fab>
              }
              media={peerMentorLogo}
              header="Peer Mentors"
              content="Get help completing your learning and certification requirements"
            />
          </Grid>
          <Divider />
        </>
      )}
      <BodyLink to="/help" style={{ paddingTop: '5px' }}>
        <SupportSect
          media={helpLogo}
          header="Help"
          content="Find answers to common questions"
        >
          {props?.children}
        </SupportSect>
      </BodyLink>
    </Grid>
  );
};

export default Support;
