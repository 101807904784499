import React from 'react';
import { Grid } from '@material-ui/core';
import { Heading4 } from 'src/views/components/DSL/typography';

interface Props {
  leftControl?: React.ReactNode;
  rightControl?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const CCTitleSection = ({
  leftControl,
  rightControl,
  children,
  style,
}: Props) => (
  <Grid
    container
    alignItems="stretch"
    style={{
      padding: '14px 20px',
      ...style,
    }}
  >
    <Grid item xs={1} container alignItems="center">
      {leftControl}
    </Grid>
    <Grid item xs container alignItems="center" justify="center">
      <Heading4>{children}</Heading4>
    </Grid>
    <Grid item xs={1} container alignItems="center">
      {rightControl}
    </Grid>
  </Grid>
);

export default React.memo(CCTitleSection);
