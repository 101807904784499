import React from 'react';
import { Grid, Dialog } from '@material-ui/core';

function ECWrapper(props) {
  const gridProps = {
    ...props,
    container: true,
    direction: props.isMobile ? 'column' : 'row',
  };

  return props.isMobile ? (
    <Dialog open={props.isMobile} fullScreen>
      <Grid {...gridProps}>{props.children}</Grid>
    </Dialog>
  ) : (
    <Grid {...gridProps}>{props.children}</Grid>
  );
}

export default ECWrapper;
