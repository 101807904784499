import React from 'react';
import inperson_red from 'src/views/styles/assets/icons/in-person_red@2x.png';
import { IconProps } from './types';

const InpersonRed = ({ compact = false }: IconProps) => {
  return (
    <img
      src={inperson_red}
      alt="Inperson Course Symbol"
      style={{ maxWidth: compact ? 13 : '100%' }}
    />
  );
};

export default InpersonRed;
