import { Grid, GridProps } from '@material-ui/core';
import React from 'react';
import config from 'src/store/config';
import {
  CalendarGrey,
  InterpreterGray,
  MapMarkerGrey,
} from 'src/views/components/DSL/Icons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import {
  Descriptor,
  DescriptorError,
  Subheading,
} from 'src/views/components/DSL/typography';
import { findLangFromID } from '../../../Enrollment/components/hooks/helpers';

function OptionDetails({ type, range, address, venue, language }) {
  const isMobile = useBreakpoint('xs');

  console.log(type);

  if (!type) {
    return <DescriptorError>No details to show</DescriptorError>;
  }

  const cell1BaseProps: GridProps = {
    container: true,
    item: true,
    alignItems: 'center',
    style: {
      width: isMobile ? '10%' : '7%',
    },
  };

  const cell2BaseProps: GridProps = {
    container: true,
    alignItems: 'center',
    item: true,
    xs: true,
    zeroMinWidth: true,
  };

  /* Date processing */
  let startmomentized = null;
  let endmomentized = null;
  let yearmomentized = null;
  let rangeLabel = '';
  let rangeDetail = '';

  if (type !== config.MODALITY_TYPES.ONLINE) {
    const begins = range?.begins;
    const ends = range?.ends;

    startmomentized = begins?.format('MMM DD');
    endmomentized = ends?.format('MMM DD');
    yearmomentized = ends?.format('YYYY');

    /*
      - add start date
      - add end date if it isnt one day course
      - add year
     */
    rangeLabel =
      startmomentized &&
      endmomentized &&
      `${startmomentized}${
        endmomentized !== startmomentized ? ' - ' + endmomentized : ''
      }, ${yearmomentized}`;
    rangeDetail = `${begins?.format('dddd')}, ${begins?.format(
      'h'
    )} - ${ends?.format('ha')}`;
  }

  const rootProps: GridProps = {
    style: {
      padding: 10,
    },
    container: true,
  };

  const rowProps: GridProps = {
    item: true,
    xs: 12,
    container: true,
    wrap: 'nowrap',
    alignItems: 'center',
    justify: 'flex-start',
  };

  return (
    <>
      {/* DATE RANGE DETAILED */}
      {
        <Grid {...rootProps}>
          {/* Row 1 */}
          <Grid {...rowProps}>
            <Grid {...cell1BaseProps}>
              <CalendarGrey />
            </Grid>

            <Grid {...cell2BaseProps}>
              <Subheading>
                {type === config.MODALITY_TYPES.ONLINE
                  ? 'Learn Online'
                  : rangeLabel}
              </Subheading>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid {...rowProps}>
            <Grid {...cell1BaseProps}></Grid>

            <Grid {...cell2BaseProps}>
              <Descriptor>
                {type === config.MODALITY_TYPES.ONLINE
                  ? 'Take at your own pace'
                  : rangeDetail}
              </Descriptor>
            </Grid>
          </Grid>
        </Grid>
      }

      {/* DISTANCE DETAILED */}
      {type === config.MODALITY_TYPES.ILT && (
        <Grid {...rootProps}>
          {/* Row 1 */}
          <Grid {...rowProps}>
            <Grid {...cell1BaseProps}>
              <MapMarkerGrey />
            </Grid>

            <Grid {...cell2BaseProps}>
              <Subheading>{venue || 'No venue found'}</Subheading>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid {...rowProps}>
            <Grid {...cell1BaseProps}></Grid>

            <Grid {...cell2BaseProps}>
              <Descriptor>{address}</Descriptor>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* LANGUAGE DETAILED */}
      <Grid {...rootProps}>
        {/* Row 1 */}
        <Grid {...rowProps}>
          <Grid {...cell1BaseProps}>
            <InterpreterGray />
          </Grid>

          <Grid {...cell2BaseProps}>
            <Subheading>{findLangFromID(language)}</Subheading>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid {...rowProps}>
          <Grid {...cell1BaseProps}></Grid>

          <Grid {...cell2BaseProps}></Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(OptionDetails);
