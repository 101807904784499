import { Card, CardContent, Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {
  Descriptor,
  Heading3,
  Heading4,
} from 'src/views/components/DSL/typography';
import { Registration } from '../containers/UnenrollContainer';

import {
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { createStyles } from '@material-ui/core/styles';
import config from 'src/store/config';
import { CourseSchema } from 'src/types';
import { objIsEmpty } from 'src/utils';
import {
  CalendarGrey,
  LanguageGrey,
  MapMarkerGrey,
} from 'src/views/components/DSL/Icons';
import {
  findLangFromID,
  formatStartDatePrimary,
} from '../../Enrollment/components/hooks/helpers';
import AddressMap from 'src/views/components/DSL/AddressMap';
import { COLORS } from 'src/views/components/DSL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export function SessionSchedItem(props: Registration & { key: any }) {
  console.log(props?.begins, props?.ends);
  const startM = props?.begins ? moment.unix(props?.begins) : undefined;
  const endM = props?.ends ? moment.unix(props?.ends) : undefined;
  const dateLabel = startM ? startM.format('MMM \n DD, YYYY') : '';
  let timeLabel = startM ? startM.format('dddd, h:mm a') : '';

  timeLabel += startM && endM ? ' - ' + endM.format('h:mm a') : '';
  const institution = props?.institution.replace(/,/g, ', ');
  const address = props?.address.replace(/,/g, ', ');

  const zoomlink = props?.zoomlink?.includes('http') ? (
    <a
      style={{
        color: COLORS.BLUE[400],
        fontFamily: 'Lato',
        fontWeight: 700,
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
      }}
      href={props?.zoomlink}
    >
      {props?.zoomlink}
    </a>
  ) : (
    ''
  );

  return (
    <Box
      width="100%"
      maxWidth={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {props.description && (
        <Heading3 style={{ padding: '8px' }} gutterBottom>
          {props.description}
        </Heading3>
      )}
      <Paper variant="outlined">
        <ListItem dense>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <CalendarGrey />
            </Avatar>
          </ListItemAvatar>
          {!objIsEmpty(dateLabel) && (
            <ListItemText primary={dateLabel} secondary={timeLabel} />
          )}
        </ListItem>
        <ListItem dense>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <MapMarkerGrey />
            </Avatar>
          </ListItemAvatar>
          {props?.institution !== 'Live Webinar' && (
            <ListItemText
              primary={
                <>
                  {institution}{' '}
                  <AddressMap address={address} institution={institution} />
                </>
              }
              secondary={<>{address}</>}
            />
          )}
          {props?.institution === 'Live Webinar' && (
            <ListItemText primary={'Live video class'} secondary={zoomlink} />
          )}
        </ListItem>
        <ListItem dense>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <LanguageGrey size="small" fontSize="default" compact />
            </Avatar>
          </ListItemAvatar>
          {props.text && <ListItemText primary={findLangFromID(props.text)} />}
        </ListItem>
      </Paper>
    </Box>
  );
}
