import { Card, Typography, CardContent } from '@material-ui/core';
import React from 'react';
import padlock from 'src/views/styles/assets/icons/lock_orange@2x.png';
import { orange } from '@material-ui/core/colors';

function ECLockedMessageBox() {
  return (
    <Card
      variant="outlined"
      style={{
        marginTop: 15,
        marginBottom: 15,
        borderLeftWidth: 6,
        borderLeftColor: orange[300],
      }}
    >
      <CardContent>
        <Typography variant="h6">
          <img
            src={padlock}
            alt="Locked"
            style={{ marginTop: 5, marginRight: 8, width: 23 }}
          ></img>
          This course is locked.
        </Typography>
        <Typography paragraph={true} style={{ marginLeft: 32 }}>
          To enroll in this course, you must complete the prerequisite(s).
        </Typography>
      </CardContent>
    </Card>
  );
}

export default React.memo(ECLockedMessageBox);
