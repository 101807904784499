import { Box, BoxProps, Divider } from '@material-ui/core';
import React from 'react';
import { getUserRole } from 'src/store/authentication/selectors';
import { isMobile, useRouter } from 'src/utils';
import { theme } from 'src/views/components/DSL';
import { ButtonAnchor } from 'src/views/components/DSL/buttons';
import {
  BGLogoWhite,
  FBLogoWhite,
  YouTubeWhite,
} from 'src/views/components/DSL/Icons';
import { Body, BodyAnchor } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';

type MRCFooterProps = BoxProps & { role?: string };

export const MRCFooter = ({ role, ...props }: MRCFooterProps) => {
  return (
    <Box
      bgcolor="#eff1f3"
      p={{ xs: 1, sm: 2, md: 3 }}
      display="flex"
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {role === 'Caregiver' ? (
        <>
          <Body align="center">
            <strong>Need help? </strong>
            Contact the Member Resource Center:
          </Body>
          <Box mx="5px" display="flex" alignItems="center">
            <BodyAnchor href="tel:18663713200" target="_bank">
              1-866-371-3200
            </BodyAnchor>
            <Box color="secondary.dark" mx={1}>
              |
            </Box>
            <BodyAnchor href="mailto:MRC@myseiubenefits.org" target="_bank">
              MRC@myseiubenefits.org
            </BodyAnchor>
          </Box>
          <Body>Monday to Friday, 8 a.m.–6 p.m.</Body>
        </>
      ) : role === 'Facilitator' ? (
        <Body align="center">
          <strong>Need help? </strong>
          Contact the Member Resource Center
        </Body>
      ) : (
        <>
          <Body align="center">
            <strong>Need help? </strong>
            Contact the Member Resource Center:
          </Body>
          <Box mx="5px" display="flex" alignItems="center">
            <BodyAnchor href="tel:18663713200" target="_bank">
              1-866-371-3200
            </BodyAnchor>
            <Box color="secondary.dark" mx={1}>
              |
            </Box>
            <BodyAnchor href="mailto:MRC@myseiubenefits.org" target="_bank">
              MRC@myseiubenefits.org
            </BodyAnchor>
          </Box>
          <Body>Monday to Friday, 8 a.m.–6 p.m.</Body>
        </>
      )}
    </Box>
  );
};

export const BGFooter = ({
  rowProps = {
    display: 'flex',
    alignItems: 'center',
    my: {
      xs: 1.5,
      sm: 2,
    },
  },
}) => {
  return (
    <Box
      bgcolor="primary.main"
      color="primary.contrastText"
      py={{ xs: 3, sm: 4 }}
      px={2}
      display="flex"
      flexDirection="column"
    >
      <Box {...rowProps}>
        <BGLogoWhite style={{ maxHeight: 27 }} />
      </Box>
      <Box {...rowProps}>
        <ButtonAnchor href="/" target="_self" contrast gutterRight>
          My Benefits
        </ButtonAnchor>
        <ButtonAnchor href="/help" target="_self" contrast gutterRight>
          Help
        </ButtonAnchor>
        <ButtonAnchor href="/contact" target="_self" contrast>
          Contact Us
        </ButtonAnchor>
      </Box>
      <Divider style={{ color: '#fff', backgroundColor: '#fff' }} />
      <Box
        {...rowProps}
        justifyContent="space-between"
        flexDirection={isMobile() ? 'column' : 'row'}
      >
        <Box display="flex">
          <ButtonAnchor
            contrast
            target="_blank"
            gutterRight
            href="https://www.facebook.com/SEIU775BenefitsGroup"
          >
            <FBLogoWhite />
          </ButtonAnchor>
          <ButtonAnchor
            contrast
            target="_blank"
            gutterRight
            href="https://www.youtube.com/user/MySEIUBenefits"
          >
            <YouTubeWhite />
          </ButtonAnchor>
        </Box>
        <Box>© 2018 SEIU 775 Benefits Group. All Rights Reserved.</Box>
      </Box>
    </Box>
  );
};

const FooterContainer = () => {
  const { pathname } = useRouter();
  const role = useSelector(getUserRole);

  return !pathname.includes('onboarding') &&
    !pathname.includes('transcripts') &&
    !pathname.includes('certificates') ? (
    <Box
      style={{
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <MRCFooter role={role} />
      <BGFooter />
    </Box>
  ) : null;
};

export default FooterContainer;
