import dialogActionTypes from './types';
import defaultState from '../defaultState';
const { CLOSE_DIALOG, OPEN_DIALOG, SET_CONTENT_KEY } = dialogActionTypes;

export default function dialogReducer(state = defaultState.dialog, action) {
  const mutations = {
    [OPEN_DIALOG]: (state, _action) => ({ ...state, open: true }),
    [CLOSE_DIALOG]: (state, _action) => ({ ...state, open: false }),
    [SET_CONTENT_KEY]: (state, action) => ({
      ...state,
      contentKey: action.contentKey,
    }),
  };

  return mutations[action.type]?.(state, action) || state;
}
