import { makeActionCreator } from '../makeActionCreator';
import enrollmentActionTypes from './types';

const {
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_FILTER,
  APPLY_FILTER,
} = enrollmentActionTypes;

export default {
  onEnrollmentsLoadError: makeActionCreator(LOAD_ERROR, 'error'),
  onEnrollmentsLoadSuccess: makeActionCreator(LOAD_SUCCESS, 'response'),
  setEnrollmentsFilters: makeActionCreator(SET_FILTER, 'filters'),
  applyEnrollmentsFilters: makeActionCreator(APPLY_FILTER, 'response'),
};
