import {
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { createStyles } from '@material-ui/core/styles';
import React from 'react';
import config from 'src/store/config';
import { CourseSchema } from 'src/types';
import { objIsEmpty } from 'src/utils';
import { CalendarGrey, LanguageGrey } from 'src/views/components/DSL/Icons';
import { Heading3, Heading4 } from 'src/views/components/DSL/typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export function CourseSchedItem(props: CourseSchema & { [key: string]: any }) {
  const fullname = props.fullname;
  const mod =
    props.type?.toUpperCase?.() === config.MODALITY_TYPES.ONL ||
    props.type?.toUpperCase?.() === config.MODALITY_TYPES.ONLINE ||
    props.type?.toUpperCase?.() === config.MODALITY_TYPES.online
      ? { primary: 'Online', secondary: 'Take at your own pace' }
      : null;
  const lang = config.LANGS_MAP_ISO_TOSTRING[props?.lang?.toLowerCase?.()];

  const classes = useStyles();

  return (
    <Box
      width="100%"
      maxWidth={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {fullname && (
        <Heading3 style={{ padding: '8px' }} gutterBottom>
          {props.fullname}
        </Heading3>
      )}
      <Paper variant="outlined">
        <ListItem dense>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <CalendarGrey />
            </Avatar>
          </ListItemAvatar>
          {!objIsEmpty(mod) && <ListItemText {...mod} />}
        </ListItem>
        <ListItem dense>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'transparent' }}>
              <LanguageGrey size="small" fontSize="default" compact />
            </Avatar>
          </ListItemAvatar>
          {lang && <ListItemText primary={lang} />}
        </ListItem>
      </Paper>
    </Box>
  );
}
