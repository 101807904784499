import React from 'react';
import { ReactComponent as ValidPassLogo } from 'src/views/styles/assets/icons/valid_pass_mb.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const ValidPass = ({ fontSize = 'small', ...props }: IconProps) => {
  return <Icon component={ValidPassLogo} fontSize={fontSize} {...props} />;
};

export default ValidPass;
