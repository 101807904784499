/**
 * CANNOT REFACTOR THIS COMPONENT UNTIL
 * ALL USE CASES HAVE BEEN FINALIZED
 */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ScheduleItem from './ScheduleItem';
import { Descriptor } from '../typography';
import { VARIANT } from 'src/views/pages/Enrollment/components/hooks/helpers';

interface ScheduleListProps {
  sessions: any;
  variant?: VARIANT;
  openDialogWithDetail?: any;
}

const ScheduleList = ({ sessions, variant }: ScheduleListProps) => {
  // console.log('ScheduleList: ', sessions, sessions.length);
  return (
    <Grid
      container
      direction="column"
      style={{
        borderRadius: 8,
        border:
          variant === VARIANT.dynamic_expandable ? '' : '1px solid #e2e5e1',
      }}
    >
      {sessions.length > 0 ? (
        sessions.map((sess, index) => {
          return (
            <ScheduleItem
              key={index}
              allSessions={sessions}
              session={sess}
              index={index}
              variant={variant}
            />
          );
        })
      ) : (
        <ScheduleItem session={sessions} variant={variant} />
      )}
      {!sessions && <Descriptor>No classes to show</Descriptor>}
    </Grid>
  );
};

export default ScheduleList;
