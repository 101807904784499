/**
 * FIXME: a.h. Enrollment app should not be using this directory
 * for anything
 * TODO: remove from application
 */
import defaultState from '../defaultState';
import { takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { getSessionDetailsForSchedule } from 'src/utils';
import { sessionNames } from '../consts';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import moment from 'moment';

/**
 * TYPES
 */
export const scheduleManagementActionTypes = {
  // request types
  REQUEST_LOAD_SCHEDULE: 'schedulemanagement/REQUEST_LOAD_SCHEDULE',
  REQUEST_UNENROLL: 'schedulemanagement/REQUEST_UNENROLL',

  // request fullfilment statuses
  SCHEDULE_LOAD_FAILURE: 'schedulemanagement/SCHEDULE_LOAD_FAILURE',
  SCHEDULE_LOAD_SUCCESS: 'schedulemanagement/SCHEDULE_LOAD_SUCCESS',

  UNENROLL_SUCCESS: 'schedulemanagement/UNENROLL_SUCCESS',
  UNENROLL_FAILURE: 'schedulemanagement/UNENROLL_FAILURE',

  // Used for sharing with schedule manager and
  QUEUE_CURRENT: 'schedulemanagement/QUEUE_CURRENT',
  CLEAR_CURRENT: 'schedulemanagement/CLEAR_CURRENT',
};

/* ACTION CREATORS */
const requestLoadSchedule = (query, onSuccess, onFailure) => ({
  type: scheduleManagementActionTypes.REQUEST_LOAD_SCHEDULE,
  query,

  // side effects
  onSuccess,
  onFailure,
});
const requestUnEnroll = (query, onSuccess, onFailure) => ({
  type: scheduleManagementActionTypes.REQUEST_UNENROLL,
  query,

  // side effects
  onSuccess,
  onFailure,
});
const queueCurrentCourse = (query, onSuccess, onFailure) => ({
  type: scheduleManagementActionTypes.QUEUE_CURRENT,
  query,

  // side effects
  onSuccess,
  onFailure,
});
const clearCurrentCourse = (query, onSuccess, onFailure) => ({
  type: scheduleManagementActionTypes.CLEAR_CURRENT,
  query,

  // side effects
  onSuccess,
  onFailure,
});

export const scheduleManagementActions = {
  // request actions
  requestLoadSchedule,
  requestUnEnroll,
  queueCurrentCourse,
  clearCurrentCourse,
};

/* SELECTORS */
const getEnrollmentsBySessionNumber = (enrollments) =>
  enrollments.reduce((obj, item) => {
    const propertyName = item['session'].sequence;
    obj[propertyName] = item;
    return obj;
  }, {});
const getAttendanceBySessionNumber = (attendance) =>
  attendance.reduce((obj, item) => {
    const propertyName = item['session'].sequence;
    obj[propertyName] = item;
    return obj;
  }, {});
/*
  add condition for checking if rescheduled item is during
  a later date than the missed course
  original.id !== rescheduled.id &&
  moment(rescheduled.begins).isAfter(original.begins);
*/
const isDifferentSession = (original, rescheduled) =>
  original.id !== rescheduled.id;
const getAttendanceTaken = (att, en) => att.session.id === en.session.id;

const getNormalizedSchedule = (enrollments, attendance, requirements) => {
  const enrollmentsByNumber = getEnrollmentsBySessionNumber(enrollments);
  const attendanceByNumber = getAttendanceBySessionNumber(attendance);

  const { total_sessions, required_sessions } = requirements;
  const sessionNumbers = [...Array(total_sessions).keys()].map((x) => x + 1);

  const schedule = {};

  for (let i = 0; i < sessionNumbers.length; i++) {
    const number = sessionNumbers[i];
    const attendanceRecordForNumber = attendanceByNumber[number];
    const enrollmentRecordForNumber = enrollmentsByNumber[number];

    const withdrawn = !enrollmentRecordForNumber;
    const cancelled = !withdrawn && enrollmentRecordForNumber.session.disabled;

    if (withdrawn) {
      schedule[number] = {
        title: sessionNames[number - 1],
        mode: `WITHDRAWN`,
      };
      continue;
    } else if (cancelled) {
      schedule[number] = {
        ...schedule[number],
        registrationId: enrollmentRecordForNumber
          ? enrollmentRecordForNumber.id
          : null,
        title: sessionNames[number - 1],
        mode: `CANCELLED`,
      };
      continue;
    }

    const required = required_sessions.includes(number);
    const attendanceTaken =
      !!attendanceRecordForNumber &&
      getAttendanceTaken(attendanceRecordForNumber, enrollmentRecordForNumber);
    const present = attendanceTaken && attendanceRecordForNumber.present;
    const absent = attendanceTaken && !attendanceRecordForNumber.present;
    const rescheduled =
      absent &&
      !!enrollmentRecordForNumber &&
      isDifferentSession(
        attendanceRecordForNumber.session,
        enrollmentRecordForNumber.session
      );

    /* Set base properties */
    schedule[number] = {
      title: sessionNames[number - 1],
      registrationId: enrollmentRecordForNumber
        ? enrollmentRecordForNumber.id
        : null,
      session: enrollmentRecordForNumber
        ? getSessionDetailsForSchedule(enrollmentRecordForNumber.session)
        : null,
      mode: `INCOMPLETE${required ? '_REQUIRED' : ''}`,
    };

    if (present) {
      schedule[number] = {
        ...schedule[number],
        mode: `COMPLETED`,
      };
    } else if (absent) {
      schedule[number] = {
        ...schedule[number],
        mode: `${rescheduled ? 'UPCOMING' : 'MISSED'}${
          required ? '_REQUIRED' : ''
        }`,
      };
    }
  }

  return schedule;
};

/* START: Schedule loading/management via api */
const loadAttendanceByCourse = async ({ studentid, coursecode }) => {
  try {
    const response = await axios.get(
      `${MOODLE_SERVICE_URL}students/${studentid}/course/${coursecode}/attendance`
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
const loadEnrollmentsByCourse = async ({ studentid, coursecode }) => {
  try {
    const response = await axios.get(
      `${MOODLE_SERVICE_URL}students/${studentid}/course/${coursecode}/registrations`
    );

    return response.data.data;
  } catch (error) {
    return error;
  }
};
const loadRequirementsByCourse = async ({ coursecode }) => {
  try {
    const response = await axios.get(
      `${MOODLE_SERVICE_URL}courses/${coursecode}/requirements`
    );

    return response.data.data;
  } catch (error) {
    return error;
  }
};
const processUnEnrollment = async ({ registrationIds }) => {
  try {
    const response = await axios.patch(`${MOODLE_SERVICE_URL}registrations`, {
      method: 'delete',
      ids: registrationIds,
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
/* END: Schedule loading/management via api */

/* Refactor */
const soonestFirst = (a, b) => {
  const aDate = moment(a.session.begins);
  const bDate = moment(b.session.begins);

  return aDate.diff(bDate);
};

const getSessionNumbersByDate = (schedule) => {
  return Object.values(schedule)
    .sort(soonestFirst)
    .map((s: any) => {
      return s?.session?.sequence;
    });
};

const getPastFutureAndCanceled = (scheduleBySessionNumber) => {
  const past = {};
  const upcoming = {};
  const canceled = {};
  const withdrawn = {};

  const today = moment();

  for (const n in scheduleBySessionNumber) {
    if (scheduleBySessionNumber[n]) {
      const session = scheduleBySessionNumber[n];
      if (!session) {
        throw new Error(`Invalid session: ${n}`);
      }

      if (session.mode === 'WITHDRAWN') {
        withdrawn[n] = session;
      } else if (session.mode === 'CANCELLED') {
        canceled[n] = session;
      } else {
        const sessionBeginningMoment = moment(session.session.begins);
        const diffFromToday = sessionBeginningMoment.diff(today);

        if (diffFromToday >= 0) {
          upcoming[n] = session;
        } else {
          past[n] = session;
        }
      }
    }
  }

  return { past, upcoming, canceled, withdrawn };
};

/* SAGAS */
function* onScheduleLoadRequest(action) {
  const { query, onSuccess, onError } = action;

  try {
    const enrollments = yield call(loadEnrollmentsByCourse, query);

    if (!enrollments.length) {
      yield put({
        type: scheduleManagementActionTypes.SCHEDULE_LOAD_SUCCESS,
        schedule: {
          past: { bySessionNumber: {}, allSessionNumbers: [] },
          upcoming: { bySessionNumber: {}, allSessionNumbers: [] },
          canceled: { bySessionNumber: {}, allSessionNumbers: [] },
        },
      });
    } else {
      const attendance = yield call(loadAttendanceByCourse, query);
      const requirements = yield call(loadRequirementsByCourse, query);

      const scheduleBySessionNumber = getNormalizedSchedule(
        enrollments,
        attendance,
        requirements
      );

      const pastFutureAndCanceled = getPastFutureAndCanceled(
        scheduleBySessionNumber
      );

      yield put({
        type: scheduleManagementActionTypes.SCHEDULE_LOAD_SUCCESS,
        schedule: {
          past: {
            bySessionNumber: pastFutureAndCanceled.past,
            allSessionNumbers: getSessionNumbersByDate(
              pastFutureAndCanceled.past
            ),
          },
          upcoming: {
            bySessionNumber: pastFutureAndCanceled.upcoming,
            allSessionNumbers: getSessionNumbersByDate(
              pastFutureAndCanceled.upcoming
            ),
          },
          canceled: {
            bySessionNumber: pastFutureAndCanceled.canceled,
            allSessionNumbers: Object.keys(pastFutureAndCanceled.canceled),
          },
          withdrawn: {
            bySessionNumber: pastFutureAndCanceled.withdrawn,
            allSessionNumbers: Object.keys(pastFutureAndCanceled.withdrawn),
          },
        },
      });

      if (onSuccess) {
        onSuccess();
      }
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

function* onRequestUnenroll(action) {
  const { query, onSuccess, onError } = action;

  try {
    yield call(processUnEnrollment, query);

    yield put({
      type: scheduleManagementActionTypes.UNENROLL_SUCCESS,
      query,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

export function* scheduleManagementSagas() {
  yield takeEvery(
    scheduleManagementActionTypes.REQUEST_LOAD_SCHEDULE,
    onScheduleLoadRequest
  );
  yield takeEvery(
    scheduleManagementActionTypes.REQUEST_UNENROLL,
    onRequestUnenroll
  );
}

export const scheduleManagementReducer = (
  state: any = defaultState.scheduleManagement,
  action
) => {
  switch (action.type) {
    // pending states
    case scheduleManagementActionTypes.REQUEST_LOAD_SCHEDULE:
      return {
        ...state,
        status: 'Pending...',
      };
    // failed states
    case scheduleManagementActionTypes.SCHEDULE_LOAD_FAILURE:
      return {
        ...state,
        status: 'Failure',
        error: action.error,
      };
    case scheduleManagementActionTypes.SCHEDULE_LOAD_SUCCESS:
      return {
        ...state,
        status: 'Success',
        schedule: {
          past: {
            ...state.schedule.past,
            ...action.schedule.past,
          },
          upcoming: {
            ...state.schedule.upcoming,
            ...action.schedule.upcoming,
          },
          canceled: {
            ...state.schedule.canceled,
            ...action.schedule.canceled,
          },
          withdrawn: {
            ...state.schedule.withdrawn,
            ...action.schedule.withdrawn,
          },
        },
      };

    case scheduleManagementActionTypes.QUEUE_CURRENT:
      return {
        ...state,
        current: action.query,
      };
    case scheduleManagementActionTypes.CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export default scheduleManagementReducer;
