/* eslint-disable react-hooks/exhaustive-deps */
/**
 * App component uses authSuccess directly and
 * skips requestAuth step - this needs to be
 * modified once authentication has been built
 */
import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { loadDashboard, loadTrainings } from 'src/store/authentication';
import {
  getAuthentication,
  getDashboardLoaded,
  getInprogressCodesFromDashboard,
  getTrainingsLoaded,
} from 'src/store/authentication/selectors';
import config from 'src/store/config';
import { LangToISOcode } from 'src/store/consts';
import { courseCatalogActionCreators } from 'src/store/coursecatalog';
import { enrolledCoursesActionCreators } from 'src/store/enrolledCourses';
import {
  getEnrollmentsLoaded,
  getForbiddenCoursePrimaryKeys,
} from 'src/store/enrolledCourses/selectors';
import { transcriptActionCreators } from 'src/store/transcripts';
import { getTranscriptsLoaded } from 'src/store/transcripts/selectors';
import { useRouter } from 'src/utils';
import { useDispatch, useSelector } from 'src/views/providers';
import './App.css';
import { theme } from './components/DSL';
import Unauthorized from './components/DSL/info/Unauthorized';
import Activating from './pages/Activating';
import CertificatesContainer from './pages/Certificates/CertificatesContainer';
import CLCContainer from './pages/CLC/containers/CLCContainer';
import ContactContainer from './pages/ContactUs/ContactContainer';
import CourseCatalogContainer from './pages/CourseCatalog/containers/CourseCatalogContainer';
import EnrollmentContainer from './pages/Enrollment/containers/EnrollmentContainer';
import HBTContainer from './pages/HBT/HBTContainer';
import HelpContainer from './pages/Help/HelpContainer';
import HomeContainer from './pages/Home/containers/HomeContainer';
import loadEnrolledCourses from './pages/Home/components/hooks/loadEnrolledCourses';
import loadTranscripts from './pages/Home/components/hooks/loadTranscripts';
import MyCoursesContainer from './pages/MyCourses/containers/MyCoursesContainer';
import OnboardingContainer from './pages/Onboarding/containers/OnboardingContainer';
import ProfileContainer from './pages/Profile/containers/ProfileContainer';
import ProtectedRoute from './pages/ProtectedRoute';
import ScheduleContainer from './pages/Schedule/containers/ScheduleContainer';
import { FooterContainer, NavbarContainer } from './pages/shared';
import SecureRetirement from './pages/SRT/SecureRetirement';
import TargetEnrollmentContainer from './pages/TargetEnrollment/containers/TargetEnrollmentContainer';
import TranscriptContainer from './pages/Transcript/TranscriptContainer';
import UnenrollContainer from './pages/Unenroll/containers/UnenrollContainer';

function App() {
  const { location } = useRouter();
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    learnerId,
    dashboard,
    zipCode,
    languagesFluentIn,
    preferredLanguage,
  } = useSelector(getAuthentication);
  const dashready = useSelector(getDashboardLoaded);
  const trainingsready = useSelector(getTrainingsLoaded);
  const transcriptsready = useSelector(getTranscriptsLoaded);
  const enrollmentsready = useSelector(getEnrollmentsLoaded);
  const forbiddenCourses = useSelector(getForbiddenCoursePrimaryKeys);

  /* STATE SYNC */
  useEffect(() => {
    let unmounted = false;
    if (unmounted || !isLoggedIn || !learnerId) {
      return;
    }

    const refreshRequired =
      window.location.href.includes('refresh=true') ||
      (new URLSearchParams(location.search).get('refresh') &&
        location.pathname.includes('mycourses')) ||
      location.pathname === '/';

    if (refreshRequired || !trainingsready) {
      loadTrainings(dispatch)(learnerId);
    }
    if (refreshRequired || !dashready) {
      loadDashboard(dispatch)(learnerId);
    }
    if (refreshRequired || !transcriptsready) {
      loadTranscripts({
        learnerId,
        onSuccess: (response) => {
          dispatch(transcriptActionCreators.onTSLoadSuccess(response));
        },
        onError: (error) =>
          dispatch(transcriptActionCreators.onTSLoadError(error)),
      });
    }
    // NOTE: the following two functions are used in My Courses to load both ONL, ILT, and WEB
    if (refreshRequired || !enrollmentsready) {
      loadEnrolledCourses({
        learnerId,
        onSuccess: (response: any) =>
          dispatch(
            enrolledCoursesActionCreators.onEnrollmentsLoadSuccess(response)
          ),
        onError: (error: any) =>
          dispatch(enrolledCoursesActionCreators.onEnrollmentsLoadError(error)),
      });
    }

    return () => {
      unmounted = true;
    };
  }, [isLoggedIn, location.search]);

  useEffect(() => {
    let unmounted = false;
    if (unmounted || !dashready) {
      return;
    }

    const refreshRequired =
      window.location.href.includes('refresh=true') ||
      (new URLSearchParams(location.search).get('refresh') &&
        location.pathname.includes('coursecatalog'));

    let codes = getInprogressCodesFromDashboard(dashboard);

    codes = codes.filter(
      (code: string | number) => config.NONCOVID[code] !== undefined
    );

    const currentReq = dashboard?.inProgress?.[0];
    const hasRoom =
      currentReq?.requiredHours -
      (currentReq?.earnedHours + currentReq?.enrolledHours);

    if (codes.length > 0 && hasRoom > 0) {
      const initialFilters = {
        code: codes,
        forbiddenCourses: forbiddenCourses,
        online: true,
        inperson: false,
        webinar: false,
        maxenddate: currentReq?.dueDate,
        zipcode: zipCode,
        language: languagesFluentIn
          ?.split(';')
          ?.map((lang) => LangToISOcode[lang]) || [
            LangToISOcode[preferredLanguage],
          ] || ['EN'],
      };

      dispatch(courseCatalogActionCreators.setFiltersForCourse(initialFilters));
      dispatch(courseCatalogActionCreators.requestLoadCourses(initialFilters));
    }

    return () => {
      unmounted = true;
    };
  }, [dashready, enrollmentsready, location.search]);

  /* PROGRESS CALCULATION */
  useEffect(() => {
    const unmounted = false;

    if (unmounted || !isLoggedIn) {
      return;
    }
  }, [isLoggedIn, dashready, enrollmentsready, transcriptsready]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      overflow="hidden"
      maxWidth="100vw"
    >
      {/* Appbar */}
      <Box height="auto" id="#root">
        <NavbarContainer />
      </Box>

      {/* Content */}
      <Box flexGrow={1} width="100vw" bgcolor={theme.palette.grey[100]} pb={2}>
        <Route
          strict
          path="/onboarding/:step"
          component={OnboardingContainer}
        />
        <Route path="/activated" component={Activating} />
        <Route path="/logout" component={Unauthorized} />

        <Switch>
          <ProtectedRoute exact path="/" component={HomeContainer} />
          <ProtectedRoute strict path="/mycourses/:subpath">
            <MyCoursesContainer />
          </ProtectedRoute>
          <ProtectedRoute path="/transcripts">
            <TranscriptContainer />
          </ProtectedRoute>
          <ProtectedRoute path="/schedule">
            <ScheduleContainer />
          </ProtectedRoute>
          <ProtectedRoute path="/certificates">
            <CertificatesContainer />
          </ProtectedRoute>
          <ProtectedRoute strict path="/profile">
            <ProfileContainer />
          </ProtectedRoute>
          <ProtectedRoute strict path="/coursecatalog">
            <CourseCatalogContainer />
          </ProtectedRoute>
          <ProtectedRoute strict path="/caregiverlearningcenter">
            <CLCContainer />
          </ProtectedRoute>
          <ProtectedRoute path="/enrollment/" component={EnrollmentContainer} />
          <ProtectedRoute path="/targetenroll">
            <TargetEnrollmentContainer />
          </ProtectedRoute>

          <ProtectedRoute path="/unenroll">
            <UnenrollContainer />
          </ProtectedRoute>

          {/* Static routes */}
          <ProtectedRoute exact path="/help">
            <HelpContainer />
          </ProtectedRoute>
          <ProtectedRoute exact path="/contact">
            <ContactContainer />
          </ProtectedRoute>
          <ProtectedRoute exact path="/health">
            <HBTContainer />
          </ProtectedRoute>
          <ProtectedRoute exact path="/secureretirement">
            <SecureRetirement />
          </ProtectedRoute>
        </Switch>
      </Box>

      <Box height="auto">
        <FooterContainer />
      </Box>
    </Box>
  );
}

export default App;
