import authReducer from './authentication';
import combineReducers from './combineReducers';
import { courseCatalogReducer } from './coursecatalog';
import { dialogReducer } from './dialog';
import { enrolledCoursesReducer } from './enrolledCourses';
import enrollmentReducer from './enrollment';
import { errorReducer } from './errors';
import { popoverReducer } from './popover';
import { transcriptReducer } from './transcripts';

export default combineReducers({
  dialog: dialogReducer,
  popover: popoverReducer,
  authentication: authReducer,
  enrollment: enrollmentReducer,
  coursecatalog: courseCatalogReducer,
  transcripts: transcriptReducer,
  error: errorReducer,
  enrolledCourses: enrolledCoursesReducer,
});
