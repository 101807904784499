import React from 'react';
import { Typography } from '@material-ui/core';

const Body2 = ({
  children,
  gutterBottom,
  noWrap,
  display,
  align,
  style,
  ...rest
}: any) => {
  return (
    <Typography
      variant="body2"
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      display={display}
      style={style}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default Body2;
