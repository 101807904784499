import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  Descriptor,
  Display,
  Subheading,
} from 'src/views/components/DSL/typography';
import seiu775bglogo from 'src/views/styles/assets/seiu-775-bg-logo_color@2x.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 1, 2),
  },
  bgLogo: {
    maxHeight: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  displayCompact: { fontSize: theme.spacing(2) },
}));

export interface LearnerInfoProps {
  learnerId: number | string;
  learnerName: string;
  learnerDOB: string;
}

export default function TCPdfHeader({
  learnerId,
  learnerName,
  learnerDOB,
}: LearnerInfoProps) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {/* LEFT */}
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <img
          src={seiu775bglogo}
          className={classes.bgLogo}
          alt="BG Logo Colored"
        />
        <Subheading
          style={{
            fontSize: '0.65em',
          }}
        >
          SEIU 775 Benefits Group
          <br />
          215 Columbia St #300, Seattle, WA 98104
        </Subheading>
        <br />
        <Display gutterBottom className={classes.displayCompact}>
          Official Training Transcript
        </Display>
      </Box>

      {/* LEFT */}
      <Box
        display="flex"
        flexDirection="column"
        width={4 / 12}
        alignItems="space-between"
        flexWrap="nowrap"
        textOverflow="ellipsis"
      >
        {learnerName && (
          <Box display="flex" justifyContent="space-between">
            <Descriptor gutterBottom>Learner Name:</Descriptor>
            <Descriptor gutterBottom>
              <strong>{learnerName}</strong>
            </Descriptor>
          </Box>
        )}
        {learnerId && (
          <Box display="flex" justifyContent="space-between">
            <Descriptor gutterBottom>Learner ID:</Descriptor>
            <Descriptor gutterBottom>
              <strong>{learnerId}</strong>
            </Descriptor>
          </Box>
        )}
        {learnerDOB && (
          <Box display="flex" justifyContent="space-between">
            <Descriptor gutterBottom>Learner DOB:</Descriptor>
            <Descriptor gutterBottom>
              <strong>{learnerDOB}</strong>
            </Descriptor>
          </Box>
        )}
      </Box>
    </Box>
  );
}
