import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from 'src/consts';
import config from 'src/store/config';
import { languageMap } from 'src/store/consts';
import { CourseSchema } from 'src/types';
import { objIsEmpty, stringifyError } from 'src/utils';
import {
  InpersonRed,
  OnlineGreen,
  WebinarBlue,
  MultiModalityFilled,
  BT30Topics,
} from 'src/views/components/DSL/Icons';

export const handleIcon = (courseType) => {
  // console.log('handleIcon: ', courseType);
  switch (courseType) {
    case 'IN-PERSON AND WEBINAR':
      return <MultiModalityFilled />;
    case 'ILT':
    case 'IN-PERSON':
      return <InpersonRed />;
    case 'WEBINAR':
      return <WebinarBlue />;
    case 'ONLINE':
      return <OnlineGreen />;
    case 'TOPIC':
      return <BT30Topics />;
    default:
      return;
  }
};
export const handleTypeText = (courseType) => {
  // console.log('handleTypeText: ', courseType);
  switch (courseType) {
    case 'IN-PERSON AND WEBINAR':
      return 'In-person and webinar';
    case 'ILT':
    case 'IN-PERSON':
      return 'In-Person';
    case 'WEBINAR':
      return 'Webinar';
    case 'ONLINE':
      return 'Online';
    default:
      return;
  }
};

/**
 *  Input: selectedOption.range ; string
 *  e.g. "1594213200 - 1594299600"
 * */
export const convertUnixToDate = (unixTimestamp) => {
  let res = 'Date not available';
  if (unixTimestamp) {
    const arr = unixTimestamp.split(' - '); //returns string value
    // use Number() to convert to numbers
    const startDate = moment.unix(Number(arr[0])).format('MMM DD');
    const endDate = moment.unix(Number(arr[1])).format(DATE_FORMAT);
    res = startDate + ' - ' + endDate;
  }
  return res;
};

export const formatStartDatePrimary = (str, end?, sessionCount?) => {
  // console.log('formatStartDatePrimary: ', str, end, sessionCount);
  if (sessionCount > 1) {
    return `${moment(str).format('MMM DD, YYYY')} - ${moment(end).format(
      'MMM DD, YYYY'
    )}`;
  }
  return moment(str).format('MMM DD, YYYY');
};

export const formatStartDateSecondary = (start, end, sessionCount?) => {
  // console.log('formatStartDateSecondary: ', start, end, sessionCount);
  if (sessionCount > 1) {
    return `${moment(start).format('dddd, h:mm a -')} ${moment(end).format(
      'h:mm a'
    )}`;
  }
  return `${moment(start).format('dddd, h:mm a -')} ${moment(end).format(
    'h:mm a'
  )}`;
};

export const findLangFromID = (selectedOptionIdNumber) => {
  // console.log(selectedOptionIdNumber);
  if (!selectedOptionIdNumber) {
    return 'Language not found';
  }

  // if two letter lang code, than find the lang
  if (selectedOptionIdNumber?.length < 3) {
    return languageMap[selectedOptionIdNumber];
  }

  // TODO: session id format may change in the future
  // curr format: 204-V008-R008-20201116-0800-CCS-I5737-LEN-1
  if (selectedOptionIdNumber?.includes?.('-')) {
    const lang = selectedOptionIdNumber.split('-')?.[7].substring(1, 3);
    return config.ISO_TO_LANG[lang];
  }

  const langCode = selectedOptionIdNumber?.slice(-4, -2);
  return config.ISO_TO_LANG[langCode];
};

export enum SCHED_MODES {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}

export enum VARIANT {
  non_dynamic_detailed = 'non_dynamic_detailed',
  dynamic_expandable = 'dynamic_expandable',
}

export const getCourseToSessoinBlock = (selectedCourse: CourseSchema) => {
  if (objIsEmpty(selectedCourse)) {
    return [];
  }

  try {
    const courseToConvert = selectedCourse as CourseSchema;

    return [
      {
        id: courseToConvert.id,
        idnumber: courseToConvert.idnumber,
        course: courseToConvert.id,
        name: courseToConvert.fullname,
        bookingmanager: null,
        duration: null,
        credits: courseToConvert.credit_hours,
        type: courseToConvert.type?.toUpperCase?.(),
        modified: null,
        sessionblockscount: 0,
        sessionscount: 0,
        range: null,
        firstsession: {
          address: null,
          institution: null,
          beings: null,
          ends: null,
        },
        lastsession: {
          address: null,
          institution: null,
          begins: null,
          ends: null,
        },
        lang: selectedCourse.lang,
        language: selectedCourse.lang,
      },
    ];
  } catch (error) {
    console.error(error);
    console.log(stringifyError(error));
    return [];
  }
};
