import React from 'react';
import { Grid } from '@material-ui/core';
import { isXS } from 'src/utils';

function TEFailedMessage() {
  /* LOCAL STATE */
  const isMobile = isXS();
  if (localStorage.getItem('state')) {
    localStorage.removeItem('state');
  }
  return (
    <Grid
      style={{
        padding: isMobile ? 0 : '48px 120px',
        backgroundColor: '#ffff',
      }}
    >
      <h1>
        {' '}
        Something went wrong while processing your enrollment
        <br />
        <br /> Please contact the Member Resource Center:
        <br /> 1-866-371-3200 | MRC@Myseiubenefits.Org
        <br /> Monday to Friday, 8 a.m.–6 p.m.
      </h1>
    </Grid>
  );
}

export default TEFailedMessage;
