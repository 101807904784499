/**
 * Purpose: https://redux.js.org/recipes/reducing-boilerplate
 *
 * Usage:
 * const addTodoAction = makeActionCreator(ADD_TODO, 'payload');
 * dispatch(addTodoAction({ payload: ... }))
 *
 * type is a redux action type and argNames is a comma separated list of
 * strings.
 *
 * @param {String} type
 * @param  {...any} argNames
 */
export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};
