import { Grid } from '@material-ui/core';
import React from 'react';
import { DownloadBlue } from 'src/views/components/DSL/Icons';
import { useRouter } from 'src/utils';
import { Back, ButtonSecondary } from 'src/views/components/DSL/buttons';
import { Heading1 } from 'src/views/components/DSL/typography';
import PageHeader from 'src/views/components/Layouts/PageHeader';
import MCTopNav from './MCTopNav';

const getButtonProps = () => ({
  startIcon: <DownloadBlue />,
  fullWidth: true,
  capitalize: true,
});

export default function MCHeader() {
  const router = useRouter();

  const toTranscripts = () => router.push(`/transcripts`);
  const toSchedule = () => router.push(`/schedule`);

  return (
    <PageHeader style={{ paddingBottom: 0 }}>
      <Back to={'/caregiverlearningcenter'}>Learning Overview</Back>
      <Grid container alignItems="center" justify="space-between">
        <Grid item zeroMinWidth>
          <Heading1 noWrap>My Courses</Heading1>
        </Grid>
        <Grid item alignItems="center">
          {router?.pathname === '/mycourses/completed' ? (
            <ButtonSecondary
              //disabled
              {...getButtonProps()}
              onClick={toTranscripts}
            >
              Transcript
            </ButtonSecondary>
          ) : (
            <ButtonSecondary
              // disabled
              {...getButtonProps()}
              onClick={toSchedule}
            >
              Schedule
            </ButtonSecondary>
          )}
        </Grid>
      </Grid>
      <MCTopNav />
    </PageHeader>
  );
}
