import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import { getRegistrationIdsFromSchedule, stringifyError } from 'src/utils';
import lmsService from '../api/LMSService';
import config from '../config';
import defaultState from '../defaultState';
import { makeActionCreator } from '../makeActionCreator';
import { scheduleManagementActionTypes } from '../schedulemanagement';

/**
 * TODO:
 * 1. onEnrollmentRequest(), improve this validation and parameterize for ce (a.h.)
    // const currentEnrollments = yield call(loadEnrollments, query);
    // console.log('currentEnrollments: ', currentEnrollments);

    // if (currentEnrollments.data && currentEnrollments.data.length > 0) {
    //   console.log('currentEnrollments: ', currentEnrollments);
    //   throw new Error('Already enrolled in AHCAS');
    // } 
    2. onSelectOptionForEnrollment(), add more strict api validation (a.h.)
 */

/**
 * TYPES
 */
export const enrollmentActionTypes = {
  // request types
  REQUEST_ENROLLMENT: 'enrollment/REQUEST_ENROLLMENT',
  REQUEST_MAKEUP: 'enrollment/REQUEST_MAKEUP',
  REQUEST_UN_ENROLLMENT: 'enrollment/REQUEST_UN_ENROLLMENT',
  REQUEST_LOAD_ENROLLMENTS: 'enrollment/REQUEST_LOAD_ENROLLMENTS',

  // request fullfilment statuses
  ENROLLMENT_SUCCESS: 'enrollment/ENROLLMENT_SUCCESS',
  ENROLLMENT_FAILURE: 'enrollment/ENROLLMENT_FAILURE',
  MAKEUP_SUCCESS: 'enrollment/MAKEUP_SUCCESS',
  MAKEUP_FAILURE: 'enrollment/MAKEUP_FAILURE',
  ENROLLMENT_LOAD_SUCCESS: 'enrollment/ENROLLMENT_LOAD_SUCCESS',
  ENROLLMENT_LOAD_FAILURE: 'enrollment/ENROLLMENT_LOAD_FAILURE',
  UN_ENROLLMENT_SUCCESS: 'enrollment/UN_ENROLLMENT_SUCCESS',
  UN_ENROLLMENT_FAILURE: 'enrollment/UN_ENROLLMENT_FAILURE',

  // new types
  SET_SELECTED_OPTION_FOR_ENROLLMENT:
    'enrollment/SET_SELECTED_OPTION_FOR_ENROLLMENT',
  SET_SESSIONS_FOR_SELECTED_OPTION:
    'enrollment/SET_SESSIONS_FOR_SELECTED_OPTION',
};

/**
 * ACTION CREATORS
 */
const requestEnrollment = (query, onSuccess, onError) => ({
  type: enrollmentActionTypes.REQUEST_ENROLLMENT,
  query,

  // side effects
  onSuccess,
  onError,
});

const enrollmentSuccess = (success) => ({
  type: enrollmentActionTypes.ENROLLMENT_SUCCESS,
  success,
});

const enrollmentFailure = (failure) => ({
  type: enrollmentActionTypes.ENROLLMENT_FAILURE,
  failure,
});

const requestMakeup = (query, onSuccess, onError) => ({
  type: enrollmentActionTypes.REQUEST_MAKEUP,
  query,

  // side effects
  onSuccess,
  onError,
});

const makeupSuccess = (success) => ({
  type: enrollmentActionTypes.MAKEUP_SUCCESS,
  success,
});

const makeupFailure = (failure) => ({
  type: enrollmentActionTypes.MAKEUP_FAILURE,
  failure,
});

const requestLoadEnrollments = (query, onSuccess, onError) => ({
  type: enrollmentActionTypes.REQUEST_LOAD_ENROLLMENTS,
  /**
   * Contains sid and enrollmenttype
   */
  query,

  // side effects
  onSuccess,
  onError,
});

const enrollmentLoadSuccess = (success) => ({
  type: enrollmentActionTypes.ENROLLMENT_SUCCESS,
  success,
});

const enrollmentLoadFailure = (failure) => ({
  type: enrollmentActionTypes.ENROLLMENT_FAILURE,
  failure,
});

const requestUnEnrollment = (query, onSuccess, onError) => ({
  type: enrollmentActionTypes.REQUEST_UN_ENROLLMENT,
  query,

  // side effects
  onSuccess,
  onError,
});

const unEnrollmentSuccess = (success) => ({
  type: enrollmentActionTypes.ENROLLMENT_SUCCESS,
  success,
});

const unEnrollmentFailure = (failure) => ({
  type: enrollmentActionTypes.ENROLLMENT_FAILURE,
  failure,
});

const setSelectedOptionForEnrollment = makeActionCreator(
  enrollmentActionTypes.SET_SELECTED_OPTION_FOR_ENROLLMENT,
  'option',
  'onSuccess',
  'onError'
);

const setSessionsForSelectedOption = makeActionCreator(
  enrollmentActionTypes.SET_SESSIONS_FOR_SELECTED_OPTION,
  'sessions',
  'onSuccess',
  'onError'
);

export const enrollmentActions = {
  // enrollment create actions
  requestEnrollment,
  enrollmentSuccess,
  enrollmentFailure,

  requestMakeup,
  makeupSuccess,
  makeupFailure,

  // enrollment delete actions
  requestUnEnrollment,
  unEnrollmentSuccess,
  unEnrollmentFailure,

  // enrollment read actions
  requestLoadEnrollments,
  enrollmentLoadFailure,
  enrollmentLoadSuccess,

  // new action creators
  setSelectedOptionForEnrollment,
};

/**
 * SAGAS
 */

interface BaseEffect {
  type: string;
  onSuccess?: () => void;
  onError?: (string) => void;
}

interface QueryEffect extends BaseEffect {
  query: any;
}

const loadEnrollments = async (query) => {
  if (!query.learner.id) {
    throw new Error('No learner id present');
  }

  console.log('loadEnrollments, query: ', query, query.learner.id);
  const reqUrl = `${MOODLE_SERVICE_URL}/learners/${query.learner.id}/registrations`;

  try {
    const response = (await axios.get(reqUrl)).data;

    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Refactor to string processing libraries
 */
const normalizeEnrollments = (enrollments) => {
  const processedSessions = [];

  for (const blockid in enrollments) {
    const firstEnrollment = enrollments[blockid];
    const firstSessionInSchedule = firstEnrollment[0].session;

    processedSessions.push({
      blockid,
      schedule: firstEnrollment,
      title: firstSessionInSchedule.course.fullname,
      unenrollmentIds: getRegistrationIdsFromSchedule(firstEnrollment).join(
        '|'
      ),
    });
  }

  return processedSessions;
};

/*
  What the function does:

  Takes an array of registrations with block ids nested in them
  (e.g [{session: {block: 1}}, {session: { block: 2}}, {session: {block: 3}}, ...])
  and transforms the array to a lookup table. The keys of the lookup table are
  blockids (e.g. {1: {}, 2: {}, 3: {}}).

  Why it was created:

  The /enrollments route renders courses that a student is enrolled in by block
  (also referred to as cohorts). Every block/cohort has n number of sessions which
  correspond to it. At least for ahcas.

  The components that are rendered upon hitting the /enrollments tab need the ability
  to iterate over every block/cohort and render a corresponding enrollment card for it.
  (e.g. [1, 2, 3, 4].map(blockid => ui for this cohort))
 */
const getGroupedEnrollmentsByBlock = (enrollments) => {
  const groupedEnrollments = {};

  enrollments.forEach((en) => {
    // check if an enrollment for this block has already been stored
    // if not then store it there
    if (!groupedEnrollments[en.sessionblock.id]) {
      groupedEnrollments[en.sessionblock.id] = [en];
    } else {
      groupedEnrollments[en.sessionblock.id].push(en);
    }
  });

  return groupedEnrollments;
};

/**
 * What this function does:
 * - takes the query containing student id
 * - loads all enrollments/registrations for that student
 * - normalizes the object shape for easier consumption
 *   by components
 * - stores normalized data in enrollments property in
 *   redux stores
 *
 * @param {Object} query
 * @param {Function} onSuccess
 * @param {Function} onError
 */
function* onEnrollmentLoadRequest({ query, onSuccess, onError }: QueryEffect) {
  console.log('onEnrollmentLoadRequest, query: ', query);
  try {
    const enrollments = yield call(loadEnrollments, query);
    const sortedEnrollments = getGroupedEnrollmentsByBlock(enrollments.data);
    const normalizedEnrollments = normalizeEnrollments(sortedEnrollments);

    yield put({
      type: enrollmentActionTypes.ENROLLMENT_LOAD_SUCCESS,
      enrollments: normalizedEnrollments,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }

    yield put({
      type: enrollmentActionTypes.ENROLLMENT_LOAD_FAILURE,
      error,
    });
  }
}

const processUnEnrollment = async ({ registrationidblock, registrationid }) => {
  try {
    const response = await axios.patch(`${MOODLE_SERVICE_URL}registrations`, {
      method: 'delete',
      ids: registrationidblock
        ? registrationidblock.split('|').map((id) => parseInt(id))
        : [registrationid],
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

function* onUnEnrollmentRequest({ query, onSuccess, onError }: QueryEffect) {
  try {
    yield call(processUnEnrollment, query);

    yield put({
      type: enrollmentActionTypes.UN_ENROLLMENT_SUCCESS,
      query,
    });

    /**
     * Ideal state: replace this with pub-sub and pub-sub would tell
     * the enrollment app when to refresh
     */
    yield put({
      type: enrollmentActionTypes.REQUEST_LOAD_ENROLLMENTS,
      query,
    });

    yield put({
      type: scheduleManagementActionTypes.REQUEST_LOAD_SCHEDULE,
      query: {
        ...query,
        registrationIds: query.projectedRegistrationIds,
        blockid: query.projectedBlockId,
      },
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: enrollmentActionTypes.UN_ENROLLMENT_FAILURE,
      error,
    });

    if (onError) {
      onError(error.message);
    }
  }
}

const processEnrollment = async (query) => {
  try {
    const reqUrl = `${MOODLE_SERVICE_URL}/registrations/target`;
    const response = await axios.post(reqUrl, query);
    console.log('processEnrollment, enrollment in index.ts');
    return response.data;
  } catch (error) {
    return error.response;
  }
};

/**
 * Makes call to /registrations and /sessions route
 * validating that the enrollmemts can be facilitated
 */
function* onMakeupRequest({ query, onSuccess, onError }: QueryEffect) {
  try {
    /**
     * Introduce condition here which prevents them from double enrolling
     */
    yield call(processEnrollment, query);

    // will contain an enrollment id provided by the backend
    const newCourse = { ...query };

    yield put({
      type: enrollmentActionTypes.ENROLLMENT_SUCCESS,
      newCourse,
    });

    yield put({
      type: enrollmentActionTypes.REQUEST_LOAD_ENROLLMENTS,
      query,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: enrollmentActionTypes.ENROLLMENT_FAILURE,
      error,
    });

    if (onError) {
      onError(error);
    }
  }
}

/**
 * The generator that requires an additional check
 * to see if student is already enrolled in an AHCAS
 * course.
 */
function* onEnrollmentRequest({ query, onSuccess, onError }: QueryEffect) {
  try {
    const enrollmentResponse = yield call(processEnrollment, {
      learner: {
        id: query.learner.id,
        email: query.learner.email,
        firstname: query.learner.firstname,
        lastname: query.learner.lastname,
      },
      schedule: query.schedule,
    });

    if (enrollmentResponse.status === 500) {
      return onError({ serverError: 'Internal server error' });
    }

    yield put({
      type: enrollmentActionTypes.ENROLLMENT_SUCCESS,
      newCourse: query,
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put({
      type: enrollmentActionTypes.ENROLLMENT_FAILURE,
      error,
    });

    if (onError) {
      onError(error);
    }
  }
}

function* onSelectOptionForEnrollment(action) {
  if (!action.option) {
    throw new Error('No option passed to onSelectOption');
  }
  try {
    let sessionsForBlock = yield call(lmsService.loadSessionsInBlock, {
      blockid: action.option?.id,
    });
    if (!sessionsForBlock) {
      throw new Error(
        'Unable to load sessions for block check loadSessionsInBlock'
      );
    }

    if (action.option?.type?.toUpperCase?.() === config.MODALITY_TYPES.ONLINE) {
      sessionsForBlock = action.option;
    }
    yield put(setSessionsForSelectedOption(sessionsForBlock));

    if (typeof action?.onSuccess === 'function') {
      action.onSuccess();
    }
  } catch (error) {
    if (typeof action?.onError === 'function') {
      action.onError(stringifyError(error));
    }

    console.error('ERROR IN SAGA CATCH BLOCK: ', stringifyError(error));
  }
}

export function* enrollmentSagas() {
  yield takeEvery(enrollmentActionTypes.REQUEST_MAKEUP, onMakeupRequest);
  yield takeEvery(
    enrollmentActionTypes.REQUEST_ENROLLMENT,
    onEnrollmentRequest
  );
  yield takeEvery(
    enrollmentActionTypes.REQUEST_UN_ENROLLMENT,
    onUnEnrollmentRequest
  );
  yield takeEvery(
    enrollmentActionTypes.REQUEST_LOAD_ENROLLMENTS,
    onEnrollmentLoadRequest
  );

  yield takeEvery(
    enrollmentActionTypes.SET_SELECTED_OPTION_FOR_ENROLLMENT,
    onSelectOptionForEnrollment
  );
}

/* REDUCERS */
const enrollmentReducer = (state = defaultState.enrollment, action) => {
  // switch (action.type) {
  //   case enrollmentActionTypes.REQUEST_ENROLLMENT:
  //     return {
  //       ...state,
  //       status: 'Pending...',
  //     };
  //   case enrollmentActionTypes.ENROLLMENT_SUCCESS:
  //     return {
  //       ...state,
  //       status: 'Success',
  //       courses: [...state.courses, action.newCourse],
  //     };
  //   case enrollmentActionTypes.ENROLLMENT_FAILURE:
  //     return {
  //       ...state,
  //       status: 'Failure',
  //       error: action.error,
  //     };
  //   case enrollmentActionTypes.REQUEST_LOAD_ENROLLMENTS:
  //     return {
  //       ...state,
  //       status: 'Pending',
  //     };
  //   case enrollmentActionTypes.ENROLLMENT_LOAD_SUCCESS:
  //     return {
  //       ...state,
  //       status: 'Success',
  //       courses: action.enrollments,
  //     };
  //   case enrollmentActionTypes.ENROLLMENT_LOAD_FAILURE:
  //     return {
  //       ...state,
  //       status: 'Failed',
  //     };
  //   case enrollmentActionTypes.REQUEST_UN_ENROLLMENT:
  //     return {
  //       ...state,
  //     };
  //   case enrollmentActionTypes.UN_ENROLLMENT_SUCCESS:
  //     return {
  //       ...state,
  //       status: 'Success',
  //     };
  //   case enrollmentActionTypes.UN_ENROLLMENT_FAILURE:
  //     return {
  //       ...state,
  //       status: 'Failed',
  //     };
  //   default:
  //     return state;

  const {
    SET_SELECTED_OPTION_FOR_ENROLLMENT,
    SET_SESSIONS_FOR_SELECTED_OPTION,
  } = enrollmentActionTypes;

  const mutations = {
    [SET_SELECTED_OPTION_FOR_ENROLLMENT]: (state, action) => {
      return {
        ...state,
        selectedOption: action.option,
      };
    },
    [SET_SESSIONS_FOR_SELECTED_OPTION]: (state, action) => {
      return {
        ...state,
        sessionsForOption: action.sessions,
      };
    },
  };

  return mutations[action.type]?.(state, action) || state;
};

export default enrollmentReducer;

/* SELECTORS */
const getSelectedOption = (state) => state.enrollment?.selectedOption;
const getSessionsForOption = (state) => state.enrollment?.sessionsForOption;

export const enrollmentSelectors = {
  getSelectedOption,
  getSessionsForOption,
};
