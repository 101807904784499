import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import defaultState from 'src/store/defaultState';
import rootReducer from 'src/store/rootReducer';
import rootSaga from 'src/store/rootSaga';
import useSagaReducer from 'use-saga-reducer';

interface Context {
  store: any;
  dispatch: Dispatch<any>;
}

const applyMiddleWare = (reducer) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reducerWithLogger = useCallback(
    (state, action) => {
      const next = reducer(state, action);
      console.log('ACTION:', action.type);
      console.log('NEXT:', next);
      return next;
    },
    [reducer]
  );

  return reducerWithLogger;
};

const useSelector = (fn = (state) => state) => {
  const { store } = useContext(context);
  return useMemo(() => fn(store), [fn, store]);
};

const useDispatch = () => {
  const { dispatch } = useContext(context);
  return useCallback((action) => dispatch(action), [dispatch]);
};

const useThunk = () => {
  const { dispatch } = useContext(context);
  return useCallback((action) => dispatch(action()), [dispatch]);
};

export { useSelector, useDispatch, useThunk };

export const context = createContext<Context>({
  store: {},
  dispatch: () => ({}),
});

context.displayName = 'GLOBAL_STORE';

export default function StoreProvider({ children }) {
  const initialState = defaultState;
  const reducer = applyMiddleWare(rootReducer);
  const [store, dispatch] = useSagaReducer(rootSaga, reducer, initialState);

  return (
    <context.Provider value={{ store, dispatch }}>{children}</context.Provider>
  );
}
