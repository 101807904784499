import { Box } from '@material-ui/core';
import React, { ReactNode, useEffect } from 'react';
import { redirectToAzure } from 'src/utils';
import { ButtonPrimary } from 'src/views/components/DSL/buttons';
import {
  Descriptor,
  DescriptorError,
  Heading1,
  Subheading,
} from 'src/views/components/DSL/typography';

interface UnauthorizedProps {
  code?: number | string;
  message?: string;
  children?: ReactNode;
}

export default function Unauthorized({
  message = 'Unable to authenticate',
  ...props
}: UnauthorizedProps) {
  if (process.env.NODE_ENV !== 'production') {
    console.info('Unauthorized: ', { message, ...props });
  }

  useEffect(() => {
    let unmounted = false;

    if (unmounted) {
      return;
    }

    window.localStorage.removeItem('id_token');
    window.sessionStorage.clear();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Box padding={2.8}>
      <Heading1 gutterBottom>Logged Out</Heading1>
      <Subheading gutterBottom>Redirecting to sign in page...</Subheading>
      {message && (
        <DescriptorError display="block" gutterBottom>
          {message}
        </DescriptorError>
      )}

      <Descriptor display="block" gutterBottom>
        If you arent redirected to the sign in page in 10 seconds, click the
        link below
      </Descriptor>
      <ButtonPrimary onClick={redirectToAzure}>Sign In</ButtonPrimary>
    </Box>
  );
}
