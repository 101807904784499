import React from 'react';
import { IconProps } from './types';
import webinar_navy from 'src/views/styles/assets/icons/webinar_navy@2x.png';

const WebinarNavy = ({ compact = false }: IconProps) => {
  return (
    <img
      src={webinar_navy}
      alt="Webinar Course Symbol"
      style={{ maxWidth: compact ? 13 : '100%' }}
    />
  );
};

export default WebinarNavy;
