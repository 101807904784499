import {
  Box,
  Card,
  CardHeader,
  Container,
  Link,
  Button,
} from '@material-ui/core';
import React from 'react';
import {
  ButtonPrimary,
  ButtonLink,
  ButtonText,
} from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import {
  Heading3,
  Heading4,
  Subheading,
  BodyAnchor,
} from 'src/views/components/DSL/typography';
import { PageContent, PageHeader } from 'src/views/components/Layouts';

export default function SecureRetirement() {
  const isMobile = useBreakpoint('xs');

  return (
    <>
      <PageHeader>
        <Heading3 gutterBottom>Secure Retirement</Heading3>
      </PageHeader>

      <PageContent spacing={3}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            disableTypography
            title={
              <Heading4 gutterBottom>About the Secure Retirement Plan</Heading4>
            }
            subheader={
              <>
                <Subheading gutterBottom>
                  The goal is to help provide additional financial security when
                  you retire. The Secure Retirement Plan is the first of its
                  kind in the nation for home care workers.
                </Subheading>
                <Subheading gutterBottom>
                  The plan is available to caregivers whose employer contributes
                  to the retirement fund. Eligible caregivers will automatically
                  be enrolled in the plan. Upon retirement, participants in the
                  plan can begin receiving a retirement benefit.{' '}
                  <BodyAnchor
                    href="http://www.seiu775.org/findyourcontract"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    See if your employer participates.
                  </BodyAnchor>{' '}
                </Subheading>

                <Subheading>
                  If you have any questions, please contact the plan
                  administrator at{' '}
                  <BodyAnchor
                    href="tel:18007268303"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    1-866-770-1917
                  </BodyAnchor>{' '}
                  and select option 3 {`(`}8 a.m. to 5 p.m. Pacific time,
                  Monday-Friday{`)`}.
                </Subheading>
              </>
            }
          />
        </Card>
        <Card variant="outlined" elevation={0}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', sm: 'row' }}
            p={1.5}
          >
            <Box width={0.8} mb={1}>
              <Heading4 gutterBottom>My Plan</Heading4>
              <Subheading>
                Update your address or beneficiaries and see other details about
                your retirement plan by logging in to your account on My Plan.
              </Subheading>
            </Box>
            <ButtonPrimary
              size="large"
              fullWidth={isMobile}
              href="https://www.millimanbenefits.com/LogOn.aspx"
            >
              Go to My Plan
            </ButtonPrimary>
          </Box>
        </Card>
      </PageContent>
    </>
  );
}
