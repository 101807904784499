import { makeActionCreator } from '../makeActionCreator';
import transcriptActionTypes from './types';

const {
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_FILTER,
  APPLY_FILTER,
} = transcriptActionTypes;

export default {
  onTSLoadError: makeActionCreator(LOAD_ERROR, 'error'),
  onTSLoadSuccess: makeActionCreator(LOAD_SUCCESS, 'response'),
  setTSFilters: makeActionCreator(SET_FILTER, 'filters'),
  applyTSFilters: makeActionCreator(APPLY_FILTER, 'response'),
};
