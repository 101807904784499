import Axios from 'axios';
import { MOODLE_SERVICE_URL } from 'src/envvars';

export async function handleUnenroll({
  learnerId = '',
  courseCode = '',
  onSuccess = undefined,
  onError = undefined,
}) {
  const dataToUnEnroll = '';
  try {
    const response = await Axios.delete(
      `${MOODLE_SERVICE_URL}/learners/${learnerId}/course/${courseCode}/unenroll`
    );
    console.log('response: ', response);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}
