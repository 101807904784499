/* TODO: opt for InputLangSearchMulti */
import { Box, Chip, MenuItem, MenuProps } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import config from 'src/store/config';
import { Descriptor, DescriptorError } from '../typography';

type InputLangSelectProps = {
  label?: string;
  onChange?: (event: any) => void;
  value?: any;
  gutterBottom?: boolean;
  name?: string;
  control: Control<Record<string, any>>;
  fullWidth?: boolean;
  defaultValue?: string[];
  [x: string]: any;
};

const languages = config.LANGS_STRING;
const LangMenuProps: Partial<MenuProps> = {
  variant: 'menu',
  closeAfterTransition: true,
  PaperProps: {
    elevation: 0,
    variant: 'outlined',
    style: {
      marginTop: 56,
      maxHeight: 500,
      width: 500,
    },
  },
};
const chipWrapperStyles: Partial<CSSProperties> = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  padding: 0,
  alignItems: 'center',
  justifyContent: 'flex-start',
};
const chipStyles: Partial<CSSProperties> = {
  fontSize: 11,
  margin: 0,
  marginRight: 3,
  padding: 0,
};

function InputLangSelectMulti({
  name,
  label,
  control,
  defaultValue,
  fullWidth = true,
  ...props
}: InputLangSelectProps) {
  const labelId = `${name}-label`;
  const [open, setopen] = useState(false);
  return (
    <Box
      display="flex"
      flexGrow={fullWidth ? 1 : 'auto'}
      flexDirection="column"
      mb={2}
    >
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <Controller
        as={
          <Select
            style={{ backgroundColor: '#fff' }}
            open={open}
            onClick={(e) => {
              console.log(e);
              setopen(!open);
            }}
            variant="outlined"
            multiple
            color="secondary"
            fullWidth
            inputProps={{ color: 'secondary' }}
            margin="dense"
            MenuProps={LangMenuProps}
            renderValue={(selected) => (
              <div style={chipWrapperStyles}>
                {(selected as string[]).map((value) => (
                  <Chip
                    size="small"
                    key={value}
                    label={value}
                    style={chipStyles}
                  />
                ))}
              </div>
            )}
            name={name}
            labelId={labelId}
            disableUnderline
          >
            {languages.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {props?.error && (
        <DescriptorError gutterBottom>{props?.error}</DescriptorError>
      )}
    </Box>
  );
}
export default React.memo(InputLangSelectMulti);
