import React from 'react';
import { useSelector } from 'src/views/providers';
import { getAuthentication } from 'src/store/authentication/selectors';
import { InputPhoneNumber } from 'src/views/components/DSL/form';
import { useForm } from 'react-hook-form';

export default function EditCell() {
  const { cellPhone } = useSelector(getAuthentication);
  const { register, errors } = useForm({
    mode: 'onChange',
  });

  const ref = register({
    validate: (v) => {
      return (
        /^\(([0-9]{3})\)[ ]([0-9]{3})[ ]([0-9]{4})$/.test(v) ||
        'Invalid phone number'
      );
    },
  });

  return (
    <>
      <InputPhoneNumber
        label="ENTER PHONE NUMBER"
        name="cellPhone"
        placeholder="(123) 456 7890"
        defaultValue={cellPhone}
        error={errors?.cellPhone?.message || ''}
        inputRef={ref}
      />
    </>
  );
}
