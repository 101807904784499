import { Grid } from '@material-ui/core';
import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Descriptor, Heading3 } from 'src/views/components/DSL/typography';

interface SeparatorProps {
  turns: number;
  progress: number;
  trajectory: number;
  count: number;
}

function Separator({ turns, progress, trajectory, count }: SeparatorProps) {
  const color =
    turns <= progress / count
      ? '#016620'
      : turns <= trajectory / count
      ? '#9bd364'
      : '#eff1f3';

  const notchHeight = 4;

  return (
    <div
      key={turns}
      style={{
        position: 'absolute',
        height: '95%',
        transform: `rotate(${turns}turn)`,
      }}
    >
      <div
        style={{
          background: color,
          width: notchHeight,
          borderRadius: 50,

          // This needs to be equal to props.strokeWidth
          height: notchHeight,
        }}
      />
    </div>
  );
}

function ProgressLabel({ requiredHours, units }) {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {requiredHours && units ? (
        <>
          <Descriptor>Total</Descriptor>
          <Grid item container alignItems="baseline" justify="center">
            <Heading3 style={{ marginRight: 5 }}>{requiredHours}</Heading3>
            <Descriptor>{units}</Descriptor>
          </Grid>
        </>
      ) : (
        <Descriptor align="center">
          No requirements
          <br /> found
        </Descriptor>
      )}
    </Grid>
  );
}

function ProgressWheel({
  requiredHours = 0,
  enrolledHours = 0,
  earnedHours = 0,
  units = 'credits',
}) {
  const trajectory = enrolledHours + earnedHours;

  return (
    <CircularProgressbarWithChildren
      value={trajectory}
      maxValue={requiredHours}
      styles={buildStyles({
        pathColor: '#c4e6a3',
        trailColor: '#e2e5e9',
        strokeLinecap: 'round',
      })}
    >
      {/* Completed progress */}
      <CircularProgressbarWithChildren
        value={earnedHours}
        maxValue={requiredHours}
        styles={buildStyles({
          pathColor: '#2a8639',
          trailColor: 'transparent',
          strokeLinecap: 'round',
        })}
      >
        <ProgressLabel
          requiredHours={requiredHours}
          units={requiredHours === 12 ? 'credits' : units}
        />
      </CircularProgressbarWithChildren>
      {requiredHours < 20 &&
        trajectory > 0 &&
        Array(requiredHours).map((_, index) => (
          <Separator
            turns={index / requiredHours}
            key={index / requiredHours}
            count={requiredHours}
            trajectory={trajectory}
            progress={earnedHours}
          />
        ))}
    </CircularProgressbarWithChildren>
  );
}

export default React.memo(ProgressWheel);
