import React from 'react';
import { Body, Descriptor } from 'src/views/components/DSL/typography';
import theme from 'src/views/styles/theme';

const CONSENTFORLANG = {
  English: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        By entering the SEIU NW Training Portal, I consent to the release of
        “educational records” as defined by Family Educational Rights and
        Privacy Act and all personally identifying information maintained by the
        SEIU Healthcare NW Training Partnership related to training I have taken
        with the Training Partnership to DSHS, Department of Health, and any
        participating agency employer.
      </Body>
      <Body gutterBottom>
        I understand that this consent can be revoked at any time by sending a
        written letter to the address below.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Korean: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        {`나는 29 U.S.C. § 1232g(a)(4)에 따라 DSHS, 보건부 및 기타 참여 기관
        고용주가 실시한 교육 파트너십(Training Partnership) 교육에 참여함으로써
        SHIU 보건 NW 교육 파트너십에서 보유하는
        “교육기록”및모든개인신원정보공개에동의합니다.
        나는본동의가아래의주소로서면통지에따라폐지될수있음을이해합니다.`}
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Lao: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        {`ຂູ້ າພະເຈາເຫັ ນີດິຍນຍອມຕໍ ການເຜີ ຍແຜ່ “ບັ ນທຶ ກທາງການຶສກສາ” ຕາມີທໄດູ້ ກໍ
        ານດໄວູ້ ໃນ 29 U.S.C. § ູ້່່ 1232g(a)(4) ແລະ ໍຂມນີທກໍ ານດໄວູ້ ວ່ າເປັ
        ນການສ່ ວນຕວີທຖື ກເກັ ບຮັ ກສາໄວູ້ ໂດຍ SEIU Healthcare NW Training
        Partnership ່ີທກ່ ຽວຂູ້ ອງກັ ບການເຝິ ກອບຮມ່ີທຂູ້ າພະເຈູ້ າໄດູ້ ເອາມາຈາກ
        Training Partnership ູ່່້່ ຕໍ D S H S , ກ ມ ສຸ ຂ ະ ພ າ ບ ແ ລ ະ ນ າ ຍ ຈູ້
        າ ງ ຂ ອ ງ ອ ງ ກ າ ນ ີ ທ ເ ຂ າ ຮ່ ວ ມ ໃ ດ ຫນຶ ງ .`}
      </Body>
      <Body gutterBottom>
        {`ຂູ້ າພະເຈາເຂາໃຈວ່ າຄວາມິຍນຍອມີນສາມາດຖື ກລບລູ້ າງໄດູ້ ທຸ ກເວລາໂດຍການສງຫນັ
        ງືສເປັ ນລາຍລັ ກອັ ກສອນຫາີທຢ່ ຂູ້ ູ້ າງລຸ່ ມີນ.`}
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Chinese: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        {`我同意就 29 U.S.C. § 1232g (a) (4) 中所定义的“教育记录(educational
          records)”和由 SEIU 医疗保 健联盟西北地区培训协作机构(SEIU Healthcare NW
          Training Partnership)所保存的、且跟我参与的
          培训协作机构的训练有关的个人身份信息对 DSHS、卫生部(Department of
          Health)和任何参与机 构公开。`}
      </Body>
      <Body gutterBottom>
        我知道在任何时候可以通过向以下地址发出书面通知去而撤销此同意书。
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Somali: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Waxaan oggolaanayaa in loo siidaayo “diiwaanada waxbarasho” sida loogu
        qeexay xeerka 29 U.S.C. § 1232g(a)(4) iyo dhammaan macluumaadka shaqsiga
        lagu aqoonsanayo ee ay hayso iskaashatada Tababarka SEIU Healthcare NW
        ee la xiriira tababarka aan la qaatay Iskaashatada Tababarka DSHS,
        Waaxda Caafimaadka, iyo hay’ad kasta oo loo shaqeeyo oo ka qayb galeysa.
      </Body>
      <Body gutterBottom>
        Waxaan fahansanahay in oggolaanshahan laga noqon karo waqti kasta iyada
        oo loo dirayo waraaq qoran cinwaanka hoos ku xusan.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Ukranian: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        {`Я даю свою згоду на видачу «навчальної особової справи», як визначено у
        29 Зводі законів США § 1232g(а)(4), а також усієї інформації, яка
        зберігається у Навчально-медичному центрі «SEIU Healthcare NW Training
        Partnership», ідентифікує мою особу і стосується мого навчання у
        Навчально-медичному центрі при Департаменті охорони здоров'я та
        соціальних служб, а також Департаменті охорони здоров'я і будь-якого
        відомчого роботодавця-учасника.`}
      </Body>
      <Body gutterBottom>
        {`Я усвідомлюю, що ця згода може бути відкликана у будь-який час шляхом
          направлення письмового листа за вказаною нижче адресою.`}
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Arabic: (
    <>
      {' '}
      <Body align="right" gutterBottom style={{ marginBottom: 10 }}>
        {`أوافق على السماح بإطلاق "السجلات التعليمية" المحددة في )4()U.S.C. §
        1232g(a 29 وجميع المعلومات الشخصية المحددة للهوية المحفوظة بواسطة شراكة
        التدريب SEIU Healthcare NW Training Partnership والمتعلقة بالتدريب الذي
        تلقيته في شراكة التدريب المقدم إلى دائرة الخدمات الاجتماعية والصحية،
        وإدارة الصحة، وأي صاحب عمل/وكالة مشاركة.`}
      </Body>
      <Body align="right" gutterBottom>
        {`أدرك أن هذه الموافقة يمكن إلغاؤها في أي وقت من خلال إرسال خطاب مكتوب إلى العنوان أدناه.`}
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Khmer: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        ខ្ញុំអនុញ្ញាតឲ្យបញ្ចេញព័តា៌ននន“ទិននន័យអបរំ”ដូចបានកុំណត់ញ្ៅកនញង29U.S.C.§1232g(a)(4)
        និងព័ត៌ានអតតសញ្ញា ណផ្ទា ល់ខល នួ រកាទុកញ្ោយ SEIU Healthcare NW Training
        Partnership ទាក់ទងនឹងការបណ្ ញ ុះបណ្តត លដដលខ្ ញបាុំ នចូ លរមួ ជាមួ យ
        Training Partnership ចុំញ្ DSHS, Department of Health ញ្ ើយនិង
        និញ្ោជកទីភ្នន កងា់ រដដលបានចូលរមួ ។ ខ្ញុំយល់ថាការអនុញ្ញា តញ្នុះអាចដកវញិ
        បានញ្ៅញ្ពលណ្តមួយក៍បាន
      </Body>
      <Body gutterBottom>
        តាមរយៈការញ្ផ្ើលញិខិតជាលាយល័កខអកសរញ្ៅកាន់អាសយោានខាងញ្ម្រកាម។
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Spanish: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Entrego mi consentimiento para la divulgación de los “registros
        educativos” como se define en 29 U.S.C. § 1232g(a)(4) y toda la
        información personal identificadora mantenida por el SEIU Healthcare NW
        Training Partnership relacionada con la capacitación que he tomado con
        Training Partnership al Departamento de Servicios Sociales y de Salud,
        al Departamento de Salud, y a cualquier agencia empleadora participante.
      </Body>
      <Body gutterBottom>
        Comprendo que este consentimiento puede ser revocado en cualquier
        momento por medio de una carta enviada a la siguiente dirección.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Tagalog: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Sumasang-ayon ako na isiwalat ang “mga rekord sa edukasyon” tulad ng
        inilarawan sa 29 U.S.C. § 1232g(a)(4) at lahat ng personal na
        makapagpapakilalang impormasyon na pinanatili ng SEIU Healthcare NW
        Training Partnership kaugnay sa pagsasanay na ginawa ko sa Training
        Partnership sa DSHS, Department of Health, at alinmang kalahok na
        employer na ahensiya.
      </Body>
      <Body gutterBottom>
        Nauunawaan ko na ang pagsang-ayon na ito ay maaaring pawalan ng bisa
        anumang oras sa pamamagitan ng pagpapadala ng nakasulat na liham sa
        address sa ibaba.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Vietnamese: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Tôi đồng ý cho phép phát hành “hồ sơ giáo dục” như đã nêu trong 29
        U.S.C. § 1232g(a)(4) và tất cả những thông tin nhận diện cá nhân do SEIU
        Healthcare NW Training Partnership nắm giữ liên quan đến lớp đào tạo tôi
        đã tham gia tại Training Partnership to DSHS, Department of Health, và
        bất cứ nhân viên cơ quan nào có tham gia.
      </Body>
      <Body gutterBottom>
        Tôi hiểu rằng giấy xác nhận cho phép này có thể bị thu hồi bất cứ lúc
        nào trong quá trình gửi thư viết tay tới địa chỉ dưới đây.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Russian: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Я согласен/согласна на раскрытие образовательной информации из личного
        дела, согласно определению таковой, данному в положениях нормативного
        акта 29 U.S.C. § 1232g(a)(4), а также всех идентифицирующих личность
        сведений, находящихся в распоряжении SEIU Healthcare NW Training
        Partnership и относящихся к обучению, пройденному мной в данной
        организации, Департаменту социальных и медицинских услуг, Департаменту
        здравоохранения и любым участвующим в программе
        организациям-работодателям.
      </Body>
      <Body gutterBottom>
        Я понимаю, что данное согласие может быть отозвано в любой момент на
        основании письменного заявления, отправленного по следующему адресу.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
  Samoan: (
    <>
      {' '}
      <Body gutterBottom style={{ marginBottom: 10 }}>
        Ua ou malie e fa’amatu’u atu “fa’amāumāuga fa’ale-a’oga” e pei ona
        fa’amatalaina i le 29 U.S.C. §1232g(a)(4) ma fa’amāumāuga patino uma ia
        te a’u o lo’o taofia e le SEIU Healthcare NW Training Partnership
        fa’atatau i a’oa’oga na ou faia i le Training Partnership i le DSHS,
        Matāgaluega o le Soifua Malōlōina, po’o nisi foi matāgaluega na auai.
      </Body>
      <Body gutterBottom>
        Ua ou mālamalama o lenei pepa fa’ataga e mafai ona toe ave’eseina i so’o
        se taimi lava i le lafoina mai lea o se tusi talosaga i le tuātusi o loo
        tūsia i lalo.
      </Body>
      <Body gutterBottom>
        SEIU Healthcare NW Training Partnership <br />
        215 Columbia St. #300 <br />
        Seattle, WA 98104 <br />
        Att. Privacy Officer
      </Body>
    </>
  ),
};

interface ConsentProps {
  lang: string;
}

export default function ConsentRelease({ lang = 'English' }: ConsentProps) {
  const content = CONSENTFORLANG[lang] || undefined;

  return (
    <>
      {!content && (
        <Descriptor gutterBottom style={{ color: theme.palette.warning.main }}>
          This document is not available in your preferred language.
        </Descriptor>
      )}
      {content || CONSENTFORLANG['English']}
    </>
  );
}
