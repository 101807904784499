import React from 'react';
import { IconProps } from './types';
import tpwhite from 'src/views/styles/assets/icons/training_white@2x.png';

const TPLogoWhite = ({ compact = false, size }: IconProps) => {
  return (
    <img
      src={tpwhite}
      alt="Training Partnerships Logo"
      style={{
        maxWidth: compact
          ? 12
          : size === 'small'
          ? 22
          : size === 'medium'
          ? 26
          : '100%',
      }}
    />
  );
};

export default TPLogoWhite;
