/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { loadDashboard, loadTrainings } from 'src/store/authentication';
import {
  getDashboardLoaded,
  getLearnerId,
  getTrainingsLoaded,
} from 'src/store/authentication/selectors';
import { enrolledCoursesActionCreators } from 'src/store/enrolledCourses';
import {
  getEnrolledCourses,
  getEnrollmentsLoaded,
} from 'src/store/enrolledCourses/selectors';
import { transcriptActionCreators } from 'src/store/transcripts';
import { getTranscriptsLoaded } from 'src/store/transcripts/selectors';
import { useRouter } from 'src/utils';
import { ButtonText } from 'src/views/components/DSL/buttons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { BodyLink } from 'src/views/components/DSL/typography';
import { PageContent } from 'src/views/components/Layouts';
import Support from 'src/views/components/Support';
import { useDispatch, useSelector } from 'src/views/providers';
import loadEnrolledCourses from '../../Home/components/hooks/loadEnrolledCourses';
import loadTranscripts from '../../Home/components/hooks/loadTranscripts';
import EmptyResults from '../../shared/Filters/EmptyResults';
import CatalogPreview from '../components/CatalogPreview';
import CLCHeader from '../components/CLCHeader';
import EnrollmentPreview from '../components/EnrollmentPreview';
import PreviewSection from '../components/PreviewSection';
import ProgressPreview from '../components/ProgressPreview';

function CLCContainer() {
  const isMobile = useBreakpoint('xs');
  const router = useRouter();
  const courses = useSelector((state) => state.coursecatalog?.courses);
  const learnerId = useSelector(getLearnerId);
  const enrolledCourses = useSelector(getEnrolledCourses);
  const dispatch = useDispatch();
  const enrollmentsready = useSelector(getEnrollmentsLoaded);
  const dashready = useSelector(getDashboardLoaded);
  const trainingsready = useSelector(getTrainingsLoaded);
  const transLoaded = useSelector(getTranscriptsLoaded);
  const hasCourses = courses?.codes?.length;
  const hasEnrollments = enrolledCourses?.idNumbers?.length;

  useEffect(() => {
    let unmounted = false;
    if (unmounted || !learnerId) {
      return;
    }

    if (!trainingsready) {
      loadTrainings(dispatch)(learnerId);
    }

    if (!dashready) {
      loadDashboard(dispatch)(learnerId);
    }

    if (!transLoaded) {
      loadTranscripts({
        learnerId,
        onSuccess: (response) =>
          dispatch(transcriptActionCreators.onTSLoadSuccess(response)),
        onError: (error) =>
          dispatch(transcriptActionCreators.onTSLoadError(error)),
      });
    }

    if (!enrollmentsready) {
      loadEnrolledCourses({
        learnerId,
        onSuccess: (response: any) =>
          dispatch(
            enrolledCoursesActionCreators.onEnrollmentsLoadSuccess(response)
          ),
        onError: (error: any) =>
          dispatch(enrolledCoursesActionCreators.onEnrollmentsLoadError(error)),
      });
    }

    return () => {
      unmounted = true;
    };
  }, [learnerId]);

  const toCatalog = () => router.push(`/coursecatalog?refresh=true`);
  const toEnrolled = () => router.push(`/mycourses/enrolled?refresh=true`);

  return (
    <>
      {/* Header */}
      <CLCHeader />

      {/* Preview Sections */}
      <PageContent>
        <Grid container direction={isMobile ? 'column' : 'row'}>
          {/* My Courses Preview */}
          <PreviewSection
            title="My Courses"
            gutterBottom
            subheader={
              hasEnrollments
                ? `${enrolledCourses?.idNumbers?.length} enrolled`
                : 'Not enrolled in any courses'
            }
            renderActions={
              hasEnrollments
                ? () => {
                    return (
                      <ButtonText
                        capitalize
                        fullWidth
                        onClick={toEnrolled}
                        size="small"
                      >
                        View All
                      </ButtonText>
                    );
                  }
                : null
            }
            gridProps={{ sm: true }}
          >
            {hasEnrollments ? (
              <EnrollmentPreview {...enrolledCourses} /> //FIXME:
            ) : (
              <EmptyResults text="You don't have any enrolled courses" />
            )}
          </PreviewSection>

          {/* Progress Preview */}
          <PreviewSection
            title="Current Requirement"
            gutterBottom
            gutterLeft={!isMobile}
            gridProps={{ sm: 4 }}
          >
            {dashready ? (
              <ProgressPreview />
            ) : (
              <EmptyResults text="No active requirements" />
            )}
          </PreviewSection>
        </Grid>

        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          wrap={isMobile ? 'wrap' : 'nowrap'}
        >
          {/* Course Catalog Preview */}
          <PreviewSection
            title="Course Catalog"
            subheader={hasCourses ? `${hasCourses} available` : ''}
            renderActions={
              hasCourses
                ? () => {
                    return (
                      <ButtonText
                        capitalize
                        fullWidth
                        onClick={toCatalog}
                        size="small"
                      >
                        View All
                      </ButtonText>
                    );
                  }
                : null
            }
            gutterBottom
            gridProps={{ sm: true }}
          >
            {hasCourses ? (
              <CatalogPreview
                isMobile={isMobile}
                expand={toCatalog}
                courses={courses}
              />
            ) : (
              <EmptyResults text="No available courses" />
            )}
          </PreviewSection>

          {/* Support */}
          <Grid item style={{ marginLeft: isMobile ? 0 : 10 }} sm={4}>
            <Support />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}

export default CLCContainer;
