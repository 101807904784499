import React from 'react';
import { IconProps } from './types';
import retirement_logo from 'src/views/styles/assets/icons/retirement-logo@2x.png';

interface RetirementLogoProps extends IconProps {
  size?: 'small' | 'medium' | 'large';
}

const RetirementLogo = ({ compact = false, size }: RetirementLogoProps) => {
  return (
    <img
      alt="icon retirement"
      src={retirement_logo}
      style={{
        maxWidth: compact
          ? 12
          : size === 'small'
          ? 12
          : size === 'medium'
          ? 50
          : size === 'large'
          ? 75
          : '100%',
      }}
    />
  );
};

export default RetirementLogo;
