import { languageMap } from 'src/store/consts';

const getCourses = ({ coursecatalog }) =>
  coursecatalog.courses ? Object.values(coursecatalog.courses) : [];
const getCourseIdnumbers = ({ coursecatalog }) =>
  Object.keys(coursecatalog.courses);
const getSelectedCourse = ({ coursecatalog }) =>
  coursecatalog.selectedCourse || {}; //   coursecatalog.selectedCourse?.[0] || {};
const getSelectedCourseOptions = ({ coursecatalog }) =>
  coursecatalog.selectedCourse?.options || [];
const getFilters = ({ coursecatalog }) => coursecatalog.filters;
const getSelectedLanguages = ({ coursecatalog }) =>
  coursecatalog?.filters?.language || [];
const getSelectedLanguagesLabel = (state) => {
  const langs = getSelectedLanguages(state);
  const labels = [];
  langs.forEach((element) => {
    labels.push(languageMap[element]);
  });
  if (!labels) {
    return '';
  }
  if (labels?.length >= 3) {
    return `${labels[0]}, ${labels[1]}, +${labels.length - 2}`;
  }
  return labels?.join?.(', ');
};
const getFiltersForSelectedCourse = (state) => {
  const coursecatalog = state.coursecatalog;

  return coursecatalog.filters.byId[coursecatalog.selectedCourse?.idnumber];
};
const getTotalCourseOptions = ({ coursecatalog }) => {
  let total = 0;

  getCourses({ coursecatalog }).forEach((course: any) => {
    total += course?.options?.length ?? 0;
  });

  return total;
};
const getSectedCourseTotalOptions = ({ coursecatalog }) => {
  return getSelectedCourseOptions({ coursecatalog })?.length;
};
const getSelectedCourseFilteredOptions = ({ coursecatalog }) =>
  coursecatalog.selectedCourse?.totalOptions || 0;
const getOptionsForCourseByCode = (code) => ({ coursecatalog }) => {
  return code
    ? coursecatalog?.courses?.byCourseCodes?.[code]?.options?.length
    : 0;
};
const getCoursesQueryObjFromFilters = (filters) => {
  /**
   * PROCESS MODALITIES (TYPES in moodle service)
   */
  const types = [];

  if (filters.online) {
    types.push('ONL');
  }
  if (filters.webinar) {
    types.push('WEB');
  }
  if (filters.inperson) {
    types.push('ILT');
  }

  /**
   * PROCESS Vertical collisions using tags
   */
  const tag = [];
  if (filters?.code?.includes('203')) {
    tag.push('BT9');
  }
  if (filters?.code?.includes('202')) {
    tag.push('BT30');
  }

  if (tag?.length) {
    filters.tag = tag;
  }

  return {
    ...(types?.length && { types: types }),
    ...(filters?.language?.length && { lang: filters.language }),
    ...(filters?.name?.length && { name: filters.name }),
    ...(filters?.code?.length && !tag?.length && { code: filters.code }),
    ...(tag?.length && { tag: tag }),
  };
};
const getCoursesQueryStringFromFilters = (state) => {
  const queryObj = getCoursesQueryObjFromFilters(state?.coursecatalog?.filters);
  return queryObj;
};

export default {
  // course list selectors
  getCourses,
  getCourseIdnumbers,
  getTotalCourseOptions,

  // selected course selectors
  getSelectedCourse,
  getSelectedCourseOptions,
  getSectedCourseTotalOptions,
  getSelectedCourseFilteredOptions,

  // filter selectors
  getFilters,
  getSelectedLanguages,
  getSelectedLanguagesLabel,
  getFiltersForSelectedCourse,
  getOptionsForCourseByCode,

  // translators
  getCoursesQueryObjFromFilters,
  getCoursesQueryStringFromFilters,
};
