import React from 'react';
import { IconProps } from './types';
import seiu775bglogo from 'src/views/styles/assets/seiu775bglogo.png';

export default function BGLogoWhite({ compact = false }: IconProps) {
  return (
    <img
      src={seiu775bglogo}
      alt="icon BG logo"
      style={{ maxWidth: compact ? 75 : 108 }}
    />
  );
}
