import React from 'react';
import { IconProps } from './types';
import community_interpreter_grey from 'src/views/styles/assets/icons/community-interpreter_grey@2x.png';

const InterpreterGrey = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Interpreter Grey"
      src={community_interpreter_grey}
      style={{ maxWidth: compact ? 18 : '100%' }}
    />
  );
};

export default InterpreterGrey;
