/* eslint-disable react/display-name */
import parse from 'html-react-parser';

import { Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { isXS } from 'src/utils';
import WageRaiseIncrease from 'src/views/components/DSL/Icons/WageRaiseIncrease';
import {
  Body,
  Descriptor,
  Heading3,
  Subheading,
} from 'src/views/components/DSL/typography';
import book_open from 'src/views/styles/assets/icons/book-open_circle@2x.png';
import calendar_check_grey from 'src/views/styles/assets/icons/calendar-check_circle@2x.png';
import clock_circle from 'src/views/styles/assets/icons/clock_circle@2x.png';
import lang_circle from 'src/views/styles/assets/icons/languages_circle@2x.png';
import { default as Section } from './ECMetaSection';

const useNavStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 'none',
    borderBottom: '1px solid #ddd',
  },
});

/* NAV */
function ECCourseMetaNav({ links = [], value, handleChange }) {
  const classes = useNavStyles();

  return (
    <Paper square className={classes.root} elevation={0} component="div">
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isXS() ? 'fullWidth' : 'standard'}
        indicatorColor="primary"
      >
        {links.map((label, i) => (
          <Tab
            style={{ textOverflow: 'ellipsis', textTransform: 'none' }}
            key={label + i}
            label={label}
            value={i}
          />
        ))}
      </Tabs>
    </Paper>
  );
}

const BadgeIcon = (props) => {
  return (
    <Grid container alignItems="center" style={{ marginBottom: 10 }}>
      {props.icon}
      <Grid item xs style={{ paddingLeft: 10 }}>
        <Subheading style={{ color: props.color || '#383c42' }}>
          {props.title}
        </Subheading>
        <Descriptor>{props.subheading}</Descriptor>
      </Grid>
    </Grid>
  );
};

//FIXME:
export default function ECCourseMeta({
  value,
  handleChange,
  links,
  code = 'NONE',
  course,
}) {
  const [expanded, setExpanded] = useState(false);
  const [showCarriculum, setshowCarriculum] = useState(false);
  const [showCommonq, setshowCommonq] = useState(false);

  const imgStyles = { maxWidth: 30, borderRadius: '50%' };
  console.log(
    'ECCourseMeta: ',
    course.summary,
    course.credit_hours,
    course.type
  );
  const summary = course
    ? parse(`${course?.summary.replace(/^"(.*)"$/, '$1')}`)
    : 'Unavailable';

  const carriculum = (
    <>
      <Section id="Curriculum">
        <Heading3 gutterBottom>Curriculum</Heading3>
        <Descriptor>Unavailable</Descriptor>
      </Section>
      <Divider />
      <Section id="FAQ">
        <Heading3 gutterBottom>Common Questions</Heading3>
        <Descriptor>Unavailable</Descriptor>
      </Section>
    </>
  );
  //

  const content = {
    // '500': function () {
    //   return (
    //     <>
    //       <Section id="About">
    //         <Heading3 gutterBottom>About</Heading3>
    //         <Body gutterBottom>
    //           Learn More &amp; Earn MoreWe know it’s important to you to provide
    //           your clients with care that truly makes a difference. As an
    //           Advanced Home Care Aide Specialist, you’ll learn more skills to
    //           provide exceptional care in complex settings.You’ll also
    //           strengthen the relationship between you and your client, get paid
    //           to attend, advance your caregiving career and receive a raise in
    //           pay — an extra $0.50 an hour.This training is just one day a week
    //           in class, plus online learning. When you attend and pass the
    //           class, you’ll receive 12 Continuing Education credits.
    //         </Body>
    //         <BadgeIcon
    //           icon={
    //             <WageRaiseIncrease
    //               style={{
    //                 ...imgStyles,
    //                 backgroundColor: '#f6fbf1',
    //               }}
    //             />
    //           }
    //           color="#2a8639"
    //           title="Wage Raise"
    //           subheading="Receive a $0.75/hour raise."
    //         />
    //         <BadgeIcon
    //           icon={
    //             <img
    //               src={clock_circle}
    //               style={{
    //                 ...imgStyles,
    //               }}
    //               alt="clock"
    //             />
    //           }
    //           title="70 paid hours"
    //           subheading="4-6 hours of in-person class/week and 3-5 hours of online learning/week."
    //         />
    //         <BadgeIcon
    //           icon={
    //             <img
    //               src={calendar_check_grey}
    //               style={{
    //                 ...imgStyles,
    //               }}
    //               alt="clock"
    //             />
    //           }
    //           title="9 weeks to complete"
    //           subheading="Minimum 8 weeks of learning and additional makeup class if needed."
    //         />
    //         <BadgeIcon
    //           icon={
    //             <img
    //               src={book_open}
    //               style={{
    //                 ...imgStyles,
    //               }}
    //               alt="clock"
    //             />
    //           }
    //           title="In-person and online"
    //           subheading="Choose time and location for in-person classes."
    //         />
    //         <BadgeIcon
    //           icon={
    //             <img
    //               src={lang_circle}
    //               style={{
    //                 ...imgStyles,
    //               }}
    //               alt="clock"
    //             />
    //           }
    //           title="English"
    //         />
    //       </Section>
    //       <Divider />
    //     </>
    //   );
    // },
    '100': function () {
      return (
        <>
          <Section id="About">
            <Heading3 gutterBottom>About</Heading3>
            <Body gutterBottom>
              {summary}
              {/* Taking Orientation and Safety prior to providing care helps
              prepare you for caring for a client or family member while you are
              in the process of receiving certification. This online course
              requires a desktop or laptop computer to be viewed properly. */}
            </Body>
            <BadgeIcon
              icon={
                <img
                  src={clock_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="5 paid hours"
            />
            <BadgeIcon
              icon={
                <img
                  src={book_open}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="Online"
            />
            <BadgeIcon
              icon={
                <img
                  src={lang_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="English and 5 other languages"
              subheading="Available in Russian, Spanish, Vietnamese, Korean, and Chinese."
            />
          </Section>

          <Divider />
        </>
      );
    },
    '300': function () {
      return (
        <>
          <Section id="About">
            <Heading3 gutterBottom>About</Heading3>
            <Body gutterBottom>{summary}</Body>
            <BadgeIcon
              icon={
                <img
                  src={clock_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title={
                course?.credit_hours && course?.credit_hours > 1
                  ? `${course.credit_hours} paid hours`
                  : '1 paid hour'
              }
            />
            <BadgeIcon
              icon={
                <img
                  src={book_open}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              // title="In-person, online, or webinar "
              title="Online"
            />
            <BadgeIcon
              icon={
                <img
                  src={lang_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="English and 11 other languages"
              subheading="Available in Russian, Spanish, Vietnamese, Korean, Chinese and more."
            />
          </Section>

          <Divider />
        </>
      );
    },
    //FIXME: check the moodle staging for BT70 copy
    '201': function () {
      return (
        <>
          <Section id="About">
            <Heading3 gutterBottom>About</Heading3>
            <Body gutterBottom>{summary}</Body>
            <BadgeIcon
              icon={
                <img
                  src={clock_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="70 paid hours"
            />
            <BadgeIcon
              icon={
                <img
                  src={calendar_check_grey}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="Minimum 10 days to complete"
              subheading="Complete the course in 10 days or over several weeks."
            />
            <BadgeIcon
              icon={
                <img
                  src={book_open}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="In-person"
              subheading="Choose time and location that work best for you"
            />
            <BadgeIcon
              icon={
                <img
                  src={lang_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="English and 8 other languages"
              subheading="Available in Russian, Spanish, Vietnamese, Korean, Chinese and more"
            />
          </Section>

          <Divider />
        </>
      );
    },
    //FIXME: check moodle staging
    '603': function () {
      return (
        <>
          <Section id="About">
            <Heading3 gutterBottom>About</Heading3>
            <Body gutterBottom>{summary}</Body>
            <BadgeIcon
              icon={
                <img
                  src={clock_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="6 hours"
            />
            <BadgeIcon
              icon={
                <img
                  src={calendar_check_grey}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="6 weeks to complete"
              subheading="One hour-long session/week for six weeks."
            />
            <BadgeIcon
              icon={
                <img
                  src={book_open}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="Webinar"
              subheading="Choose time for instructor-led live video classes."
            />
            <BadgeIcon
              icon={
                <img
                  src={lang_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="English and Spanish"
              subheading="Available in Russian, Spanish, Vietnamese, Korean, Chinese and more"
            />
          </Section>

          <Divider />
        </>
      );
    },
    '203': function () {
      return (
        <>
          <Section id="About">
            <Heading3 gutterBottom>About</Heading3>
            <Body gutterBottom>{summary}</Body>
            <BadgeIcon
              icon={
                <img
                  src={clock_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="4 paid hours"
            />

            <BadgeIcon
              icon={
                <img
                  src={book_open}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="Online"
              subheading="Learn at your own pace."
            />
            <BadgeIcon
              icon={
                <img
                  src={lang_circle}
                  style={{
                    ...imgStyles,
                  }}
                  alt="clock"
                />
              }
              title="English and 5 other languages"
              subheading="Available in Russian, Spanish, Vietnamese, Korean, and Chinese."
            />
          </Section>
          {/* <Divider /> */}
          {/* <Section id="Curriculum">
            <Heading3 gutterBottom>Curriculum</Heading3>
            <Descriptor>Unavailable</Descriptor>
          </Section>
          <Divider />
          <Section id="FAQ">
            <Heading3 gutterBottom>Common Questions</Heading3>
            <Descriptor>Unavailable</Descriptor>
          </Section>
          <Divider /> */}
        </>
      );
    },

    NONE: () => <Heading3>Summary unavailable</Heading3>,
  };

  return (
    <Grid component={Paper} elevation={0} square container direction="column">
      <ECCourseMetaNav
        links={links}
        value={value}
        handleChange={handleChange}
      />
      {content[code]?.({
        expanded,
        showCarriculum,
        showCommonq,
        setExpanded,
        setshowCarriculum,
        setshowCommonq,
      })}
      <Grid id="Enroll" />
    </Grid>
  );
}
