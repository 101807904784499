/**
 * 06/11/2020 a.h. TODO:
 *
 * - make getExpandPreviewLabel
 * - refactor label computation so it will only happen once
 */
import React from 'react';
import theme from 'src/views/components/DSL/theme';
import { getMomentizedRange, capitalizeString } from 'src/utils';
import { Heading4, Descriptor } from 'src/views/components/DSL/typography';
import config from 'src/store/config';
import { findLangFromID } from '../../../Enrollment/components/hooks/helpers';
const { MODALITY_TYPES } = config;
const { ILT, WEBINAR, ONLINE } = MODALITY_TYPES;

export const getDistanceLabel = ({ distanceFromUser }) => {
  if (!distanceFromUser) {
    return <span>No distance found</span>;
  }

  if (distanceFromUser <= 10) {
    return (
      <span>
        Within <span style={{ color: theme.palette.success.dark }}>10mi</span>
      </span>
    );
  }

  if (distanceFromUser <= 20) {
    return <span>Within 20mi</span>;
  }

  return <span>Beyond 20mi</span>;
};

export const getRangePreviewLabel = ({
  expanded,
  distanceFromUser,
  type,
  firstsession,
}) => {
  if (!type) {
    return null;
  }

  const rangeMoment =
    firstsession?.begins && firstsession?.ends
      ? getMomentizedRange({
          begins: firstsession.begins,
          ends: firstsession.ends,
        })
      : null;
  const startmomentized = rangeMoment?.begins?.format('MMM DD');
  const endmomentized = rangeMoment?.ends?.format('MMM DD');
  const rangeLabel =
    startmomentized &&
    endmomentized &&
    `${startmomentized}${
      endmomentized !== startmomentized ? ' - ' + endmomentized : ''
    }`;

  const modalityLabel = capitalizeString(type);

  return (
    {
      ILT: (
        <Heading4 noWrap>
          {expanded ? getDistanceLabel({ distanceFromUser }) : rangeLabel}
        </Heading4>
      ),
      ONLINE: (
        <Heading4 noWrap>
          {expanded ? modalityLabel : `Learn ${modalityLabel}`}
        </Heading4>
      ),
      WEBINAR: (
        <Heading4 noWrap>{expanded ? modalityLabel : rangeLabel}</Heading4>
      ),
    }[type] || <Descriptor>Info not found</Descriptor>
  );
};

export const getRangeDetailLabel = ({
  type,
  firstsession,
  expanded,
  idnumber,
}) => {
  if (expanded) {
    return null;
  }

  const rangeMoment =
    firstsession?.begins && firstsession?.ends
      ? getMomentizedRange({
          begins: firstsession.begins,
          ends: firstsession.ends,
        })
      : null;
  const starttimemomentized = rangeMoment?.begins?.format('h');
  const endtimemomentized = rangeMoment?.ends?.format('ha');

  let rangeDetailLabel = null;
  const range =
    starttimemomentized &&
    endtimemomentized &&
    `${rangeMoment?.ends?.format(
      'dddd'
    )}, ${starttimemomentized} - ${endtimemomentized}`;

  switch (type) {
    case WEBINAR:
    case ILT:
      rangeDetailLabel =
        (range && (
          <Descriptor noWrap>
            {`${range}`} • {findLangFromID(idnumber)}
            {/* {'English'} */}
          </Descriptor>
        )) ||
        null;
      break;
    case ONLINE:
      rangeDetailLabel = (
        <Descriptor noWrap>{findLangFromID(idnumber)}</Descriptor>
      );
      break;
    default:
      break;
  }

  return <Descriptor noWrap>{rangeDetailLabel}</Descriptor>;
};

/* button contents change based on expand status */
export const getExpandButtonPreviewLabel = ({
  expanded,
  type,
  distanceFromUser,
}) => {
  if (expanded) {
    return null;
  }

  switch (type) {
    case ILT:
      return !expanded ? (
        getDistanceLabel({ distanceFromUser })
      ) : (
        <span>No distance</span>
      );
    case WEBINAR:
    case ONLINE:
      return <span>{capitalizeString(type)}</span>;
    default:
      return <span>No details</span>;
  }
};

export const getExpandButtonDetailLabel = ({
  firstsession,
  type,
  expanded,
}) => {
  if (expanded) {
    return null;
  }

  switch (type) {
    case ILT:
    case WEBINAR:
    case ONLINE:
      return <Descriptor noWrap>{firstsession?.address}</Descriptor>;
    default:
      return null;
  }
};
