import React from 'react';
import PageHeader from 'src/views/components/Layouts/PageHeader';
import Back from 'src/views/components/DSL/buttons/Back';
import { Heading1 } from 'src/views/components/DSL/typography';

const CCPageHeader = () => {
  return (
    <PageHeader>
      <Back to={'/caregiverlearningcenter'}>Learning Overview</Back>
      <Heading1>Course Catalog</Heading1>
    </PageHeader>
  );
};

export default React.memo(CCPageHeader);
