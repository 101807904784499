import { Grid } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { useDispatch, useSelector } from 'src/views/providers';
import { authSelectors } from 'src/store/authentication';
import config from 'src/store/config';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { dialogActionCreators } from 'src/store/dialog';
import {
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import { Heading4 } from 'src/views/components/DSL/typography';
import CCContentSection from './CCContentSection';
import CCDialogCloseButton from './CCDialogCloseButton';
import CCFooterSection from './CCFooterSection';
import CCTitleSection from './CCTitleSection';
import CCCourseTypeFilter from './filters/CCCourseTypeFilter';

const { getSelectedLanguagesLabel } = courseCatalogSelectors;
const { setDialogContentKey, closeDialog } = dialogActionCreators;

function CCGeneralFilters() {
  /* GLOBAL STATE */
  const dispatch = useDispatch();
  // const options = useSelector(courseCatalogSelectors.getTotalCourseOptions);
  const options = useSelector(
    (state) => state.coursecatalog.courses?.codes?.length || 0
  );
  const selectedLanguage = useSelector(
    (state) => state.coursecatalog.filters?.language?.[0] || 'English'
  );
  const defaultLanguage = useSelector(authSelectors.getPreferredLanguage);

  const reqCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(setDialogContentKey(null));
  };

  return (
    <>
      <CCTitleSection
        leftControl={<CCDialogCloseButton onClick={reqCloseDialog} />}
      >
        Filters
      </CCTitleSection>
      <CCContentSection>
        {/* <CCCourseTypeFilter label="Course Type" /> */}
        <br />
        <Grid container direction="column" style={{ margin: `20px 0px` }}>
          <Heading4 gutterBottom style={{ marginBottom: 10 }}>
            Language
          </Heading4>
          <ButtonOutlined
            rounded
            style={{ backgroundColor: '#fff' }}
            focused={selectedLanguage?.length > 0}
            endIcon={<ArrowDropDown color="secondary" fontSize="small" />}
            onClick={() => {
              dispatch(
                dialogActionCreators.setDialogContentKey(
                  config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_LANGUAGE
                )
              );
              dispatch(dialogActionCreators.openDialog());
            }}
          >
            {selectedLanguage || defaultLanguage || 'Language'}
          </ButtonOutlined>
        </Grid>
      </CCContentSection>
      <CCFooterSection
        leftControl={
          <ButtonSecondary fullWidth capitalize onClick={reqCloseDialog}>
            Cancel
          </ButtonSecondary>
        }
        rightControl={
          <ButtonPrimary onClick={reqCloseDialog} fullWidth>
            {`Show ${options || ''} options`}
          </ButtonPrimary>
        }
      />
    </>
  );
}

export default React.memo(CCGeneralFilters);
