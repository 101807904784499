import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { dialogActionCreators } from 'src/store/dialog';
import { popoverActionCreators } from 'src/store/popover';
import { useDispatch, useSelector } from 'src/views/providers';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { CloseGrey } from 'src/views/components/DSL/Icons';
import { Heading4 } from 'src/views/components/DSL/typography';
import {
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import CCTitleSection from '../CCTitleSection';
import CCContentSection from '../CCContentSection';
import CCFooterSection from '../CCFooterSection';
import CCDialogBackButton from '../CCDialogBackButton';
import { ISOToLang, LangToISOcode } from 'src/store/consts';
import config from 'src/store/config';
import {
  courseCatalogActionCreators,
  courseCatalogSelectors,
} from 'src/store/coursecatalog';
import { authSelectors } from 'src/store/authentication';

const { setPopoverContentKey, closePopover } = popoverActionCreators;
const { setDialogContentKey } = dialogActionCreators;
const { requestLoadCourses, setFiltersForCourse } = courseCatalogActionCreators;
const { getFilters } = courseCatalogSelectors;
const { getTrainings, getPreferredLanguage } = authSelectors;

// Styling components for the Language Picker
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 6,
      borderWidth: 1,
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
      },
    },
  })
);

export type CCLanguageFilterProps = {
  segWayType: string;
  onClose?: any;
};

/* MAIN FUNCTION  */
export default function CCLanguageFilter({
  segWayType,
  onClose = null,
}: CCLanguageFilterProps) {
  const isMobile = useBreakpoint('xs');
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector(getFilters).language;
  const [checked, setChecked] = React.useState(() => {
    const initialChecked = language.reduce(
      (acc, current) => ({ ...acc, [ISOToLang[current]]: current }),
      {}
    );

    return initialChecked;
  });
  const [applyingFilters, setApplyingFilters] = useState(false);

  const handleToggle = (value: string) => () => {
    const newChecked = { ...checked };

    if (checked[value]) {
      delete newChecked[value];
    } else {
      newChecked[value] = LangToISOcode[value];
    }

    setChecked(newChecked);
  };

  const handleSubmit = () => {
    setApplyingFilters(true);
    const entries = Object.values(checked);
    if (entries?.length > 0) {
      console.log(entries);
      //TODO: get the course code
      dispatch(
        setFiltersForCourse({
          language: entries,
        })
      );

      dispatch(
        requestLoadCourses(
          () => {
            setApplyingFilters(false);

            if (segWayType === config.SEGWAY_TYPES.DIALOG) {
              dispatch(
                setDialogContentKey(
                  config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_OVERVIEW
                )
              );
            } else {
              dispatch(closePopover());
              dispatch(setPopoverContentKey(null));

              if (typeof onClose === 'function') {
                onClose();
              }
            }
          },
          (error) => {
            console.log(error);
            if (segWayType === config.SEGWAY_TYPES.DIALOG) {
              dispatch(
                setDialogContentKey(
                  config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_OVERVIEW
                )
              );
            } else {
              dispatch(closePopover());
              dispatch(setPopoverContentKey(null));

              if (typeof onClose === 'function') {
                onClose();
              }
            }
          }
        )
      );
    }
  };

  const onCancel = () => {
    if (segWayType === config.SEGWAY_TYPES.DIALOG) {
      dispatch(
        setDialogContentKey(config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_OVERVIEW)
      );
    } else if (segWayType === config.SEGWAY_TYPES.POPOVER) {
      dispatch(closePopover());
      dispatch(setPopoverContentKey(null));
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  /* PROPS TO BE PASSED TO CHILDREN */
  const ccTitleProps = {
    leftControl: <CCDialogBackButton onClick={onCancel} />,
  };
  const ccContentProps = {
    disableTypography: true,
    style: {
      height: isMobile ? 'auto' : 300,
      padding: 0,
    },
  };
  const ccFooterProps = {
    leftControl: (
      <ButtonSecondary
        fullWidth
        disabled={applyingFilters}
        capitalize
        onClick={onCancel}
      >
        Cancel
      </ButtonSecondary>
    ),
    rightControl: (
      <ButtonPrimary
        onClick={handleSubmit}
        fullWidth
        disabled={applyingFilters}
      >
        {applyingFilters ? 'Applying filters...' : 'Apply'}
      </ButtonPrimary>
    ),
  };

  return (
    <>
      {/* Title */}
      {isMobile ? (
        <CCTitleSection {...ccTitleProps}>Language</CCTitleSection>
      ) : (
        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ padding: '10px 20px' }}
        >
          <Heading4>Language</Heading4>
          <IconButton
            disabled={applyingFilters}
            onClick={onCancel}
            edge="end"
            style={{ padding: 10 }}
          >
            <CloseGrey compact />
          </IconButton>
        </Grid>
      )}
      {/* Body */}
      <CCContentSection {...ccContentProps}>
        <List className={classes.root} dense>
          {Object.keys(LangToISOcode).map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem
                key={value}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
                classes={classes}
              >
                <ListItemIcon>
                  <Checkbox
                    color="secondary"
                    edge="start"
                    checked={!!checked[value]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            );
          })}
        </List>
      </CCContentSection>
      {/* Footer */}
      <CCFooterSection {...ccFooterProps} />
    </>
  );
}
