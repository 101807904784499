import React from 'react';
import { ReactComponent as PenLOGO } from './svg/pen_blue.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const PenBlue = ({ fontSize = 'small', ...props }: IconProps) => {
  return <Icon fontSize={fontSize} component={PenLOGO} {...props} />;
};

export default PenBlue;
