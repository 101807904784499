import { Drawer, Grid } from '@material-ui/core';
import React from 'react';
import { MOODLE } from 'src/envvars';
import HeroContent from './HeroContent';

function ECHero({ image = '', children, classes, isMobile = false }) {
  const content = <HeroContent isMobile={isMobile}>{children}</HeroContent>;
  const media = `${MOODLE}${image?.replace(/ /g, '%20')}`;

  return isMobile ? (
    <Grid
      container
      style={{
        width: '100%',
        height: 200,
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        backgroundImage: `url(${
          media ||
          'https://images.unsplash.com/photo-1594952961393-a91e1d938c43?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max'
        })`,
      }}
    >
      {content}
    </Grid>
  ) : (
    <Drawer
      className={classes.drawerLeft}
      variant="permanent"
      classes={{
        paper: classes.leftPaper,
      }}
      anchor="left"
    >
      <Grid
        container
        className={classes.hero}
        style={{
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'none',
          backgroundImage: `url(${
            media ||
            'https://images.unsplash.com/photo-1594952961393-a91e1d938c43?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max'
          })`,
        }}
      >
        {content}
      </Grid>
    </Drawer>
  );
}

export default ECHero;
