import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DSLButtonProps, useButtonBaseStyles } from '.';

const useStyles = makeStyles({
  root: (props: DSLButtonProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const baseStyles = useButtonBaseStyles(props);
    return {
      ...baseStyles,
      textTransform: 'capitalize',
    };
  },
});

interface Props extends DSLButtonProps {
  focused?: boolean;
  rounded?: boolean;
  gutterRight?: boolean;
  gutterBottom?: boolean;
}

export default function ButtonSecondary({
  onClick,
  disabled,
  children,
  size = 'medium',
  gutterBottom,
  fullWidth,
  capitalize,
  style,
  rounded,
  startIcon,
  endIcon,
  ...rest
}: DSLButtonProps & Props) {
  const classes = useStyles({
    size,
    gutterBottom,
    capitalize,
    rounded,
  });

  return (
    <Button
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      startIcon={startIcon}
      endIcon={endIcon}
      color="secondary"
      variant="outlined"
      className={classes.root}
      disabled={disabled}
      onClick={onClick}
      size={size || 'medium'}
      fullWidth={fullWidth}
      style={style}
      {...rest}
    >
      {children}
    </Button>
  );
}
