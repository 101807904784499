import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './views/App';
import { theme } from './views/components/DSL';
import StoreProvider from './views/providers';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById('root')
);
