import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useRouter, isMobile } from 'src/utils';
import { ButtonText } from 'src/views/components/DSL/buttons';
import {
  ClockGrey,
  ContactUsDefault,
  LinksResDefault,
  PhoneDefault,
} from 'src/views/components/DSL/Icons';
import {
  Descriptor,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';
import helpLogo from 'src/views/styles/assets/help@2x.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  support: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ContactContainer() {
  const classes = useStyles();
  const anchorStyles = {
    display: 'flex',
    alignItems: 'center',
  };
  const router = useRouter();

  return (
    <Container disableGutters maxWidth="md" className={classes.root}>
      <Grid container spacing={2}>
        {/* Contact Us */}
        <Grid item xs={12} sm={7}>
          <Heading4 gutterBottom>Contact Us</Heading4>
          <Paper elevation={0} variant="outlined" className={classes.paper}>
            <Box mb={2}>
              <Heading4 gutterBottom>Member Resource Center</Heading4>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Button
                variant="text"
                color="secondary"
                target="_blank"
                href="tel:1-866-371-3200"
                rel="noreferrer noopener"
                size="medium"
                startIcon={<PhoneDefault />}
              >
                1-866-371-3200
              </Button>

              <Button
                variant="text"
                color="secondary"
                target="_blank"
                href="mailto:MRC@myseiubenefits.org"
                rel="noreferrer noopener"
                size="medium"
                startIcon={<ContactUsDefault />}
              >
                MRC@myseiubenefits.org
              </Button>

              <Button
                variant="text"
                size="medium"
                disabled
                startIcon={<ClockGrey compact />}
              >
                <Subheading
                  gutterBottom
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <strong style={{ marginRight: 8 }}>Hours</strong> Monday -
                  Friday, 8 a.m. - 6 p.m.
                </Subheading>
              </Button>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent={isMobile() ? 'center' : 'flex-end'}
            >
              <Button
                variant="text"
                color="secondary"
                href="https://www.myseiubenefits.org/help/training-benefits-support/"
                rel="noreferrer noopener"
                size="small"
                endIcon={<LinksResDefault fontSize="small" />}
              >
                More information
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Heading4 gutterBottom>Support</Heading4>
          <Paper elevation={0} variant="outlined" className={classes.support}>
            <Avatar
              src={helpLogo}
              alt={'Support Emblem'}
              className={classes.large}
            />
            <Heading4 gutterBottom>Help</Heading4>
            <Descriptor gutterBottom>
              Find answers to common questions.
            </Descriptor>

            <ButtonText size="small" onClick={() => router.push('/help')}>
              View Help Page
            </ButtonText>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
