import { TableBody, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer, {
  TableContainerProps,
} from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment';
import React from 'react';
import { TRAINING_CODE } from 'src/consts';
import { CourseCompletionsEntity } from 'src/types';
import TCPdfFooter from './TCPdfFooter';
import TCPdfHeader, { LearnerInfoProps } from './TCPdfHeader';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 612,
    // height: 800,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  table: {
    flex: 1,
    maxWidth: '100%',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
    // flex: 1,
    '& > *': {
      fontSize: 10,
      textOverflow: 'ellipsis',
    },
  },
  tableRow: {
    borderBottom: '1px solid #000',
    '& > *': {
      textOverflow: 'ellipsis',
      fontSize: 8,
    },
  },
  tableCell: {
    fontSize: 7,
  },
}));

export type TSDOMID = 'tspdf';
interface TCPdfProps extends TableContainerProps {
  id: TSDOMID;
  learnerInfo: LearnerInfoProps;
  completions: CourseCompletionsEntity[];
}

// TODO: instructor info
export const PROGRAM = (code) => {
  const trainingCode = code.substring(0, 4);
  let res = '';
  switch (true) {
    case trainingCode.includes(TRAINING_CODE.BT_7.CODE):
      res = 'BT7';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_9.CODE):
      res = 'BT9';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_30.CODE):
      res = 'BT30';
      break;
    case trainingCode.includes(TRAINING_CODE.BT_70.CODE):
      res = 'BT70';
      break;
    case trainingCode.includes(TRAINING_CODE.CE_12.CODE):
      res = 'CE';
      break;
    case trainingCode.includes(TRAINING_CODE.AHCAS.CODE):
      res = 'AHCAS';
      break;
    case trainingCode.includes(TRAINING_CODE.OS.CODE) ||
      trainingCode.includes(TRAINING_CODE.ORIENTATION.CODE) ||
      trainingCode.includes(TRAINING_CODE.SAFETY.CODE):
      res = 'O&S';
      break;
    case trainingCode.includes(TRAINING_CODE.MINDFUL_WC.CODE):
      res = 'Tools for Calm';
      break;
    default:
      break;
  }
  return res;
};

function TCPdf({ id, learnerInfo, completions }: TCPdfProps) {
  const classes = useStyles();

  return (
    <TableContainer
      id={id}
      className={classes.paper}
      component={Paper}
      variant="outlined"
      square
      style={{ width: '100%' }}
    >
      {/* HEADER */}
      <TCPdfHeader {...learnerInfo} />

      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.tableHead} component="th">
          <TableCell align="left" colSpan={1}>
            COMPLETED
          </TableCell>
          <TableCell>COURSE NAME</TableCell>
          <TableCell align="left">DSHS ID</TableCell>
          <TableCell align="left">
            PROGRAM<sup>*</sup>
          </TableCell>
          <TableCell align="left">HOURS</TableCell>
          <TableCell align="left">COURSE TYPE</TableCell>
          <TableCell align="left">INSTRUCTOR</TableCell>
        </TableHead>
        <TableBody component="tbody">
          {completions?.map((row, i) => (
            <TableRow
              className={classes.tableRow}
              key={row.name + '' + i}
              style={{ pageBreakInside: 'avoid' }}
            >
              <TableCell align="left" colSpan={1}>
                {row.completed_date
                  ? moment(row.completed_date).format('MM/DD/YYYY')
                  : 'N/A'}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="left">{row.dshsId || ''}</TableCell>
              <TableCell align="left">{PROGRAM(row.courseId)}</TableCell>
              <TableCell align="left">{parseFloat(row.units)}</TableCell>
              <TableCell align="left">{row.modality}</TableCell>
              <TableCell align="left">
                {row.instructor || 'instructor'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* FOOTER */}
      <TCPdfFooter />
    </TableContainer>
  );
}

export default React.memo(TCPdf);
