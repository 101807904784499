import { Box, BoxProps, MenuItem } from '@material-ui/core';
import Select, { SelectProps } from '@material-ui/core/Select';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import config from 'src/store/config';
import { Descriptor, DescriptorError } from '../typography';

type InputLangSelectProps = BoxProps &
  SelectProps & {
    label?: string;
    onChange?: (event: any) => void;
    value?: any;
    gutterBottom?: boolean;
    name?: string;
    control: Control<Record<string, any>>;
    fullWidth?: boolean;
    [x: string]: any;
  };

const languages = config.LANGS_STRING;

function InputLangSelect({
  name,
  label,
  control,
  defaultValue = 'English',
  fullWidth = true,
  ...props
}: InputLangSelectProps) {
  const labelId = `${name}-label`;
  return (
    <Box
      display="flex"
      flexGrow={fullWidth ? 1 : 'auto'}
      flexDirection="column"
      mb={1}
    >
      {label && <Descriptor gutterBottom>{label}</Descriptor>}
      <Controller
        as={
          <Select
            style={{ backgroundColor: '#fff' }}
            variant="outlined"
            color="secondary"
            inputProps={{
              style: {
                width: '100%',
                backgroundColor: 'white',
              },
            }}
            fullWidth
            margin="dense"
            MenuProps={{
              variant: 'menu',
              PaperProps: {
                elevation: 0,
                variant: 'outlined',
                style: {
                  marginTop: 56,
                  maxHeight: 500,
                  width: 500,
                },
              },
            }}
            name={name}
            labelId={labelId}
            disableUnderline
          >
            {languages.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {props?.error && (
        <DescriptorError gutterBottom>{props?.error}</DescriptorError>
      )}
    </Box>
  );
}
export default React.memo(InputLangSelect);
