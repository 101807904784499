import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Switch,
} from '@material-ui/core';
import React, { useState } from 'react';
import { getAuthentication } from 'src/store/authentication/selectors';
import {
  Body,
  DescriptorError,
  Heading3,
  Heading4,
  Subheading,
} from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import EditDialog from '../components/EditDialog';
import FieldReadOnly from '../components/FieldReadOnly';
import ProfileCard from '../components/ProfileCard';

const useCardStyles = makeStyles({
  root: {
    marginBottom: 16,
    borderRadius: 8,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#383c42',
  },
  subheader: {
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: 'Lato',
    color: '#5f6772',
  },
  content: {
    paddingTop: 8,
    paddingBottom: 8,
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  fieldsList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#fff',
  },
});

export default function ProfileContainer() {
  const { isLoggedIn, error, ...authentication } = useSelector(
    getAuthentication
  );
  const cardStyles = useCardStyles();
  const cardProps: any = {
    variant: 'outlined',
    className: cardStyles.root,
  };
  const cardTitleProps = {
    className: cardStyles.title,
    disableTypography: true,
  };

  const [editDialog, setShowEditDialog] = useState({
    open: false,
    contentKey: '',
  });

  const openEditDialog = (contentKey) => () =>
    setShowEditDialog({
      ...editDialog,
      open: true,
      contentKey,
    });

  const closeEditDialog = () =>
    setShowEditDialog({
      open: false,
      contentKey: '',
    });

  const changeContactPref = async (event, checked) => {
    event.persist();
    console.log('clicked contact pref, ', event?.target?.name, checked);
  };

  return (
    <>
      {!isLoggedIn && (
        <Box p={2}>
          <Heading3>Unauthorized</Heading3>
        </Box>
      )}
      {error && (
        <Box p={2}>
          <DescriptorError>{error}</DescriptorError>
        </Box>
      )}
      {isLoggedIn && (
        <Box
          display="flex"
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          alignItems="flex-start"
          p={{
            xs: 0,
            sm: 3,
          }}
        >
          <EditDialog {...editDialog} handleClose={closeEditDialog} />

          {/* Profile card */}
          <ProfileCard
            isLoggedIn={isLoggedIn}
            error={error}
            {...authentication}
          />

          {/* Edit cards */}
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            height="auto"
            m={{ xs: 2, sm: 0 }}
          >
            {/* Contact Information */}
            <Card {...cardProps}>
              <CardHeader {...cardTitleProps} title="Contact Information" />
              <Divider />
              <CardContent className={cardStyles.content}>
                {[
                  {
                    name: 'email',
                    label: 'Email',
                    value: authentication?.email,
                    onClick: openEditDialog('email'),
                  },
                  {
                    name: 'cellPhone',
                    label: 'Cell Phone',
                    value: authentication?.cellPhone,
                    onClick: openEditDialog('cellPhone'),
                  },
                  {
                    name: 'homePhone',
                    label: 'Home Phone',
                    value: authentication?.homePhone,
                    onClick: openEditDialog('homePhone'),
                  },
                  {
                    name: 'address',
                    label: 'Address',
                    value: authentication?.address1,
                    onClick: openEditDialog('address'),
                  },
                ].map((field, k) => (
                  <FieldReadOnly
                    {...field}
                    last={k === 3}
                    key={k + '' + field.name}
                  />
                ))}
              </CardContent>
            </Card>

            {/* Languages */}
            <Card {...cardProps}>
              <CardHeader {...cardTitleProps} title="Languages" />
              <Divider />
              <CardContent className={cardStyles.content}>
                {[
                  {
                    name: 'preferredLanguage',
                    label: 'Preferred Language',
                    value: authentication?.preferredLanguage,
                    onClick: openEditDialog('preferredLanguage'),
                  },
                  {
                    name: 'languagesFluentIn',
                    label: 'Language(s) Fluent in',
                    value: authentication?.languagesFluentIn,
                    onClick: openEditDialog('languagesFluentIn'),
                  },
                ].map((field, k) => (
                  <FieldReadOnly
                    {...field}
                    last={k === 1}
                    key={k + '' + field.name}
                  />
                ))}
              </CardContent>
            </Card>

            {/* Contact Preferences */}
            <Card {...cardProps}>
              <CardHeader
                {...cardTitleProps}
                title={<Heading4 gutterBottom>Contact Preferences</Heading4>}
                subheader={
                  <Body>
                    Important or required communications regarding learning,
                    health or retirement benefits may still be sent to you
                    through email, text or phone.
                  </Body>
                }
              />
              <Divider />
              <CardContent className={cardStyles.content}>
                {[
                  {
                    name: 'mobile',
                    label: 'Text',
                    value:
                      authentication?.contactPreference?.indexOf('mobile') > -1,
                    onClick: changeContactPref,
                  },
                  {
                    name: 'homePhone',
                    label: 'Phone',
                    value:
                      authentication?.contactPreference?.indexOf('homePhone') >
                      -1,
                    onClick: changeContactPref,
                  },
                  {
                    name: 'email',
                    label: 'Email',
                    value:
                      authentication?.contactPreference?.indexOf('email') > -1,
                    onClick: changeContactPref,
                  },
                ].map((field, k) => (
                  <Box
                    key={k}
                    display="flex"
                    maxWidth={1}
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    py={{ xs: 2, sm: 3 }}
                    borderBottom={k !== 2 && '1px solid #e2e5e9'}
                  >
                    <Subheading>{field.label}</Subheading>

                    <Switch
                      color="secondary"
                      size="small"
                      // disabled
                      name={field.name}
                      // onChange={field.onClick}
                      // checked={false}
                    />
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </>
  );
}
