/* eslint-disable react/display-name */
import {
  ContactUsDefault,
  HealthDefault,
  HelpDefault,
  MBHomeDefault,
  RetirementDefault,
  Seiu775bgDefault,
  TrainingDefault,
} from 'src/views/components/DSL/Icons';
import { EXTERNAL_LINK, INTERNAL_LINK } from './linktypes';

export const LN_LINKS = [
  {
    text: 'My Benefits',
    to: '/',
    startIcon: MBHomeDefault,
    type: INTERNAL_LINK,
  },
  {
    text: 'Caregiver Learning Center',
    to: '/caregiverlearningcenter',
    startIcon: TrainingDefault,
    type: INTERNAL_LINK,
  },
  {
    text: 'Health',
    to: '/health',
    startIcon: HealthDefault,
    type: INTERNAL_LINK,
  },
  {
    text: 'Secure Retirement',
    to: '/secureretirement',
    startIcon: RetirementDefault,
    type: INTERNAL_LINK,
  },
  {
    divider: true,
  },
  {
    text: 'Help',
    to: '/help',
    startIcon: HelpDefault,
    type: INTERNAL_LINK,
  },
  {
    text: 'Contact Us',
    to: '/contact',
    startIcon: ContactUsDefault,
    type: INTERNAL_LINK,
  },
  {
    divider: true,
  },
  {
    text: 'SEIU 775 Benefits Group',
    href: 'https://www.myseiubenefits.org/',
    startIcon: Seiu775bgDefault,
    type: EXTERNAL_LINK,
  },
  {
    divider: true,
  },
];

export const LN_EXT_LINKS = [
  {
    text: 'SEIU 775 Union',
    href: 'http://seiu775.org/',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
  {
    text: 'Carina',
    href:
      'https://www.carinacare.com/?utm_source=bg&utm_medium=referral&utm_campaign=bg&utm_content=menu',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
  {
    text: 'Skills and Videos',
    href: 'https://www.myseiubenefits.org/odx93xufwy/',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
  {
    divider: true,
  },
  {
    text: 'Community Interpreter',
    href:
      'https://www.myseiubenefits.org/training/language-support/community-interpreter-information/',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
  {
    text: 'Individual ProviderOne',
    href: 'https://ipone.publicpartnerships.com/login.aspx',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
  {
    divider: true,
  },
  {
    text: 'Terms of Use',
    href:
      'https://seiu775bg.my.salesforce.com/?ec=302&startURL=%2Fcontent%2Fsession%3Furl%3Dhttps%253A%252F%252Fseiu775bg--c.na126.content.force.com%252Fservlet%252Fservlet.FileDownload%253Ffile%253D015d0000009IFGp',
    startIcon: () => null,
    type: EXTERNAL_LINK,
  },
];
