import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface Props extends TypographyProps {
  contrast?: boolean;
}

const Display = ({ contrast, style, ...rest }: Props) => (
  <Typography
    variant="h1"
    style={{
      color: contrast ? '#fff' : undefined,
      ...style,
    }}
    {...rest}
  />
);

export default React.memo(Display);
