import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Schedule } from 'src/views/components/DSL/schedule';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { Body, Heading3, Heading4 } from 'src/views/components/DSL/typography';
import {
  SCHED_MODES,
  VARIANT,
} from '../../Enrollment/components/hooks/helpers';
import { Registration } from '../containers/UnenrollContainer';

export default function BlockEnrolledCourseInfo(props: Registration) {
  const [scheduleViewMode] = React.useState(SCHED_MODES.LIST);
  const checkForUT = props.description.includes('Module 1, Basic Training 70');
  // console.log(props);

  let sessionsForOption = [];
  if (checkForUT === true) {
    const utObj = {
      ...props,
      description: 'Union Time',
      ends: moment.unix(props.begins),
      begins: moment.unix(props.begins).subtract(30, 'minutes'),
    };
    sessionsForOption = [
      utObj,
      {
        ...props,
        begins: moment.unix(props?.begins),
        ends: moment.unix(props?.ends),
      },
    ];
  }
  return (
    <Grid>
      <List disablePadding>
        <ListItem>
          <Heading3>{props.description}</Heading3>
        </ListItem>

        {/* Renders session schedule in different modes */}
        <Schedule
          mode={scheduleViewMode}
          sessions={sessionsForOption}
          variant={VARIANT.dynamic_expandable}
        />
      </List>
    </Grid>
  );
}
