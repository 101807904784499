/* eslint-disable react-hooks/rules-of-hooks */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DSLButtonProps, useButtonBaseStyles } from '.';

const useStyles = makeStyles({
  root: (props: DSLButtonProps) => useButtonBaseStyles(props),
});
export default function ButtonPrimary(props: DSLButtonProps) {
  const classes = useStyles(props);

  return (
    <Button
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      color="secondary"
      variant="contained"
      className={classes.root}
      {...props}
    />
  );
}
