import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { useAutocomplete } from '@material-ui/lab';
import React from 'react';
import { getPreferredLanguage } from 'src/store/authentication/selectors';
import config from 'src/store/config';
import { useInputStyles } from 'src/views/components/DSL/form';
import { useSelector } from 'src/views/providers';

export default function PrefLangs() {
  const inputClasses = useInputStyles();
  const preferredLanguage = useSelector(getPreferredLanguage);
  const {
    getInputProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    options: config.LANGS_STRING,
    defaultValue: preferredLanguage,
    filterSelectedOptions: false,
    blurOnSelect: false,
    autoSelect: true,
    autoHighlight: true,
    open: true,
    getOptionSelected: (option, value) => value === option,
  });

  return (
    <Box
      maxHeight={{ sm: '75%', xs: '95%' }}
      display="flex"
      flexDirection="column"
    >
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          classes: inputClasses,
          margin: 'dense',
          endAdornment: (
            <IconButton {...getClearProps()} size="small">
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          ),
        }}
        placeholder="Search languages"
        multiline={false}
        {...getInputProps()}
      />
      <Box flex={1} overflow="auto">
        <List dense={true} role="list">
          {groupedOptions?.map?.((option, index) => {
            return (
              <ListItem
                divider
                role="listitem"
                button
                color="secondary"
                selected={option === inputValue}
                key={index + '' + option}
                {...getOptionProps({ option, index })}
              >
                <ListItemText primary={option} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
