/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { getDashboard } from 'src/store/authentication/selectors';
import { DescriptorError } from 'src/views/components/DSL/typography';
import { useSelector } from 'src/views/providers';
import ProgressCard from '../../Home/components/ProgressCard';

function ProgressPreview() {
  const errorStyles = { fontSize: 16 };
  const dashboard = useSelector(getDashboard);
  const progress = dashboard?.inProgress?.[0] || null;
  const error = !progress;

  return (
    <>
      {error ? (
        <DescriptorError style={errorStyles}>
          No active requirements found.
        </DescriptorError>
      ) : (
        <>
          {/* Progress Card */}
          {progress && <ProgressCard {...progress} />}
        </>
      )}
    </>
  );
}

export default React.memo(ProgressPreview);
