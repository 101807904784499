import { makeStyles } from '@material-ui/core';
import Link, { LinkProps } from '@material-ui/core/Link';
import React from 'react';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    // proprietary
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },

    // conditionally assigned
    textTransform: 'capitalize',

    // defaults
    textDecoration: 'none',
    color: palette.secondary[400],
    fontWeight: 700,
    letterSpacing: 0,
  },
}));

const BodyAnchor = (props: LinkProps) => {
  const classes = useStyles();
  return (
    <Link
      underline={props?.underline || 'none'}
      className={classes.root}
      {...props}
    />
  );
};

export default React.memo(BodyAnchor);
