import { all } from 'redux-saga/effects';
import { sessionsSagas } from './sessions';
import { enrollmentSagas } from './enrollment';
import { scheduleManagementSagas } from './schedulemanagement';
import { makeupSagas } from './makeups';
import { courseCatalogSagas } from './coursecatalog';

export default function* rootSaga() {
  yield all([
    courseCatalogSagas(),
    sessionsSagas(),
    enrollmentSagas(),
    scheduleManagementSagas(),
    makeupSagas(),
  ]);
}
