import React from 'react';
import { ReactComponent as ChevronDownBlueLOGO } from './svg/icons-chevron-down-18-turquoise-50.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

const ChevronDownBlue = ({ fontSize = 'small', ...props }: IconProps) => {
  return (
    <Icon fontSize={fontSize} component={ChevronDownBlueLOGO} {...props} />
  );
};

export default ChevronDownBlue;
