import transcriptActionTypes from './types';
import defaultState from '../defaultState';

const {
  LOAD_SUCCESS,
  LOAD_ERROR,
  SET_FILTER,
  APPLY_FILTER,
} = transcriptActionTypes;

export default function transcriptReducer(
  state = defaultState.transcripts,
  action
) {
  const mutations = {
    [LOAD_ERROR]: (state, _action) => ({
      ...state,
      error: action.error,
      status: 'FINISHED',
    }),
    [SET_FILTER]: (state, _action) => ({
      ...state,
      contentKey: action.contentKey,
    }),
    [LOAD_SUCCESS]: (state, _action) => ({
      ...state,
      byIdNumbers: action.response,
      status: 'FINISHED',
      idNumbers: Object.keys(action?.response || {}),
    }),
    [APPLY_FILTER]: (state, _action) => ({
      ...state,
      byIdNumber: action.response,
      status: 'FINISHED',
      idNumbers: Object.keys(action?.response || {}),
    }),
  };

  return mutations[action.type]?.(state, action) || state;
}
