import { TypographyProps } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import theme from 'src/views/styles/theme';
import Back from './Back';
import ButtonAnchor from './ButtonAnchor';
import ButtonLink from './ButtonLink';
import ButtonMultiState from './ButtonMultiState';
import ButtonOutlined from './ButtonOutlined';
import ButtonPrimary from './ButtonPrimary';
import ButtonRound from './ButtonRound';
import ButtonSecondary from './ButtonSecondary';
import ButtonSwitch from './ButtonSwitch';
import ButtonText from './ButtonText';
import DSLButton from './DSLButton';

export type DSLButtonProps = MuiButtonProps &
  TypographyProps & {
    gutterTop?: boolean;
    gutterBottom?: boolean;
    gutterRight?: boolean;
    gutterLeft?: boolean;
    capitalize?: boolean;
    rounded?: boolean;
    focused?: boolean;
  };

export const useButtonBaseStyles = (props: DSLButtonProps): CSSProperties => ({
  textTransform: props?.capitalize ? 'capitalize' : 'uppercase',
  fontWeight: 700,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flexWrap: 'nowrap',
  borderRadius: props?.rounded ? 25 : 4,
  marginTop: props?.gutterTop ? theme.spacing(1) : 'initial',
  marginBottom: props?.gutterBottom ? theme.spacing(1) : 'initial',
  marginRight: props?.gutterRight ? theme.spacing(1) : 'initial',
  marginLeft: props?.gutterLeft ? theme.spacing(1) : 'initial',
});

export {
  ButtonRound,
  ButtonText,
  ButtonLink,
  ButtonSecondary,
  Back,
  ButtonOutlined,
  DSLButton,
  ButtonPrimary,
  ButtonMultiState,
  ButtonSwitch,
  ButtonAnchor,
};
