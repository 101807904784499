import { Box, CircularProgress, Divider } from '@material-ui/core';
import React from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import { Descriptor } from 'src/views/components/DSL/typography';

export function renderFormControls({ disabled, isSubmitting }) {
  return (
    <>
      <ButtonPrimary
        disabled={disabled || isSubmitting}
        type="submit"
        size="large"
      >
        {isSubmitting ? (
          <CircularProgress size={20} color="secondary" />
        ) : (
          'Next'
        )}
      </ButtonPrimary>

      <Box display="flex" flexWrap="nowrap" alignItems="center" my={1}>
        <Box flexGrow={1}>
          <Divider />
        </Box>
        <Box mx={1}>
          <Descriptor>Already have an account?</Descriptor>
        </Box>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </Box>

      <ButtonSecondary capitalize size="large" gutterBottom>
        Log in
      </ButtonSecondary>
    </>
  );
}
