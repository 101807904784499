/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridProps } from '@material-ui/core';
import React from 'react';
import { getAuthentication } from 'src/store/authentication/selectors';
import { useSelector } from 'src/views/providers';
import HCInstructorSect from '../components/HCInstructorSect';
import HCStudentSect from '../components/HCStudentSect';
import ProfilePanel from '../components/ProfilePanel';

function HomeContainer() {
  const { role, dashboard, ...authentication } = useSelector(getAuthentication);

  const sectProps: GridProps = {
    item: true,
    xs: 12,
    md: 4,
  };

  return (
    <Grid container direction="column" alignItems="center">
      {/* ProfilePreview */}
      <ProfilePanel authentication={authentication} />

      {/* Cards */}
      {role?.includes('Caregiver') ? (
        <HCStudentSect sectProps={sectProps} dashboard={dashboard} />
      ) : role?.includes('Facilitator') ? (
        <HCInstructorSect sectProps={sectProps} />
      ) : (
        <Grid {...sectProps}>No dashboard found for user</Grid>
      )}
    </Grid>
  );
}

export default HomeContainer;
