import axios, { AxiosResponse } from 'axios';
import { LEARNER_STEPS_URL } from 'src/envvars';
import lmsService from 'src/store/api/LMSService';
import { LearnerSteps } from 'src/types';
import { arrayToObject } from 'src/utils';
import AuthService from '../api/AuthService';
import config from '../config';
import defaultState from '../defaultState';
import { makeActionCreator } from '../makeActionCreator';
import * as authSelectors from './selectors';
import { getVerticalProgression } from './selectors';

/* ACTION TYPES */
const REQUEST_AUTH = 'authentication/REQUEST_AUTH';
const AUTH_FAILED = 'authentication/AUTH_FAILED';
const AUTH_SUCCESS = 'authentication/AUTH_SUCCESS';
const DASHBOARD_LOAD_SUCCESS = 'authentication/DASHBOARD_LOAD_SUCCESS';
const DASHBOARD_LOAD_FAILED = 'authentication/DASHBOARD_LOAD_FAILED';
const TRAININGS_LOAD_SUCCESS = 'authentication/TRAININGS_LOAD_SUCCESS';
const TRAININGS_LOAD_FAILED = 'authentication/TRAININGS_LOAD_FAILED';
const LEARNER_STEPS_LOADED = 'learnersteps/LEARNER_STEPS_LOADED';

export const learnerStepsLoaded = makeActionCreator(
  LEARNER_STEPS_LOADED,
  'steps'
);

/* ACTIONS */
export const requestAuth = makeActionCreator(
  REQUEST_AUTH,
  'query',
  'onSuccess',
  'onError'
);
export const authSuccess = makeActionCreator(AUTH_SUCCESS, 'response');
export const authFailed = makeActionCreator(AUTH_FAILED, 'error');
export const dashboardLoadSuccess = makeActionCreator(
  DASHBOARD_LOAD_SUCCESS,
  'response'
);
export const dashboardLoadFailed = makeActionCreator(
  DASHBOARD_LOAD_FAILED,
  'error'
);
export const trainingsLoadSuccess = makeActionCreator(
  TRAININGS_LOAD_SUCCESS,
  'response'
);
export const trainingsLoadFailed = makeActionCreator(
  TRAININGS_LOAD_FAILED,
  'error'
);

/** ASYNC ACTIONS */
export const loadDashboard = (dispatch) => async (learnerid) => {
  try {
    const response = await lmsService.getDashboard(learnerid);
    dispatch(dashboardLoadSuccess(response));
  } catch (error) {
    dispatch(dashboardLoadFailed(error));
  }
};

export const loadTrainings = (dispatch) => async (learnerid) => {
  try {
    const response = await AuthService.getUserTrainings(learnerid);
    const byCourseCodes = arrayToObject(
      response,
      'training_code',
      (element) => {
        // eslint-disable-next-line eqeqeq
        if (element.training_status?.toLowerCase() == 'active') {
          return element;
        }
      }
    );

    const trainings: any = {
      byCourseCodes: byCourseCodes || {},
      codes: Object.keys(byCourseCodes || {}),
    };

    const hasProgression = getVerticalProgression({
      authentication: { trainings },
    });

    if (hasProgression) {
      const stepresponse: AxiosResponse<'data'> = (
        await axios.get(`${LEARNER_STEPS_URL}/${learnerid}`)
      )?.data;

      if (stepresponse) {
        dispatch(
          learnerStepsLoaded(
            arrayToObject(
              stepresponse,
              'vertical',
              (el: LearnerSteps) => el?.step
            )
          )
        );
      }
    }

    dispatch(trainingsLoadSuccess(trainings));
  } catch (error) {
    dispatch(trainingsLoadFailed(error));
  }
};

/* REDUCER */
export default function authReducer(
  state = defaultState.authentication,
  action
) {
  const mutations = {
    /* Success/Failure of dashboard view */
    [DASHBOARD_LOAD_SUCCESS]: (state, action) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        ...action.response,
        status: config.REQUEST_STATUSES.FINISHED,
      },
    }),
    [DASHBOARD_LOAD_FAILED]: (state, action) => ({
      ...state,
      dashboard: {
        ...defaultState.authentication.dashboard,
        error: action.error,
      },
    }),

    [TRAININGS_LOAD_SUCCESS]: (state, action) => ({
      ...state,
      trainings: {
        ...state.trainings,
        ...action.response,
        status: config.REQUEST_STATUSES.FINISHED,
      },
    }),
    [TRAININGS_LOAD_FAILED]: (state, action) => ({
      ...state,
      trainings: {
        ...defaultState.authentication.trainings,
        error: action.error,
      },
    }),

    /* Success/Failure of DEX profile loading */
    [AUTH_FAILED]: (state, action) => ({
      ...defaultState.authentication,
      error: action.error,
    }),
    [AUTH_SUCCESS]: (state, action) => ({
      ...state,
      isLoggedIn: true,
      ...action.response,
    }),
    [LEARNER_STEPS_LOADED]: (state, action) => ({
      ...state,
      learnersteps: action.steps,
    }),
  };

  return mutations[action?.type]?.(state, action) || state;
}

/* FIXME: Add the used selectors into this object and export the object */

export const authentication = {
  authActionTypes: { REQUEST_AUTH, AUTH_FAILED, AUTH_SUCCESS },
  authSelectors,
  authActionCreators: {
    requestAuth,
    authSuccess,
    authFailed,
    dashboardLoadSuccess,
    dashboardLoadFailed,
    trainingsLoadSuccess,
    trainingsLoadFailed,
  },
  loadDashboard,
  loadTrainings,
  authReducer,
};

export { authSelectors };
