import React from 'react';
import list_image_light from 'src/views/styles/assets/icons/list_image-text@2x.png';

export default function ListIcon() {
  return (
    <img
      alt="List Grey"
      src={list_image_light}
      style={{
        // maxWidth: size === 'small' ? 18 : size === 'medium' ? 22 : '100%',
        width: '50%',
      }}
    />
  );
}
