import { mergeDeep } from 'src/utils';
import defaultState from '../defaultState';
import courseCatalogActionTypes from './types';

const {
  SET_COURSES,
  SET_SELECTED_COURSE,
  SET_SELECTED_COURSE_OPTIONS,
  SET_FILTERS_FOR_COURSES,
  SET_FILTERS_FOR_COURSE_OPTIONS,
  COMMIT_FILTERS_FOR_COURSE_OPTIONS,
} = courseCatalogActionTypes;

export default function courseCatalogReducer(
  state = defaultState.coursecatalog,
  action
) {
  const mutations = {
    [SET_COURSES]: (state, action) => {
      return {
        ...state,
        courses: {
          ...state.courses,
          ...action.courses,
        },
      };
    },
    [SET_SELECTED_COURSE]: (state, action) => {
      return {
        ...state,
        selectedCourse: action.selectedCourse,
      };
    },
    [SET_SELECTED_COURSE_OPTIONS]: (state, action) => {
      const newState = { ...state };
      newState.selectedCourse.options = action.options;
      return newState;
    },
    [SET_FILTERS_FOR_COURSES]: (state, action) => {
      return {
        ...state,
        filters: mergeDeep(state.filters, action.filters),
      };
    },
    [COMMIT_FILTERS_FOR_COURSE_OPTIONS]: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          byId: {
            ...state.filters.byId,
            [action.idnumber]: {
              ...state.filters.byId[action.idnumber],
              ...action.filters,
            },
          },
        },
      };
    },
    [SET_FILTERS_FOR_COURSE_OPTIONS]: (state, action) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          byId: {
            ...state.filters.byId,
            [action.idnumber]: {
              ...state.filters.byId[action.idnumber],
              ...action.filters,
            },
          },
        },
      };
    },
  };

  return mutations[action.type]?.(state, action) || state;
}
