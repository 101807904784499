import React from 'react';
import { Grid, DialogActions, DialogActionsProps } from '@material-ui/core';

interface Props extends DialogActionsProps {
  leftControl: React.ReactNode;
  rightControl: React.ReactNode;
  children?: React.ReactNode;
}

const CCFooterSection = ({
  leftControl,
  rightControl,
  children,
  ...rest
}: Props) => {
  if (!leftControl && !rightControl && !children) {
    return null;
  }

  return (
    <DialogActions {...rest}>
      {children}

      <Grid container alignItems="stretch" spacing={1}>
        <Grid item xs container alignItems="center" justify="center">
          {leftControl}
        </Grid>
        <Grid item xs container alignItems="center" justify="center">
          {rightControl}
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default React.memo(CCFooterSection);
