import { makeActionCreator } from '../makeActionCreator';
import dialogActionTypes from './types';

const { CLOSE_DIALOG, OPEN_DIALOG, SET_CONTENT_KEY } = dialogActionTypes;

const dialogActionCreators = {
  openDialog: makeActionCreator(OPEN_DIALOG),
  closeDialog: makeActionCreator(CLOSE_DIALOG),
  setDialogContentKey: makeActionCreator(SET_CONTENT_KEY, 'contentKey'),
};

export default dialogActionCreators;
