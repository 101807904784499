/* eslint-disable react/display-name */
import { Box, Popover } from '@material-ui/core';
import React from 'react';
import config from 'src/store/config';
import theme from 'src/views/components/DSL/theme';
import { Descriptor, Heading1 } from 'src/views/components/DSL/typography';
import CCLanguageFilter from '../components/filters/CCLanguageFilter';
import { DialogContentKeys } from 'src/types';

export type PopoverProp = {
  open: boolean;
  onClose?: any;
  anchorEl?: any;
  contentKey: DialogContentKeys;
};

function CCPopoverContainer({
  open,
  onClose,
  anchorEl,
  contentKey,
}: PopoverProp) {
  const content = {
    GENERAL_FILTERS_LANGUAGE: () => (
      <CCLanguageFilter segWayType={config.SEGWAY_TYPES.POPOVER} />
    ),
  };

  return anchorEl ? (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{ marginTop: 10 }}
      onClose={onClose}
    >
      {/* Wrapper helps control width of popover */}
      {open && (
        <Box
          style={{
            minWidth: 350,
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 6,
          }}
        >
          {content[contentKey]?.({
            segWayType: config.SEGWAY_TYPES.POPOVER,
            onClose,
          }) || (
            <Heading1 style={{ padding: 30, fontSize: 25 }}>
              404 - Content not found
              <Descriptor>
                {'\n'}Check the code in CCFilterPopoverContainer
              </Descriptor>
            </Heading1>
          )}
        </Box>
      )}
    </Popover>
  ) : null;
}

export default React.memo(CCPopoverContainer);
