import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { createMuiTheme } from '@material-ui/core/styles';

const palette: PaletteOptions = {
  primary: {
    light: '#ebe1fb',
    main: '#9880c0',
    dark: '#351f65',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ccecf3',
    main: '#00a7cc',
    dark: '#0a8fad',
    contrastText: '#fff',
  },
  error: {
    main: '#e65858',
  },
  background: {
    default: '#eff1f3',
  },
};

export default createMuiTheme({
  palette,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'Roboto', 'Roboto Slab', 'Lato'].join(','),
    fontSize: 14,
  },
});
