import React from 'react';
import { withRouter } from 'react-router-dom';
import { RetirementLogo } from 'src/views/components/DSL/Icons';
import GeneralPreview from './GeneralPreview';

function RetirementPreview() {
  return (
    <GeneralPreview
      title="Secure Retirement"
      subheader="Learn about the retirement benefit, and access My Plan"
      avatar={<RetirementLogo size="medium" />}
    />
  );
}

export default withRouter(React.memo(RetirementPreview));
