import React from 'react';
import { InputDateRange } from 'src/views/components/DSL/form';
import moment from 'moment';

export default function DateFilter({ formData }) {
  const range = {
    start: moment(formData?.startdate),
    end: moment(formData?.enddate),
    maxdate: moment(formData?.maxdate),
  };

  return (
    <InputDateRange
      onChange={(start, end) => {
        console.log(start, end);
      }}
      {...range}
    />
  );
}
