import React from 'react';
import { ReactComponent as MBDefault } from 'src/views/styles/assets/icons/my-benefits_default.svg';
import { Icon } from '@material-ui/core';
import { IconProps } from './types';

function MBHomeDefault({ fontSize = 'small', ...props }: IconProps) {
  return <Icon component={MBDefault} fontSize={fontSize} {...props} />;
}

export default MBHomeDefault;
