import { Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2, 2, 2),
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props extends GridProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default function PageHeader({ style, children, ...rest }: Props) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={style}
      {...rest}
    >
      <Grid item xs={12} sm={10}>
        {children}
      </Grid>
    </Grid>
  );
}
