const COLORS = {
  COMMON: {
    WHITE: '#fff',
    BLACK: '#000',
  },

  // BRAND, GENERAL EMPHASIS
  PURPLE: {
    100: '#F8F5FE',
    200: '#EBE1FB',
    300: '#8568B4',
    400: '##8568B4',
    500: '#351F65',
  },

  // LINKS, BUTTONS
  BLUE: {
    100: '#EBF8FA',
    200: '#CCECF3',
    300: '#00a2c6',
    400: '#087189',
    500: '#005d72',
  },

  // ATTENTION, CAUTION
  ORANGE: {
    100: '#FFF9E5',
    200: '#FFF1CO',
    300: '#F6BF00',
    400: '#E27200',
    500: '#B95E00',
  },

  // WARNING, ERRORS
  RED: {
    100: '#FDF5F2',
    200: '#F9E2DA',
    300: '#E07652',
    400: '#D33C08',
    500: '#AB3108',
  },

  // SUCCESS, BENEFITS
  GREEN: {
    100: '#F6FBF1',
    200: '#E3F3D3',
    300: '#6EB72E',
    400: '#2A8639',
    500: '#2A8639',
  },

  // BACKGROUNDS / TEXT COLORS
  GREY: {
    100: '#F7F8F9',
    200: '#EFF1F3',
    300: '#E2E5E9',
    400: '#6D737C',
    500: '#5F6772',
    600: '#383C42',
  },
};

export default COLORS;
