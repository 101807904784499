import React from 'react';
import { IconProps } from './types';
import carret_right_grey from 'src/views/styles/assets/icons/caret-right_menu@2x.png';

const CarretRightGrey = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Right Carret Grey"
      src={carret_right_grey}
      style={{ maxWidth: compact ? 10 : '100%' }}
    />
  );
};

export default CarretRightGrey;
