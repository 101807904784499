import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { Heading4 } from '../typography';

interface LoadingPageProps {
  message?: string;
  children?: React.ReactNode;
}

export default function LoadingPage(props: LoadingPageProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
      p={2}
    >
      <CircularProgress
        color="primary"
        variant="indeterminate"
        style={{ marginBottom: 8 }}
      />

      <Heading4 gutterBottom>{props?.message || 'Loading...'}</Heading4>

      {props.children}
    </Box>
  );
}
