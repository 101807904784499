import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

const Overline = ({
  children,
  gutterBottom,
  noWrap,
  display,
  align,
  style,
  color,
  ...rest
}: TypographyProps) => {
  return (
    <Typography
      variant="overline"
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      display={display}
      style={style}
      color={color}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default React.memo(Overline);
