import React from 'react';
import { Grid, Avatar } from '@material-ui/core';
import Notifications from './Notifications';
import {
  Subheading,
  Descriptor,
  Heading1,
} from 'src/views/components/DSL/typography';
import { isXS } from 'src/utils';
import { theme } from 'src/views/components/DSL';
import { Authentication } from 'src/types';

function PPUserPreview({ fullname, username, role }) {
  return (
    <Grid item xs container alignItems="center" justify="flex-start">
      <Avatar
        alt={`First Last`}
        style={{
          marginRight: 13,
          cursor: 'pointer',
          backgroundColor:
            role === 'instructor'
              ? theme.palette.secondary.main
              : theme.palette.success.main,
        }}
      >
        {fullname ? fullname.charAt(0).toUpperCase() : 'U'}
      </Avatar>

      <Grid item xs sm container direction="column" zeroMinWidth>
        <Subheading>{fullname}</Subheading>
        <Descriptor noWrap>{username}</Descriptor>
      </Grid>
    </Grid>
  );
}

function ProfilePanel({ authentication }: { authentication: Authentication }) {
  // prop consolidation
  const role = authentication.role;
  const fullname =
    authentication?.firstName && authentication?.lastName
      ? `${authentication?.firstName} ${authentication?.lastName}`
      : 'No Full Name';
  const username =
    authentication?.email || authentication?.userName || 'No User Name';

  return (
    <Grid
      item
      container
      alignItems="center"
      style={{
        backgroundColor: '#fff',
        padding: '10px 20px',
        borderBottom: '1px solid #ddd',
        marginBottom: isXS() ? 15 : 20,
      }}
    >
      <PPUserPreview role={role} fullname={fullname} username={username} />

      {role?.includes('Caregiver') && <Notifications count={1} />}

      {role?.includes('Facilitator') && (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify="flex-start"
          style={{ padding: `${isXS() ? 8 : 15}px 0px` }}
        >
          <Heading1>Instructor View</Heading1>
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(ProfilePanel);
