import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Hidden, Typography } from '@material-ui/core';
import carret_left_blue from 'src/views/styles/assets/icons/carret-left_blue@2x.png';
import ScheduleHeader from '../components/ScheduleHeader';
import ScheduleGridHeader from '../components/ScheduleGridHeader';
import ScheduleGrid from '../components/ScheduleGrid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ScheduleFooter from '../components/ScheduleFooter';
import { useSelector } from 'src/views/providers';
import { getAuthentication } from 'src/store/authentication/selectors';
import { useRouter } from 'src/utils';
import { getEnrolledCoursesAsArray } from 'src/store/enrolledCourses/selectors';
import SPageHeader from '../components/SPageHeader';
import { PageContent } from 'src/views/components/Layouts';
import { ScheduleLearnerInfoProps } from '../components/SPdfHeader';
import SPdf from '../components/SPdf';
import { EnrollmentScheduleProps } from 'src/types';
import moment from 'moment';
import { useBreakpoint } from 'src/views/components/DSL/theme';

function ScheduleContainer() {
  const isMobile = useBreakpoint('xs');
  const { learnerId, firstName, lastName } = useSelector(getAuthentication);
  const enrolled: EnrollmentScheduleProps = useSelector(
    getEnrolledCoursesAsArray
  );
  // console.log('ScheduleContainer: ', enrolled);
  const learnerInfo: ScheduleLearnerInfoProps = {
    learnerId: learnerId,
    learnerName: `${firstName} ${lastName}`,
  };

  const download = async (learnerName) => {
    try {
      const canvas = await html2canvas(document.getElementById('sched'), {
        scale: 3,
      });
      if (canvas) {
        console.log(canvas);
      }

      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('l', 'mm', 'letter');

      const imgWidth = 279.4;
      const pageHeight = pdf.internal.pageSize.height; // 215.9

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0; //top padding
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 10) {
        position = heightLeft - imgHeight;
        pdf.addPage();

        pdf.addImage(imgData, 'PNG', 0, position + 10, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        console.log('inside:', heightLeft, position);
      }

      pdf.setProperties({
        title: `${learnerName}_Class_Schedule_${moment().format('YYYYMMDD')}`,
        author: 'SEIU 775 Benefits Group',
      });

      pdf.save(`${learnerName}_Class_Schedule_${moment().format('YYYYMMDD')}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <SPageHeader
        downlaod={() => download(learnerInfo.learnerName)}
        disabled={!learnerId}
      />

      <PageContent
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          overflowX: isMobile ? 'scroll' : 'hidden',
        }}
      >
        <SPdf id={'sched'} learnerInfo={learnerInfo} enrolled={enrolled} />
      </PageContent>
    </div>
  );
}

export default ScheduleContainer;
