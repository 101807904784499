import { Grid, makeStyles, TableFooter } from '@material-ui/core';
import React from 'react';
import { DATE_FORMAT } from 'src/consts';
import { Overline } from 'src/views/components/DSL/typography';
import FooterSprite from './FooterSprite.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 1, 2),
  },
  bgLogo: {
    maxHeight: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  displayCompact: { fontSize: theme.spacing(2) },
  legend: {
    fontSize: 7,
  },
}));

export default function TCPdfFooter() {
  const classes = useStyles();
  return (
    <TableFooter
      id="transcript_footer"
      component={Grid}
      container
      className={classes.root}
    >
      <img
        src={FooterSprite}
        alt="Static version of footer content"
        style={{ maxWidth: '100%', scale: 5 }}
      />
      <Overline noWrap style={{ fontSize: 7, letterSpacing: 0 }}>
        THE SEIU 775 BENEFITS GROUP INCLUDES THE SEIU NW TRAINING PARTNERSHIP A
        NONPROFIT 501(c)(3) SCHOOL FOR CAREGIVERS
      </Overline>

      <Overline noWrap style={{ fontSize: 8, letterSpacing: 0, lineHeight: 1 }}>
        {moment().format(DATE_FORMAT)}
      </Overline>
    </TableFooter>
  );
}
