import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { useRouter } from 'src/utils';
import { useBreakpoint } from 'src/views/components/DSL/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
  },
}));

export default function MCTopNav() {
  const classes = useStyles();
  const isMobile = useBreakpoint('sm');
  const router = useRouter();

  return (
    <Tabs
      indicatorColor="primary"
      className={classes.root}
      variant={isMobile ? 'fullWidth' : 'standard'}
      value={router.pathname || '/mycourses/enrolled'}
    >
      <Tab
        label="Enrolled"
        value="/mycourses/enrolled"
        onClick={() => router.push('/mycourses/enrolled')}
      />
      <Tab
        label="Completed"
        value="/mycourses/completed"
        onClick={() => router.push('/mycourses/completed')}
      />
    </Tabs>
  );
}
