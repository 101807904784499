import BREAKPOINTS from './breakpoints';

export const mediaSmUp = () =>
  `@media (min-width:${BREAKPOINTS.values['sm']}px)`;

export const mediaMdUp = () =>
  `@media (min-width:${BREAKPOINTS.values['md']}px)`;

export const mediaLgUp = () =>
  `@media (min-width:${BREAKPOINTS.values['lg']}px)`;

export const mediaXlUp = () =>
  `@media (min-width:${BREAKPOINTS.values['xl']}px)`;
