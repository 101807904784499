import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from 'src/consts';
import { theme } from 'src/views/components/DSL';

interface CertInfoProps {
  courseId?: string;
  program?: string;
  date?: string;
  units?: string;
}

interface CreateDataProps {
  name: 'Completion Date' | 'Program' | 'Type' | 'Units' | '';
  value: string;
}

function createData({ name = '', value = 'N/A' }: CreateDataProps) {
  return { name, value };
}

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  tableCell: {
    flexWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
  },
});

export default function CertInfoCard(props: CertInfoProps) {
  const classes = useStyles();

  const courseId = props?.courseId || '';
  const modality = courseId.includes('ILT')
    ? 'In Person'
    : courseId.includes('ONL')
    ? 'Online'
    : courseId.includes('WEB')
    ? 'Webinar'
    : 'Unknown';

  const rows = [
    createData({
      name: 'Completion Date',
      value: props?.date && moment(props.date).format(DATE_FORMAT),
    }),
    createData({ name: 'Program', value: props?.program }),
    createData({
      name: 'Type',
      value: props?.courseId?.includes('ONL') ? 'Online' : modality,
    }),
    createData({ name: 'Units', value: props?.units }),
  ];

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined">
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} color="success">
              <strong style={{ color: theme.palette.success.main }}>
                {rows[0].name}
              </strong>
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {rows[0].value}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.slice(1, rows.length).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
