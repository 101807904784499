import {
  BT30_SKILLS,
  BT_MANDATORY,
  TRAINING_CODE,
  TRAINING_STATUS,
} from 'src/consts';

// checks to see if the learner completed or is enrolled in mandatory courses
export const checkMandatoryCourses = (
  transcript,
  enrolledCourses,
  selectedCourse
) => {
  const completed = [];
  let mandatoryHours = 0;
  // check transcript
  for (let i = 0; i < transcript.length; i++) {
    switch (true) {
      case transcript[i].courseId &&
        (transcript[i].courseId.includes(BT_MANDATORY.CAREGIVER_CLIENT_EXP) ||
          transcript[i].courseId.includes(
            BT_MANDATORY.CAREGIVER_CLIENT_EXP_LEGACY_203
          ) ||
          transcript[i].courseId.includes(
            BT_MANDATORY.CAREGIVER_CLIENT_EXP_LEGACY_300
          )): // 1hr
        mandatoryHours += 1;
        break;
      case transcript[i].dshsId &&
        transcript[i].dshsId === BT_MANDATORY.PERSON_CENTERED_APPROACH_DSHSID: // 1hr
        mandatoryHours += 1;
        break;
      case transcript[i].dshsId &&
        transcript[i].dshsId ===
          BT_MANDATORY.RECOGNIZING_REPORTING_CONSUMER_ABUSE_DSHSID: // 1hr
        mandatoryHours += 1;
        break;
      case transcript[i].dshsId &&
        transcript[i].dshsId ===
          BT_MANDATORY.REDUCING_THE_SPREAD_OF_INFECTION_DSHSID: // 1hr
        mandatoryHours += 1;
        break;
      case transcript[i].dshsId &&
        transcript[i].dshsId === BT_MANDATORY.MEDICATION_ASSISTANCE_DSHSID: // 2hr
        mandatoryHours += 2;
        break;
      case transcript[i].courseId &&
        transcript[i].courseId.includes(BT30_SKILLS.PREP): //1hr
        mandatoryHours += 1;
        break;
      case transcript[i].courseId &&
        transcript[i].courseId.includes(BT30_SKILLS.MOBILITY): //3hr
        mandatoryHours += 3;
        break;
      case transcript[i].courseId &&
        transcript[i].courseId.includes(BT30_SKILLS.PERSONALCARE): // 3hr
        mandatoryHours += 3;
        break;
      default:
        break;
    }
  }

  // check enrolled
  for (let j = 0; j < enrolledCourses.length; j++) {
    switch (true) {
      case enrolledCourses[j].course.idnumber.includes(
        BT_MANDATORY.CAREGIVER_CLIENT_EXP
      ): // 1hr
        mandatoryHours += 1;
        break;
      case enrolledCourses[j].course.idnumber.includes(
        BT_MANDATORY.PERSON_CENTERED_APPROACH
      ): // 1hr
        mandatoryHours += 1;
        break;
      case enrolledCourses[j].course.idnumber.includes(
        BT_MANDATORY.RECOGNIZING_REPORTING_CONSUMER_ABUSE
      ): // 1hr
        mandatoryHours += 1;
        break;
      case enrolledCourses[j].course.idnumber.includes(
        BT_MANDATORY.REDUCING_THE_SPREAD_OF_INFECTION
      ): // 1hr
        mandatoryHours += 1;
        break;
      case enrolledCourses[j].course.idnumber.includes(
        BT_MANDATORY.MEDICATION_ASSISTANCE
      ): // 2hr
        mandatoryHours += 2;
        break;
      case enrolledCourses[j].course.idnumber.includes(BT30_SKILLS.PREP): //1hr
        mandatoryHours += 1;
        break;
      case enrolledCourses[j].course.idnumber.includes(BT30_SKILLS.MOBILITY): //3hr
        mandatoryHours += 3;
        break;
      case enrolledCourses[j].course.idnumber.includes(
        BT30_SKILLS.PERSONALCARE
      ): // 3hr
        mandatoryHours += 3;
        break;
      default:
        break;
    }
  }

  // check selectedCourse
  if (selectedCourse) {
    switch (true) {
      case selectedCourse.idnumber.includes(BT_MANDATORY.CAREGIVER_CLIENT_EXP): // 1hr
        mandatoryHours += 1;
        break;
      case selectedCourse.idnumber.includes(
        BT_MANDATORY.PERSON_CENTERED_APPROACH
      ): // 1hr
        mandatoryHours += 1;
        break;
      case selectedCourse.idnumber.includes(
        BT_MANDATORY.RECOGNIZING_REPORTING_CONSUMER_ABUSE
      ): // 1hr
        mandatoryHours += 1;
        break;
      case selectedCourse.idnumber.includes(
        BT_MANDATORY.REDUCING_THE_SPREAD_OF_INFECTION
      ): // 1hr
        mandatoryHours += 1;
        break;
      case selectedCourse.idnumber.includes(BT_MANDATORY.MEDICATION_ASSISTANCE): // 2hr
        mandatoryHours += 2;
        break;
      case selectedCourse.idnumber.includes(BT30_SKILLS.PREP): //1hr
        mandatoryHours += 1;
        break;
      case selectedCourse.idnumber.includes(BT30_SKILLS.MOBILITY): //3hr
        mandatoryHours += 3;
        break;
      case selectedCourse.idnumber.includes(BT30_SKILLS.PERSONALCARE): // 3hr
        mandatoryHours += 3;
        break;
      default:
        break;
    }
  }
  return mandatoryHours;
};

// returns message if enrollment should NOT be completed
export const progressCalculator = (
  dashboard,
  transcript,
  selectedCourse,
  enrolledCourses
) => {
  // console.log(
  //   '[progressCalculator] transcript, selectedCourse, enrolledCourse: ',
  //   transcript,
  //   selectedCourse,
  //   enrolledCourses
  // );
  const currentReq = dashboard?.inProgress?.[0];
  // hasRoom variable is the number of hours left to complete the current training requirement
  const hasRoom =
    currentReq?.requiredHours -
    (currentReq?.earnedHours + currentReq?.enrolledHours);

  if (hasRoom < selectedCourse?.credit_hours) {
    return `Unable to enroll: exceeded credit/hour limit.
        Please select a different course that has ${hasRoom} credit/hour`;
  }

  // for BT9 or BT30 leaners, check that they still have room to take core or electives
  // if not, enrollment process will be prevented with a message
  if (
    (currentReq.trainingCode === TRAINING_CODE.BT_9.CODE ||
      currentReq.trainingCode === TRAINING_CODE.BT_30.CODE) &&
    currentReq.trainingStatus === TRAINING_STATUS.ACTIVE
  ) {
    const hoursCoreCourses =
      currentReq.trainingCode === TRAINING_CODE.BT_30.CODE ? 10 : 4;

    // check to see if the learner completed the core classes or has signed up for the core classes
    const hoursCoreCoursesCompleted = checkMandatoryCourses(
      transcript,
      enrolledCourses,
      selectedCourse
    );
    // console.log('hoursCoreCoursesCompleted: ', hoursCoreCoursesCompleted);
    // console.log('hoursCoreCourses: ', hoursCoreCourses);
    // console.log('hasRoom: ', hasRoom);
    // console.log(
    //   'line 220 check: ',
    //   selectedCourse?.credit_hours +
    //     hoursCoreCourses -
    //     hoursCoreCoursesCompleted,
    //   hoursCoreCourses - hoursCoreCoursesCompleted
    // );
    // make sure the learner has enough hours to take the selectedCourse and any missing core courses
    if (hoursCoreCoursesCompleted < hoursCoreCourses) {
      if (hasRoom < hoursCoreCourses - hoursCoreCoursesCompleted) {
        return `Unable to enroll: must take all mandatory course(s).
          You still need ${
            hoursCoreCourses - hoursCoreCoursesCompleted
          } hours of mandatory course(s)`;
      }
    }

    // if the learner has enougth core hours, then make sure they don't go over the limit
    // NOTE: change the operator to >= after testing
    if (hoursCoreCoursesCompleted === hoursCoreCourses) {
      if (hasRoom < selectedCourse?.credit_hours) {
        return `Unable to enroll: exceeded credit/hour limit.
        Please select a different course that has ${hasRoom} credit/hour`;
      }
    }
    return '';
  }
};
