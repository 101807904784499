/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, List, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { getAuthentication } from 'src/store/authentication/selectors';
import { CourseCompletionsEntity, TranscriptStore } from 'src/types';
import { ButtonText } from 'src/views/components/DSL/buttons';
import theme, { useBreakpoint } from 'src/views/components/DSL/theme';
import { DescriptorError } from 'src/views/components/DSL/typography';
import EmptyResults from 'src/views/pages/shared/Filters/EmptyResults';
import { useSelector } from 'src/views/providers';
import CompletedCourse from './CompletedCourse';
import MCSideNav from './MCSideNav';

function getYears(courses: CourseCompletionsEntity[]): string[] {
  return courses.map((c) => moment(c.completed_date).get('y').toString());
}

/*
  courses.forEach((c: CourseCompletionsEntity) => {
    const idnumber: string = c.courseId.toString();

    const vert: string = getVerticalFromCourseId(idnumber);
    const cnum: string = getCNumFromCourseId(idnumber);

    console.log(vert, cnum);
  });
*/

export default function MCCompleted({ error, byIdNumbers }: TranscriptStore) {
  const isMobile = useBreakpoint('xs');
  const { firstName, lastName } = useSelector(getAuthentication);
  const courses: CourseCompletionsEntity[] = Object.values(byIdNumbers);
  const options = ['All', ...Array.from(new Set(getYears(courses)))];
  const [year, setYear] = useState('All');

  const handleClick = (event: {
    currentTarget: {
      dataset: {
        value: any;
      };
    };
  }) => {
    const { value } = event.currentTarget.dataset;
    setYear(value); // --> year value as string
  };

  return (
    <Grid container>
      {/* Desktop Filter */}

      <MCSideNav
        title="Completion Year"
        handleSelect={handleClick}
        selection={year}
        options={options}
      />

      <Grid
        item
        xs={12}
        md={9}
        style={{ paddingLeft: isMobile ? 0 : theme.spacing(2) }}
      >
        {/* Filter */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {courses?.length} Course{`${courses?.length !== 1 ? 's' : ''}`}
          <Typography style={{ fontWeight: 700 }} variant="body2">
            Sort by{' '}
            <ButtonText capitalize disabled>
              Completion Date
            </ButtonText>
          </Typography>
        </Box>

        {/* List */}
        {!error && <DescriptorError gutterBottom>{error}</DescriptorError>}

        {courses?.length ? (
          <List>
            {courses.map((course: CourseCompletionsEntity) => (
              <CompletedCourse
                key={course.courseId}
                completed={course.completed_date}
                name={course.name}
                src={course.image}
                certLink={`/certificates?${new URLSearchParams({
                  firstname: firstName,
                  lastname: lastName,
                  program: course?.courseId?.toString().slice(0, 3),
                  units: '' + course.units + ' hours',
                  courseId: '' + course.courseId,
                  date: course.completed_date,
                }).toString()}`}
              />
            ))}
          </List>
        ) : (
          <EmptyResults text="You don’t have any completed courses. When you complete courses, you will see the course and certificates here." />
        )}
      </Grid>
    </Grid>
  );
}
