import { Box } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { getAuthentication } from 'src/store/authentication/selectors';
import { InputEmail } from 'src/views/components/DSL/form';
import { Body } from 'src/views/components/DSL/typography';
import { emailRegEx } from 'src/views/pages/Onboarding/containers/OnboardingContainer';
import { useSelector } from 'src/views/providers';

type Inputs = {
  email: string;
  confirmEmail: string;
};

export default function EditEmail() {
  const { email } = useSelector(getAuthentication);
  const { register, errors, watch } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      email,
      confirmEmail: '',
    },
  });

  return (
    <>
      <InputEmail
        name="email"
        placeholder="Enter your email address"
        label="EMAIL ADDRESS"
        error={errors?.email?.message}
        inputRef={register({
          pattern: {
            value: emailRegEx,
            message: 'Invalid email address',
          },
        })}
      />

      <Box p={2} bgcolor="#f7f8f9" my={1} borderRadius="8px">
        <Body gutterBottom style={{ fontWeight: 'bold' }}>
          Important information about changing to a new email address:
        </Body>
        <ul>
          <li>
            This email address is your login email address for My Benefits.
          </li>
          <li>
            Information about learning, health or retirement will be sent to
            this email address (if relevant).
          </li>
        </ul>
      </Box>

      <InputEmail
        name="confirmEmail"
        placeholder="Re-enter email address"
        label="CONFIRM EMAIL ADDRESS"
        error={errors?.confirmEmail?.message}
        inputRef={register({
          validate: (value) =>
            value === watch('email') || 'Email addresses dont match',
        })}
      />
    </>
  );
}
