import { Grid, makeStyles, TableFooter } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 1, 2),
  },
  bgLogo: {
    maxHeight: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  displayCompact: { fontSize: theme.spacing(2) },
  legend: {
    fontSize: 7,
  },
}));

export default function SPdfFooter() {
  const classes = useStyles();
  return (
    <TableFooter className={classes.root}>
      <Grid
        container
        direction="column"
        style={{
          fontSize: '0.6em',
        }}
      >
        <p>
          <strong
            style={{
              fontStyle: 'italic',
            }}
          >
            *Schedule is subject to change.
          </strong>
        </p>

        <h3 style={{ fontFamily: 'Lato' }}>
          Thank you for registering, here are a few tips to help you access
          training.
        </h3>

        <p>
          <strong>Be Prepared for Class</strong> <br />
          Be prepared! Bring a photo ID, a pen, and a snack. Check your schedule
          in My Benefits prior to your class to ensure you have the most
          up-to-date information. Make sure you are on time! Our instructors
          reserve the right to not allow late arrivals.
        </p>

        <p>
          <strong>Sign in and out of the Roster</strong> <br />
          Please remember to sign in and out of the roster at the beginning and
          the end of class. Signatures are required to receive class credit.
        </p>

        <p>
          <strong>
            Only Registered Students and Interpreters are Allowed in Class
          </strong>{' '}
          <br />
          Only registered students and pre-approved interpreters are allowed in
          class. Students may not bring Consumers, children, or any other
          visitors to class unless previously arranged.
        </p>

        <p>
          <strong>Class Cancellations</strong> <br />
          If you need to cancel class registration you must do so at least 72
          hours in advance of the class time. For a list of class cancellations,
          please visit https://www.myseiubenefits.org/class-cancellation-policy/
        </p>
        {/* <Overline
          noWrap
          style={{ fontSize: 8, letterSpacing: 0, lineHeight: 1 }}
        >
          {moment().format(DATE_FORMAT)} Page 1 of 1
        </Overline> */}
      </Grid>
    </TableFooter>
  );
}
