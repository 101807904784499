import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    // TODO: move to dsl
    padding: `10px 15px`,
  },
}));

function PageContent({ style = null, children, ...rest }: GridProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      style={style}
      {...rest}
    >
      <Grid item xs={12} sm={10}>
        {children}
      </Grid>
    </Grid>
  );
}

export default PageContent;
