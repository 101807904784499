import { Grid } from '@material-ui/core';
import React from 'react';
import { isXS, useRouter } from 'src/utils';
import {
  Back,
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import { Heading1 } from 'src/views/components/DSL/typography';
import PageHeader from 'src/views/components/Layouts/PageHeader';

export default function CLCHeader() {
  const { history } = useRouter();
  const isMobile = isXS();

  const toMyCourses = () => history.push(`/mycourses/enrolled?refresh=true`);
  const toCatalog = () => history.push(`/coursecatalog?refresh=true`);

  return isMobile ? (
    <PageHeader>
      <Back to={'/'}>Home</Back>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Heading1 gutterBottom>Caregiver Learning Center</Heading1>
        <Grid
          item
          xs={isMobile ? 12 : 5}
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <ButtonSecondary fullWidth onClick={toCatalog} capitalize>
            Enroll
          </ButtonSecondary>
          <ButtonPrimary
            fullWidth
            noWrap
            onClick={toMyCourses}
            capitalize
            gutterLeft
          >
            My Courses
          </ButtonPrimary>
        </Grid>
      </Grid>
    </PageHeader>
  ) : (
    <PageHeader>
      <Back to={'/'}>Home</Back>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Heading1 gutterBottom>Caregiver Learning Center</Heading1>
        <Grid
          item
          xs={isMobile ? true : 5}
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <ButtonSecondary fullWidth onClick={toCatalog} capitalize>
            Enroll
          </ButtonSecondary>
          <ButtonPrimary
            fullWidth
            noWrap
            onClick={toMyCourses}
            capitalize
            gutterLeft
          >
            My Courses
          </ButtonPrimary>
        </Grid>
      </Grid>
    </PageHeader>
  );
}
