/* eslint-disable react/display-name */
import { IconButton, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { theme } from 'src/views/components/DSL';
import {
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import { Heading4 } from 'src/views/components/DSL/typography';
import EditAddress from './EditAddress';
import EditCell from './EditCell';
import EditEmail from './EditEmail';
import EditHome from './EditHome';
import FluentLangs from './FluentLangs';
import PrefLangs from './PrefLangs';

const titles = {
  email: 'Email',
  homePhone: 'Home Phone',
  cellPhone: 'Cell Phone',
  address: 'Address',
  preferredLanguage: 'Preferred Language',
  languagesFluentIn: 'Language(s) Fluent In',
};

const FormContent = {
  email: () => <EditEmail />,
  cellPhone: () => <EditCell />,
  homePhone: () => <EditHome />,
  languagesFluentIn: () => <FluentLangs />,
  preferredLanguage: () => <PrefLangs />,
  address: () => <EditAddress />,
};

export default function EditDialog({ open, contentKey, handleClose }) {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={fullScreen ? 'xs' : 'sm'}
      fullWidth
      open={open && typeof FormContent[contentKey] === 'function'}
      scroll="paper"
    >
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Heading4>{titles?.[contentKey]}</Heading4>
        <IconButton size="small" edge="end" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{FormContent[contentKey]?.()}</DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={handleClose} capitalize>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary onClick={handleClose}>Save</ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}
