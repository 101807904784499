import { TableBody, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer, {
  TableContainerProps,
} from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment';
import React from 'react';
import { EnrollmentScheduleProps } from 'src/types';
import { COLORS } from 'src/views/components/DSL';
import { Subheading } from 'src/views/components/DSL/typography';
import SPdfFooter from './SPdfFooter';
import SPdfHeader, { ScheduleLearnerInfoProps } from './SPdfHeader';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 800,
    // height: 612,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  table: {
    flex: 1,
    maxWidth: '100%',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
    // flex: 1,
    '& > *': {
      fontSize: 10,
      textOverflow: 'ellipsis',
    },
  },
  tableRow: {
    borderBottom: '1px solid #000',
    '& > *': {
      textOverflow: 'ellipsis',
      fontSize: 8,
    },
  },
  tableCell: {
    fontSize: 7,
  },
}));

export type SDOMID = 'sched';
interface SPdfProps extends TableContainerProps {
  id: SDOMID;
  learnerInfo: ScheduleLearnerInfoProps;
  enrolled: EnrollmentScheduleProps;
}

function SPdf({ id, learnerInfo, enrolled }: SPdfProps) {
  const classes = useStyles();
  // console.log('Spdf: ', enrolled);
  const BT70idNum = '2010421'; // module 1 of BT70
  return (
    <TableContainer
      id={id}
      className={classes.paper}
      component={Paper}
      variant="outlined"
      square
      // style={{ width: '100%' }}
    >
      {/* HEADER */}
      <SPdfHeader {...learnerInfo} />

      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.tableHead} component="th">
          <TableCell align="left" colSpan={1}>
            CLASS
          </TableCell>
          <TableCell align="left" colSpan={1}>
            DATE
          </TableCell>
          <TableCell align="left" colSpan={1}>
            TIME
          </TableCell>
          <TableCell align="left" colSpan={2}>
            LOCATION
          </TableCell>
          <TableCell align="right" colSpan={1}>
            LANG
          </TableCell>
        </TableHead>
        <TableBody component="tbody">
          {enrolled.map(
            (row, i) =>
              row &&
              row.session && (
                <TableRow className={classes.tableRow} key={row.name + '' + i}>
                  <TableCell align="left" colSpan={1}>
                    <Subheading
                      style={{
                        fontSize: '1.2em',
                      }}
                    >
                      {row.course.idnumber.includes(BT70idNum) ? (
                        <>
                          Union Time <br /> {row.course.fullname}
                        </>
                      ) : (
                        row.course.fullname
                      )}
                    </Subheading>
                  </TableCell>
                  <TableCell align="left" colSpan={1}>
                    <Subheading
                      style={{
                        fontSize: '1.2em',
                      }}
                    >
                      {moment
                        .unix(row?.session?.[0]?.['coursestarttime'])
                        .format('dddd')}
                      <br />
                      {moment
                        .unix(row?.session?.[0]?.['coursestarttime'])
                        .format('M/D/YYYY')}
                    </Subheading>
                  </TableCell>
                  <TableCell align="left" colSpan={1}>
                    <Subheading
                      style={{
                        fontSize: '1.2em',
                      }}
                    >
                      {row.course.idnumber.includes(BT70idNum)
                        ? `${moment
                            .unix(row?.session?.[0]?.['coursestarttime'])
                            .subtract(30, 'minutes')
                            .format('h:mma')} - ${moment
                            .unix(row?.session?.[0]?.['coursestarttime'])
                            .format('h:mma')}, 
                            ${moment
                              .unix(row?.session?.[0]?.['coursestarttime'])
                              .format('h:mma')} - ${moment
                            .unix(row?.session?.[0]?.['courseendtime'])
                            .format('h:mma')}`
                        : `${moment
                            .unix(row?.session?.[0]?.['coursestarttime'])
                            .format('h:mma')} - ${moment
                            .unix(row?.session?.[0]?.['courseendtime'])
                            .format('h:mma')}`}
                    </Subheading>
                  </TableCell>
                  <TableCell align="left" colSpan={2}>
                    <Subheading
                      style={{
                        fontSize: '1.2em',
                      }}
                    >
                      {row?.session?.[0]?.['institution'] !== 'Live Webinar' ? (
                        <>
                          {row?.session?.[0]?.['institution'].replace(
                            /,/g,
                            ', '
                          )}
                          <br />
                          {row?.session?.[0]?.['address'].replace(/,/g, ', ')}
                        </>
                      ) : (
                        <a
                          style={{
                            color: COLORS.BLUE[400],
                            textDecoration: 'none',
                          }}
                          href={row?.session?.[0]?.['shorturl']}
                        >
                          {row?.session?.[0]?.['shorturl']}
                        </a>
                      )}
                    </Subheading>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <Subheading
                      style={{ fontSize: '1.2em', textAlign: 'right' }}
                    >
                      {row.course.lang || ''}
                    </Subheading>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>

      {/* FOOTER */}

      {enrolled.some((el) => el.session) ? (
        <SPdfFooter />
      ) : (
        <Subheading>
          {'There is no upcoming in-person or webinar courses'}
        </Subheading>
      )}
    </TableContainer>
  );
}

export default React.memo(SPdf);
