import React from 'react';
import darkGreenCheck from 'src/views/styles/assets/icons/check-circle_dark-green.png';
import { IconProps } from './types';

const CheckDarkGreen = ({ compact = false, fontSize = 'small' }: IconProps) => {
  return (
    <img
      alt="darkGreenCheck_img"
      src={darkGreenCheck}
      style={{ maxWidth: compact ? 14 : fontSize === 'small' ? 30 : '100%' }}
    />
  );
};

export default CheckDarkGreen;
