import { Box, capitalize, Grid } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MOODLE_SERVICE_URL, API } from 'src/envvars';
import { capitalizeString, useRouter } from 'src/utils';
import {
  Back,
  ButtonPrimary,
  ButtonSecondary,
} from 'src/views/components/DSL/buttons';
import { DownloadBlue } from 'src/views/components/DSL/Icons';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import { DescriptorError, Heading3 } from 'src/views/components/DSL/typography';
import { PageContent, PageHeader } from 'src/views/components/Layouts';
import CertInfoCard from './components/CertInfoCard';
import CertViewer from './components/CertViewer';

interface CCQuery {
  courseId?: string;
  firstname?: string;
  lastname?: string;
  program?: string;
  date?: string;
  units?: string;
}

export default function CertificatesContainer() {
  const router = useRouter();
  const isMobile = useBreakpoint('xs');
  const query: CCQuery = router.query;

  const [certURL, setCertURL] = useState('');
  const [certURLQueryString, setcertURLQueryString] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    Axios.get(`${MOODLE_SERVICE_URL}/tests/generateHash`, {
      params: query,
    })
      .then((res) => {
        console.log(query, res.data.querystring);
        setcertURLQueryString(res.data.querystring);
      })
      .catch((error) =>
        setError(error.message || 'Unable to load certificate')
      );
  }, [query]);

  useEffect(() => {
    setCertURL(`${API}/generateCertificate${certURLQueryString}`);
  }, [certURLQueryString]);

  return (
    <>
      {/* FIXME: Move to CertificateHeader in /components */}
      <PageHeader>
        <Back to={'/mycourses/completed'}>Completed Courses</Back>
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          justify={isMobile ? 'flex-start' : 'space-between'}
          alignItems={isMobile ? 'flex-start' : 'center'}
        >
          <Grid item xs>
            <Heading3 gutterBottom>
              {capitalize(query?.program || 'Course Not Found')}
            </Heading3>
          </Grid>
          <Grid item xs={isMobile ? true : 4} container alignItems="center">
            <Grid item xs style={{ paddingRight: 5 }}>
              <ButtonSecondary fullWidth capitalize disabled>
                Review
              </ButtonSecondary>
            </Grid>
            <Grid item xs style={{ paddingLeft: 5 }}>
              <ButtonPrimary
                startIcon={<DownloadBlue />}
                onClick={() => window.open(certURL, '_blank')}
                fullWidth
              >
                Download
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>

      {/* Content */}
      <PageContent>
        {error && <DescriptorError>{error}</DescriptorError>}
        {!error && certURL && (
          <Box mb={2} display="flex" justifyContent="center" width={1}>
            <CertViewer file={certURL} />
          </Box>
        )}
        {!error && certURL && (
          <Box display="flex" justifyContent="center" width={1}>
            <Box
              mb={1}
              display="flex"
              justifyContent="center"
              width={{ xs: 1, md: 8 / 12 }}
            >
              <CertInfoCard
                date={query.date}
                program={capitalizeString(query.program)}
                courseId={query.courseId}
                units={query.units}
              />
            </Box>
          </Box>
        )}
      </PageContent>
    </>
  );
}
