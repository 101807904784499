import { Box, makeStyles, AvatarProps } from '@material-ui/core';
import React from 'react';
import { theme } from '..';

const useStyles = (theme) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      flexWrap: 'nowrap',
      padding: theme.spacing(0, 2),
    },
  }))(theme);

interface BannerProps {
  avatar?: AvatarProps;
  children?: React.ReactNode;
}

export default function Banner(props: BannerProps) {
  const classes = useStyles(theme);

  return (
    <Box className={classes.root}>
      {props.avatar && (
        <Box mr={1.5} display="flex" alignItems="center">
          {props.avatar}
        </Box>
      )}

      {props.children && (
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          {props.children}
        </Box>
      )}
    </Box>
  );
}
