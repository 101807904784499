import React from 'react';
import { isXS } from 'src/utils';
import {
  CarretRightGrey,
  MapMarkerGrey,
  LinksResDefault,
} from 'src/views/components/DSL/Icons';
import {
  IconButton,
  Dialog,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Box,
} from '@material-ui/core';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

interface AddressMapProps {
  address: string;
  institution: string;
  room?: string | any;
  width?: string;
  height?: string;
}

const isMobile = isXS();

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    closeButton: {
      // color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode;
  address: string;
  institution: string;
  room?: string;
  onClose: () => void;
  onRedirectClick: (url: any) => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    classes,
    onClose,
    address,
    institution,
    // room,
    onRedirectClick,
  } = props;

  const redirectUrl = JSON.stringify(address);

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <List style={{ padding: 0 }}>
        <ListItem style={{ padding: 0 }}>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
              <MapMarkerGrey />
            </IconButton>
          ) : null}
          <ListItemText
            primary={
              <Box overflow="hidden" textOverflow="ellipsis" maxWidth="75%">
                {institution}
                {/* <br /> {room} */}
              </Box>
            }
            secondary={address}
          />
        </ListItem>
      </List>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

export default function AddressMap({
  address,
  institution,
}: // room,
AddressMapProps) {
  const [open, setOpen] = React.useState(false);

  const url = `https://www.google.com/maps/embed/v1/search?key=AIzaSyC_56krRcc0GJtFX9efCEFCsyBMG_9912Y&q=${encodeURIComponent(
    address
  )}`;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirectLink = (address) => {
    window.open(
      `https://www.google.com/maps/embed/v1/search?key=AIzaSyC_56krRcc0GJtFX9efCEFCsyBMG_9912Y&q=${encodeURIComponent(
        address
      )}`
    );
  };

  return (
    <>
      <IconButton size="medium" onClick={handleClickOpen}>
        <CarretRightGrey compact />
      </IconButton>
      <Dialog
        fullScreen={isMobile ? true : false}
        open={open}
        style={{ padding: 0 }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          address={address}
          institution={institution}
          // room={room}
          onRedirectClick={handleRedirectLink}
        />
        <DialogContent style={{ padding: 0, overflow: 'hidden' }} dividers>
          <iframe
            title="Map"
            frameBorder="0"
            style={{
              width: isMobile ? '100vw' : '680px',
              height: isMobile ? '100vh' : '680px',
              minWidth: isMobile ? null : '400px',
            }}
            src={url}
            scrolling="no"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
