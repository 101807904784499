import React, { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { MOODLE_SERVICE_URL } from 'src/envvars';
import { useSelector } from 'src/views/providers';
import {
  getIsLoggedIn,
  getLearnerId,
  getStudentEmail,
  getFirstname,
  getLastname,
  getUserDashboard,
} from 'src/store/authentication/selectors';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { Heading3 } from 'src/views/components/DSL/typography';
import { DSLButton } from 'src/views/components/DSL/buttons';
import TEReview from '../components/TEReview';
import TEFailedMessage from '../components/TEFailedMessage';
import TEConfirmed from '../components/TEConfirmed';
import { useBreakpoint } from 'src/views/components/DSL/theme';
import {
  compareCourseSequence,
  getSessionsInStr,
  getBlocksInStr,
  getCoursesFromSessions,
  compareCourseStartDate,
} from '../components/TEutility';
import { LoadingPage, TransitionAlert } from 'src/views/components/DSL/info';

/**
 * Landing page after the learner signs in using Azure redirect userflow
 *
 * @param cache block={blockid},...&&sessions={sessionid1},{sessionid2},,,
 */
function TargetEnrollmentContainer() {
  /* CACHE RELATED */
  const [cache] = useState(JSON.parse(localStorage.getItem('state')));

  /* LOCAL STATE */
  const [isLoading, setIsLoading] = useState(true); // used to determine fetchData is completed or not
  const [sessionsData, setSessionsData] = useState([]); // sessions data returned from fetchData()
  const [sessBlockData, setSessBlocksData] = useState([]); // sessions block data returned from fetchData()

  const [success, setSuccess] = useState(false); // enrollment confirmation success
  const [pending, setPending] = useState(false); // enrollment confirmation in progress
  const [failed, setFailed] = useState(false); // enrolment confirmation failed
  const [error, setError] = useState('');
  const [processingEnroll, setProcessingEnroll] = useState(false);

  /* OTHER VAR */
  const isMobile = useBreakpoint('xs');
  const isTablet = useBreakpoint('md');

  const userLoggedIn = useSelector(getIsLoggedIn);
  const learnerEmail = useSelector(getStudentEmail);
  const learnerId = useSelector(getLearnerId);
  const learnerFirstname = useSelector(getFirstname);
  const learnerLastname = useSelector(getLastname);
  const dashboard = useSelector(getUserDashboard);
  let coursesTotalCredit = 0;

  // grab the data to populate the page
  useEffect(() => {
    async function fetchData(cache) {
      // NOTE: courses get sorted by start date
      const sortCourses = (data) => {
        return data.data ? data.data.sort(compareCourseStartDate) : [];
      };
      const sortCoursesInBlockSess = (data) => {
        return data.length > 1 ? data.sort(compareCourseStartDate) : [];
      };

      if (cache.sessions) {
        const sessionsStr = getSessionsInStr(cache.sessions);
        const configForSessions: AxiosRequestConfig = {
          method: 'get',
          url: `${MOODLE_SERVICE_URL}/sessions/search?${sessionsStr}`,
          headers: {},
          data: '',
        };
        await axios
          .request(configForSessions)
          .then(function (response) {
            // console.log('fetching sessions: ', response.data);
            setSessionsData(sortCourses(response.data));
          })
          .catch(function (error) {
            console.log('error in fetching sessions: ', error);
          });
      }
      if (cache.blocks) {
        const blockStr = getBlocksInStr(cache.blocks);
        const configForSessionBlock: AxiosRequestConfig = {
          method: 'get',
          url: `${MOODLE_SERVICE_URL}/blocks/search?${blockStr}`,
          headers: {},
          data: '',
        };
        await axios
          .request(configForSessionBlock)
          .then((response) => {
            // console.log(response.data.data);
            if (response.data.data.length > 1) {
              let getAllSessionBlockIds = [];
              for (let i = 0; i < response.data.data.length; i++) {
                getAllSessionBlockIds = [
                  ...getAllSessionBlockIds,
                  response.data.data[i].id,
                ];
              }
              const axiosArr = [];
              for (let j = 0; j < getAllSessionBlockIds.length; j++) {
                const newPromise = axios({
                  method: 'get',
                  url: `${MOODLE_SERVICE_URL}/blocks/${getAllSessionBlockIds[j]}/sessions`,
                });
                axiosArr.push(newPromise);
              }

              let axiosRes = [];
              return axios
                .all(axiosArr)
                .then(
                  axios.spread((...responses) => {
                    responses.map(
                      (resp) => (axiosRes = [...axiosRes, resp.data.data[0]])
                    );
                    setSessBlocksData(sortCoursesInBlockSess(axiosRes));
                  })
                )
                .catch(function (error) {
                  console.log(
                    'error in fetching sessions using multiple blocks: ',
                    error
                  );
                });
            } else {
              return axios
                .get(
                  `${MOODLE_SERVICE_URL}/blocks/${response.data.data[0].id}/sessions`
                )
                .then(function (response) {
                  console.log('session block response: ', response);
                  setSessBlocksData(sortCourses(response.data));
                })
                .catch(function (error) {
                  console.log(
                    'error in fetching sessions using block info: ',
                    error
                  );
                });
            }
          })
          .catch(function (error) {
            console.log('error in fetching block info: ', error);
          });
      }
    }
    fetchData(cache);
    setIsLoading(false);
  }, [cache]);

  useEffect(() => {
    //if the learner has taken more than required hours, don't let them take the course
    if (dashboard) {
      const currentReq = dashboard?.inProgress?.[0];
      const hasRoom =
        currentReq?.requiredHours -
        (currentReq?.earnedHours + currentReq?.enrolledHours);
      // console.log(hasRoom, dashboard);
      if (hasRoom === 0 || hasRoom < coursesTotalCredit) {
        setError('Unable to enroll: exceeded credit/hour limit ');
        setProcessingEnroll(true);
      }
    }
  }, [dashboard, coursesTotalCredit]);

  const sessionsForOption =
    sessionsData.length > 0
      ? sessionsData
      : sessBlockData.length > 0
      ? sessBlockData
      : [];
  const selectedCourse = getCoursesFromSessions(sessionsForOption);

  // an array containing objects of ids to be added to query
  const scheduleArrWithIds = [];
  if (selectedCourse && sessionsForOption) {
    // console.log('sessionsForOption to confirm: ', sessionsForOption);
    sessionsForOption.map((sessionItem) => {
      if (sessionItem.course?.id) {
        // console.log('sessionItem: ', sessionItem);
        scheduleArrWithIds.push({
          courseid: sessionItem.course.id,
          sessionid: sessionItem.id,
          blockid: sessionItem.block,
        });
        coursesTotalCredit += sessionItem.course?.credit_hours;
      }
    });
  }

  // constructing the payload to send to the backend
  const query = {
    learner: {
      id: learnerId,
      email: learnerEmail,
      firstname: learnerFirstname,
      lastname: learnerLastname,
    },
    schedule: scheduleArrWithIds,
  };

  /* CHANGE HANDLER */
  const onClickEnrollAndConfirm = async (query) => {
    console.log(query);
    if (!query.schedule || !query.learner.id) {
      setPending(false);
      setFailed(true);
    }

    const reqUrl = `${MOODLE_SERVICE_URL}/registrations/target`;
    if (query.schedule.length < 5) {
      await axios
        .post(reqUrl, query)
        .then(function (response) {
          console.log('onClickEnrollAndConfirm SUCCESS: ', response.data);
          setPending(false);
          setSuccess(!success);
        })
        .catch(function (error) {
          console.log('onClickEnrollAndConfirm FAIL: ', error);
          setPending(false);
          setFailed(true);
        });
    } else {
      // sessions > 5 need to be split into chunks
      const chunked = [];
      for (let i = 0; i < query.schedule.length; i += 4) {
        const chunk = query.schedule.slice(i, i + 4);
        chunked.push(chunk);
      }

      const result = [];
      for (let i = 0; i < chunked.length; i++) {
        await axios
          .post(reqUrl, { learner: query.learner, schedule: chunked[i] })
          .then(function (response) {
            result.push(response.data);
          })
          .catch(function (error) {
            console.log('onClickEnrollAndConfirm FAIL: ', error);
            setPending(false);
            setFailed(true);
          });
      }
      if (result.length === chunked.length) {
        console.log('onClickEnrollAndConfirm SUCCESS: ', result);
        setPending(false);
        setSuccess(true);
      }
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!isLoading && sessionsForOption ? (
        <Dialog open fullScreen style={{ padding: 0 }}>
          {/* Display Confirmation Message */}

          <Grid>{error ? <TransitionAlert message={error} /> : ''}</Grid>
          {success && !failed && (
            <>
              <DialogContent style={{ padding: 0 }}>
                <TEConfirmed />
                {selectedCourse.length > 1 &&
                  sessionsForOption.map((selectedCourse1, index) => {
                    return (
                      <TEReview
                        key={index}
                        selectedCourse={selectedCourse1.course}
                        sessionsForOption={selectedCourse1}
                        courseCount={selectedCourse.length}
                      />
                    );
                  })}
                {selectedCourse.length === 1 &&
                  selectedCourse.map((selectedCourse1, index) => {
                    return (
                      <TEReview
                        key={index}
                        selectedCourse={selectedCourse1}
                        sessionsForOption={sessionsForOption}
                        courseCount={selectedCourse.length}
                      />
                    );
                  })}
              </DialogContent>
            </>
          )}

          {/* Display the assigned courses and sessions */}
          {!success &&
            !failed &&
            (isMobile ? (
              selectedCourse.length > 0 ? (
                // MOBILE VIEW
                <>
                  <DialogTitle>
                    <Heading3>Review Assigned Course</Heading3>
                  </DialogTitle>
                  <DialogContent style={{ padding: 0 }}>
                    {' '}
                    {selectedCourse.length > 1 &&
                      sessionsForOption.map((selectedCourse1, index) => {
                        return (
                          <TEReview
                            key={index}
                            selectedCourse={selectedCourse1.course}
                            sessionsForOption={selectedCourse1}
                            courseCount={selectedCourse.length}
                          />
                        );
                      })}
                    {selectedCourse.length === 1 &&
                      selectedCourse.map((selectedCourse1, index) => {
                        return (
                          <TEReview
                            key={index}
                            selectedCourse={selectedCourse1}
                            sessionsForOption={sessionsForOption}
                            courseCount={selectedCourse.length}
                          />
                        );
                      })}
                  </DialogContent>
                  <DialogActions>
                    <DSLButton
                      fullWidth={isMobile ? true : false}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setPending(true);
                        onClickEnrollAndConfirm(query);
                      }}
                      disabled={processingEnroll || error.length > 0}
                    >
                      {pending ? (
                        <CircularProgress
                          style={{ marginLeft: '5px' }}
                          color="inherit"
                        />
                      ) : (
                        ''
                      )}{' '}
                      {userLoggedIn ? 'Confirm Enrollment' : 'Getting ready'}
                    </DSLButton>
                  </DialogActions>
                </>
              ) : (
                <LoadingPage />
              )
            ) : // DESKTOP VIEW
            selectedCourse.length > 0 ? (
              <>
                <DialogContent style={{ padding: 0 }}>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start"
                    style={{
                      padding: isTablet ? '32px 50px 0px' : '48px 100px 0px',
                      backgroundColor: '#ffff',
                    }}
                  >
                    <Grid>
                      <Heading3>Review Assigned Course</Heading3>
                    </Grid>
                  </Grid>
                  {selectedCourse.length > 1 &&
                    sessionsForOption.map((selectedCourse1, index) => {
                      return (
                        <TEReview
                          key={index}
                          selectedCourse={selectedCourse1.course}
                          sessionsForOption={selectedCourse1}
                          courseCount={selectedCourse.length}
                        />
                      );
                    })}
                  {selectedCourse.length === 1 &&
                    selectedCourse.map((selectedCourse1, index) => {
                      return (
                        <TEReview
                          key={index}
                          selectedCourse={selectedCourse1}
                          sessionsForOption={sessionsForOption}
                          courseCount={selectedCourse.length}
                        />
                      );
                    })}
                  <Grid
                    style={{
                      backgroundColor: '#ffff',
                      padding: isTablet ? '0px 50px 32px' : '0px 100px 48px',
                    }}
                  >
                    <DSLButton
                      fullWidth={isMobile ? true : false}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setPending(true);
                        onClickEnrollAndConfirm(query);
                      }}
                      disabled={processingEnroll || error.length > 0}
                    >
                      {pending ? (
                        <CircularProgress
                          style={{ marginLeft: '5px' }}
                          color="inherit"
                        />
                      ) : (
                        ''
                      )}{' '}
                      {userLoggedIn ? 'Confirm Enrollment' : 'Getting ready'}
                    </DSLButton>
                  </Grid>
                </DialogContent>
              </>
            ) : (
              <LoadingPage />
            ))}

          {/* Display Confirmation Error Message */}
          {failed && !success && <TEFailedMessage />}
        </Dialog>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
export default TargetEnrollmentContainer;
