import React from 'react';
import { IconProps } from './types';
import filter_blue from 'src/views/styles/assets/icons/filter_blue@2x.png';

const FilterDialsBlue = ({ compact = false }: IconProps) => {
  return (
    <img
      alt="Close"
      src={filter_blue}
      style={{ maxWidth: compact ? 14 : '100%' }}
    />
  );
};

export default FilterDialsBlue;
