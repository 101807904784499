import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React from 'react';
import { getAuthentication } from 'src/store/authentication/selectors';
import { getTranscriptsCompletions } from 'src/store/transcripts/selectors';
import { CourseCompletionsEntity } from 'src/types';
import { PageContent } from 'src/views/components/Layouts';
import { useSelector } from 'src/views/providers';
import HiddenTable from './components/HiddenTable';
import TCPageHeader from './components/TCPageHeader';
import TCPdf from './components/TCPdf';
import { LearnerInfoProps } from './components/TCPdfHeader';
import abbySig from './components/Abby_sig.png';
import adamSig from './components/Adam_sig.png';
import seiu775bglogo from 'src/views/styles/assets/seiu-775-bg-logo_color@2x.png';

function TranscriptContainer() {
  const { learnerId, firstName, lastName, dob } = useSelector(
    getAuthentication
  );
  const completions: CourseCompletionsEntity[] = useSelector(
    getTranscriptsCompletions
  );

  const learnerInfo: LearnerInfoProps = {
    learnerId: learnerId,
    learnerDOB: dob,
    learnerName: `${firstName} ${lastName}`,
  };

  const download = async (learnerName) => {
    console.log(completions);
    try {
      const pdf = new jsPDF('p', 'mm', 'letter');
      autoTable(pdf, {
        html: '#pdfhead',
        alternateRowStyles: {
          fillColor: [255, 255, 255],
        },
        didDrawCell: function (data) {
          if (data.column.index === 0 && data.column) {
            pdf.addImage(
              seiu775bglogo,
              'PNG',
              data.cell.x,
              data.cell.y,
              40,
              15
            );
          }
        },
      });
      autoTable(pdf, { html: '#transcript_pdf_table' });
      autoTable(pdf, {
        html: '#pdffoot',
        didDrawCell: function (data) {
          if (data.column.index === 2) {
            pdf.addImage(abbySig, 'PNG', data.cell.x, data.cell.y, 30, 20);
          }
          if (data.column.index === 3) {
            pdf.addImage(adamSig, 'PNG', data.cell.x, data.cell.y, 30, 20);
          }
        },
      });
      autoTable(pdf, {
        html: '#footBar',
        alternateRowStyles: {
          fillColor: [255, 255, 255],
        },
      });
      pdf.save(`${firstName}_${lastName}_SEIU_Transcript.pdf`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TCPageHeader
        downlaod={() => download(learnerInfo.learnerName)}
        disabled={!learnerId}
      />
      <PageContent
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <TCPdf
          id={'tspdf'}
          learnerInfo={learnerInfo}
          completions={completions}
        />
        <HiddenTable
          learnerName={learnerInfo.learnerName}
          learnerDOB={learnerInfo.learnerDOB}
          learnerId={learnerInfo.learnerId}
        />
      </PageContent>
    </>
  );
}

export default TranscriptContainer;
