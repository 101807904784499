import { Box, TextField } from '@material-ui/core';
/* eslint-disable no-use-before-define */
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { LANGUAGES_MB } from 'src/store/consts';
import { useInputStyles } from 'src/views/components/DSL/form';
import { Descriptor } from 'src/views/components/DSL/typography';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const languages = LANGUAGES_MB;

export default function FluentLangs() {
  const classes = useInputStyles();
  const [inputValue, setInputValue] = useState([]);

  const handleChange = (event, newValue) =>
    setInputValue([...inputValue, newValue]);

  return (
    <Box height={600} maxWidth={1}>
      <Descriptor gutterBottom>{`Fluent Language`}</Descriptor>
      <Autocomplete
        multiple
        limitTags={3}
        getLimitTagsText={(num) => `${num} more`}
        open={true}
        options={languages}
        componentName="language"
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        fullWidth
        color="secondary"
        disablePortal
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                className: classes.root,
              }}
              variant="outlined"
              color="secondary"
              placeholder="Fluent Language"
            />
          );
        }}
      />
    </Box>
  );
}
