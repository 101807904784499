import { Grid, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'src/store/config';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { dialogActionCreators } from 'src/store/dialog';
import { DSLButton } from 'src/views/components/DSL/buttons';
import ButtonOutlined from 'src/views/components/DSL/buttons/ButtonOutlined';
import { Heading4 } from 'src/views/components/DSL/typography';
import CCDialogCloseButton from '../components/CCDialogCloseButton';
import CCDialogContent from './CCContentSection';
import CCFooterSection from './CCFooterSection';
import CCTitleSection from './CCTitleSection';
import { getMomentDate } from 'src/utils';
import { DATE_FORMAT } from 'src/consts';

const { setDialogContentKey } = dialogActionCreators;

const {
  getFiltersForSelectedCourse,
  getSelectedCourseFilteredOptions,
} = courseCatalogSelectors;

function CCFiltersOverview() {
  const dispatch = useDispatch();
  const formData = useSelector(getFiltersForSelectedCourse);
  const optionCount = useSelector(getSelectedCourseFilteredOptions);

  const error = null;

  const distanceLabel = !formData
    ? 'No distance selected'
    : !formData.zipcode
    ? `No zipcode found`
    : `${formData.radius}mi from ${formData.zipcode}`;

  const start =
    formData?.startdate ||
    getMomentDate(new Date().toISOString()).format(DATE_FORMAT);
  const end =
    formData?.enddate ||
    getMomentDate(start).add(10, 'days').format(DATE_FORMAT);

  return (
    <>
      <CCTitleSection
        leftControl={
          <CCDialogCloseButton
            onClick={() => {
              dispatch(
                setDialogContentKey(config.DIALOG_CONTENT_KEYS.COURSE_OPTIONS)
              );
            }}
          />
        }
      >
        Filters
      </CCTitleSection>
      <CCDialogContent>
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}

        {/* Filter segwary group */}
        <Grid container direction="column" spacing={1}>
          <Heading4 gutterBottom>Distance</Heading4>
          <ButtonOutlined
            rounded
            color="secondary"
            focused={formData?.radius && formData?.zipcode}
            onClick={() => {
              dispatch(
                setDialogContentKey(config.DIALOG_CONTENT_KEYS.DISTANCE)
              );
            }}
            endIcon={<ArrowDropDown color="secondary" />}
          >
            {distanceLabel}
          </ButtonOutlined>
          <br />

          <Heading4 gutterBottom>Dates</Heading4>
          <ButtonOutlined
            rounded
            color="secondary"
            focused={formData?.startdate || formData?.enddate}
            onClick={() => {
              dispatch(setDialogContentKey(config.DIALOG_CONTENT_KEYS.DATES));
            }}
            endIcon={<ArrowDropDown color="secondary" />}
          >
            {`${start} - ${end}`}
          </ButtonOutlined>
        </Grid>
      </CCDialogContent>
      <CCFooterSection
        leftControl={
          <DSLButton
            onClick={() => {
              dispatch(
                setDialogContentKey(config.DIALOG_CONTENT_KEYS.COURSE_OPTIONS)
              );
            }}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            Cancel
          </DSLButton>
        }
        rightControl={
          <DSLButton
            onClick={() => {
              dispatch(
                setDialogContentKey(config.DIALOG_CONTENT_KEYS.COURSE_OPTIONS)
              );
            }}
            fullWidth
            variant="contained"
            color="secondary"
          >
            {`Show ${optionCount || ''} option(s)`}
          </DSLButton>
        }
      />
    </>
  );
}

export default React.memo(CCFiltersOverview);
