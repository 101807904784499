import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BrokenImageRounded } from '@material-ui/icons';
import React from 'react';
import config from 'src/store/config';
import {
  CalendarGrey,
  InpersonRed,
  LanguageGrey,
  OnlineGreen,
  WebinarNavy,
} from 'src/views/components/DSL/Icons';

/* 
  Fuzzy search or wildcard map to show proper icons for each 
  modality type.
*/
const MODALITY_ICON = {
  [config.MODALITY_TYPES.ILT]: <InpersonRed />,
  'in-person': <InpersonRed />,
  INPERSON: <InpersonRed />,

  [config.MODALITY_TYPES.ONLINE]: <OnlineGreen />,
  [config.MODALITY_TYPES.ONL]: <OnlineGreen />,
  online: <OnlineGreen />,

  [config.MODALITY_TYPES.WEBINAR]: <WebinarNavy />,
  [config.MODALITY_TYPES.WEB]: <WebinarNavy />,
  webinar: <WebinarNavy />,
  /* 
    FIXME: haven't found official aggreed upon type
    for iltweb (mixed modality), include it in types
    file if it is to be supported
  */
};

const MODALITY_TITLE = {
  [config.MODALITY_TYPES.ILT]: 'In-Person',
  'in-person': 'In-Person',
  INPERSON: 'In-Person',

  [config.MODALITY_TYPES.ONLINE]: 'Online',
  [config.MODALITY_TYPES.ONL]: 'Online',
  online: 'Online',

  [config.MODALITY_TYPES.WEBINAR]: 'Webinar',
  [config.MODALITY_TYPES.WEB]: 'Webinar',
  webinar: 'Webinar',
  /* 
    FIXME: haven't found official aggreed upon type
    for iltweb (mixed modality), include it in types
    file if it is to be supported
  */
};

const MODALITY_SUBTITLE = {
  [config.MODALITY_TYPES.ONLINE]: 'Learn at your own pace',
  [config.MODALITY_TYPES.ONL]: 'Learn at your own pace',
  online: 'Learn at your own pace',

  [config.MODALITY_TYPES.WEBINAR]: 'Webinar',
  [config.MODALITY_TYPES.WEB]: 'Webinar',
  webinar: 'Webinar',
  /* 
    FIXME: haven't found official aggreed upon type
    for iltweb (mixed modality), include it in types
    file if it is to be supported
  */
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    avatar: {
      backgroundColor: '#e2e5e9',
    },
  })
);

function ModalityBanner({ title = '', subtitle = '', icon }) {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
}

export default function ECOptionDetails(props: any) {
  const classes = useStyles();

  const isOnline =
    props?.type?.toUpperCase() !== config.MODALITY_TYPES.ONL ||
    props?.type?.toUpperCase() !== config.MODALITY_TYPES.ONLINE ||
    props?.type?.toUpperCase() !== config.MODALITY_TYPES.online;

  return (
    <List className={classes.root}>
      {/* Modality Icon */}
      <ModalityBanner
        icon={
          MODALITY_ICON[props?.type] || <BrokenImageRounded fontSize="small" />
        }
        title={MODALITY_TITLE[props?.type] || props?.type || 'Unknown modality'}
      />

      {/* Cadence Type & Schedule */}
      {isOnline ? (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <CalendarGrey />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Take at your own pace" />
          {/* TODO: Insert Schedule Here */}
        </ListItem>
      ) : null}

      {/* Language and Locale */}
      {!props?.lang && !props?.language ? null : (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <LanguageGrey compact />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              config.LANGS_MAP_ISO_TOSTRING[props.lang?.toLowerCase()] ||
              config.LANGS_MAP_ISO_TOSTRING[props.language?.toLowerCase()]
            }
          />
        </ListItem>
      )}
    </List>
  );
}
