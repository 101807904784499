import { Grid } from '@material-ui/core';
import React from 'react';
import { Back, ButtonPrimary } from 'src/views/components/DSL/buttons';
import { DownloadBlue } from 'src/views/components/DSL/Icons';
import { Heading3 } from 'src/views/components/DSL/typography';
import { PageHeader } from 'src/views/components/Layouts';

interface SPageHeaderProps {
  downlaod: () => Promise<void>;
  disabled: boolean;
}

export default function SPageHeader({
  downlaod,
  disabled = true,
}: SPageHeaderProps) {
  return (
    <PageHeader>
      <Back to="/mycourses/enrolled">Enrolled Courses</Back>

      <Grid container alignItems="center" justify="space-between">
        <Grid item zeroMinWidth>
          <Heading3 gutterBottom>Schedule</Heading3>
        </Grid>
        <Grid item alignItems="center">
          <ButtonPrimary
            disabled={disabled}
            onClick={downlaod}
            startIcon={<DownloadBlue fontSize="small" />}
          >
            Download
          </ButtonPrimary>
        </Grid>
      </Grid>
    </PageHeader>
  );
}
