import React from 'react';
import { IconProps } from './types';
import webinar_blue from 'src/views/styles/assets/icons/webinar_blue@2x.png';

const WebinarBlue = ({ compact = false }: IconProps) => {
  return (
    <img
      src={webinar_blue}
      alt="Webinar Course Symbol"
      style={{ maxWidth: compact ? 12 : '100%' }}
    />
  );
};

export default WebinarBlue;
