import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import { useRouter } from 'src/utils';
import { HealthLogo } from 'src/views/components/DSL/Icons';
import GeneralPreview from './GeneralPreview';

function HomePreview() {
  const router = useRouter();
  return (
    <GeneralPreview
      title="Health"
      subheader="Learn about the health insurance benefits and free programs, and access My Plan"
      avatar={<HealthLogo size="medium" />}
    />
  );
}

export default HomePreview;
