import React from 'react';

function PhoneDefault(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 15.99" width="1em" height="1em" {...props}>
      <defs>
        <clipPath id="prefix__a" transform="translate(-5 -5)">
          <path fill="none" d="M0 0h26v26H0z" />
        </clipPath>
      </defs>
      <g data-name="Layer 2">
        <g clipPath="url(#prefix__a)" data-name="Layer 1">
          <path
            d="M16 1.5a14.29 14.29 0 01-2 7.31A14.59 14.59 0 018.8 14a14.18 14.18 0 01-7.31 2 .76.76 0 01-.49-.16.59.59 0 01-.25-.44L0 12.15a.74.74 0 01.06-.5.7.7 0 01.38-.34L4 9.81a.74.74 0 01.47 0 .62.62 0 01.4.25l1.54 1.88a10.86 10.86 0 003.26-2.27 11.69 11.69 0 002.27-3.25L10 4.81a.79.79 0 01-.25-.4.7.7 0 010-.47l1.5-3.5a.59.59 0 01.34-.35.74.74 0 01.5-.07l3.25.75a.62.62 0 01.44.25.86.86 0 01.22.48z"
            fill="#7c838c"
          />
        </g>
      </g>
    </svg>
  );
}

export default React.memo(PhoneDefault);
