import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { US_STATES } from 'src/consts';
import { InputText } from 'src/views/components/DSL/form';
import { Descriptor } from 'src/views/components/DSL/typography';

export default function EditAddress() {
  const states = US_STATES;
  const inputStyles = {
    padding: '.25rem',
  };

  return (
    <>
      <InputText label="Street address" />
      <InputText label="Apartment, suite, unit, etc. (optional)" />
      <Grid container direction="row">
        <Grid item xs={12} md={6} style={inputStyles}>
          <InputText label="City" />
        </Grid>
        <Grid item xs={6} md={3} style={{ ...inputStyles, marginTop: '-11px' }}>
          {/* <InputText label="State" /> */}
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Descriptor style={{ paddingBottom: '.5rem' }}>State</Descriptor>
            <Select
              displayEmpty
              style={{
                border: '1px solid #fff',
                borderRadius: '5px',
                marginTop: '-10px',
              }}
            >
              {states.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3} style={inputStyles}>
          <InputText label="Zip" />
        </Grid>
      </Grid>
    </>
  );
}
