/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Hidden } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';
import { authSelectors } from 'src/store/authentication';
import config from 'src/store/config';
import { courseCatalogSelectors } from 'src/store/coursecatalog';
import { dialogActionCreators } from 'src/store/dialog';
import { popoverActionCreators } from 'src/store/popover';
import { ButtonOutlined } from 'src/views/components/DSL/buttons';
import { FilterDialsBlue } from 'src/views/components/DSL/Icons';
import { useDispatch, useSelector } from 'src/views/providers';
import CCSearchFilter from './filters/CCSearchFilter';

function CCGeneralFilterOpeners({ setFilterAnchorEl }) {
  /* GLOBAL STATE */
  const dispatch = useDispatch();

  const selectedLanguage = useSelector(
    courseCatalogSelectors.getSelectedLanguagesLabel
  );
  const defaultLanguage = useSelector(authSelectors.getPreferredLanguage);

  /* LOCAL STATE */
  const [expanded] = useState(false);

  /* CHANGE HANDLERS */

  const onRequestSegway = ({ contentKey, segwayType, event }) => {
    /**
     * - detect segwayType
     * - set content key for that (dialog.contentKey or popover.contentKey)
     * - set anchorEl so popover can position beneath it
     */
    if (segwayType === config.SEGWAY_TYPES.POPOVER) {
      setFilterAnchorEl(event?.currentTarget);
      dispatch(popoverActionCreators.setPopoverContentKey(contentKey));
      dispatch(popoverActionCreators.openPopover());
    } else if (segwayType === config.SEGWAY_TYPES.DIALOG) {
      dispatch(dialogActionCreators.setDialogContentKey(contentKey));
      dispatch(dialogActionCreators.openDialog());
    }
  };

  return (
    <Grid container alignItems="center" spacing={2} style={{ padding: 16 }}>
      <Hidden smUp>
        <Grid container>
          <CCSearchFilter />
          <Grid item style={{ marginLeft: 8 }}>
            {!expanded && (
              <ButtonOutlined
                fullWidth
                rounded
                color="secondary"
                startIcon={<FilterDialsBlue compact color="inherit" />}
                style={{ backgroundColor: 'white' }}
                onClick={(event) => {
                  onRequestSegway({
                    contentKey:
                      config.DIALOG_CONTENT_KEYS.GENERAL_FILTERS_OVERVIEW,
                    segwayType: config.SEGWAY_TYPES.DIALOG,
                    event,
                  });
                }}
              >
                Filters
              </ButtonOutlined>
            )}
          </Grid>
        </Grid>
      </Hidden>

      <Hidden xsDown>
        <CCSearchFilter />
        <Grid item>
          <ButtonOutlined
            rounded
            fullWidth
            gutterRight
            focused={selectedLanguage?.length > 0}
            onClick={(event) => {
              onRequestSegway({
                contentKey:
                  config.POPOVER_CONTENT_KEYS.GENERAL_FILTERS_LANGUAGE,
                segwayType: config.SEGWAY_TYPES.POPOVER,
                event,
              });
            }}
            style={{ backgroundColor: '#fff' }}
            endIcon={<ArrowDropDown color="secondary" fontSize="small" />}
          >
            {selectedLanguage || defaultLanguage || 'Language'}
          </ButtonOutlined>
        </Grid>
        {/* <Grid item>
          <CCCourseTypeFilter />
        </Grid> */}
      </Hidden>
    </Grid>
  );
}

export default CCGeneralFilterOpeners;
