import React from 'react';
import moment from 'moment';
import {
  Grid,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@material-ui/core';
import {
  CalendarGrey,
  LanguageGrey,
  MapMarkerGrey,
  ChevronDownBlue,
} from 'src/views/components/DSL/Icons';
import { Descriptor, Subheading, Heading3, Overline } from '../typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddressMap from 'src/views/components/DSL/AddressMap';
import {
  findLangFromID,
  VARIANT,
} from 'src/views/pages/Enrollment/components/hooks/helpers';

interface ScheduleItemProps {
  session: any;
  allSessions?: any;
  variant: VARIANT;
  index?: number;
  children?: React.ReactNode;
}

/* a.h. dateVariable{M} -> M stands for moment */
function ScheduleItem({
  variant,
  allSessions,
  session,
  index,
  children,
}: ScheduleItemProps) {
  // console.log('ScheduleItem: ', allSessions, session, index);
  let content = null;
  const startTimeM = moment(session?.begins);
  const endTimeM = moment(session?.ends);
  const daytime = `${startTimeM.format('dddd')}, ${startTimeM.format(
    'h:mm a'
  )} - ${endTimeM.format('h:mm a')}`;
  const tr =
    session?.course?.idnumber.substring(0, 3) || session?.text?.substring(0, 3); // get the training vertical code

  const sessionCount = allSessions.length;
  // console.log(session, tr);
  switch (variant) {
    /* non_dynamic_detailed */
    case VARIANT.non_dynamic_detailed:
      content = (
        <Grid container direction="row">
          <Grid
            item
            xs={2}
            sm={1}
            container
            direction="column"
            alignItems="flex-start"
          >
            <>
              {index !== 0 &&
              index !== sessionCount &&
              moment(allSessions[index].begins).format('YYYY-MM-DD') ===
                moment(allSessions[index - 1].begins).format('YYYY-MM-DD') ? (
                ''
              ) : (
                <>
                  <Overline color="primary">
                    {startTimeM.format('MMM')}
                  </Overline>
                  <Heading3>{startTimeM.format('DD')}</Heading3>
                </>
              )}
            </>
          </Grid>

          <Grid
            item
            xs={10}
            sm={11}
            container
            alignItems="center"
            style={{ borderBottom: '1px solid #e2e5e1', paddingBottom: 5 }}
          >
            <Grid container alignItems="center">
              <Box overflow="hidden" textOverflow="ellipsis" maxWidth="100%">
                <Subheading style={{ color: '#5f6772', marginRight: 5 }} noWrap>
                  {tr === '201' && index === 0
                    ? ''
                    : tr === '603'
                    ? `Class ${index + 1}`
                    : `Class ${index}`}
                  <strong> {session.description}</strong>
                </Subheading>
              </Box>
            </Grid>
            <Box overflow="hidden" textOverflow="ellipsis" maxWidth="100%">
              <Descriptor noWrap>
                {daytime} ·
                <span
                  style={{
                    color:
                      session.institution === 'Live Webinar' && tr === '201'
                        ? '#aa5600'
                        : '#5f6772',
                  }}
                >
                  {' '}
                  {session.institution}
                </span>
              </Descriptor>
            </Box>
          </Grid>
        </Grid>
      );
      break;
    /* end of non_dynamic_detailed */
    /* dynamic_expandable */
    case VARIANT.dynamic_expandable:
      content = (
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ChevronDownBlue />}>
            <Grid
              container
              direction="row"
              style={{
                // borderRadius: '8px',
                padding: '0px',
              }}
            >
              <Grid
                item
                xs={2}
                sm={1}
                container
                direction="column"
                alignItems="flex-start"
              >
                <Overline color="primary">{startTimeM.format('MMM')}</Overline>
                <Heading3>{startTimeM.format('DD')}</Heading3>
              </Grid>

              <Grid item xs={10} sm={11} container alignItems="center">
                <Grid container alignItems="center">
                  <Subheading>{session.description}</Subheading>
                </Grid>
                <Descriptor>
                  {daytime} · {session.institution}
                </Descriptor>
              </Grid>
            </Grid>
          </AccordionSummary>
          <Divider />
          <AccordionDetails style={{ padding: 0 }}>
            <List dense style={{ padding: 0, width: '100%' }}>
              <ListItem dense>
                <IconButton size="small" style={{ paddingRight: '16px' }}>
                  <CalendarGrey />
                </IconButton>
                <ListItemText
                  primary={startTimeM.format('MMM D, YYYY')}
                  secondary={daytime}
                />
              </ListItem>
              <ListItem
                dense
                onClick={() => (
                  <AddressMap
                    address={session.address}
                    institution={session.institution}
                  />
                )}
              >
                <IconButton size="small" style={{ paddingRight: '16px' }}>
                  <MapMarkerGrey />
                </IconButton>
                <ListItemText
                  primary={
                    <>
                      {session.institution}
                      <br />
                      {session.zoomlink}
                      <AddressMap
                        address={session.address}
                        institution={session.institution}
                        // room={session.zoomlink}
                      />
                    </>
                  }
                  secondary={session.address.replace(/,/g, ', ')}
                />
              </ListItem>
              <ListItem dense>
                <IconButton size="small" style={{ paddingRight: '16px' }}>
                  <LanguageGrey compact />
                </IconButton>
                <ListItemText
                  primary={findLangFromID(session.idnumber || session.text)}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      );
      break;
    /* end of dynamic_expandable */
    default:
      content = (
        <Grid container direction="row">
          <Descriptor>No Schedule To Show</Descriptor>
        </Grid>
      );
  }

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{
        padding: variant === VARIANT.dynamic_expandable ? 5 : 10,
      }}
    >
      {content}
      {children}
    </Grid>
  );
}

export default ScheduleItem;
