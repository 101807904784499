import defaultState from '../defaultState';
import { makeActionCreator } from '../makeActionCreator';

/**
 * TYPES
 */
const errorActionTypes = {
  SET_ERROR: 'global/SET_ERROR',
  CLEAR_ERROR: 'global/CLEAR_ERROR',
};

/**
 * ACTION CREATORS
 */
const setError = makeActionCreator(errorActionTypes.CLEAR_ERROR, 'error');
const clearError = makeActionCreator(errorActionTypes.CLEAR_ERROR);

const errorActionCreators = {
  setError,
  clearError,
};

/**
 * REDUCERS
 *
 * @param {Object} state
 * @param {Object} action
 */
const errorReducer = (state = defaultState.error, action) => {
  switch (action.type) {
    case errorActionTypes.SET_ERROR:
      return {
        ...state,
        ...action.error,
      };
    case errorActionTypes.CLEAR_ERROR:
      return defaultState.error;
    default:
      return state;
  }
};

/* SELECTORS */
const errorSelectors = {
  getGlobalError: (state) => state.error,
};

export { errorReducer, errorActionTypes, errorActionCreators, errorSelectors };
